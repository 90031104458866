/**
 * Component for displaying products in the Return.
 *
 * @component
 */

import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Icon,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  Collapse,
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import UpdateResolution from './UpdateResolution';

const { Option } = Select;
const { Panel } = Collapse;

class ReturnDetailsProduct extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      loadingRestockSingle: false,
      loadingUpdateProduct: false,
      quantity: {},
      pendingInstantExchangeApprovals: {},
      isModalOpen: false,
      restockLocationId: undefined,
      locations: undefined,
      restockMultipleLocation: false,
      updateReturnResolution: false,
      resolutionOptions: [],
      showResolutionOptions: false,
      updatedResolutions: [],
      currentProductPage: 1,
      productPageSize: 1,
      returnId: undefined,
      lineItems: undefined,
      shippingAmount: 0.0,
      shippingRefundFull: false,
      shippingCurrency: undefined,
      refundTotal: undefined,
      restockType: 'no_restock',
      restockLocationId: undefined,
      note: null,
      notify: false,
      currency: undefined,
      refundRequest: undefined,
      customRefundTotal: undefined,
      refundTotalAfterExchange: undefined,
      refundTotalBeforeFees: undefined,
    };
  }

  handleProductPageChange = (page) => {
    this.setState({ currentProductPage: page });
  };

  incrementProduct = (quantity, variant) => {
    if (this.state.quantity[variant] >= quantity) {
      let newQuantity = this.state.quantity;
      newQuantity[variant] = quantity;
      this.setState({
        quantity: newQuantity,
      });
    } else {
      let newQuantity = this.state.quantity;
      newQuantity[variant] = newQuantity[variant] + 1;
      this.setState({
        quantity: newQuantity,
      });
    }
  };

  decrementProduct = (variant) => {
    if (this.state.quantity[variant] <= 1) {
      let newQuantity = this.state.quantity;
      newQuantity[variant] = 1;
      this.setState({
        quantity: newQuantity,
      });
    } else {
      let newQuantity = this.state.quantity;
      newQuantity[variant] = newQuantity[variant] - 1;
      this.setState({
        quantity: newQuantity,
      });
    }
  };

  incrementInstantExchange = (pendingInstantExchangeApprovals, variant) => {
    if (this.state.pendingInstantExchangeApprovals[variant] >= pendingInstantExchangeApprovals) {
      let newInstantExchangeQuantity = this.state.pendingInstantExchangeApprovals;
      newInstantExchangeQuantity[variant] = pendingInstantExchangeApprovals;
      this.setState({
        pendingInstantExchangeApprovals: newInstantExchangeQuantity,
      });
    } else {
      let newInstantExchangeQuantity = this.state.pendingInstantExchangeApprovals;
      newInstantExchangeQuantity[variant] = newInstantExchangeQuantity[variant] + 1;
      this.setState({
        pendingInstantExchangeApprovals: newInstantExchangeQuantity,
      });
    }
  };

  decrementInstantExchange = (variant) => {
    if (this.state.pendingInstantExchangeApprovals[variant] <= 1) {
      let newInstantExchangeQuantity = this.state.pendingInstantExchangeApprovals;
      newInstantExchangeQuantity[variant] = 1;
      this.setState({
        pendingInstantExchangeApprovals: newInstantExchangeQuantity,
      });
    } else {
      let newInstantExchangeQuantity = this.state.pendingInstantExchangeApprovals;
      newInstantExchangeQuantity[variant] = newInstantExchangeQuantity[variant] - 1;
      this.setState({
        pendingInstantExchangeApprovals: newInstantExchangeQuantity,
      });
    }
  };

  getResoulutions = (returnSettings) => {
    let resolutionOptions = {
      refundToOriginal: 'Refund',
      refundToExchange: 'Exchange',
      refundToCredit: 'Store credit',
    };
    for (let i = 1; i <= 3; i++) {
      const customKey = `refundToCustom${i}`;
      const customLabelKey = `refundToCustom${i}Label`;
      if (returnSettings[customKey]) {
        resolutionOptions = {
          ...resolutionOptions,
          [customKey]: returnSettings[customLabelKey],
        };
      }
    }
    if (this.context.featureStoreWideExchange) {
      resolutionOptions = {
        ...resolutionOptions,
        storeWideExchange: 'Store-Wide Exchange',
      };
    }
    if (this.context.featureAdvancedExchanges) {
      resolutionOptions = {
        ...resolutionOptions,
        advancedExchange: 'Collection Exchange',
      };
    }
    const options = Object.entries(resolutionOptions).map(([key, value]) => (
      <Option key={key} value={key}>
        {value}
      </Option>
    ));
    return options;
  };

  componentDidMount = async () => {
    try {
      let newQuantity = {};
      let newInstantExchangeQuantity = {};
      this.props.productsObject.map((product) => {
        newQuantity[product.variantId] = product.quantity;
        newInstantExchangeQuantity[product.variantId] = product.pendingInstantExchangeApprovals;
        this.setState({
          quantity: newQuantity,
          pendingInstantExchangeApprovals: newInstantExchangeQuantity,
        });
      });
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        return;
      }
      const resolutionOptions = this.getResoulutions(response.data);
      this.setState({
        updateReturnResolution: response.data.updateReturnResolution,
        resolutionOptions,
      });
    } catch (err) {
      message.error('Error retrieving your settings. Try again.', 5);
    }

    try {
      const { ReturnId } = this.props.productsObject[0];
      const response = await axios.post('/api/refunds', {
        returnId: ReturnId,
        isStoreCredit: false,
        isRefund: true, 
      });

      if (response.data.status === 'error') {
        message.error(
          'Error preparing Instant Exchange interface. Please try again or contact us.',
          8
        );
        return;
      }

      const {
        refundSettingsRefundShippingCosts,
        refundSettingsRestock,
        refundSettingsRestockLocationId,
        refundSettingsRestockLocationName,
        refundSettingsNotificationsCheck,
      } = response.data.refundSettings;

      this.setState(
        {
          return: response.data.return,
          products: response.data.return.Products,
          lineItems: response.data.lineItems,
          lineItemsOriginal: response.data.lineItemsOriginal,
          currency: response.data.currency,
          labelFee: response.data.labelFee,
          restockingFee: response.data.restockingFee,
          restockingFeeType: response.data.return.restockingFeeType,
          shippingRefundFull: refundSettingsRefundShippingCosts,
          restockEnabled: refundSettingsRestock,
          restockType: refundSettingsRestock ? 'return' : 'no_restock',
          restockLocationId: refundSettingsRestock
            ? parseInt(refundSettingsRestockLocationId)
            : undefined,
          restockLocationName: refundSettingsRestock
            ? refundSettingsRestockLocationName
            : undefined,
          locations: refundSettingsRestock
            ? [
              {
                id: parseInt(refundSettingsRestockLocationId),
                name: refundSettingsRestockLocationName,
              },
            ]
            : undefined,
          notify: refundSettingsNotificationsCheck,
        },
        () => {
          this.calculateInstantExchangeRefund();
        }
      );
    } catch (err) {
      message.error('Error finding return. Please try again.', 5);
    }
  };

  componentWillReceiveProps = (props) => {
    let newQuantity = {};
    let newInstantExchangeQuantity = {};
    props.productsObject.map((product) => {
      newQuantity[product.variantId] = product.quantity;
      newInstantExchangeQuantity[product.variantId] = product.pendingInstantExchangeApprovals;
      this.setState({
        quantity: newQuantity,
        pendingInstantExchangeApprovals: newInstantExchangeQuantity,
      });
    });
  };

  restockItem = async (lineItem, restockId) => {
    try {
      this.setState({
        loadingRestockSingle: true,
      });
      const data = {
        lineItem,
      };
      if (restockId) {
        data.restockId = restockId;
      }
      const response = await axios.post('/api/returns/restock', data);

      if (response.data.status === 'error') {
        this.setState({
          loadingRestockSingle: false,
        });
        return message.error(response.data.error, 10);
      }

      const returns = response.data.returns;
      const productsOfReturns = response.data.productsOfReturns;

      this.props.updateData({
        returns,
        productsOfReturns,
      });

      this.props.setVisibilityOfResetSearchButton(true);
      this.props.handleGetReturn();
      this.setState({
        loadingRestockSingle: false,
      });
      this.setState({
        isModalOpen: false,
        loadingRestockSingle: false,
      });
      return message.success('Item(s) restocked successfully.', 5);
    } catch (err) {
      this.setState({
        loadingRestockSingle: false,
      });
      return message.error('Error restocking item(s). Try again.', 4);
    }
  };

  handleUpdateProduct = async (productId, returnId, variantId) => {
    const newQuantity = this.state.quantity[variantId];
    this.props.handleLoadingState(true);
    this.setState({
      loadingUpdateProduct: true,
    });
    try {
      const response = await axios.post('/api/returns/updateProduct', {
        productId,
        returnId,
        newQuantity,
      });
      this.setState({
        loadingUpdateProduct: false,
      });
      this.props.handleLoadingState(false);
      if (response.data.error) {
        return  message.error(response.data.message, 4);
      }
      return this.props.handleUpdate();
    } catch (err) {
      this.setState({
        loadingUpdateProduct: true,
      });
      this.props.handleLoadingState(false);
      return message.error('Something went wrong. Please try again.', 4);
    }
  };

  handleUpdateInstantExchange = async (productId, returnId, variantId) => {
    const newInstantExchangeQuantity = this.state.pendingInstantExchangeApprovals[variantId];

    try {
      const response = await axios.post('/api/returns/updateInstantExchange', {
        productId,
        returnId,
        newInstantExchangeQuantity,
      });

      if (response.data.error) {
        return message.error(response.data.message, 4);
      }

      const { customRefundTotal } = response.data;
  
      this.setState({
        customRefundTotal: customRefundTotal || 0,
        refundTotal: customRefundTotal || 0,
        refundTotalAfterExchange: customRefundTotal || 0,
      });

      this.issueInstantExchangeRefund();
    } catch (err) {
      return message.error('Something went wrong Please try again.', 4);
    }
  };

  calculateInstantExchangeRefund = async () => {
    const returnId = this.props.productsObject[0].ReturnId;
    const requestedLineItems = this.state.lineItems;
    const {
      shippingAmount,
      shippingRefundFull,
      refundTotal,
    } = this.state;
    // calculate refund
    try {
      const response = await axios.post('/api/refunds/calculate', {
        returnId,
        isStoreCredit: false,
        requestedLineItems,
        shippingAmount,
        shippingRefundFull,
        refundTotal,
      });
  
      let disableRefundButton = false;
      const invalidRefundTotal =
        response.data.refundTotal == null ||
        parseFloat(response.data.refundTotal) === 0.0;
  
      if (invalidRefundTotal) {
        disableRefundButton = true;
  
        this.setState({
          ...response.data,
          restockEnabled: false,
          restockType: 'no_restock',
          restockLocationId: null,
          initialLoading: false,
          disableRefundButton,
          customRefundTotal: undefined,
          shippingCostsKey: Date.now(),
          refundTotalKey: Date.now(),
          exchangeTotal: response.data?.totalexchangeOrderPrice,
        });
      } else {
        if (
          parseFloat(response.data.refundTotal) <=
          parseFloat(response.data.totalexchangeOrderPrice)
        ) {
          disableRefundButton = true;
        }
  
        this.setState({
          ...response.data,
          initialLoading: false,
          disableRefundButton,
          customRefundTotal: undefined,
          shippingCostsKey: Date.now(),
          refundTotalKey: Date.now(),
          exchangeTotal: response.data.totalexchangeOrderPrice,
        });
      }
    } catch (err) {
      message.error('Error finding return. Please try again.', 5);
    }
  };

  issueInstantExchangeRefund = async () => {
    const returnId = this.props.productsObject[0].ReturnId;
    const isStoreCredit = false;
    const {
      lineItems, 
      shippingRefundFull, 
      shippingAmount,
      shippingCurrency, 
      restockType, 
      restockLocationId, 
      note, 
      notify, 
      currency, 
      refundRequest, 
      customRefundTotal,
      refundTotal,
      refundTotalBeforeFees,
      refundTotalAfterExchange,
    } = this.state;
    
    // check for missing data
    const missingRestockLocation =
      restockType === 'return' && restockLocationId == null;
    if (missingRestockLocation) {
      return message.error(
        'Please select a restock location first. You can also reload restock locations or disable restocking.',
        8
      );
    }
  
    this.setState({
      submissionActive: true,
    });
  
    try {
      const response = await axios.post('/api/refunds/issue', {
        isStoreCredit,
        returnId,
        lineItems,
        shippingAmount,
        shippingRefundFull,
        shippingCurrency,
        customRefundTotal,
        refundTotal,
        refundTotalBeforeFees,
        restockType,
        restockLocationId,
        note,
        notify,
        currency,
        refundRequest,
        refundTotalAfterExchange,
      });
  
      if (response.data.status === 'error') {
        this.setState({
          errorRefund: true,
          submissionActive: false,
        });
        return message.error('Error issuing refund. Please try again.', 5);
      }
  
      this.setState(
        {
          submissionActive: false,
          successRefund: true,
        },
        () => {
          message.success('Refund issued successfully.', 5);
          this.props.handleGetReturn();
        }
      );
    } catch (err) {
      this.setState({
        submissionActive: false,
      });
      message.error('Error issuing refund. Please try again.', 5);
    }
  }

  queryLocations = async () => {
    try {
      const response = await axios.get('/api/refunds/locations');

      if (response.data.status === 'error') {
        return message.error('Error finding locations. Please try again.', 5);
      }

      const { locations } = response.data;
      let restockLocationId = undefined;
      if (this.state.restockLocationId) {
        restockLocationId = this.state.restockLocationId;
      } else if (locations != null && locations.length === 1) {
        restockLocationId = locations[0].id;
      }

      return this.setState({
        ...response.data,
        restockLocationId,
      });
    } catch (err) {
      message.error('Error finding locations. Please try again.', 5);
    }
  };
  updateLocationId = (e) => {
    const restockLocationId = e;
    this.setState({
      restockLocationId,
    });
  };

  showModal = () => {
    this.setState({
      loadingRestockSingle: true,
    });
    this.queryLocations();
    this.setState({
      isModalOpen: true,
    });
  };
  handleOk = (item) => {
    let restockLocationId = this.state.restockLocationId;
    if (item && restockLocationId) {
      this.restockItem(item, restockLocationId);
    }
  };
  handleCancel = () => {
    this.setState({
      isModalOpen: false,
      loadingRestockSingle: false,
    });
  };

  onChangeRestockType = (e) => {
    const restockMultipleLocation = e.target.checked;
    this.setState({
      restockMultipleLocation,
    });
  };

  updateResolution = (data) => {
    this.setState({
      ...this.state.updatedResolutions,
      ...data,
    });
  };

  closeResolutionOptions = () => {
    this.setState({
      showResolutionOptions: false,
    });
  };

  handleProductWeight = async (productId) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.get(
        `/api/returns/updateWeight/${productId}`
      );
      await this.props.handleGetReturn();
      return message.success('Weight updated successfully.', 4);
    } catch (err) {
      message.error('Error refreshing weight of product. Try again.', 5);
    }
  };

  getKeysTrue(data) {
    const keys = Object.keys(data);
    const trueKeys = keys.filter((key) => data[key] === true);
    return trueKeys.join(', ');
  }

  render() {
    const { productsObject } = this.props;

    return (
      <React.Fragment>
        {/* <Row type="flex" justify="start" align="top">
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={this.props.productsObject}
              renderItem={(item) => (
                <List.Item
                  key={`${item.ReturnId}-${item.variantId}-${item.id}`}
                >
                  <Col span={6}>
                    <Row align="middle">
                      <img
                        width="80%"
                        alt="Product Image"
                        src={item.imageUrl}
                        style={{ marginTop: 30 }}
                      />
                    </Row>
                  </Col>
                  <Col span={18}>
                    <Descriptions
                      title={`${item.quantity}x ${item.productName} (${item.variantName})`}
                      column={1}
                      size="middle"
                      bordered={true}
                    >
                      <Descriptions.Item
                        label={
                          <React.Fragment>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                border: '2px solid #808080',
                              }}
                            >
                              {this.state.quantity[item.variantId] === 1 ? (
                                <Button
                                  style={{ height: 'auto' }}
                                  disabled={true}
                                  type="link"
                                  size="large"
                                >
                                  -
                                </Button>
                              ) : (
                                <Button
                                  style={{ height: 'auto' }}
                                  type="link"
                                  size="large"
                                  onClick={() => this.decrementProduct(item.variantId)}
                                >
                                  -
                                </Button>
                              )}
                              <span style={{ padding: '15px' }}>
                                {this.state.quantity[item.variantId]}
                              </span>
                              {this.state.quantity[item.variantId] ===
                              item.quantity ? (
                                <Button
                                  style={{ height: 'auto' }}
                                  disabled={true}
                                  type="link"
                                  size="large"
                                >
                                  +
                                </Button>
                              ) : (
                                <Button
                                  style={{ height: 'auto' }}
                                  type="link"
                                  size="large"
                                  onClick={() =>
                                    this.incrementProduct(
                                      item.quantity,
                                      item.variantId
                                    )
                                  }
                                >
                                  +
                                </Button>
                              )}
                            </div>
                          </React.Fragment>
                        }
                      >
                        {this.props.productsObject.length == 1 ? (
                          item.quantity !=
                          this.state.quantity[item.variantId] ? (
                            <Popconfirm
                              title={
                                <>
                                  <div>
                                    Are you sure you want to delete this
                                    product?
                                  </div>
                                  <div>
                                    You cannot undo your actions. Proceed with
                                    caution.
                                  </div>
                                </>
                              }
                              onConfirm={() =>
                                this.handleUpdateProduct(
                                  item.id,
                                  item.ReturnId,
                                  item.variantId,
                                  item.quantity
                                )
                              }
                              okText="Yes"
                              cancelText="Cancel"
                            >
                              <Button type="link" size="large">
                                Remove
                              </Button>
                            </Popconfirm>
                          ) : (
                            <Button disabled={true} type="link" size="large">
                              Remove
                            </Button>
                          )
                        ) : (
                          <Popconfirm
                            title={
                              <>
                                <div>
                                  Are you sure you want to delete this product?
                                </div>
                                <div>
                                  You cannot undo your actions. Proceed with
                                  caution.
                                </div>
                              </>
                            }
                            onConfirm={() =>
                              this.handleUpdateProduct(
                                item.id,
                                item.ReturnId,
                                item.variantId,
                                item.quantity
                              )
                            }
                            okText="Yes"
                            cancelText="Cancel"
                          >
                            <Button type="link" size="large">
                              Remove
                            </Button>
                          </Popconfirm>
                        )}
                      </Descriptions.Item>
                      {this.props.featureExchangeOrders ? (
                        <Descriptions.Item
                          label={
                            item.restocked ? null : (
                              <React.Fragment>
                                <div style={{ display: 'flex' }}>
                                  {!this.state.restockMultipleLocation ? (
                                    <React.Fragment>
                                      <Popconfirm
                                        title={
                                          item.quantity > 1
                                            ? `Restock ${item.quantity} items?`
                                            : `Restock ${item.quantity} item?`
                                        }
                                        onConfirm={() => this.restockItem(item)}
                                        okText="Yes"
                                        cancelText="Cancel"
                                      >
                                        <Button
                                          size="small"
                                          icon="rollback"
                                          loading={
                                            this.state.loadingRestockSingle
                                          }
                                          disabled={
                                            this.state.loadingRestockSingle
                                          }
                                          style={{ marginRight: 10 }}
                                        >
                                          {item.quantity > 1
                                            ? `Restock ${item.quantity} items`
                                            : `Restock ${item.quantity} item`}
                                        </Button>
                                      </Popconfirm>
                                      <Tooltip
                                        // placement="top"
                                        title="This will restock the item(s) at their original location from the order. Only use if items have not been restocked otherwise, e.g. as part of a refund or exchange order. We cannot detect restocking activities that take place outside our platform."
                                      >
                                        <Icon
                                          style={{
                                            position: 'relative',
                                            top: '6px',
                                          }}
                                          type="info-circle"
                                        />
                                      </Tooltip>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Button
                                        size="small"
                                        icon="rollback"
                                        loading={
                                          this.state.loadingRestockSingle
                                        }
                                        disabled={
                                          this.state.loadingRestockSingle
                                        }
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.showModal(item)}
                                      >
                                        {item.quantity > 1
                                          ? `Restock ${item.quantity} items`
                                          : `Restock ${item.quantity} item`}
                                      </Button>
                                      <Tooltip title="You can now restock at any other available location, the product must be available there first otherwise product will be unable to restock">
                                        <Icon
                                          style={{
                                            position: 'relative',
                                            top: '6px',
                                          }}
                                          type="info-circle"
                                        />
                                      </Tooltip>
                                      {this.state.locations && (
                                        <Modal
                                          title="Restock Item"
                                          visible={this.state.isModalOpen}
                                          onOk={() => this.handleOk(item)}
                                          onCancel={this.handleCancel}
                                        >
                                          <span>Restock item: </span>
                                          <Select
                                            defaultValue={
                                              this.state &&
                                              this.state.restockLocationId !=
                                                null
                                                ? this.state.restockLocationId
                                                : undefined
                                            }
                                            style={{ width: 380 }}
                                            onChange={this.updateLocationId.bind(
                                              this
                                            )}
                                            placeholder="Select restock location"
                                          >
                                            {this.state.locations.map(
                                              (location) => {
                                                return (
                                                  <Option
                                                    key={location.id}
                                                    value={location.id}
                                                  >
                                                    {location.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </Modal>
                                      )}
                                    </React.Fragment>
                                  )}
                                </div>
                                <br></br>
                                <Checkbox
                                  onChange={this.onChangeRestockType.bind(this)}
                                  checked={this.state.restockMultipleLocation}
                                >
                                  Enable to restock on different location
                                </Checkbox>
                              </React.Fragment>
                            )
                          }
                        >
                          {item.restocked ? (
                            <Tag color="#1890ff">{`${item.restockedQuantity} ${
                              item.restockedQuantity > 1 ? 'items' : 'item'
                            } Restocked`}</Tag>
                          ) : null}
                          {item.exchanged ? (
                            <Tag color="#1890ff">{`${item.exchangedQuantity} ${
                              item.exchangedQuantity > 1 ? 'items' : 'item'
                            } Exchanged`}</Tag>
                          ) : null}

                          {item.refunded ? (
                            <Tag color="#1890ff">{`${item.refundedQuantity} ${
                              item.refundedQuantity > 1 ? 'items' : 'item'
                            } Refunded`}</Tag>
                          ) : null}

                          {item.storeCredited ? (
                            <Tag color="#1890ff">{`Store-Credit issued for ${
                              item.storeCreditedQuantity
                            } ${
                              item.storeCreditedQuantity > 1 ? 'items' : 'item'
                            }`}</Tag>
                          ) : null}
                        </Descriptions.Item>
                      ) : null}

                      {item.sku != null && item.sku !== '' ? (
                        <Descriptions.Item label="SKU">
                          {item.sku}
                        </Descriptions.Item>
                      ) : null}
                      <Descriptions.Item label="Stock Location Name">
                        {item.stockLocationName || '-'}
                      </Descriptions.Item>
                      {this.state.updateReturnResolution &&
                      this.context.featureUpdateResoultion &&
                      !item.exchanged &&
                      !item.refunded &&
                      !item.storeCredited &&
                      this.props.returnObject.returnStatus === 'Pending' ? (
                        <React.Fragment>
                          <Descriptions.Item label="Action">
                            <Button
                              onClick={() =>
                                this.setState({
                                  showResolutionOptions: true,
                                  selectedProduct: item,
                                })
                              }
                            >
                              {item.resolutionLabel
                                ? item.resolutionLabel === 'Store credit'
                                  ? 'Store Credit'
                                  : item.resolutionLabel
                                : this.props.returnObject.resolution &&
                                  this.props.returnObject.resolution.includes(
                                    'refundToCustom'
                                  )
                                ? this.props.returnObject.resolutionLabel
                                : this.props.resolutionOptions[
                                    this.props.returnObject.resolution
                                  ]}
                              <Icon type="edit" />
                            </Button>
                            {this.state.showResolutionOptions && (
                              <UpdateResolution
                                {...this.props}
                                item={this.state.selectedProduct}
                                visible={this.state.showResolutionOptions}
                                handleClose={this.closeResolutionOptions}
                              />
                            )}
                          </Descriptions.Item>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Descriptions.Item label="Action">
                            {item.resolutionLabel
                              ? item.resolutionLabel
                              : this.props.returnObject.resolution &&
                                this.props.returnObject.resolution.includes(
                                  'refundToCustom'
                                )
                              ? this.props.returnObject.resolutionLabel
                              : this.props.resolutionOptions[
                                  this.props.returnObject.resolution
                                ]}
                          </Descriptions.Item>
                        </React.Fragment>
                      )}
                      {item.exchangeItemVariantId ? (
                        <Descriptions.Item label="Exchange item">{`${
                          item.quantity
                        }x ${
                          item.exchangeItemProductName
                            ? item.exchangeItemProductName
                            : item.productName
                        } - ${item.exchangeItemVariantName} (${
                          item.exchangeItemPrice +
                          ' ' +
                          this.props.returnObject.currency
                        })`}</Descriptions.Item>
                      ) : null}
                      <Descriptions.Item label="Customer Notes">
                        {item.notes || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Reason">
                        {item.returnReason}
                      </Descriptions.Item>
                      <Descriptions.Item label="Weight per Item">
                        {item.weightInGrams || 0} grams /{' '}
                        {((item.weightInGrams || 0) / 28.35).toFixed(1)} ounces{' '}
                        <Tooltip
                          title={
                            <span>
                              This is the weight that Shopify stores for the
                              item on the original order. If changes were made
                              to the weight of the item in Shopify, please press
                              the button{' '}
                              <Icon
                                style={{
                                  fontSize: '16px',
                                  alignItems: 'center',
                                }}
                                type="reload"
                              />{' '}
                              to retrieve the latest weight.
                            </span>
                          }
                        >
                          <Icon
                            style={{ fontSize: '16px', alignItems: 'center' }}
                            type="info-circle"
                          />
                        </Tooltip>
                        <Button
                          onClick={() => this.handleProductWeight(item.id)}
                          style={{
                            border: 'none',
                            marginLeft: '5px',
                            alignItems: 'center',
                          }}
                          icon={'reload'}
                        />
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      title={
                        <div
                          span={24}
                          style={{ justifyContent: 'center', display: 'flex' }}
                        >
                          Additional questions:
                        </div>
                      }
                      column={1}
                      size="middle"
                      bordered={true}
                      style={{ paddingTop: 30 }}
                    >
                      {item?.extraNotes &&
                        Object.entries(item?.extraNotes).map(([key, value]) => (
                          <Descriptions.Item label={key}>
                            {value.type !== 'checkbox'
                              ? value.value.toString()
                              : this.getKeysTrue(value.value)}
                          </Descriptions.Item>
                        ))}
                    </Descriptions>
                  </Col>
                </List.Item>
              )}
            />
          </Col>
        </Row> */}

        {/* NEW IMPLEMENTATION HERE */}
        <div className="flex-row flex-row--noMargin">
          <div className="flex-col-xxs-12">
            <Row
              style={{ justifyContent: 'center', marginBottom: 20 }}
              gutter={[16, 16]}
            ></Row>
            {productsObject?.map((item) => (
              <div
                key={`${item.ReturnId}-${item.variantId}-${item.id}`}
                className="flex-row flex-row--noMargin"
                style={{ flexDirection: 'column', gap: 20, marginBottom: 20 }}
              >
                <div
                  className="flex-row flex-row--noMargin flex-middle-xxs flex-center-xxs"
                  style={{ gap: 30 }}
                >
                  <div style={{ padding: 0, maxWidth: '175px' }}>
                    {item.imageUrl ? (
                      <img
                        alt="Product Image"
                        src={item.imageUrl}
                        style={{maxWidth:'100%',maxHeight:'100%',borderRadius:'8px'}}
                      />
                    ) : (
                      <div style={{width: '115px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '115px', 
                        borderRadius: '10px', 
                        backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
                        <img
                          src={
                            'https://assets.reviews.io/img/all-global-assets/icons/icon-blank-photos--md.svg'
                          }
                          alt="Product Image"
                          width="60"
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                    }}
                  >
                    <strong
                      style={{ textAlign: 'center' }}
                    ><div className="TextHeading TextHeading--xxxxs u-marginBottom--none">
                        {`${item.quantity}x ${item.productName} (${item.variantName})`}
                      </div>
                    </strong>
                    <div
                      className="flex-row flex-row--noMargin flex-between-xxs"
                      style={{ width: '100%', height: 30 }}
                    >
                      {this.state.quantity[item.variantId] === 1 ? (
                        <Button
                          style={{ height: 'auto', width: 30 }}
                          disabled={true}
                          type="ghost"
                          size="small"
                          icon="minus"
                        ></Button>
                      ) : (
                        <Button
                          style={{ height: 'auto', width: 30 }}
                          type="ghost"
                          size="small"
                          onClick={() => this.decrementProduct(item.variantId)}
                          icon="minus"
                        ></Button>
                      )}
                      <div
                        className="flex-row flex-row--noMargin flex-middle-xxs"
                        style={{
                          justifyContent: 'center',
                          height: '100%',
                          pointerEvents: 'none',
                        }}
                      >
                        <div className="TextHeading TextHeading--xxxxs u-marginBottom--none">
                          {this.state.quantity[item.variantId]}
                        </div>
                      </div>
                      {this.state.quantity[item.variantId] === item.quantity ? (
                        <Button
                          style={{ height: 'auto', width: 30 }}
                          disabled={true}
                          type="ghost"
                          size="small"
                          icon="plus"
                        ></Button>
                      ) : (
                        <Button
                          style={{ height: 'auto', width: 30 }}
                          type="ghost"
                          size="small"
                          onClick={() =>
                            this.incrementProduct(item.quantity, item.variantId)
                          }
                          icon="plus"
                        ></Button>
                      )}
                    </div>
                    {this.props.productsObject.length == 1 ? (
                      item.quantity != this.state.quantity[item.variantId] ? (
                        <Popconfirm
                          title={
                            <>
                              <div className="TextHeading TextHeading--xxs">
                                Are you sure you want to delete this product?
                              </div>
                              <div className="TextBody TextBody--xxs">
                                You cannot undo your actions. Proceed with
                                caution.
                              </div>
                            </>
                          }
                          onConfirm={() =>
                            this.handleUpdateProduct(
                              item.id,
                              item.ReturnId,
                              item.variantId,
                              item.quantity
                            )
                          }
                          okText="Yes"
                          cancelText="Cancel"
                        >
                          <Button type="ghost" size="large">
                            Remove
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Button disabled={true} type="ghost" size="large">
                          Remove
                        </Button>
                      )
                    ) : (
                      <Popconfirm
                        title={
                          <>
                            <div className="TextHeading TextHeading--xxs">
                              Are you sure you want to delete this product?
                            </div>
                            <div className="TextBody TextBody--xxs">
                              You cannot undo your actions. Proceed with
                              caution.
                            </div>
                          </>
                        }
                        onConfirm={() =>
                          this.handleUpdateProduct(
                            item.id,
                            item.ReturnId,
                            item.variantId,
                            item.quantity
                          )
                        }
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <Button type="ghost" size="large">
                          Remove
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                </div>
                {this.props.featureExchangeOrders ? (
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ gap: 10, justifyContent: 'center' }}
                  >
                    {item.restocked ? (
                      <Tag color="#1890ff">{`${item.restockedQuantity} ${
                        item.restockedQuantity > 1 ? 'items' : 'item'
                      } Restocked`}</Tag>
                    ) : null}
                    {item.exchanged ? (
                      <Tag color="#1890ff">{`${item.exchangedQuantity} ${
                        item.exchangedQuantity > 1 ? 'items' : 'item'
                      } Exchanged`}</Tag>
                    ) : null}

                    {item.refunded ? (
                      <Tag color="#1890ff">{`${item.refundedQuantity} ${
                        item.refundedQuantity > 1 ? 'items' : 'item'
                      } Refunded`}</Tag>
                    ) : null}

                    {item.storeCredited ? (
                      <Tag color="#1890ff">{`Store-Credit issued for ${
                        item.storeCreditedQuantity
                      } ${
                        item.storeCreditedQuantity > 1 ? 'items' : 'item'
                      }`}</Tag>
                    ) : null}
                  </div>
                ) : null}
                {/* TABLE CONTENT HERE */}
                <Collapse style={{backgroundColor: "transparent"}} >
                  <Panel header={<span>Show Details</span>} key="1" style={{backgroundColor: "transparent"}}>
                    <div style={{ marginTop: 0 }}>
                      <Descriptions column={1} size="middle" bordered={true}>
                        {item.sku != null && item.sku !== '' ? (
                          <Descriptions.Item label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">SKU</strong>}>
                            <div className="TextBody TextBody--xxxs u-marginBottom--none">
                              {item.sku}
                            </div>
                          </Descriptions.Item>
                        ) : null}
                        <Descriptions.Item
                          label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">Stock location name</strong>}
                        >
                          <div className="TextBody TextBody--xxxs u-marginBottom--none">
                            {item.stockLocationName || '-'}
                          </div>
                        </Descriptions.Item>
                        {this.state.updateReturnResolution &&
                        this.context.featureUpdateResoultion &&
                        !item.exchanged &&
                        !item.refunded &&
                        !item.storeCredited &&
                        this.props.returnObject.returnStatus === 'Pending' ? (
                            <React.Fragment>
                              <Descriptions.Item label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">Action</strong>}>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      showResolutionOptions: true,
                                      selectedProduct: item,
                                    })
                                  }
                                  type="ghost"
                                  size="small"
                                  // icon="edit"
                                >
                                  {item.resolutionLabel
                                    ? item.resolutionLabel === 'Store credit'
                                      ? 'Store Credit'
                                      : item.resolutionLabel
                                    : this.props.returnObject.resolution &&
                                    this.props.returnObject.resolution.includes(
                                      'refundToCustom'
                                    )
                                      ? this.props.returnObject.resolutionLabel
                                      : this.props.resolutionOptions[
                                        this.props.returnObject.resolution
                                      ]}
                                </Button>
                                {this.state.showResolutionOptions && (
                                  <UpdateResolution
                                    {...this.props}
                                    item={this.state.selectedProduct}
                                    visible={this.state.showResolutionOptions}
                                    handleClose={this.closeResolutionOptions}
                                  />
                                )}
                              </Descriptions.Item>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Descriptions.Item label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none" >Action</strong>}>
                                <div className="TextBody TextBody--xxxs u-marginBottom--none">
                                  {item.resolutionLabel
                                    ? item.resolutionLabel
                                    : this.props.returnObject.resolution &&
                                    this.props.returnObject.resolution.includes(
                                      'refundToCustom'
                                    )
                                      ? this.props.returnObject.resolutionLabel
                                      : this.props.resolutionOptions[
                                        this.props.returnObject.resolution
                                      ]}
                                </div>
                              </Descriptions.Item>
                            </React.Fragment>
                          )}
                        {item.exchangeItemVariantId ? (
                          <Descriptions.Item
                            label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">Exchange item</strong>}
                          > <div className="TextBody TextBody--xxxs u-marginBottom--none">
                              {`${item.quantity}x ${
                            item.exchangeItemProductName
                              ? item.exchangeItemProductName
                              : item.productName
                          } - ${item.exchangeItemVariantName} (${
                            item.exchangeItemPrice +
                            ' ' +
                            this.props.returnObject.currency
                          })`}
                            </div>
                          </Descriptions.Item>
                        ) : null}
                        {item.wantsInstantExchange ? (
                          <Descriptions.Item label={
                            <>
                              <strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">
                                Instant Exchange 
                                <Tooltip
                                  title={
                                    <span>
                                      Enter the number of items of this product 
                                      that are in order and accepted to be counted 
                                      as returned. Excess quantities will be 
                                      considered rejected.
                                    </span>
                                  }
                                >
                                  <Icon
                                    style={{ fontSize: '11px', alignItems: 'center', marginLeft: 3 }}
                                    type="info-circle"
                                  />
                                </Tooltip>
                              </strong>

                            </>
                          }>
                            <div className="TextBody TextBody--xxxs u-marginBottom--none">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 15,
                                }}
                              >
                                <div
                                  className="flex-row flex-row--noMargin flex-between-xxs"
                                  style={{ width: '100%', height: 30 }}
                                >
                                  {this.state.pendingInstantExchangeApprovals[item.variantId] <= 1 ? (
                                    <Button
                                      style={{ height: 'auto', width: 30 }}
                                      disabled={true}
                                      type="ghost"
                                      size="small"
                                      icon="minus"
                                    ></Button>
                                  ) : (
                                    <Button
                                      style={{ height: 'auto', width: 30 }}
                                      type="ghost"
                                      size="small"
                                      onClick={() => this.decrementInstantExchange(item.variantId)}
                                      icon="minus"
                                    ></Button>
                                  )}
                                  <div
                                    className="flex-row flex-row--noMargin flex-middle-xxs"
                                    style={{
                                      justifyContent: 'center',
                                      height: '100%',
                                      pointerEvents: 'none',
                                    }}
                                  >
                                    <div className="TextHeading TextHeading--xxxxs u-marginBottom--none">
                                      {this.state.pendingInstantExchangeApprovals[item.variantId]}
                                    </div>
                                  </div>
                                  {this.state.pendingInstantExchangeApprovals[item.variantId] === item.pendingInstantExchangeApprovals ? (
                                    <Button
                                      style={{ height: 'auto', width: 30 }}
                                      disabled={true}
                                      type="ghost"
                                      size="small"
                                      icon="plus"
                                    ></Button>
                                  ) : (
                                    <Button
                                      style={{ height: 'auto', width: 30 }}
                                      type="ghost"
                                      size="small"
                                      onClick={() =>
                                        this.incrementInstantExchange(item.pendingInstantExchangeApprovals, item.variantId)
                                      }
                                      icon="plus"
                                    ></Button>
                                  )}
                                </div>
                              </div>
                              {this.props.productsObject.length === 1 ? (
                                <div
                                  className="flex-row flex-row--noMargin"
                                  style={{ flexDirection: 'column', gap: 10 }}
                                >
                                  {item.pendingInstantExchangeApprovals > 0 ? (
                                    <Popconfirm
                                      title={
                                        <>
                                          <div className="TextHeading TextHeading--xxs">
                                            Are you sure you want to accept this product?
                                          </div>
                                          <div className="TextBody TextBody--xxs">
                                            This action will issue a refund of {item.price * this.state.pendingInstantExchangeApprovals[item.variantId]} {this.props.returnObject.currency}.
                                            If all products are returned in acceptable condition, please select the 
                                            maximum value. You cannot undo your actions. Proceed with caution.
                                          </div>
                                        </>
                                      }
                                      onConfirm={() =>
                                        this.handleUpdateInstantExchange(
                                          item.id,
                                          item.ReturnId,
                                          item.variantId,
                                          item.pendingInstantExchangeApprovals
                                        )
                                      }
                                      okText="Yes"
                                      cancelText="Cancel"
                                    >
                                      <Button type="ghost" size="small" style={{ marginTop: 10 }}>Accept</Button>
                                    </Popconfirm>
                                  ) : (
                                    <Button disabled={true} type="ghost" size="small" style={{ marginTop: 10 }}>
                                      Accept
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className="flex-row flex-row--noMargin"
                                  style={{ flexDirection: 'column', gap: 10 }}
                                >
                                  {item.pendingInstantExchangeApprovals > 0 ? (
                                    <Popconfirm
                                      title={
                                        <>
                                          <div className="TextHeading TextHeading--xxs">
                                            Are you sure you want to accept this product?
                                          </div>
                                          <div className="TextBody TextBody--xxs">
                                            This action will issue a refund of {item.price * this.state.pendingInstantExchangeApprovals[item.variantId]} {this.props.returnObject.currency}.
                                            If all products are returned in acceptable condition, please select the 
                                            maximum value. You cannot undo your actions. Proceed with caution.
                                          </div>
                                        </>
                                      }
                                      onConfirm={() =>
                                        this.handleUpdateInstantExchange(
                                          item.id,
                                          item.ReturnId,
                                          item.variantId,
                                          item.pendingInstantExchangeApprovals
                                        )
                                      }
                                      okText="Yes"
                                      cancelText="Cancel"
                                    >
                                      <Button type="ghost" size="small" style={{ marginTop: 10 }}>
                                        Accept
                                      </Button>
                                    </Popconfirm>
                                  ) : (
                                    <Button type="ghost" size="small" disabled={true} style={{ marginTop: 10 }}>
                                      Accept
                                    </Button>
                                  )}
                                </div>
                              )}
                            </div>
                          </Descriptions.Item>
                        ) : null}
                        <Descriptions.Item label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">Customer notes</strong>}>
                          <div className="TextBody TextBody--xxxs u-marginBottom--none">
                            {item.notes || '-'}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label={<strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">Reason</strong>}>
                          <div className="TextBody TextBody--xxxs u-marginBottom--none">
                            {item.returnReason}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs"
                              style={{ gap: 10 }}
                            >
                              <strong className="TextHeading TextHeading--xxxxs u-marginBottom--none">Weight per item</strong>
                              <Tooltip
                                title={
                                  <span>
                                    This is the weight that Shopify stores for the
                                    item on the original order. If changes were made
                                    to the weight of the item in Shopify, please
                                    press the reload button to retrieve the latest
                                    weight.
                                  </span>
                                }
                              >
                                <Icon
                                  style={{ fontSize: '11px', alignItems: 'center' }}
                                  type="info-circle"
                                />
                              </Tooltip>
                            </div>
                          }
                        >
                          <div
                            className="flex-row flex-row--noMargin"
                            style={{ flexDirection: 'column', gap: 10 }}
                          >
                            <div className="TextBody TextBody--xxxs u-marginBottom--none">
                              {item.weightInGrams || 0} grams /{' '}
                              {((item.weightInGrams || 0) / 28.35).toFixed(1)}{' '}
                              ounces
                            </div>
                            <Tooltip title="Reload weight">
                              <Button
                                onClick={() => this.handleProductWeight(item.id)}
                                size="small"
                                type="ghost"
                                icon={'reload'}
                              />
                            </Tooltip>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                    {/* LAST SECTION HERE */}
                    {this.props.featureExchangeOrders ? (
                      <>
                        <div>
                          {item.restocked ? null : (
                            <React.Fragment>
                              <div
                                className="flex-row flex-row--noMargin"
                                style={{
                                  gap: 15,
                                  flexDirection: 'column',
                                  marginTop: 20,
                                }}
                              >
                                <Row
                                  type="flex"
                                  style={{
                                    columnGap: '10px',
                                    flexFlow: 'nowrap',
                                  }}
                                >
                                  <div>
                                    <Checkbox
                                      onChange={this.onChangeRestockType.bind(this)}
                                      checked={this.state.restockMultipleLocation}
                                    ></Checkbox>
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    Enable to restock on different location
                                  </div>
                                </Row>
                                {!this.state.restockMultipleLocation ? (
                                  <React.Fragment>
                                    <div
                                      className="flex-col-xxs-12 flex-middle-xxs"
                                      style={{ display: 'flex', gap: 10, padding: 0 }}
                                    >
                                      <Popconfirm
                                        title={
                                          item.quantity > 1
                                            ? `Restock ${item.quantity} items?`
                                            : `Restock ${item.quantity} item?`
                                        }
                                        onConfirm={() => this.restockItem(item)}
                                        okText="Yes"
                                        cancelText="Cancel"
                                      >
                                        <Button
                                          // icon="rollback"
                                          type="ghost"
                                          loading={this.state.loadingRestockSingle}
                                          disabled={this.state.loadingRestockSingle}
                                          // style={{ padding: 'var(--input-padding)' }}
                                          style ={{ width: 150 }}
                                        >
                                          {item.quantity > 1
                                            ? `Restock ${item.quantity} items`
                                            : `Restock ${item.quantity} item`}
                                        </Button>
                                      </Popconfirm>
                                      <Tooltip title="This will restock the item(s) at their original location from the order. Only use if items have not been restocked otherwise, e.g. as part of a refund or exchange order. We cannot detect restocking activities that take place outside our platform.">
                                        <Icon
                                          style={{
                                            position: 'relative',
                                            fontSize:'11px'
                                          }}
                                          type="info-circle"
                                        />
                                      </Tooltip>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div
                                      className="flex-col-xxs-12 flex-middle-xxs"
                                      style={{ display: 'flex', gap: 10, padding: 0 }}
                                    >
                                      <Button
                                        size="small"
                                        icon="rollback"
                                        loading={this.state.loadingRestockSingle}
                                        disabled={this.state.loadingRestockSingle}
                                        onClick={() => this.showModal(item)}
                                        style={{ padding: 'var(--input-padding)' }}
                                        type="ghost"
                                      >
                                        {item.quantity > 1
                                          ? `Restock ${item.quantity} items`
                                          : `Restock ${item.quantity} item`}
                                      </Button>
                                      <Tooltip title="You can now restock at any other available location, the product must be available there first otherwise product will be unable to restock">
                                        <Icon
                                          style={{
                                            position: 'relative',
                                          }}
                                          type="info-circle"
                                        />
                                      </Tooltip>
                                      {this.state.locations && (
                                        <Modal
                                          title="Restock Item"
                                          visible={this.state.isModalOpen}
                                          onOk={() => this.handleOk(item)}
                                          onCancel={this.handleCancel}
                                        >
                                          <span>Restock item: </span>
                                          <Select
                                            defaultValue={
                                              this.state &&
                                              this.state.restockLocationId != null
                                                ? this.state.restockLocationId
                                                : undefined
                                            }
                                            style={{ width: 380 }}
                                            onChange={this.updateLocationId.bind(
                                              this
                                            )}
                                            placeholder="Select restock location"
                                          >
                                            {this.state.locations.map((location) => {
                                              return (
                                                <Option
                                                  key={location.id}
                                                  value={location.id}
                                                >
                                                  {location.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </Modal>
                                      )}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </>
                    ) : null}
                    {item?.extraNotes && Object.entries(item.extraNotes).length !== 0 && (
                      <>
                        <Divider>Additional questions</Divider>
                        <Descriptions column={1} size="middle" bordered={true}>
                          {item?.extraNotes &&
                            Object.entries(item?.extraNotes).map(([key, value]) => (
                              <Descriptions.Item label={<strong>{key}</strong>}>
                                {value.type !== 'checkbox'
                                  ? value.value.toString()
                                  : this.getKeysTrue(value.value)}
                              </Descriptions.Item>
                            ))}
                        </Descriptions>
                      </>
                    )}
                  </Panel>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReturnDetailsProduct;
