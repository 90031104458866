/**
 * Component for our main Analytics Dashboard displaying
 * different KPIs on a Return- or Item-Level.
 *
 * @component
 */

import React from 'react';
import {
  DatePicker,
  Row,
  Col,
  Spin,
  Card,
  Checkbox,
  Tooltip,
  Icon,
  message,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { Link } from 'react-router-dom';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { trackingVitally } from '../../../../helpers/vitally';

const { RangePicker } = DatePicker;


const pluralize = (word, count) => (count === 1 ? word : `${word}s`);

/**
 * Formats seconds into days and hours
 * @param {number | string} seconds
 * @returns {string} Formatted time in days and hours
 */
const formatTime = (seconds) => {
  if (Number.isNaN(seconds)) {
    return seconds;
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);

  if (!days) {
    return `${hours} ${pluralize('hour', hours)}`;
  }

  if (!hours) {
    return `${days} ${pluralize('day', days)}`;
  }

  return `${days} ${pluralize('day', days)} + ${hours} ${pluralize(
    'hour',
    hours
  )}`;
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: undefined,
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      adjustForOrderDate: false,
      includeOpenOrders: true,
      checkboxDisabled: false,
      numberOfTotalReturns: '-',
      numberOfTotalProducts: '-',
      averageProductsPerReturn: '-',
      totalReturnValue: '-',
      averageReturnValue: '-',
      numberOfOrders: '-',
      returnRate: '-',
      currency: '-',
      numberOfCompletedReturns: '-',
      valueOfCompletedReturns: '-',
      numberOfRefundToOriginal: '-',
      valueOfRefundToOriginal: '-',
      rateOfRefundToOriginal: '-',
      numberOfRefundToExchange: '-',
      valueOfRefundToExchange: '-',
      rateOfRefundToExchange: '-',
      numberOfRefundToCredit: '-',
      valueOfRefundToCredit: '-',
      rateOfRefundToCredit: '-',
      numberOfAdvancedExchange: '-',
      valueOfAdvancedExchange: '-',
      rateOfAdvancedExchange: '-',
      averageTimeToReturn: '-',
      shortestTimeToReturn: '-',
      longestTimeToReturn: '-',
    };
  }

  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }
  async handleSubmit() {
    this.setState({
      loading: true,
    });

    const { startDate, endDate, adjustForOrderDate, includeOpenOrders } =
      this.state;

    if (!startDate || !endDate) {
      this.setState({
        loading: false,
      });
      return message.error('Select a start and end date.', 4);
    }
    try {
      const response = await axios.post('/api/analytics', {
        startDate,
        endDate,
        adjustForOrderDate,
        includeOpenOrders,
      });

      if (response.data.error) {
        return message.error('Error fetching analytics data from server.', 4);
      }

      return this.setState({
        ...response.data,
        loading: false,
      });
    } catch (err) {
      message.error('Error fetching analytics data.', 4);
    }
  }

  onChange(dates, dateStrings) {
    // syntax: function(dates: [moment, moment], dateStrings: [string, string])
    trackFSEvent('Check Analytics Timeframe', {
      feature: 'Analytics Overview',
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    });
    trackingVitally('rr_analyticstimeframe_adjusted');
    this.setState(
      {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        checkboxDisabled: false,
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  toggleAdjustToOrderDate() {
    this.setState(
      {
        adjustForOrderDate: !this.state.adjustForOrderDate,
      },
      () => {
        const validDateEntered =
          this.state.startDate != null && this.state.endDate != null;

        if (validDateEntered) {
          this.handleSubmit();
        }
      }
    );
  }

  toggleIncludeOpenOrders() {
    this.setState(
      {
        includeOpenOrders: !this.state.includeOpenOrders,
      },
      () => {
        const validDateEntered =
          this.state.startDate != null && this.state.endDate != null;

        if (validDateEntered) {
          this.handleSubmit();
        }
      }
    );
  }

  render() {
    const { date } = this.state;
    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      const tooLate = date && current.diff(date, 'days') > 366;
      const tooEarly = date && date.diff(current, 'days') > 45;
      return tooEarly || tooLate;
    };

    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="top"
          // style={{ paddingBottom: 35 }}
        >
          <Col>
            <h1 className="TextHeading TextHeading--sm u-marginBottom--md">Analytics</h1>
          </Col>
        </Row>

        <Spin spinning={this.state.loading} indicator={<span> </span>}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="u-marginBottom--md"
            style={{rowGap:'20px'}}
            gutter={15}
          >
            <Col 
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <RangePicker
                style={{width:'100%'}}
                defaultValue={[this.state.startDate, this.state.endDate]}
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                  'Year to Date': [moment().startOf('year'), moment()],
                  'Last Year': [
                    moment().subtract(1, 'year').startOf('year'),
                    moment().subtract(1, 'year').endOf('year'),
                  ],
                }}
                onChange={this.onChange.bind(this)}
                disabledDate={disabledDate}
                onCalendarChange={(dateArray) => {
                  const endDateSelected = dateArray[1] != null;
                  if (endDateSelected) {
                    return this.setState({
                      date: undefined,
                    });
                  }

                  const startDateSelected = dateArray[0];
                  if (startDateSelected) {
                    this.setState({
                      date: dateArray[0],
                    });
                  }
                }}
              />
            </Col>

            <Col> 
              <div>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ columnGap: 20 }}
                >
                  <Tooltip placement="bottom" title="Include returns only for orders with an order date within the specified timeframe. Otherwise all returns for the specified timeframe will be included.">
                    <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--xs">
                      <Checkbox
                        onChange={this.toggleAdjustToOrderDate.bind(this)}
                        checked={this.state.adjustForOrderDate}
                        disabled={this.state.checkboxDisabled}
                      >
                        <div className="TextBody TextBody--xxs u-marginBottom--none">
                          Adjust for order date
                        </div>
                      </Checkbox>
                    </div>
                  </Tooltip>

                  <Checkbox
                    onChange={this.toggleIncludeOpenOrders.bind(this)}
                    checked={this.state.includeOpenOrders}
                    disabled={this.state.checkboxDisabled}
                  >
                    <div className="TextBody TextBody--xxs u-marginBottom--none">
                      Include open orders
                    </div>
                  </Checkbox>
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
        

        <Spin
          spinning={this.state.loading}
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={15} style={{ textAlign: 'center' }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of orders with a subsequent return divided by the number of orders."
              >
                <Card
                  title="Return rate"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.returnRate}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip placement="top" title="Number of Returns created.">
                <Card
                  title="Return volume"
                  bordered={false}
                  hoverable={true}
                  style={{ marginBottom: 15 }}
                  extra={
                    <React.Fragment>
                      <a href="/dashboard/analytics/trends/return-volume">
                        <Icon type="area-chart" style={{ fontSize: 16 }} />
                      </a>
                    </React.Fragment>
                  }
                >
                  {this.state.numberOfTotalReturns}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip placement="top" title="Number of orders received.">
                <Card
                  title="Order volume"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.numberOfOrders}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip placement="top" title="Value of returned items.">
                <Card
                  title="Return value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.totalReturnValue} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Value of returned items divided by the number of returns."
              >
                <Card
                  title="Average return value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.averageReturnValue} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Value of returned items that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
              >
                <Card
                  title="Completed return value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.valueOfCompletedReturns} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip placement="top" title="Number of items returned.">
                <Card
                  title="Returned Items"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.numberOfTotalProducts}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items returned divided by the number of returns."
              >
                <Card
                  title="Average items per return"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.averageProductsPerReturn}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of returns that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
              >
                <Card
                  title="Completed return volume"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.numberOfCompletedReturns}
                </Card>
              </Tooltip>
            </Col>
          </Row>

          <div className="u-marginTop--lg u-marginBottom--sm">
            <div className='TextHeading TextHeading--sm  u-marginBottom--md'>Item based metrics</div>
          </div>

          <Row gutter={15} style={{ marginTop: '10px', textAlign: 'center' }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Refund divided by number of items returned."
              >
                <Card
                  title="Refund rate"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.rateOfRefundToOriginal}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Refund."
              >
                <Card
                  title="Refund volume"
                  bordered={false}
                  hoverable={true}
                  style={{ marginBottom: 15 }}
                  extra={
                    <React.Fragment>
                      <a href="/dashboard/analytics/trends/refund-volume">
                        <Icon type="area-chart" style={{ fontSize: 16 }} />
                      </a>
                    </React.Fragment>
                  }
                >
                  {this.state.numberOfRefundToOriginal}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Value of items selected for Refund."
              >
                <Card
                  title="Refund value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.valueOfRefundToOriginal} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Exchange divided by number of items returned."
              >
                <Card
                  title="Exchange rate"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.rateOfRefundToExchange}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Exchange."
              >
                <Card
                  title="Exchange volume"
                  bordered={false}
                  hoverable={true}
                  style={{ marginBottom: 15 }}
                  extra={
                    <React.Fragment>
                      <a href="/dashboard/analytics/trends/exchange-volume">
                        <Icon type="area-chart" style={{ fontSize: 16 }} />
                      </a>
                    </React.Fragment>
                  }
                >
                  {this.state.numberOfRefundToExchange}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Value of items selected for Exchange."
              >
                <Card
                  title="Exchange value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.valueOfRefundToExchange} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Store-Credit divided by number of items returned."
              >
                <Card
                  title="Store credit rate"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.rateOfRefundToCredit}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Store-Credit."
              >
                <Card
                  title="Store credit volume"
                  bordered={false}
                  hoverable={true}
                  style={{ marginBottom: 15 }}
                  extra={
                    <React.Fragment>
                      <a href="/dashboard/analytics/trends/store-credit-volume">
                        <Icon type="area-chart" style={{ fontSize: 16 }} />
                      </a>
                    </React.Fragment>
                  }
                >
                  {this.state.numberOfRefundToCredit}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Value of items selected for Store-Credit."
              >
                <Card
                  title="Store credit value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.valueOfRefundToCredit} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Collection-Exchange divided by number of items returned."
              >
                <Card
                  title="Collection exchange rate"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.rateOfAdvancedExchange}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Collection-Exchange."
              >
                <Card
                  title="Collection exchange volume"
                  bordered={false}
                  hoverable={true}
                  style={{ marginBottom: 15 }}
                  extra={
                    <React.Fragment>
                      <Link to="/dashboard/analytics/trends/advanced-exchange-volume">
                        <Icon type="area-chart" style={{ fontSize: 16 }} />{' '}
                      </Link>
                    </React.Fragment>
                  }
                >
                  {this.state.numberOfAdvancedExchange}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Number of items selected for Collection-Exchange."
              >
                <Card
                  title="Collection exchange value"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {this.state.valueOfAdvancedExchange} {this.state.currency}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Avareage time from Order Date to Return Requested date."
              >
                <Card
                  title="Average time-to-return"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {formatTime(this.state.averageTimeToReturn)}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Shortest time from Order Date to Return Requested date."
              >
                <Card
                  title="Shortest time-to-return"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {formatTime(this.state.shortestTimeToReturn)}
                </Card>
              </Tooltip>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="top"
                title="Longest time from Order Date to Return Requested date."
              >
                <Card
                  title="Longest time-to-return"
                  bordered={false}
                  hoverable={false}
                  style={{ marginBottom: 15 }}
                  extra={<React.Fragment></React.Fragment>}
                >
                  {formatTime(this.state.longestTimeToReturn)}
                </Card>
              </Tooltip>
            </Col>
          </Row>
        </Spin>

        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ paddingTop: 40 }}
        >
          <Col>
            <h4 className="TextHeading TextHeading--xxxxs">
              Want to see another Metric in the dashboard? Let us know{' '}
              <a
                href="mailto:hello@richcommerce.co?subject=Please%20add%20New%20Metric%20to%20the%20dashboard"
                target="_blank"
                style={{color:'#000000',textDecoration:'underline'}}
              >
                here
              </a>
              .
            </h4>
          </Col>
        </Row>

        <Row type="flex" justify="center" align="top" style={{marginBottom:30}}>
          <Col>
            <div className="TextBody TextBody--xxxs u-textAlign--center">
              Note: If you encounter issues on this
              Analytics-Page make sure to disable any ad-blockers in your browser.
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Dashboard;
