/**
 * Component for Connections settings page in the Dashboard.
 *
 * @component
 */

import { Divider, message } from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import {
  LogoGorgias,
  LogoIntercom,
  LogoKlaviyo,
  LogoShopify,
  LogoShopMinis,
  LogoStripe,
} from '../../../../components/CustomReturnsIcons';
import GorgiasModal from '../../../../components/GorgiasModal';
import ShopMinisModal from '../../../../components/ShopMinisModal';

import { IntegrationCard } from '../../../../components/IntegrationCard';
import KlaviyoModal from '../../../../components/KlaviyoModal';
import StripeModal from '../../../../components/StripeModal';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { getAppInfo } from '../../../../helpers/appInfo';
import { createShopifyUrl } from '../../../../helpers/createShopifyUrl';
import { trackFSEvent } from '../../../../helpers/fullstory';
import '../../../../styles/App.css';
import { trackingVitally } from '../../../../helpers/vitally';

class ConnectPlatform extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      brandSettings: {},
      connectionSettings: {},
      connectionStatus: {},
      returnSettings: {},
      clientId: 'efdd8904c3999b79312362f88fc31059',
      modalVisible: '',
      isProductionApp: false,
      env: null,
      shopifyUrl: null,
      developmentStore: null,
      loading: true, // Start with loading
    };
  }

  generateShopifyUrl = async () => {
    const shopifyUrl = await createShopifyUrl('/dashboard/connect/shopify');
    this.setState({
      shopifyUrl,
    });
  };

  async fetchClientId() {
    try {
      const info = await getAppInfo();
      if (info.clientId) {
        this.setState({
          clientId: info.clientId,
        });
      }
      this.setState({
        isProductionApp: info.isProductionApp,
        env: info.env,
      });
    } catch (err) {
      message.error('error fetching clientId of application');
    }
  }

  modalVisible = (value) => {
    this.setState({
      modalVisible: value,
    });
  };

  componentDidMount = () => {
    this.initializeComponent(); // Initialize the component by calling all async functions
  };

  // Wrapper function to manage all async calls
  initializeComponent = async () => {
    try {
      // Run all async functions sequentially
      await this.fetchClientId();
      await this.generateShopifyUrl();
      await this.loadConnectionSettings();
      await this.fetchReturnSettings();
      await this.fetchBrandSettings();

      // Stop loading once all functions have completed
      this.setState({ loading: false });
    } catch (err) {
      // Stop loading and handle any errors
      this.setState({ loading: false });
      message.error('Error loading connection settings. Please try again.', 4);
    }
  };

  // Function to load connection settings
  loadConnectionSettings = async () => {
    const response = await axios.get('/api/connections');
    if (response.data.status === 'error') {
      throw new Error('Error loading connection settings'); // Throw error if any issue
    }

    const shopifyUrl = response.data.brandSettings.storeShopifyUrl;
    if (!shopifyUrl) {
      message.error(
        "Please set your Shopify Store URL first before connecting. You'll find it under >>> Brand Settings",
        10
      );
    }

    const connectionStatus = this.getConnectionStatus(
      response.data.connectionSettings
    );

    // Update the state with data from the API
    this.setState({
      ...response.data,
      connectionStatus,
    });
  };

  getConnectionStatus = (connectionSettings) => {
    let connectionStatus = this.state.connectionStatus;
    connectionSettings.map((connection) => {
      connectionStatus = {
        ...connectionStatus,
        [connection.identifier]: connection.status,
      };
    });
    return connectionStatus;
  };

  fetchReturnSettings = async () => {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data === null) {
        return message.error(
          'Error retrieving your return settings. Try again.',
          5
        );
      }
      this.setState({ returnSettings: response.data }, () => {
        this.updateConnectionStatus();
      });
    } catch (err) {
      message.error(
        'Error retrieving your return settings. Please try again.',
        5
      );
    }
  };

  fetchBrandSettings = async () => {
    try {
      const response = await axios.get('/api/brandSettings');
      if (response.data === null) {
        return message.error(
          'Error retrieving your brand settings. Try again.',
          5
        );
      }
      this.setState({ brandSettings: response.data }, () => {
        this.updateConnectionStatus();
      });
    } catch (err) {
      message.error(
        'Error retrieving your brand settings. Please try again.',
        5
      );
    }
  };

  updateConnectionStatus = () => {
    const { returnSettings, connectionStatus, brandSettings } = this.state;
    const updatedConnectionStatus = { ...connectionStatus };

    if (
      returnSettings &&
      returnSettings.stripePublishableKey &&
      returnSettings.stripeSecretKey
    ) {
      updatedConnectionStatus['stripe'] = 'connected';
    } else {
      updatedConnectionStatus['stripe'] = 'disconnected';
    }
    if (brandSettings.isShopminisExtensionEnabled) {
      updatedConnectionStatus['shopminis'] = 'connected';
    } else {
      updatedConnectionStatus['shopminis'] = 'disconnected';
    }
    this.setState({ connectionStatus: updatedConnectionStatus });
  };

  refreshConnectionStatus = async () => {
    await this.fetchReturnSettings();
  };

  trackingEvent = (appName) => {
    trackFSEvent('Connect app', {
      feature: 'Integrations',
      integratedApp: appName,
    });
    trackingVitally(`rr_${appName}_integrated`);
  };

  render() {
    const nonceToken = '123456';

    const url = window.location.href;
    const urlObject = new URL(url);
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;

    const oAuthRedirectUrlIntercom = `${urlProtocol}//${urlHostname}/dashboard/connect/intercom`;

    const intercomUrl =
      'https://app.intercom.io/oauth?client_id=6d86b2f5-d4d1-41ef-b224-44e8dd1d4615&state=' +
      nonceToken +
      '&redirect_uri=' +
      oAuthRedirectUrlIntercom;
    let data = [
      {
        title: 'Intercom',
        identifier: 'intercom',
        oAuthUrl: intercomUrl,
        // logo: '/logos/intercom.svg',
        logo: <LogoIntercom></LogoIntercom>,
        description:
          'Connect to Intercom to integrate a return-widget directly into the Intercom messenger. Your customers and support-agents will love you.',
      },
      {
        title: <>Klaviyo</>,
        identifier: 'klaviyo',
        // logo: '/logos/klaviyo.png',
        logo: <LogoKlaviyo></LogoKlaviyo>,
        description:
          'Connect Rich Returns to Klaviyo. We push information to Klaviyo each time a Return is created or updated.',
      },
      {
        title: <>Gorgias</>,
        identifier: 'gorgias',
        // logo: '/logos/gorgias.jpeg',
        logo: <LogoGorgias></LogoGorgias>,
        description:
          'Connect Rich Returns to Gorgias. Put Return-information side-by-side with your customer tickets.',
      },
      {
        title: <>Stripe</>,
        identifier: 'stripe',
        logo: <LogoStripe></LogoStripe>,
        description:
          'Connect Rich Returns to Stripe. Collect your return-related payments seamlessly.',
      },
      {
        title: <>Shop minis</>,
        identifier: 'shopminis',
        logo: <LogoShopMinis></LogoShopMinis>,
        description:
          'Connect Rich Returns to Shop app. Enable our shop minis extension for the Shop app.',
      },
    ];

    if (this.state.shopifyUrl) {
      data.unshift({
        title: 'Shopify',
        identifier: 'shopify',
        oAuthUrl: this.state.shopifyUrl,
        // logo: '/logos/shopify.svg',
        logo: <LogoShopify></LogoShopify>,
        description:
          'Connect your Shopify store to enable returns. Greatly improve customer satisfaction and reduce agent support time for returns.',
      });
    }

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <PageButtonHeader headingTitle={'Connect platform'}></PageButtonHeader>
        <div style={{ marginTop: 30 }}>
          <div className="flex-row">
            {data.map((item) => {
              return (
                <div
                  className="flex-col-xxs-12 flex-col-xs-12 flex-col-sm-6 flex-col-md-4 flex-col-lg-3 u-displayFlex u-flexAlignItems--stretch"
                  key={item.identifier}
                >
                  <IntegrationCard
                    item={item}
                    connectionStatus={this.state.connectionStatus}
                    modalVisible={this.modalVisible}
                    trackingEvent={this.trackingEvent}
                  ></IntegrationCard>
                </div>
              );
            })}
          </div>
        </div>
        {this.state.modalVisible === 'klaviyo' ? (
          <KlaviyoModal
            modalForKlaviyoVisible={this.state.modalVisible === 'klaviyo'}
            modalVisible={this.modalVisible}
          />
        ) : null}
        {this.state.modalVisible === 'gorgias' ? (
          <GorgiasModal
            modalForGorgiasVisible={this.state.modalVisible === 'gorgias'}
            modalVisible={this.modalVisible}
          />
        ) : null}
        {this.state.modalVisible === 'stripe' ? (
          <StripeModal
            modalForStripeVisible={this.state.modalVisible === 'stripe'}
            modalVisible={this.modalVisible}
            refreshConnectionStatus={this.refreshConnectionStatus}
          />
        ) : null}
        {this.state.modalVisible === 'shopminis' ? (
          <ShopMinisModal
            modalForShopMinisVisible={this.state.modalVisible === 'shopminis'}
            modalVisible={this.modalVisible}
          />
        ) : null}
        <Divider />
        <div className="TextBody TextBody--xxs u-textCenter--all">
          Missing a service?
          <a href="mailto:hello@richcommerce.co"> Let us know here.</a>
        </div>
      </React.Fragment>
    );
  }
}

export default ConnectPlatform;
