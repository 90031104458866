/**
 * Component for our Collection-Exchanges feature
 * that loads a list of matching Exchange-Products
 * for the Product in question.
 * 
 * @component
 */

import React from 'react';
import axios from 'axios';
import {
  Drawer,
  Row,
  Spin,
  Form,
  List,
  Button,
  message,
} from 'antd';
import ShowProductDetails from './ShowProductDetails';
import { FormattedMessage, defineMessages } from "react-intl";
import '../../../styles/Visitor.css'
import { placeholderImageUrl } from '../../../constants/constants';

const innerWidthOfWindow = window.innerWidth;
const isMobile = innerWidthOfWindow && innerWidthOfWindow <= 480 ? true : false;

class ExchangeOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerTitle: 'Select new Item',
      loading: true,
      showProductDetails: false,
      selectedProductId: undefined,
      products: [],
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.post('/api/visitors/return/exchange/advanced/products', {
        companyIdentifier: this.props.companyIdentifier,
        returnNumber: this.props.returnNumber,
        advancedExchangeOptions: this.props.advancedExchangeOptions,
      });
      if (response.data)
        this.setState({
          products: response.data.products,
          loading: false,
        });
    } catch (err) {
      this.setState({
        loading: false,
      });

      if (err.response && err.response.status != 404)
        return message.error('Error fetching products. Please try again.', 5);
    }
  }

  async showProductDetails(showProductDetails = true, productId) {
    this.setState({
      showProductDetails: showProductDetails,
      selectedProductId: productId,
      drawerTitle: showProductDetails ? 'Product Details' : 'Select new Item',
    });
  }

  handleCancel() {
    let lineItemId = this.props.lineItemIdToBeExchanged;
    this.props.closeExchangeOptions(lineItemId);
  }

  updateExchangedProduct(productInfo) {
    this.props.updateExchangedProduct(productInfo);
    this.props.closeExchangeOptions();
  }

  render() {
    const drawerTitle = defineMessages({
      newItem: {
        id: "app.visitor.advancedExchange.newProduct",
        description: "Title for modal to select exchange product",
        defaultMessage: "Select new Item",
      },
      details: {
        id: "app.visitor.advancedExchange.productDetails",
        description: "Details of product selected for exchange",
        defaultMessage: "Product Details",
      },
    });
    const { formatMessage } = this.props.intl;
    return (
      <Drawer
        title={this.state.drawerTitle === "Select new Item" ? formatMessage(drawerTitle.newItem) : formatMessage(drawerTitle.details)}
        placement='right'
        width={isMobile ? '100%' : '50%'}
        closable={false}
        onClose={this.handleCancel.bind(this)}
        visible={this.props.visible}
      >
        {this.state.loading ? (
          <Row type='flex' justify='space-around' align='middle'>
            <Spin
              spinning={this.state.loading}
              style={{ paddingTop: '45px' }}
            ></Spin>
          </Row>
        ) : (
          <div style={{paddingBottom: '40px'}} className='productsDrawer'>
            {!this.state.showProductDetails && (
              <React.Fragment>
                <List
                  itemLayout='horizontal'
                  dataSource={this.state.products}
                  renderItem={(item) => (
                    <List.Item
                      // extra={[
                      //   <a href='' target='_blank' key='view-details'>
                      //     View Details
                      //   </a>,
                      // ]}
                    >
                      <List.Item.Meta
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.showProductDetails(true, item.id)}
                        avatar={
                          <img width='100px' height='100px' src={item?.image?.src ? item.image.src : placeholderImageUrl} />
                        }
                        title={<a>{item.title}</a>}
                      />
                    </List.Item>
                  )}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'left',
                  }}
                >
                  <Button onClick={this.handleCancel.bind(this)} style={{ marginRight: 10 }}>
                    <FormattedMessage
                      id="app.visitor.order.buttonGoBack"
                      description="Button to go back one screen"
                      defaultMessage="Go back"
                    />
                  </Button>
                </div>
              </React.Fragment>
            )}
            {this.state.showProductDetails && (
              <ShowProductDetails
                {...this.props}
                lineItemIdToBeExchanged={this.props.lineItemIdToBeExchanged}
                productId={this.state.selectedProductId}
                companyIdentifier={this.props.companyIdentifier}
                returnNumber={this.props.returnNumber}
                showProductDetails={this.showProductDetails.bind(this)}
                updateExchangedProduct={this.updateExchangedProduct.bind(this)}
                currentProduct={this.props.currentProduct}
              />
            )}
          </div>
        )}
      </Drawer>
    );
  }
}

const WrappedExchangeOptions = Form.create()(ExchangeOptions);
export default WrappedExchangeOptions;
