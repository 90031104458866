import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// screens
import Logout from '../components/Logout';
import AuditLog from '../pages/dashboard/AuditLog';
import Returns from '../pages/dashboard/returns/Returns';
import Account from '../pages/dashboard/settings/account/Account';
import AccountDirect from '../pages/dashboard/settings/account/components/PlansDirectBilling';
import SettingsApi from '../pages/dashboard/settings/api/SettingsApi';
import Brand from '../pages/dashboard/settings/brand/Brand';
import CustomDomain from '../pages/dashboard/settings/brand/CustomDomain';
import ConnectPlatform from '../pages/dashboard/settings/connections/ConnectPlatform';
import Email from '../pages/dashboard/settings/email/Email';
import EmailAdmin from '../pages/dashboard/settings/email/EmailAdmin';
import ExchangeSettings from '../pages/dashboard/settings/exchanges/index';
import Payment from '../pages/dashboard/settings/payment/StripePayment';
import SettingsRefunds from '../pages/dashboard/settings/refunds/Refunds';
import Settings from '../pages/dashboard/settings/returns/Settings';
import SettingsRules from '../pages/dashboard/settings/rules/Rules';
import Translations from '../pages/dashboard/settings/translations/Translations';
import InventoryManagement from '../pages/dashboard/settings/inventory/InventoryManagement';
import Carrier from '../pages/dashboard/settings/carriers/Carriers';
import WarrantySettings from '../pages/dashboard/settings/warranties/index';

import Customer from '../pages/dashboard/analytics/customer/index';
import AnalyticsExport from '../pages/dashboard/analytics/export/Export';
import Reports from '../pages/dashboard/analytics/reports/index';
import Dashboard from '../pages/dashboard/analytics/stats/StatsFull';
import Trends from '../pages/dashboard/analytics/trends/index';
import ShopifySync from '../pages/dashboard/settings/shopifySync/shopifySync';

//styles
import Page from '../components/Page';
import Homepage from '../pages/dashboard/home/index';
import ConnectCourier from '../pages/dashboard/settings/connections/ConnectCourier';
import CustomerIncentives from '../pages/dashboard/settings/returns/components/CustomerIncentives';
import '../styles/App.css';
import ErrorRedirect from './ErrorRedirect';

class DashboardRouter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/dashboard"
          render={(props) => (
            <Page {...props} component={Homepage} title="Homepage" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/returns"
          render={(props) => (
            <Page {...props} component={Returns} title="Returns" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/account/profile"
          render={(props) => (
            <Page {...props} component={Account} title="Your Account" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/account/plan"
          render={(props) => (
            <Page {...props} component={AccountDirect} title="Account" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/platform"
          render={(props) => (
            <Page {...props} component={ConnectPlatform} title="Connect Platform" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/courier"
          render={(props) => (
            <Page {...props} component={ConnectCourier} title="Connect Carrier" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings"
          render={(props) => (
            <Page {...props} component={Settings} title="Return settings" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges"
          render={(props) => (
            <Page {...props} component={ExchangeSettings} title="Exchanges" defaultActiveKey="1"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/variants"
          render={(props) => (
            <Page {...props} component={ExchangeSettings} title="Variant Exchanges" defaultActiveKey="1"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/advanced"
          render={(props) => (
            <Page {...props} component={ExchangeSettings} title="Collection Exchanges" defaultActiveKey="2"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/storeWideExchange"
          render={(props) => (
            <Page {...props} component={ExchangeSettings} title="Store-Wide Exchanges" defaultActiveKey="4"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/orders"
          render={(props) => (
            <Page {...props} component={ExchangeSettings} title="Exchange Orders" defaultActiveKey="3"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/account/api"
          render={(props) => (
            <Page {...props} component={SettingsApi} title="Return settings - API keys" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/rules"
          render={(props) => (
            <Page {...props} component={SettingsRules} title="Return settings - Advanced Rules" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/carrires"
          render={(props) => (
            <Page {...props} component={Carrier} title="Return settings - Prepaid Labels" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/warranties"
          render={(props) => (
            <Page {...props} component={WarrantySettings} title="Return settings - Warranties" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/refunds"
          render={(props) => (
            <Page {...props} component={SettingsRefunds} title="Return settings - Refunds" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/payment"
          render={(props) => (
            <Page {...props} component={Payment} title="Stripe Payments" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/incentives"
          render={(props) => (
            <Page {...props} component={CustomerIncentives} title="Customer Incentives" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/brand"
          render={(props) => (
            <Page {...props} component={Brand} title="Brand settings" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/brand/custom-domain"
          render={(props) => (
            <Page {...props} component={CustomDomain} title="Custom Domain" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/brand/translations"
          render={(props) => (
            <Page {...props} component={Translations} title="Translations" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/email"
          render={(props) => (
            <Page {...props} component={Email} title="Customer notifications" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/email/admin"
          render={(props) => (
            <Page {...props} component={EmailAdmin} title="Staff notifications" />
          )}
        />
        
        <Route
          exact={true}
          path="/dashboard/settings/inventory"
          render={(props) => (
            <Page {...props} component={InventoryManagement} title="Inventory Management" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/export"
          render={(props) => (
            <Page {...props} component={AnalyticsExport} title="Export" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/sync"
          render={(props) => (
            <Page {...props} component={ShopifySync} title="Shopify Sync" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/account/audit-log"
          render={(props) => (
            <Page {...props} component={AuditLog} title="Audit Log" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/dashboard"
          render={(props) => (
            <Page {...props} component={Dashboard} title="Analytics Dashboard" />
          )}
        />
        <Route
          path="/dashboard/analytics/customer"
          render={(props) => (
            <Page {...props} component={Customer} title="Customers" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/trends"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/trends/return-volume"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends" defaultActiveKey="2"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/trends/refund-volume"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends" defaultActiveKey="3"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/trends/exchange-volume"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends" defaultActiveKey="4"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/trends/advanced-exchange-volume"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends" defaultActiveKey="5"/>
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/trends/store-credit-volume"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends" defaultActiveKey="6"/>
          )}
        />
        <Route
          path="/dashboard/analytics/reports"
          component={Reports}
        />
        <Route
          exact={true}
          path="/dashboard/logout"
          render={(props) => (
            <Page {...props} component={Logout} title="Logout" />
          )}
        />
        <Route path="*" component={ErrorRedirect} />
      </Switch>
    );
  }
}

export default DashboardRouter;
