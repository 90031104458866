/**
 * Component for our Exchange Orders to let the
 * user create and view them.
 *
 * @component
 */

import { Button, Checkbox, Popconfirm, Tooltip, message } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import VideoPopup from '../../../../components/VideoPopup';

class ReturnDetailsExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCreateExchangeOrder: false,
      exchangeOrderRestockAll: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        return;
      }
      const { exchangeOrderRestock } = response.data;
      this.setState({
        exchangeOrderRestockAll: exchangeOrderRestock,
      });
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  }

  toggleRestockAll = () => {
    this.setState({
      exchangeOrderRestockAll: !this.state.exchangeOrderRestockAll,
    });
  };

  createExchangeOrder = async (returnObject, orderType) => {
    try {
      this.setState({
        loadingCreateExchangeOrder: true,
      });

      const { exchangeOrderRestockAll } = this.state;

      const response = await axios.post('/api/returns/exchangeOrder', {
        returnId: returnObject.id,
        orderType,
        exchangeOrderRestockAll,
      });

      if (response.data.status === 'error') {
        this.setState({
          loadingCreateExchangeOrder: false,
        });
        this.props.handleGetReturn();
        return message.error(response.data.error, 10);
      }
      this.props.handleGetReturn();

      this.setState({
        loadingCreateExchangeOrder: false,
      });

      return message.success('Exchange order created successfully.', 5);
    } catch (err) {
      this.setState({
        loadingCreateExchangeOrder: false,
      });
      return message.error(
        'Error while creating an exchange order. Try again.',
        4
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        {!this.props.featureExchangeOrders ? (
          <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
            <div className="flex-col-xxs-12">
              <div
                className="flex-row flex-row--noMargin flex-middle-xxs"
                style={{ gap: 5 }}
              >
                <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Exchange</strong>
              </div>
            </div>
            <div className="flex-col-xxs-12" style={{ padding: 0 }}>
              <div className="flex-row" style={{ rowGap: 10 }}>
                <div
                  className="flex-col-xxs-12 flex-col-sm-6"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                  }}
                >
                  <Tooltip
                    title="Click here to create an exchange order. Please note that exchange orders are available on the Premium-Plan"
                    placement="bottom"
                  >
                    <Button
                      disabled={true}
                      style={{
                        width: '100%',
                      }}
                    >
                      Exchange-Order
                    </Button>
                  </Tooltip>
                </div>
                <div
                  className="flex-col-xxs-12 flex-col-sm-6"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                  }}
                >
                  <Tooltip
                    title="Click here to create a draft order. Please note that exchange orders are available on the Premium-Plan"
                    placement="bottom"
                  >
                    <Button
                      disabled={true}
                      style={{
                        width: '100%',
                      }}
                    >
                      Draft-Order
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ) : this.props.returnObject.exchangeOrderType ? (
          <div className="flex-row flex-row--noMargin" style={{ rowGap: 10 }}>
            <div className="flex-col-xxs-12">
              <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Exchange</strong>
            </div>
            <div className="flex-col-xxs-12">
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href={
                  this.props.returnObject.exchangeOrderType === 'draft'
                    ? this.props.storeShopifyUrl +
                      '/admin/draft_orders/' +
                      this.props.returnObject.exchangeOrderId
                    : this.props.storeShopifyUrl +
                      '/admin/orders/' +
                      this.props.returnObject.exchangeOrderId
                }
                style={{
                  width: '100%',
                }}
              >
                View Exchange Order on Shopify
              </Button>
            </div>
          </div>
        ) : this.props.orderIncludesExchangeItem ? (
          <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
            <div className="flex-col-xxs-12">
              <div
                className="flex-row flex-row--noMargin flex-middle-xxs"
                style={{ gap: 5 }}
              >
                <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Exchange</strong>
              </div>
            </div>
            <div className="flex-col-xxs-12" style={{ padding: 0 }}>
              <div
                className="flex-row flex-row--noMargin"
                style={{ width: '100%', rowGap: 10 }}
              >
                <div className="flex-col-xxs-12 flex-col-md-6">
                  <Popconfirm
                    title={
                      <React.Fragment>
                        <p className="TextBody TextBody--xxs">
                          Create an exchange order and mark it as paid based on
                          the exchange-items in this return?
                        </p>
                        <p>
                          <Checkbox
                            checked={this.state.exchangeOrderRestockAll}
                            onChange={this.toggleRestockAll.bind(this)}
                          >
                            Restock original items
                          </Checkbox>
                        </p>
                      </React.Fragment>
                    }
                    onConfirm={() =>
                      this.createExchangeOrder(this.props.returnObject, 'order')
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                  >
                    <Button
                      disabled={this.state.loadingCreateExchangeOrder}
                      loading={this.state.loadingCreateExchangeOrder}
                      style={{
                        width: '100%',
                      }}
                    >
                      Exchange-Order
                    </Button>
                  </Popconfirm>
                </div>
                <div className="flex-col-xxs-12 flex-col-md-6">
                  <Popconfirm
                    title={
                      <React.Fragment>
                        <p className="TextBody TextBody--xxs">
                          Create a draft order that can be edited afterwards
                          based on the exchange-items in this return?
                        </p>
                        <p>
                          <Checkbox
                            checked={this.state.exchangeOrderRestockAll}
                            onChange={this.toggleRestockAll.bind(this)}
                          >
                            Restock original items
                          </Checkbox>
                        </p>
                      </React.Fragment>
                    }
                    onConfirm={() =>
                      this.createExchangeOrder(this.props.returnObject, 'draft')
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                  >
                    <Button
                      disabled={this.state.loadingCreateExchangeOrder}
                      loading={this.state.loadingCreateExchangeOrder}
                      style={{
                        width: '100%',
                      }}
                    >
                      Draft-Order
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
            <div className="flex-col-xxs-12">
              <div
                className="flex-row flex-row--noMargin flex-middle-xxs"
                style={{ gap: 5 }}
              >
                <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Exchange</strong>
              </div>
            </div>
            <div className="flex-col-xxs-12" style={{ padding: 0 }}>
              <div
                className="flex-row flex-row--noMargin"
                style={{ width: '100%', rowGap: 10 }}
              >
                <div
                  className="flex-col-xxs-12 flex-col-md-6"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                  }}
                >
                  <Tooltip
                    title="There are no exchange items in this order"
                    placement="bottom"
                  >
                    <Button
                      disabled={true}
                      style={{
                        width: '100%',
                      }}
                    >
                      Exchange-Order
                    </Button>
                  </Tooltip>
                </div>
                <div
                  className="flex-col-xxs-12 flex-col-md-6"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                  }}
                >
                  <Tooltip
                    title="There are no exchange items in this order"
                    placement="bottom"
                  >
                    <Button
                      disabled={true}
                      style={{
                        width: '100%',
                      }}
                    >
                      Draft-Order
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ReturnDetailsExchange;
