/**
 * Component for overview settings page of our Warranites in the Dashboard.
 *
 * @component
 */

import { Tabs, Button, Form, Row } from 'antd';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import Page from '../../../../components/Page';
import WarrantyReturns from './WarrantyReturns';
import CaseIssues from './CaseIssues';
const { TabPane } = Tabs;

const FormItem = Form.Item;

class WarrantySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: props.defaultActiveKey || '1',
      saveHandlers: {},
      cancelHandler: {},
      showWarrantyProductButton: false,
    };
  }

  setSaveHandler = (tabKey, handler) => {
    this.setState((prevState) => ({
      saveHandlers: {
        ...prevState.saveHandlers,
        [tabKey]: handler,
      },
    }));
  };

  setCancelHandler = (tabKey, handler) => {
    this.setState((prevState) => ({
      cancelHandler: {
        ...prevState.cancelHandler,
        [tabKey]: handler,
      },
    }));
  };

  handleSave = (e) => {
    const { activeKey, saveHandlers } = this.state;
    if (saveHandlers[activeKey]) {
      saveHandlers[activeKey](e);
    }
  };

  handleCancel = (e) => {
    const { activeKey, cancelHandler } = this.state;
    if (cancelHandler[activeKey]) {
      cancelHandler[activeKey](e);
    }
  };

  showDefaultSaveButton = () => {
    if(this.state.activeKey !== '2' && this.state.activeKey !== '5') {
      return true;
    }

    if(this.state.activeKey === '5' && this.state.showInstantExchangesButtons) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <React.Fragment>
        <PageButtonHeader
          headingTitle={'Warranties'}
          headingRightContent={
            <FormItem>
              <div style={{ minHeight: 38 }}>
                {this.state.showWarrantyProductButton &&
                  this.state.activeKey === '2' && (
                    <Row type="flex" justify="end" align="top" className="u-columnGap--md">
                    <Button onClick={this.handleCancel}>
                      Cancel
                    </Button>{' '}
                    <Button onClick={this.handleSave} type="primary">
                      Save changes
                    </Button>
                  </Row>
                )}
              </div>
            </FormItem>
          }
        ></PageButtonHeader>
        <div className="ContentPanel  u-paddingTop--sm">
          <Tabs
            activeKey={this.state.activeKey}
            onChange={(key) => this.setState({ activeKey: key })}
            defaultActiveKey={this.props.defaultActiveKey || '1'}
            animated={false}
            style={{ minHeight: 500 }}
          >
            <TabPane tab="Case Issues" key="1">
              <Page
                component={CaseIssues}
                title="Variant Exchanges"
                setSaveHandler={(handler) => this.setSaveHandler('1', handler)}
              />
            </TabPane>
            <TabPane tab={<span>Warranty Returns</span>} key="2">
              <Page
                component={WarrantyReturns}
                title="Collection Exchanges"
                setSaveHandler={(handler) => this.setSaveHandler('2', handler)}
                setCancelHandler={(handler) => this.setCancelHandler('2', handler)}
                setShowAdvancedExchangesButtons={(value) => this.setState({ showWarrantyProductButton: value })}
              />
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default WarrantySettings;
