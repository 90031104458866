/**
 * Component for overview settings page of our Exchanges in the Dashboard.
 *
 * @component
 */

import { Tabs, Button, Form, Row } from 'antd';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import Page from '../../../../components/Page';
import AdvancedExchanges from './AdvancedExchanges';
import ExchangeOrders from './ExchangeOrders';
import StoreWideExchanges from './StoreWideExchange';
import VariantExchanges from './VariantExchanges';
import InstantExchanges from './InstantExchanges';
import axios from 'axios';
const { TabPane } = Tabs;

const FormItem = Form.Item;

class ExchangeSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: props.defaultActiveKey || '1',
      saveHandlers: {},
      cancelHandler: {},
      showAdvancedExchangesButtons: false,
      showInstantExchangesButtons: false,
    };
  }

  setSaveHandler = (tabKey, handler) => {
    this.setState((prevState) => ({
      saveHandlers: {
        ...prevState.saveHandlers,
        [tabKey]: handler,
      },
    }));
  };

  setCancelHandler = (tabKey, handler) => {
    this.setState((prevState) => ({
      cancelHandler: {
        ...prevState.cancelHandler,
        [tabKey]: handler,
      },
    }));
  };

  handleSave = (e) => {
    const { activeKey, saveHandlers } = this.state;
    if (saveHandlers[activeKey]) {
      saveHandlers[activeKey](e);
    }
  };

  handleCancel = (e) => {
    const { activeKey, cancelHandler } = this.state;
    if (cancelHandler[activeKey]) {
      cancelHandler[activeKey](e);
    }
  };

  showDefaultSaveButton = () => {
    if(this.state.activeKey !== '2' && this.state.activeKey !== '5') {
      return true;
    }

    if(this.state.activeKey === '5' && this.state.showInstantExchangesButtons) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <React.Fragment>
        <PageButtonHeader
          headingTitle={'Exchanges'}
          headingRightContent={
            <FormItem>
              <div style={{ minHeight: 38 }}>
                {this.showDefaultSaveButton() && (
                  <Button
                    type="primary"
                    onClick={this.handleSave}
                    style={{ padding: 'var(--input-padding)' }}
                  >
                    Save changes
                  </Button>
                )}
                {this.state.showAdvancedExchangesButtons &&
                  this.state.activeKey === '2' && (
                    <Row type="flex" justify="end" align="top" className="u-columnGap--md">
                    <Button onClick={this.handleCancel}>
                      Cancel
                    </Button>{' '}
                    <Button onClick={this.handleSave} type="primary">
                      Save changes
                    </Button>
                  </Row>
                )}
              </div>
            </FormItem>
          }
        ></PageButtonHeader>
        <div className="ContentPanel  u-paddingTop--sm">
          <Tabs
            activeKey={this.state.activeKey}
            onChange={(key) => this.setState({ activeKey: key })}
            defaultActiveKey={this.props.defaultActiveKey || '1'}
            animated={false}
            style={{ minHeight: 500 }}
          >
            <TabPane tab="Variant Exchanges" key="1">
              <Page
                component={VariantExchanges}
                title="Variant Exchanges"
                setSaveHandler={(handler) => this.setSaveHandler('1', handler)}
              />
            </TabPane>
            <TabPane tab={<span>Collection Exchanges</span>} key="2">
              <Page
                component={AdvancedExchanges}
                title="Collection Exchanges"
                setSaveHandler={(handler) => this.setSaveHandler('2', handler)}
                setCancelHandler={(handler) => this.setCancelHandler('2', handler)}
                setShowAdvancedExchangesButtons={(value) => this.setState({ showAdvancedExchangesButtons: value })}
              />
            </TabPane>
            <TabPane tab={<span>Exchange Orders</span>} key="3">
              <Page
                component={ExchangeOrders}
                title="Exchange Orders"
                setSaveHandler={(handler) => this.setSaveHandler('3', handler)}
              />
            </TabPane>
            <TabPane tab={<span>Store-Wide Exchanges</span>} key="4">
              <Page
                component={StoreWideExchanges}
                title="Store-Wide Exchanges"
                setSaveHandler={(handler) => this.setSaveHandler('4', handler)}
              />
            </TabPane>
            <TabPane tab={<span>Instant Exchanges</span>} key="5">
              <Page component={InstantExchanges} title="Instant Exchanges"
                setShowInstantExchangesButtons={(value) => this.setState({ showInstantExchangesButtons: value })}
                setSaveHandler={(handler) => this.setSaveHandler('5', handler)}
              />
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default ExchangeSettings;
