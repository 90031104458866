/**
 * Component to register Canada Post.
 *
 * @component
 */

import React from 'react';
import { Modal, Input, message, Row, Col, Divider, Button } from 'antd';
import axios from 'axios';
import { trackingVitally } from '../../../../../helpers/vitally';

const LABEL_SERVICE = 'canadapost';

class CanadapostModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      active: false,
      keyNumber: null,
      labelApiKey: null,
      canadapostCustomerNumber: null,
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`/api/carrierSettings/${LABEL_SERVICE}`);
      if (response.data) {
        const { carrier } = response.data;
        this.setState({
          id: carrier.id,
          active: carrier.active,
          labelApiKey: carrier.labelApiKey,
          canadapostCustomerNumber: carrier.canadapostCustomerNumber,
        });
      }
    } catch (err) {
      if (err.response.status !== 404) {
        console.log(err);
        message.error('something went wrong please try again', 5);
      }
    }
  }

  validateFields = () => {
    const { labelApiKey, canadapostCustomerNumber } = this.state;
    if (!labelApiKey || labelApiKey === '') {
      message.error('API Key is required.');
      return false;
    }
    if (!canadapostCustomerNumber || canadapostCustomerNumber === '') {
      message.error('Customer Number is required.');
      return false;
    }
    return true;
  };

  getPayload = () => {
    const { id, active, labelApiKey, canadapostCustomerNumber } = this.state;
    return {
      id,
      active,
      labelApiKey,
      canadapostCustomerNumber,
      labelService: LABEL_SERVICE,
    };
  };

  handleOk = async () => {
    this.setState({
      loading: true,
    });
    if (!this.validateFields()) {
      this.setState({
        loading: false,
      });
      return;
    }
    const payload = this.getPayload();
    trackingVitally('rr_Canada Post_integrated');
    try {
      const response = await axios.post('/api/carrierSettings/update', payload);
      console.log(response);
      message.success(
        'Canada Post integration has been successfully set up',
        5
      );
      this.props.setModalVisible(null);
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error('something went wrong please try again', 5);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  generateApiKey = async () => {
    let keyNumber = this.state.keyNumber;
    if (!keyNumber || keyNumber === '') {
      return message.error('Please enter your username and password');
    }
    const credentials = keyNumber.replace(/\s/g, '');
    const bytes = new TextEncoder().encode(credentials);
    const base64Credentials = btoa(
      String.fromCharCode(...new Uint8Array(bytes))
    );
    this.handleChange('labelApiKey', base64Credentials);
  };

  render() {
    return (
      <Modal
        title="Set up your Canada Post integration"
        visible={true}
        width="50%"
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={() => this.props.setModalVisible(null)}
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Generate API Key</h4>
            <span>
              Type in your Canada Post username and password to generate your
              API key. Please enter in the following format:{' '}
              <strong>username:password</strong> (no spaces in-between). For
              more information, please follow our
            </span>
            <span style={{ display: 'inline-block' }}>&nbsp;</span>
            <a
              href="https://support.richreturns.io/en/articles/10085631-canada-post-integration"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Canada Post API documentation</span>
            </a>
          </Col>
          <Col span={10}>
            <Row>
              <Input
                placeholder="username:password"
                onChange={(e) => {
                  this.handleChange('keyNumber', e.target.value);
                }}
              />
            </Row>
            <Row>
              <Button
                type="primary"
                onClick={this.generateApiKey}
                style={{ width: '100%' }}
              >
                Generate API Key
              </Button>
            </Row>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ paddingTop: '30px' }}
        >
          <Col span={10}>
            <h4>API Key</h4>
            <span>
              CanadaPost API key will auto generate after you type your username
              and password.
            </span>
          </Col>
          <Col span={10}>
            <Input
              name="labelApiKey"
              disabled={true}
              value={this.state.labelApiKey}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Customer number</h4>
            <span>CanadaPost requires the customer number</span>
          </Col>
          <Col span={10}>
            <Input
              name="canadapostCustomerNumber"
              onChange={(e) =>
                this.handleChange('canadapostCustomerNumber', e.target.value)
              }
              value={this.state.canadapostCustomerNumber}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default CanadapostModal;
