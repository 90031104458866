import React, { Component } from 'react';
import { Tabs, Button } from 'antd';
// import prettier from "prettier/standalone";
// import htmlParser from "prettier/plugins/html";
import beautify from 'js-beautify';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';

const { TabPane } = Tabs;

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.content || '',
    };
  }

  handleChange = (newContent) => {
    this.props.onChange(newContent);
    this.setState({ content: newContent });
  };

  formatHtml = () => {
    try {
      const formattedContent = beautify.html(this.state.content, {
        indent_size: 2,
        wrap_line_length: 80,
        preserve_newlines: true,
      });
      this.setState({ content: formattedContent });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ content: this.props.content });
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Tabs type="card" style={{ width: '100%' }}>
          {/* Tab Editor */}
          <TabPane tab="Editor" key="1">
            <Button onClick={this.formatHtml} style={{ marginBottom: '10px' }}>
              Format HTML
            </Button>
            <AceEditor
              mode="html"
              theme="monokai"
              name="html-editor"
              fontSize={14}
              lineHeight={19}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={this.state.content}
              onChange={this.handleChange}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                tabSize: 2,
              }}
              style={{ width: '100%', height: '500px' }}
            />
          </TabPane>

          {/* Tab Preview */}
          <TabPane tab="Preview" key="2">
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                minHeight: '300px',
                background: '#fff',
              }}
              dangerouslySetInnerHTML={{ __html: this.state.content }}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Editor;
