/**
 * Component for Warranty-Returns settings page
 * that let's a user add a new Warranty-option.
 *
 * @component
 */

import React from 'react';
import axios from 'axios';
import {
  Form,
  Input,
  Row,
  Button,
  Col,
  Card,
  message,
  Modal,
  Table,
  Tag
} from 'antd';
// import DraggableCollectionList from './DraggableCollectionList';
import StoreWideProducts from '../../../../portal/storeWideExchanges/StoreWideProducts';
import { IconTray } from '../../../../../components/CustomReturnsIcons';
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext';

const FormItem = Form.Item;
class AddAdvancedExchange extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedCollectionId: '',
      warrantyTitle: this.props.fieldIndex ? this.props.warrantyFields[this.props.fieldIndex - 1]?.warrantyTitle : '',
      warrantyTimeLimit: this.props.fieldIndex ? this.props.warrantyFields[this.props.fieldIndex - 1]?.warrantyTimeLimit : 1,
      warrantyProducts: this.props.fieldIndex ? this.props.warrantyFields[this.props.fieldIndex - 1]?.warrantyProducts : [],
      selectedWarrantyProducts: [],
      storeWideModel: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        return;
      }
      const selectedWarrantyProducts = response.data.warrantyFields?.flatMap(field => field?.warrantyProducts || []) || [];
      this.setState({
        returnSettings: response.data,
        selectedWarrantyProducts,
      });
    } catch (err) {
      message.error(`Error getting Return Settings from the server`);
    }

    if (this.props.setSaveHandler) {
      this.props.setSaveHandler(this.handleSubmit);
    }
  }

  handleKeyChange = (e) => {
    const target = e.target?.id;
    const value = e.target?.value?.trim();

    this.setState({
      [target]: value,
    });
  };

  handleSubmit = async () => {
    let {
      warrantyTimeLimit,
      warrantyTitle,
      warrantyProducts
    } = this.state;
    if (!warrantyTitle) {
      return message.error(
        'The title field cannot be empty.',
        3
      );
    }
    if (!warrantyTimeLimit) {
      return message.error(
        'Please set time limit value for the warranty.',
        3
      );
    }
    if (warrantyProducts.length === 0) {
      return message.error(
        'Atleast one product should be included in warranty',
        3
      );
    }
    const warrantyFields = this.props.warrantyFields || [];
    if (this.props.fieldIndex) {
      const index = this.props.fieldIndex - 1;
      warrantyFields[index].warrantyTitle = this.state.warrantyTitle;
      warrantyFields[index].warrantyTimeLimit = this.state.warrantyTimeLimit;
      warrantyFields[index].warrantyProducts = this.state.warrantyProducts;
    } else {
      warrantyFields.push({
        warrantyTitle,
        warrantyTimeLimit,
        warrantyProducts,
        creationDate: Date()
      });
    }
    try {
      const response = await axios.post('/api/returnSettings/update', {
        warrantyFields
      });
      if (response.status === 200) {
        this,this.setState({
          warrantyTitle: '',
          warrantyTimeLimit: 1,
          warrantyProducts: []
        })
        this.props.resetEditArguments();
        return message.success(
          `Warranty option ${
            this.props.fieldIndex ? 'updated' : 'created'
          } successfully.`,
          3
        );
      }
    } catch (err) {
      return message.error('Error while saving the warranty options.', 3);
    }
  };

  handleStoreWide = () => {
    const storeWideState = this.state.storeWideModel;
    this.setState({
      storeWideModel: !storeWideState,
    })
  }

  handleWarrantyProduct = async (products, variant) => {
    try {
      const fields = this.state.warrantyProducts;
      const selectedWarrantyProducts = this.state.selectedWarrantyProducts;
      const selectedProduct = products.find(
        (product) => product.id === variant.product_id
      );
      const field = {};
      if (selectedProduct?.variants?.length > 1) {
        field.variantTitle = variant.title;
      }
      field.title = selectedProduct.title;
      field.variantId = variant.id;
      fields.push(field);
      selectedWarrantyProducts.push(field);
      this.setState({
        warrantyProducts: fields,
        selectedWarrantyProducts,
      })
    } catch (err) {
      return message.error(`Error while selecting product for warranty`);
    }
  };

  handleDeleteWarrantyProduct = (variant) => {
    try {
      const fields = this.state.warrantyProducts;
      const selectedWarrantyProducts = this.state.selectedWarrantyProducts;
      const remainingProducts = fields.filter(product => product.variantId !== variant.variantId);
      const remainingSelectedProducts = selectedWarrantyProducts.filter(product => product.variantId !== variant.variantId);
      return this.setState({
        warrantyProducts: remainingProducts,
        selectedWarrantyProducts: remainingSelectedProducts,
      })
    } catch (err) {
      return message.error(`Error while removing product from warranty`);
    }
  }

      columns = [
        {
          title: 'Product Name',
          key: 'productName',
          dataIndex: 'title',
          render: (title, payload) => {
            return (
              <Tag
                color="gold"
                key={
                  payload.variantTitle
                    ? `${title}(${payload.variantTitle})`
                    : title
                }
                style={{ marginBottom: 3 }}
              >
                {payload.variantTitle
                  ? `${title}(${payload.variantTitle})`
                  : title}
              </Tag>
            );
          },
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
              <Button type="ghost" size="small" onClick={() => this.handleDeleteWarrantyProduct(record)}>Delete</Button>
            </div>
          ),
        },
      ];

  render() {
    const { getFieldDecorator } = this.props.form;
    const { collections } = this.state;
    return (
      <div>
        <div layout="vertical">
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Warranty Title">
                <p>
                  Enter the title for the Warranty.
                </p>
                <FormItem>
                  {getFieldDecorator('warrantyTitle', {
                    initialValue: this.state.warrantyTitle,
                  })(
                    <Input
                      placeholder="Enter Warranty Title"
                      onChange={(e) => this.handleKeyChange(e)}
                    />
                  )}
                </FormItem>
              </Card>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Warranty Time Limit">
                <p>
                  Enter the time limit (in days) for the Warranty.
                </p>
                <FormItem>
                  {getFieldDecorator('warrantyTimeLimit', {
                    initialValue: this.state.warrantyTimeLimit,
                  })(
                    <Input
                      placeholder="Enter Warranty Time Limit"
                      onChange={(e) => this.handleKeyChange(e)}
                      type="number"
                      min={1}
                    />
                  )}
                </FormItem>
              </Card>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Add Products">
                <p>
                  Select multiple products to apply the warranty to all of them at once..
                </p>
                <FormItem noStyle>
                  <Button onClick={this.handleStoreWide}>
                    Select Products
                  </Button>
                </FormItem>
              </Card>
            </Col>
          </Row>

          {this.state.storeWideModel &&
            <Modal
              className="removeFormMargin"
              width={730}
              title="Select Products"
              visible={this.state.storeWideModel}
              onCancel={this.handleStoreWide}
              footer={[
                <Button
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  onClick={this.handleStoreWide}
                >
                  Done
                </Button>,
              ]}
            >
              <StoreWideProducts
                warrantySelection={true}
                handleWarrantyProduct={this.handleWarrantyProduct}
                companyIdentifier={this.context.identifier}
                returnSettings={this.state.returnSettings}
                isMerchantDashboard={true}
                warrantyProducts={this.state.selectedWarrantyProducts}
              />
            </Modal>
          }

          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Selected Products">
                <p>
                  Warranty method and other customized fields
                  will be shown to customers for these selected products.
                </p>
                <Table
                  bordered={false}
                  columns={this.columns}
                  dataSource={this.state.warrantyProducts}
                  rowKey={(record) => record.id}
                  locale={{
                    emptyText: (
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">No Data</p>
                        </div>
                      </div>
                    ),
                  }}
                  style={{ minWidth: '768px' }}
                  />
              </Card>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            align="top"
            className="u-marginTop--xlg"
          >
            <Col span={22}>
              <FormItem>
                <Row type="flex" justify="end" align="top" className="u-columnGap--md">
                  <Button onClick={this.props.toggleAddExchangeForm}>
                    Cancel
                  </Button>{' '}
                  <Button onClick={this.handleSubmit} type="primary">
                    Save changes
                  </Button>
                </Row>
              </FormItem>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

const WrappedAddAdvancedExchange = Form.create()(AddAdvancedExchange);
export default WrappedAddAdvancedExchange;
