/**
 * Component to register Shipstation.
 *
 * @component
 */

import React from 'react';
import { Modal, Input, message, Row, Col, Divider } from 'antd';
import axios from 'axios';
import { trackingVitally } from '../../../../../helpers/vitally';
// import { trackFSEvent } from '../helpers/fullstory';
const LABEL_SERVICE = 'shipstation';

class ShipstationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      active: false,
      labelApiKey: null,
      labelApiSecret: null,
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`/api/carrierSettings/${LABEL_SERVICE}`);
      if (response.data) {
        const { carrier } = response.data;
        this.setState({
          id: carrier.id,
          active: carrier.active,
          labelApiKey: carrier.labelApiKey,
          labelApiSecret: carrier.labelApiSecret,
        });
      }
    } catch (err) {
      if (err?.response?.status !== 404) {
        console.log(err);
        message.error('something went wrong please try again', 5);
      }
    }
  }

  validateFields = () => {
    const { labelApiKey, labelApiSecret } = this.state;
    if (!labelApiKey || labelApiKey === '') {
      message.error('API Key is required.');
      return false;
    }
    if (!labelApiSecret || labelApiSecret === '') {
      message.error('API Secret is required.');
      return false;
    }
    return true;
  };

  getPayload = () => {
    const { id, active, labelApiKey, labelApiSecret } = this.state;
    return {
      id,
      active,
      labelApiKey,
      labelApiSecret,
      labelService: LABEL_SERVICE,
    };
  };

  handleOk = async () => {
    this.setState({
      loading: true,
    });
    if (!this.validateFields()) {
      this.setState({
        loading: false,
      });
      return;
    }
    const payload = this.getPayload();
    trackingVitally('rr_ShipStation_integrated');
    try {
      const response = await axios.post('/api/carrierSettings/update', payload);
      console.log(response);
      message.success(
        'Shipstation integration has been successfully set up',
        5
      );
      this.props.setModalVisible(null);
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error('something went wrong please try again', 5);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Modal
        title="Set up your Shipstation integration"
        visible={true}
        width="50%"
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={() => this.props.setModalVisible(null)}
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>API Key</h4>
            <span>
              Enter your API-Key here (also called Token in some cases). Click
              on the link at the top to see where to find it.
            </span>
          </Col>
          <Col span={10}>
            <a
              href="https://ss.shipstation.com/#/settings/api"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find here in your Shipstation account
            </a>
            <br />
            {/* {this.props.inputFieldValidation === false && (
              <p style={{ color: '#f00000' }}>
                API key cannot contain "API", "test", "sandbox" or special
                characters
              </p>
            )} */}
            <Input
              name="labelApiKey"
              onChange={(e) => this.handleChange('labelApiKey', e.target.value)}
              value={this.state.labelApiKey}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>API Secret</h4>
            <span>Enter your API-Secret here.</span>
          </Col>
          <Col span={10}>
            <Input
              // type = "password"
              value={this.state.labelApiSecret}
              onChange={(e) =>
                this.handleChange('labelApiSecret', e.target.value)
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ShipstationModal;
