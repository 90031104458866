/**
 * Component for our Return details sidebar that
 * lets users view and edit the details of a Return.
 *
 * @component
 */

import { Badge, Button, DatePicker, Divider, Drawer, Icon, message, Select, Tag, Tooltip, Input, Collapse, Card, Row, Col, Typography, Popconfirm, Checkbox } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { Link } from 'react-router-dom';
import '../../../../styles/App.css';
import ReturnDetailsCustomer from './ReturnDetailsCustomer';
import ReturnDetailsExchange from './ReturnDetailsExchange';
import ReturnDetailsLabelAutomatic from './ReturnDetailsLabelAutomatic';
import ReturnDetailsLabelManual from './ReturnDetailsLabelManual';
import ReturnDetailsNoteCustomer from './ReturnDetailsNoteCustomer';
import ReturnDetailsNoteStaff from './ReturnDetailsNoteStaff';
import ReturnDetailsPhotoUpload from './ReturnDetailsPhotoUpload';
import ReturnDetailsProduct from './ReturnDetailsProduct';
import ReturnDetailsRefundButton from './ReturnDetailsRefundButton';
import ReturnDetailsTimeline from './ReturnDetailsTimeline';
import StripeWebhookDetails from './StripeWebhookDetails';
const Option = Select.Option;
const { TextArea } = Input;
const { Panel } = Collapse;
const pluralize = (word, count) => (count === 1 ? word : `${word}s`);
const { Text } = Typography;

/**
 * Formats seconds into days and hours
 * @param {number | string} seconds
 * @returns {string} Formatted time in days and hours
 */
// eslint-disable-next-line no-unused-vars
const formatTime = (seconds) => {
  if (Number.isNaN(seconds)) {
    return seconds;
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);

  if (!days) {
    return `${hours} ${pluralize('hour', hours)}`;
  }

  if (!hours) {
    return `${days} ${pluralize('day', days)}`;
  }

  return `${days} ${pluralize('day', days)} + ${hours} ${pluralize(
    'hour',
    hours
  )}`;
};

const RefundSummary = ({ refund, refundCurrency }) => {
  console.log(refund)
  const lineItemsQuantity = refund.lineItems?.length;
  if(!lineItemsQuantity) {
    return null;
  }

  const { 
    lineItemAmount, 
    lineItemDiscount, 
    lineItemTax, 
    shipping: shippingCosts, 
    restockingFee, 
    restockingFeeType, 
    labelFee, 
    refundTotal, 
    storeCreditType, 
    refundTotalBeforeFees, 
    refundTotalAfterFees, 
    totalBonusDiscount, 
    totalBonusCredits } = refund;
  

  return <React.Fragment>
      <Row type="flex" justify="space-around" align="top">
        <Col span={16}>
          <p style={{ marginBottom: 0 }}>Items subtotal</p>
          <p>
            <Text type="secondary">
              {lineItemsQuantity > 1
                ? `${lineItemsQuantity} items`
                : `${
                    lineItemsQuantity
                      ? lineItemsQuantity
                      : '-'
                  } item`}
            </Text>
          </p>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <p>
            {lineItemAmount &&
              lineItemAmount.toFixed(2)}{' '}
            {refundCurrency}
          </p>
        </Col>
      </Row>

      {lineItemDiscount > 0 ? (
        <Row type="flex" justify="space-around" align="top">
          <Col span={16}>
            <p style={{ marginBottom: 0 }}>Discount</p>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <p>
              -
              {lineItemDiscount &&
                lineItemDiscount.toFixed(2)}{' '}
              {refundCurrency}
            </p>
          </Col>
        </Row>
      ) : null}

      <Row type="flex" justify="space-around" align="top">
        <Col span={16}>
          <p>Tax (included)</p>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <p>
            {lineItemTax &&
              lineItemTax.toFixed(2)}{' '}
            {refundCurrency}
          </p>
        </Col>
      </Row>

      <Row type="flex" justify="space-around" align="top">
        <Col span={16}>
          <p>Shipping</p>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <p>
            {!Number.isNaN(shippingCosts)
              ? `${shippingCosts} ${refundCurrency}`
              : '-'}
          </p>
        </Col>
      </Row>

      {storeCreditType === 'DISCOUNT_CODE' && (
        <Row type="flex" justify="space-around" align="top">
          <Col span={16}>
            <p>Bonus Discount</p>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <p>
              {totalBonusDiscount
                ? `${totalBonusDiscount.toFixed(
                  2
                )} ${refundCurrency}`
                : `0.00 ${refundCurrency}`}
            </p>
          </Col>
        </Row>
      )}
      {storeCreditType === "GIFT_CARD" && (
        <Row type="flex" justify="space-around" align="top">
          <Col span={16}>
            <p>Bonus Credit</p>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <p>
              {totalBonusCredits
                ? `${totalBonusCredits.toFixed(
                  2
                )} ${refundCurrency}`
                : `0.00 ${refundCurrency}`}
            </p>
          </Col>
        </Row>
      )}
      <Row type="flex" justify="space-around" align="top">
        <Col span={16}>
            <p>
              <strong>Refund total</strong>
            </p>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <p>
            {refundTotalBeforeFees
              ? `${parseFloat(
                refundTotalBeforeFees
              ).toFixed(2)} ${refundCurrency}`
              : '-'}
          </p>
        </Col>
      </Row>

      {labelFee ? (
        <Row type="flex" justify="space-around" align="top">
          <Col span={16}>
            <p>Label fee:</p>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <p>
              {labelFee
                ? `${parseFloat(labelFee).toFixed(
                  2
                )} ${
                    refundCurrency
                      ? refundCurrency
                      : ''
                  }`
                : '-'}
            </p>
          </Col>
        </Row>
      ) : null}

      {restockingFee ? (
        <Row type="flex" justify="space-around" align="top">
          <Col span={16}>
            <p>
              <Icon type="minus-circle" /> Restocking fee:
            </p>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <p>
              {restockingFee
                ? `${parseFloat(
                  restockingFee
                ).toFixed(2)} ${
                    restockingFeeType === 'fixed'
                      ? refundCurrency
                      : '%'
                  }`
                : '-'}
            </p>
          </Col>
        </Row>
      ) : null}

      {labelFee || restockingFee ? (
        <Row type="flex" justify="space-around" align="top">
          <Col span={16}>
            <p>
              <strong>Refund total after fees</strong>
            </p>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <p>
              {refundTotal
                ? `${parseFloat(
                    refundTotalAfterFees
                ).toFixed(2)} ${refundCurrency}`
                : '-'}
            </p>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
}

class ReturnDetails extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      stateOfReturn: null,
      notesKey: undefined,
      isLoading: false,
      drawerVisible: false,
      returnMethod: undefined,
      returnObject: null,
      loading: true,
      productsOfReturn: null,
      labelService: null,
      shipDate: null,
      labelStatus: null,
      storeAddress: [],
      selectedStoreAddress: null,
      useGiftApiForStoreCredit: undefined,
      useDiscountCodeForStoreCredit: undefined,
      useStoreCredit: undefined,
      width: '50%',
      discountCodeStatusTracking: true,
      rejectionObservation: null,
      showObservationField: false,
      requireCaseIssues: false,
      warrantyCaseIssues: [],
      caseIssue: null,
    };
  }

  onChange = (date, dateString) => {
    this.setState({
      shipDate: dateString,
    });
  };

  handleChange(value) {
    if (value === 'Rejected') {
      this.setState({
        showObservationField: true,
        stateOfReturn: value,
      })
      return ;
    }
    this.setState({
      showObservationField: false,
      stateOfReturn: value,
    });
  }

  handleGetReturn = async () => {
    try {
      const response = await axios.post(`/api/returns/${this.props.returnId}`);
      if (response.data.labelService && response.data.labelService.length > 0) {
        this.setState({
          labelService: response.data.labelService[0].labelService,
          // fetch {useGiftApiForStoreCredit, useDiscountCodeForStoreCredit} from returnSettings
          useGiftApiForStoreCredit:
            response.data.labelService[0].useGiftApiForStoreCredit,
          useDiscountCodeForStoreCredit:
            response.data.labelService[0].useDiscountCodeForStoreCredit,
          useStoreCredit:
            response.data.labelService[0].useStoreCredit,
        });
      }
      if (response?.data?.returnObject?.returnStatus === 'Rejected') {
        this.setState({
          showObservationField: true,
          rejectionObservation: response.data.returnObject.rejectionObservation,
        });
      }
      this.setState({
        returnObject: response.data.returnObject,
        productsOfReturn: [...response.data.productsOfReturn],
        loading: false,
        labelStatus: response.data.returnObject.labelStatus,
        returnMethod: response.data.returnObject.shippingMethod,
        requireCaseIssues: response.data.labelService[0]?.requireCaseIssues,
        warrantyCaseIssues: response.data.labelService[0]?.warrantyCaseIssues,
        caseIssue: response.data.returnObject.caseIssue,
      });
    } catch (err) {
      return message.error(
        'Error getting return details. Please try again.',
        5
      );
    }
  };

  componentDidMount = async () => {
    this.handleGetReturn();
    if (this.context.featureMultipleAddress) {
      this.getStoreAddresses();
    }
    this.adjustDrawerWidth();
  };

  adjustDrawerWidth = () => {
    try {
      const innerWidthOfWindow = window.innerWidth;
      if (innerWidthOfWindow) {
        if (innerWidthOfWindow >= 992) {
          this.setState({
            width: '50%',
          });
        } else if (innerWidthOfWindow >= 768) {
          this.setState({
            width: '70%',
          });
        } else if (innerWidthOfWindow >= 576) {
          this.setState({
            width: '80%',
          });
        } else {
          this.setState({
            width: '100%',
          });
        }
      }
    } catch (err) {
      console.log('Error while adjustig drawer width: ', err);
    }
  };
  getStoreAddresses = async () => {
    try {
      const response = await axios.get('/api/returnSettings/multipleAddress');
      const storeAddress = response.data.getStoreAddress;
      const filterAddress = storeAddress.find((e) => {
        return e.defaultActive === true;
      });
      this.setState({
        storeAddress,
        selectedStoreAddress: filterAddress,
      });
    } catch (err) {
      console.log('Error, unable to get store address', err);
    }
  };

  handleOnChange = (id) => {
    const filterStoreAddress = this.state.storeAddress.find((e) => {
      return e.id === id;
    });

    this.setState({
      selectedStoreAddress: filterStoreAddress,
    });
  };

  handleLoadingState = (value) => {
    this.setState({
      isLoading: value,
    });
  };

  handleUpdate = async () => {
    trackFSEvent('Update Return Order', {
      feature: 'Return Settings',
      updateStatus: this.state.stateOfReturn
        ? this.state.stateOfReturn
        : this.state.returnObject.returnStatus,
      refundStatus: true,
      discountCodeStatus:
        this.state.returnObject?.StoreCredits[0] &&
        this.state.returnObject?.StoreCredits[0].type === 'discountCode'
          ? true
          : false,
      customer:
        this.state.returnObject.customerFirstName +
        ' ' +
        this.state.returnObject.customerLastName,
      customerEmail: this.state.returnObject.customerEmail,
      resolution: this.state.returnObject.resolution,
      returnMethod: this.returnMethodDefaultValue(),
    });
    this.setState({
      isLoading: true,
    });
    const isWarrantyMethod = this.state.requireCaseIssues && this.state.returnMethod === 'warranty' && !this.state.caseIssue;
    if (isWarrantyMethod) {
      this.setState({
        isLoading: false,
      });
      return message.error(
        'Warranty case issue is required!',
        2
      );
    }
    try {
      const response = await axios.post('/api/returns/update', {
        stateOfReturn: this.state.stateOfReturn
          ? this.state.stateOfReturn
          : this.state.returnObject.returnStatus,
        returnId: this.props.returnId,
        shippingMethod: this.state.returnMethod,
        shipDate: this.state.shipDate
          ? this.state.shipDate
          : moment().format('YYYY-MM-DD'),
        selectedStoreAddress: this.state.selectedStoreAddress
          ? this.state.selectedStoreAddress
          : null,
        rejectionObservation: this.state.rejectionObservation,
        caseIssue: this.state.caseIssue,
      });
      if (
        response.data.err &&
        response.data.err.status === 'autoRefundError' &&
        response.data.err.error
      ) {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.err.error);
      }

      if (response.data.status === 'error') {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.error, 4);
      }
      this.handleGetReturn();
      this.setState({
        isLoading: false,
      });
      if (response.data.labelError) {
        return message.error(
          'Error while attempting to create a new label. Try again.',
          2
        );
      }

      if (response.data.exchangeFailed?.error) {
        return message.error(
          'Error while attempting to create an exchange order. Try again.',
          2
        );
      }

      if (response.data.exchangeFailed?.error) {
        return message.error(
          'Error while attempting to create an exchange order. Try again.',
          2
        );
      }

      return message.success('Status updated successfully.', 4);
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      if (err.status === 'autoRefundError') {
        return message.error(err.error, 5);
      } else {
        return message.error(
          'Error updating status return. Please try again.',
          5
        );
      }
    }
  };

  returnMethodDefaultValue = () => {
    if (this.state.returnObject.shippingMethod === 'methodLabelCreation') {
      return 'Pre-Paid label (automatic)';
    }
    if (this.state.returnObject.shippingMethod === 'methodLabelManual') {
      return 'Pre-Paid label (manual)';
    }
    if (this.state.returnObject.shippingMethod === 'methodCustomerPaidLabel') {
      return 'Customer paid return label';
    }
    if (this.state.returnObject.shippingMethod === 'methodCustomerChoice') {
      return 'Customer responsible for Return Shipping';
    }
    if (this.state.returnObject.shippingMethod === 'methodInStore') {
      return 'In-Store return';
    }
    if (this.state.returnObject.shippingMethod === 'warranty') {
      return 'Warranty';
    }
    return '';
  };

  handleRejectionObservation = (e) => {
    this.setState({
      rejectionObservation: e.target.value,
    })
  }

  handleSoftDeleteReturn = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      const response = await axios.delete(`/api/returns/${this.props.returnId}`);
      if (
        response.data.err &&
        response.data.err.error
      ) {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.err.error);
      }
      if (response.data.status === 'error') {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.error, 4);
      }

      this.setState({
        isLoading: false,
      });

      this.props.hideDrawer();
      return message.success('Return deleted successfully.', 4);
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      return message.error('Error deleting return. Please try again.', 5);
    }
  }

  render() {
    let productsObject = [];
    let orderIncludesExchangeItem = false;
    if (!isEmpty(this.state.productsOfReturn)) {
      this.state.productsOfReturn.map((item) => {
        if (
          item.ReturnId === this.props.returnId &&
          item.wantsToReturn === true
        ) {
          productsObject.push(item);
          if (item.exchangeItemVariantId != null) {
            orderIncludesExchangeItem = true;
          }
        }
      });
    }

    const restockingFeeType =
      this.state.returnObject && this.state.returnObject.restockingFeeType;
    // calculate refund total
    // eslint-disable-next-line no-unused-vars
    let refundCurrency;
    let refundTotal = 0.0;
    let refundExists =
      this.state.returnObject &&
      this.state.returnObject.RefundTransactions &&
      this.state.returnObject.RefundTransactions.length > 0;
    let refundError = false;
    let refundErrorMessage;

    if (refundExists) {
      this.state.returnObject.RefundTransactions.map((transaction) => {
        refundCurrency = transaction.currency;
        refundTotal = refundTotal + parseFloat(transaction.amount);

        const errorStates = ['error', 'failure'];
        const errorExists = errorStates.includes(transaction.status);
        if (errorExists) {
          refundError = true;
          const { message, gateway } = transaction;
          if (refundErrorMessage) {
            refundErrorMessage = `${refundErrorMessage}\n(${gateway}) ${message}`;
          } else {
            refundErrorMessage = `Check the refund manually in Shopify for details. Shopify says:\n(${gateway}) ${message}`;
          }
        }
      });
    }

    // calculate store credit
    let storeCreditCurrency;
    let storeCreditTotal = 0.0;
    let storeCreditId;
    let storeCreditType;
    let customerId = this.state.returnObject?.customerId;
    let storeCreditExists =
      this.state.returnObject &&
      this.state.returnObject.StoreCredits &&
      this.state.returnObject.StoreCredits.length > 0;

    if (storeCreditExists) {
      this.state.returnObject.StoreCredits.map((storeCredit) => {
        storeCreditCurrency = storeCredit.currency;
        storeCreditTotal = storeCreditTotal + parseFloat(storeCredit.amount);
        storeCreditId = storeCredit.externalId;
        storeCreditType = storeCredit.type;
      });
    }
    return (
      <React.Fragment>
        {this.state.loading ? null : (
          <Drawer
            title={
              <div
                className="flex-row flex-between-xxs flex-middle-xxs flex-row--noMargin"
                style={{ gap: 10 }}
              >
                <div className="flex-row flex-middle-xxs flex-row--noMargin">
                  <div className="TextHeading TextHeading--xxs u-marginBottom--none">
                    {this.state.returnObject.returnNumber}
                  </div>
                </div>
                <div className="flex-row flex-row--noMargin">
                  <Button
                    onClick={this.handleUpdate.bind(this)}
                    loading={this.state.isLoading}
                    type="primary"
                    style={{ marginRight: 10 }}
                  >
                    Update
                  </Button>
                  <Button onClick={() => this.props.hideDrawer()}>
                    Cancel
                  </Button>
                </div>
              </div>
            }
            placement="right"
            visible={this.props.drawerVisible}
            onClose={() => this.props.hideDrawer()}
            closable={false}
          >
            <div
              className="flex-row flex-row--noMargin"
              style={{ flexDirection: 'column', gap: 20 }}
            >
              <div
                className="flex-row"
              >
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--sm">Current status</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <Select
                    defaultValue={this.state.returnObject.returnStatus}
                    onChange={this.handleChange.bind(this)}
                    size="default"
                    style={{ width: '100%', minWidth: '213px' }}
                  >
                    <Option value="Pending">Pending</Option>
                    <Option value="Approved">Approved</Option>
                    <Option value="Shipped">Shipped</Option>
                    <Option value="In Transit">In Transit</Option>
                    <Option value="Received">Received</Option>
                    <Option value="Resolved">Resolved</Option>
                    <Option value="Rejected">Rejected</Option>
                  </Select>
                </div>
              </div>
              {this.state.returnObject.platform === 'manual' ? null : (
                <>
                  <div
                    className="flex-row"
                  >
                    <div className="flex-col-xxs-12">
                      <strong className="TextHeading TextHeading--xxxs u-marginBottom--sm">Order details</strong>
                    </div>
                    <div className="flex-col-xxs-12">
                      <Button
                        type="ghost"
                        href={
                          this.context.storeShopifyUrl +
                          '/admin/orders/' +
                          this.state.returnObject.orderId
                        }
                        target="_blank"
                        size="small"
                        style={{
                          padding: 'var(--input-padding)',
                          width: '100%',
                        }}
                      >
                        Go to order on Shopify
                      </Button>
                    </div>
                  </div>
                  {this.state.returnObject &&
                  this.state.returnObject.Refunds &&
                  this.state.returnObject.Refunds[0] ? (
                      <div
                        className="flex-row"
                      >
                        <div className="flex-col-xxs-12">
                          <div
                            className="flex-row flex-row--noMargin flex-middle-xxs"
                            style={{ gap: 5 }}
                          >
                            <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Refund</strong>
                            {refundError ? (
                              <Badge status="error" />
                            ) : (
                              <Badge 
                                status="success" 
                                text={
                                  <span>
                                    Refund issued ({refundCurrency}{" "}
                                    {refundTotal})
                                  </span>
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex-col-xxs-12">
                          <React.Fragment>
                            {refundError ? (
                              <Tooltip
                                title={refundErrorMessage}
                                placement="bottom"
                              >
                                <Button
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  View details
                                </Button>
                              </Tooltip>
                            ) : ""}
                          </React.Fragment>
                        </div>

                        <Collapse 
                          className="return-details-collapse"
                          style={{
                            backgroundColor: "transparent", 
                            marginLeft: "0.5rem", 
                            width: "100%",
                            boxShadow: "0 1px 3px 0px rgba(0,0,0,0.05)",
                            borderRadius: "5px",
                            border: "1px solid #d9d9d9",
                            marginTop: "1rem"
                          }} 
                          expandIcon={() => <></>}
                        >
                          <Panel header={<b>Refund Details</b>} key="1" style={{backgroundColor: "transparent", borderBottom: "none"}}>
                            {this.state.returnObject.Refunds.map((refund) => (
                              <div style={{display: "flex", flexDirection: "column", gap: "1rem", fontSize: "14px"}}>
                                <div>
                                  <p style={{ marginBottom: 0 }}>
                                   Refunded at
                                  </p>
                                  <p style={{ marginBottom: 0 }}>
                                    {moment(refund.createdAt).format(
                                      'MMM DD, YYYY [at] HH:mm A'
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <p style={{ marginBottom: 0 }}>
                                    Refund reason
                                  </p>
                                  <p style={{ marginBottom: 0 }}>
                                    {refund.note ?? '-'}
                                  </p>
                                </div>
                                <RefundSummary refund={refund} refundCurrency={refundCurrency} />
                              </div>
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                    ) : (!storeCreditExists &&
                      <div
                        className="flex-row"
                        style={{ gap: 10 }}
                      >
                        <div className="flex-col-xxs-12">
                          <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Refund this order?</strong>
                        </div>
                        <div className="flex-col-xxs-12">
                          <div
                            className="flex-row u-rowGap--md"
                          >
                            <div className="flex-col-sm-6 flex-col-xxs-12">
                              <ReturnDetailsRefundButton
                                returnId={this.state.returnObject.id}
                                returnObject={this.state.returnObject}
                                showStoreCreditDialog={false}
                                showRefundDialog={true}
                                handleGetReturn={this.handleGetReturn}
                              ></ReturnDetailsRefundButton>
                            </div>
                            <div className="flex-col-sm-6 flex-col-xxs-12">
                              <Button
                                href={
                                  this.context.storeShopifyUrl +
                                '/admin/orders/' +
                                this.state.returnObject.orderId +
                                '/refund'
                                }
                                target="_blank"
                                style={{
                                  width: '100%',
                                }}
                              >
                                Refund on Shopify
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {!(
                    this.context.featureStoreCredit &&
                    (this.context.featureGiftApiForStoreCredit ||
                      this.context.featureDiscountCodeForStoreCredit)
                  ) &&
                  (this.state.useGiftApiForStoreCredit ||
                    this.state.useDiscountCodeForStoreCredit) ? (
                      storeCreditExists ? (
                        <div
                          className="flex-row"
                        >
                          <div className="flex-col-xxs-12">
                            <div
                              className="flex-row flex-row--noMargin flex-between-xxs flex-middle-xxs"
                              style={{ gap: 5 }}
                            >
                              <div
                                className="flex-row flex-row--noMargin flex-middle-xxs"
                                style={{ gap: 5 }}
                              >
                                <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Store credit</strong>
                                <Tooltip
                                  placement="bottom"
                                  title="Store-Credit issued & sent to customer"
                                >
                                  <Badge status="success" />
                                </Tooltip>
                              </div>
                              <div
                                className="flex-row flex-row--noMargin flex-middle-xxs"
                                style={{ columnGap: 5 }}
                              >
                                <div className="TextHeading TextHeading--xxxs u-marginBottom--none">Credit total:</div>
                                <div className="TextBody TextBody--xxxs u-marginBottom--none">{storeCreditTotal}</div>
                                <div className="TextBody TextBody--xxxs u-marginBottom--none">{storeCreditCurrency}</div>
                              </div>
                            </div>
                          </div>
                          <div className="flex-col-xxs-12">
                            <Button
                              target="_blank"
                              href={
                                this.context.storeShopifyUrl +
                                  '/admin/gift_cards/' +
                                  storeCreditId
                              }
                            >
                                  View Gift-Card on Shopify
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="flex-row"
                        >
                          <div className="flex-col-xxs-12">
                            <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Issue store credit?</strong>
                          </div>
                          <div className="flex-col-xxs-12" >
                            <div
                              className="flex-row"
                            >
                              {this.context.featureStoreCredit && (
                                <div className="flex-col-sm-6 flex-col-xxs-12">
                                  <React.Fragment>
                                    <ReturnDetailsRefundButton
                                      returnId={this.state.returnObject.id}
                                      returnObject={this.state.returnObject}
                                      showStoreCreditDialog={true}
                                      showRefundDialog={false}
                                      handleGetReturn={this.handleGetReturn}
                                    ></ReturnDetailsRefundButton>
                                  </React.Fragment>
                                </div>
                              )}
                              <div className="flex-col-sm-6 flex-col-xxs-12">
                                <Button
                                  type="ghost"
                                  href={
                                    this.context.storeShopifyUrl +
                                  '/admin/gift_cards/new'
                                  }
                                  target="_blank"
                                >
                                Send gift card on Shopify
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : storeCreditExists ? (
                      <div
                        className="flex-row"
                      >
                        <div className="flex-col-xxs-12">
                          <div
                            className="flex-row flex-row--noMargin flex-between-xxs flex-middle-xxs"
                            style={{ gap: 5 }}
                          >
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs"
                              style={{ gap: 5 }}
                            >
                              <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Store credit</strong>
                              <Tooltip
                                placement="bottom"
                                title={
                                  <span>
                                    {storeCreditType === "discountCode" ? 
                                      "Discount Code issued & sent to customer" 
                                      : storeCreditType === "storeCredit" ? 
                                        "Store Credit issued & sent to customer" 
                                        : "Gift Card issued & sent to customer"}
                                  </span>
                                }
                              >
                                <Badge status="success" />
                              </Tooltip>
                            </div>
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs"
                              style={{ columnGap: 5 }}
                            >
                              <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Credit total:</strong>
                              <div className="TextBody TextBody--xxxs u-marginBottom--none">{storeCreditTotal}</div>
                              <div className="TextBody TextBody--xxxs u-marginBottom--none">{storeCreditCurrency}</div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-col-xxs-12">
                          {storeCreditType === 'discountCode' ? (
                            <div>
                              <Button
                                target="_blank"
                                href={
                                  this.context.storeShopifyUrl +
                                  '/admin/discounts'
                                }
                                style={{
                                  width: '100%',
                                }}
                              >
                                View discount on Shopify
                              </Button>
                            </div>
                          ) : storeCreditType === 'storeCredit' ? (
                            <div>
                              <Button
                                target="_blank"
                                href={
                                  this.context.storeShopifyUrl +
                                  "/admin/customers/" + 
                                  customerId + 
                                  "/store_credit_account/" +
                                  storeCreditId + 
                                  "/transactions?selectedView=all"
                                }
                                style={{
                                  width: '100%',
                                }}
                              >
                                View store credit on Shopify
                              </Button>
                            </div>
                            ) : (
                              <div>
                                <Button
                                  target="_blank"
                                  href={
                                    this.context.storeShopifyUrl +
                                    '/admin/gift_cards/' +
                                    storeCreditId
                                  }
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                    View gift card on Shopify
                                </Button>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    ) : ( this.context.featureStoreCredit && !(this.state.returnObject && this.state.returnObject.Refunds && this.state.returnObject.Refunds[0]) &&
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ gap: 10 }}
                      >
                        <div className="flex-col-xxs-12">
                          <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Store credit</strong>
                        </div>
                        <div
                          className="flex-col-xxs-12"
                          style={{
                            padding: 0,
                            flexDirection: 'column',
                            display: 'flex',
                            rowGap: 10,
                          }}
                        >
                          <div
                            className="flex-row" style={{rowGap:'15px'}}>
                            
                            {this.context.featureStoreCredit &&
                              this.context.featureGiftApiForStoreCredit &&
                              this.state.useGiftApiForStoreCredit && (
                              <React.Fragment>
                                <div className="flex-col-sm-6 flex-col-xxs-12">
                                  <ReturnDetailsRefundButton
                                    returnId={this.state.returnObject.id}
                                    returnObject={this.state.returnObject}
                                    showStoreCreditDialog={true}
                                    showRefundDialog={false}
                                    handleGetReturn={this.handleGetReturn}
                                    storeCreditType="GIFT_CARD"
                                  ></ReturnDetailsRefundButton>{' '}
                                </div>
                              </React.Fragment>
                            )}
                            
                            <div className="flex-col-sm-6 flex-col-xxs-12">
                              <Button
                                type="ghost"
                                href={
                                  this.context.storeShopifyUrl +
                                '/admin/gift_cards/new'
                                }
                                target="_blank"
                                style={{
                                  width: '100%',
                                }}
                              >
                                Gift card on Shopify
                              </Button>
                            </div>
                            
                            {this.context.featureStoreCredit &&
                              this.context.featureDiscountCodeForStoreCredit &&
                              this.state.useDiscountCodeForStoreCredit && (
                              <React.Fragment>
                                <div className="flex-col-sm-6 flex-col-xxs-12">
                                  <ReturnDetailsRefundButton
                                    returnId={this.state.returnObject.id}
                                    returnObject={this.state.returnObject}
                                    showStoreCreditDialog={true}
                                    showRefundDialog={false}
                                    handleGetReturn={this.handleGetReturn}
                                    storeCreditType="DISCOUNT_CODE"
                                  ></ReturnDetailsRefundButton>{' '}
                                </div>
                              </React.Fragment>
                            )}
                            
                            <div className="flex-col-sm-6 flex-col-xxs-12">
                              <Button
                                type="ghost"
                                href={
                                  this.context.storeShopifyUrl +
                                '/admin/discounts'
                                }
                                target="_blank"
                                style={{
                                  width: '100%',
                                }}
                              >
                                Discount on Shopify
                              </Button>
                            </div>

                            {this.state.useStoreCredit && (
                              <React.Fragment>
                                <div className="flex-col-sm-6 flex-col-xxs-12">
                                  <ReturnDetailsRefundButton
                                    returnId={this.state.returnObject.id}
                                    returnObject={this.state.returnObject}
                                    showStoreCreditDialog={true}
                                    showRefundDialog={false}
                                    handleGetReturn={this.handleGetReturn}
                                    storeCreditType="STORE_CREDIT"
                                  ></ReturnDetailsRefundButton>{' '}
                                </div>
                              </React.Fragment>
                            )}
                            
                            <div className="flex-col-sm-6 flex-col-xxs-12">
                              <Button
                                type="ghost"
                                href={
                                  this.context.storeShopifyUrl +
                                  "/admin/customers/" + 
                                  customerId
                                }
                                target="_blank"
                                style={{
                                  width: '100%',
                                }}
                              >
                                Store-credit on Shopify
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state.returnObject.restockingFeeValue ? (
                    <div
                      className="flex-row"
                    >
                      <div className="flex-col-xxs-12">
                        <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Restocking fee</strong>
                      </div>
                      <div className="flex-col-sm-6 flex-col-xxs-12">
                        <div className="TextBody TextBody--xxxs u-marginBottom--none">
                          {`${parseFloat(
                            this.state.returnObject.restockingFeeValue
                          ).toFixed(2)} ${
                            restockingFeeType === 'fixed'
                              ? this.state.returnObject.currency
                              : '%'
                          }`}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!this.state.returnObject.ruleMethodCustomerPaidLabel &&
                  this.state.returnObject.labelFeeValue ? (
                      <div
                        className="flex-row flex-row--noMargin"
                      >
                        <div className="flex-col-xxs-12">
                          <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Label fee</strong>
                        </div>
                        <div className="flex-col-sm-6 flex-col-xxs-12">
                          {`${parseFloat(
                            this.state.returnObject.labelFeeValue
                          ).toFixed(2)} ${this.state.returnObject.currency}`}
                        </div>
                      </div>
                    ) : null}
                  {this.state.labelService === 'shipstation' &&
                  this.state.labelStatus !== 'SUCCESS' ? (
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ gap: 10 }}
                      >
                        <div className="flex-col-xxs-12">
                          <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Shipping Date (ShipStation)</strong>
                        </div>
                        <div className="flex-col-sm-6 flex-col-xxs-12">
                          <DatePicker
                            onChange={this.onChange}
                            defaultValue={moment()}
                            disabledDate={(current) => {
                              return (
                                moment().add(-1, 'days') >= current ||
                              moment().add(7, 'days') <= current
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                </>
              )}
              <ReturnDetailsExchange
                returnObject={this.state.returnObject}
                orderIncludesExchangeItem={orderIncludesExchangeItem}
                storeShopifyUrl={this.context.storeShopifyUrl}
                handleGetReturn={this.handleGetReturn}
                featureExchangeOrders={this.context.featureExchangeOrders}
              />
              <ReturnDetailsLabelAutomatic
                returnObject={this.state.returnObject}
                handleGetReturn={this.handleGetReturn}
              />
              {this.state.returnObject.labelStatus === 'ERROR' &&
                this.state.returnObject.suggestAddressValidator && (
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gap: 10 }}
                >
                  <div className="flex-col-xxs-12">
                    <div
                      className="flex-row flex-row--noMargin flex-middle-xxs"
                      style={{ gap: 5 }}
                    >
                      <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Rich Returns Recommendations</strong>
                      <Icon type="bulb" className="bulbIcon" theme="filled" />
                    </div>
                  </div>
                  <div className="flex-col-xxs-12" style={{ padding: 0 }}>
                    <div
                      className="flex-row flex-row--noMargin flex-start-xxs"
                      style={{ rowGap: 10, flexDirection: 'column' }}
                    >
                      <div className="flex-col-xxs-12">
                        <div className="TextBody TextBody--xxxs u-marginBottom--none">
                          Address Validator Plus can eliminate 95% of label
                          issues.
                        </div>
                      </div>
                      <div className="flex-col-xxs-12">
                        <Button
                          href="https://apps.shopify.com/address-validator"
                          target="_blank"
                          size="small"
                          type="ghost"
                          style={{
                            padding: 'var(--input-padding)',
                            width: '100%',
                          }}
                        >
                            Try it here
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.showObservationField &&
                <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                  <div className="flex-col-xxs-12">
                    <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Rejection Observation</strong>
                  </div>
                  <div className="flex-col-xxs-12">
                    <TextArea
                      rows={4}
                      placeholder="Optional field. Observations of return rejection."
                      defaultValue={this.state.returnObject?.rejectionObservation}
                      onChange={this.handleRejectionObservation}
                    />
                  </div>
                </div>
              }
              {this.context.featureStripePayment && (
                <React.Fragment>
                  {this.state.returnObject.orderStatus === 'Paid' && (
                    <StripeWebhookDetails
                      headingText={'Order Payment Status'}
                      text={'Order payment successful'}
                      returnObject={this.state.returnObject}
                    />
                  )}
                  {this.state.returnObject.labelFeeStatus === 'Paid' && (
                    <StripeWebhookDetails
                      headingText={'Label Fee Status'}
                      text={'Label Fee has been paid'}
                      returnObject={this.state.returnObject}
                    />
                  )}
                  {this.state.returnObject.paymentErrorMessage && (
                    <StripeWebhookDetails
                      paymentError={true}
                      returnObject={this.state.returnObject}
                    />
                  )}
                </React.Fragment>
              )}
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Customer</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <div className="TextBody TextBody--xxxs u-marginBottom--none">
                    {this.state.returnObject.customerFirstName +
                      ' ' +
                      this.state.returnObject.customerLastName}
                  </div>
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Email</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <ReturnDetailsCustomer
                    buttonType="ghost"
                    handleGetReturn={this.handleGetReturn}
                    returnObject={this.state.returnObject}
                    buttonText={this.state.returnObject.customerEmail}
                  />
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <div
                    className="flex-row flex-row--noMargin flex-middle-xxs"
                    style={{ gap: 5 }}
                  >
                    <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Order number - name</strong>
                    <Tooltip
                      placement="bottom"
                      title="Order-Number is Shopify's internal and increasing number for orders. Order-Name can include things like pre- or postfixes. For the majority of Shopify Stores the Order-Number equals the Order-Name."
                    >
                      <Icon type="info-circle" style={{fontSize:'11px'}} />
                    </Tooltip>
                  </div>
                </div>
                <div className="flex-col-xxs-12">
                  <div className="TextBody TextBody--xxxs u-marginBottom--none">
                    {this.state.returnObject.orderName ? (
                      <span>
                        #{this.state.returnObject.orderNumber} /{' '}
                        {this.state.returnObject.orderName}
                      </span>
                    ) : (
                      <span>#{this.state.returnObject.orderNumber}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong  className="TextHeading TextHeading--xxxs u-marginBottom--none">Order date</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <div className="TextBody TextBody--xxxs u-marginBottom--none">
                    {moment(this.state.returnObject.orderDate).format(
                      'MMM DD, YYYY'
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Return requested</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <div className="TextBody TextBody--xxxs u-marginBottom--none">
                    {moment(this.state.returnObject.requestDate).format(
                      'MMM DD, YYYY'
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Time elapsed since return request</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <div className="TextBody TextBody--xxxs u-marginBottom--none">
                    {moment(this.state.returnObject.requestDate).diff(
                      this.state.returnObject.orderDate,
                      'hours'
                    )}{' '}
                  hours
                  </div>
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Return Source</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <Tag color="purple">
                    {this.state.returnObject?.returnSource === "shopmini" ? "Shop Mini" : "Web Portal"}
                  </Tag>
                </div>
              </div>
              <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Resolution</strong>
                </div>
                <div className="flex-col-xxs-12">
                  <Tag color="purple">
                    {this.state.returnObject.resolution &&
                  this.state.returnObject.resolution.includes('refundToCustom')
                      ? this.state.returnObject.resolutionLabel
                      : this.props.resolutionOptions[
                        this.state.returnObject.resolution
                      ]}
                  </Tag>

                  
                </div>
              </div>
              {this.context.featureMultipleAddress && (
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gap: 10, width: '100%' }}
                >
                  <div className="flex-col-xxs-12">
                    <div
                      className="flex-row flex-row--noMargin flex-middle-xxs"
                      style={{ gap: 5 }}
                    >
                      <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Select store address</strong>
                      <Tooltip
                        placement="bottom"
                        title="Select you required Store for correct return-label creation. Return-label Is created on he basis of selected store address."
                      >
                        <Icon type="info-circle" style={{fontSize:'11px'}} />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex-col-xxs-12">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={'Select Store Address'}
                      value={
                        this.state.selectedStoreAddress &&
                        this.state.selectedStoreAddress.id
                      }
                      onChange={(e) => this.handleOnChange(e)}
                    >
                      {this.state.storeAddress &&
                        this.state.storeAddress.length > 0 &&
                        this.state.storeAddress.map((address) => (
                          <Option value={address.id} key={address.id}>
                            {address.locationName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}
              <div
                className="flex-row flex-row--noMargin"
                style={{ gap: 10, width: '100%' }}
              >
                <div className="flex-col-xxs-12">
                  <div
                    className="flex-row flex-row--noMargin flex-middle-xxs"
                    style={{ gap: 5 }}
                  >
                    <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Return method</strong>
                    <Tooltip
                      placement="bottom"
                      title="To change the Return-Method to an automatic Prepaid-Label, make sure that the Status of the Return is 'Approved'. Otherwise a label will not be issued."
                    >
                      <Icon type="info-circle" style={{fontSize:'11px'}}/>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex-col-xxs-12">
                  <Select
                    style={{ width: '100%', minWidth: '213px' }}
                    defaultValue={this.returnMethodDefaultValue()}
                    onChange={(e) => this.setState({ returnMethod: e })}
                  >
                    <Option value="methodLabelCreation">
                      Pre-Paid Return Label (automatic)
                    </Option>
                    <Option value="methodLabelManual">
                      Pre-Paid Return Label (manual)
                    </Option>
                    <Option value="methodCustomerPaidLabel">
                      Customer is responsible for label
                    </Option>
                    <Option value="methodInStore">In-Store Return</Option>
                    <Option value="methodCustomerChoice">
                      Customer is responsible for shipping
                    </Option>
                    {this.context.isWarrantyAvailable &&
                      <Option value="warranty">
                        Warranty
                      </Option>
                    }
                  </Select>
                </div>
              </div>
              {(this.state.returnMethod === 'warranty') &&
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ gap: 10, width: '100%' }}
                >
                  <div className="flex-col-xxs-12">
                    <div
                      className="flex-row flex-row--noMargin flex-middle-xxs"
                      style={{ gap: 5 }}
                    >
                      <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Case Issues</strong>
                      <Tooltip
                        placement="bottom"
                        title="Select the case issue. It will be used to categorize the warranty related returns."
                      >
                        <Icon type="info-circle" style={{fontSize:'11px'}}/>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex-col-xxs-12">
                    {
                      this.state.warrantyCaseIssues?.length > 0 ?
                        <Select
                          style={{ width: '100%', minWidth: '213px' }}
                          placeholder={'Select Case Issue'}
                          defaultValue={this.state.returnObject?.caseIssue}
                          onChange={(e) => this.setState({ caseIssue: e })}
                        >
                          {
                            this.state.warrantyCaseIssues?.map((value) => {
                              return (
                                <Option value={value?.caseIssue} key={value?.position}>
                                  {value?.caseIssue}
                                </Option>
                              )
                            })
                          }
                        </Select>
                      : <span>Case issues can be customizd from {' '} 
                          <Link to="/dashboard/settings/warranties">Warranties</Link>{' '} menu.
                        </span>
                    }
                  </div>
                </div>
              }
              <ReturnDetailsLabelManual
                returnObject={this.state.returnObject}
                handleGetReturn={this.handleGetReturn}
                returnId={this.props.returnId}
              />
              <ReturnDetailsPhotoUpload
                featureCustomerPhotoUpload={
                  this.context.featureCustomerPhotoUpload
                }
                returnObject={this.state.returnObject}
                closeImageDialog={this.closeImageDialog}
                customerPhotoUrl1={this.state.returnObject.customerPhotoUrl1}
                customerPhotoUrl2={this.state.returnObject.customerPhotoUrl2}
                customerPhotoUrl3={this.state.returnObject.customerPhotoUrl3}
                photoUploads={this.state.returnObject.photoUploads}
              />

              <ReturnDetailsNoteStaff
                returnObject={this.state.returnObject}
                returnId={this.props.returnId}
                staffNotes={this.state.returnObject.staffNotes}
                handleGetReturn={this.handleGetReturn}
              />

              <ReturnDetailsTimeline returnId={this.props.returnId} />
              
              <div className="c-drawerProductDetail">
                <div className="flex-row flex-row--noMargin">
                  <div className="flex-col-xxs-12">
                    <Divider>Products</Divider>
                  </div>
                </div>

                <ReturnDetailsProduct
                  productsObject={productsObject}
                  featureExchangeOrders={this.context.featureExchangeOrders}
                  handleGetReturn={this.handleGetReturn}
                  returnObject={this.state.returnObject}
                  resolutionOptions={this.props.resolutionOptions}
                  handleUpdate={this.handleUpdate}
                  handleLoadingState={this.handleLoadingState}
                  updateData={this.props.updateData.bind(this)}
                  setVisibilityOfResetSearchButton={this.props.setVisibilityOfResetSearchButton.bind(
                    this
                  )}
                />
              </div>

              <div className="flex-col-xxs-12">
                <Popconfirm
                  title={
                    <React.Fragment>
                      <p className="TextBody TextBody--xxs">
                      Are you sure? By deleting this return, it will no longer be accessible and all actions already taken will not be undone. 
                      Once deleted, this return will not appear in reports, metrics, or analytics.
                      </p>
                    </React.Fragment>
                  }
                  onConfirm={() =>
                    this.handleSoftDeleteReturn()
                  }
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                  okButtonProps={{ 
                    danger: true,
                    style: { backgroundColor: '#c54f4d', color: '#FFF', border: 'none', }
                  }}
                >
      
                  <Button
                    loading={this.state.isLoading}
                    size="small"
                    className="delete-return-btn"
                    style={{
                      backgroundColor:'#c54f4d',
                      color: '#FFF',
                      border: 'none',
                      padding: 'var(--input-padding)',
                      width: '100%',
                    }}
                  >
                    Delete Return Request
                  </Button>
                </Popconfirm>
              </div>

              <ReturnDetailsNoteCustomer
                returnObject={this.state.returnObject}
              />
            </div>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

export default ReturnDetails;
