/**
 * Component to load the Table containing all of the Returns
 * within the main Returns-Dashboard.
 *
 * @component
 */

import { Badge, Button, Icon, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import '../../../../styles/App.css';
import ReturnDetails from './ReturnDetails';
import ReturnManagement from './ReturnManagement';
import { trackingVitally } from '../../../../helpers/vitally';

const typeColor = {
  return: {
    color: '#004085',
    backgroundColor: '##81ecec',
    borderColor: '#b8daff'
  },
  giftReturn: {
    width: '52px',
    textAlign: 'center',
    color: '#856404',
    backgroundColor: '#fdcb6e',
    borderColor: '#e17055'
  },
  warranty: {
    textAlign: 'center',
    color: '#856404',
    backgroundColor: '#fdcb6e',
    borderColor: '#e17055'
  },
};

class ReturnsTable extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      returnId: null,
      currentPage: 1,
      showIconToolTip: false,
      modalRecord: null,
    };
    this.resolutionOptions = {
      refundToOriginal: 'Refund',
      refundToExchange: 'Exchange',
      refundToCredit: 'Store credit',
      advancedExchange: 'Collection Exchange',
      multiple: 'Multiple',
      refundToCustom1: 'Custom 1',
      refundToCustom2: 'Custom 2',
      refundToCustom3: 'Custom 3',
      storeWideExchange: 'Store-Wide Exchange',
      warranty: "Warranty",
    };

    this.typeReturn = {
      return: "Return",
      giftReturn: "Gift",
      warranty: "Warranty",
    };

    this.statusColorMap = {
      Pending: 'orange',
      Approved: 'cyan',
      Rejected: 'magenta',
      'In Progress': 'blue',
    };

    this.columns = [
      {
        title:  <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Return <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'returnNumber',
        key: 'returnNumber',
        onHeaderCell: () => {
          return {
            onClick: () => this.props.handleSorter('returnNumber')
          };
        },
        className: 'filter',
      },
      {
        title: <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Status <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'returnStatus',
        key: 'returnStatus',
        render: (text) => {
          const color = this.statusColorMap[text] || 'blue';
          return (
            <span>
              <Tag color={color} key={text}>
                {text}
              </Tag>
            </span>
          );
        },
        onHeaderCell: () => {
          return {
            onClick: () => this.props.handleSorter('returnStatus')
          };
        },
        className: 'filter',
      },
      {
        title: <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Journey <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'returnStep',
        key: 'returnStep',
        render: (text, record) => (
          <Tooltip 
            title={
              <>
                {`Carrier: ${this.getCarrierName(record.labelRateProvider)}`}<br/>
                {this.context.featureAutoTracking ? null : `For ETA and Auto-Tracking services, see our Plus Plan in Account > Your Plan.`}
                {!(this.context.featureAutoTracking) 
                  ? null 
                  : (
                    <>
                      {`Order status: ${record.latestReturnStatusSubtype ? this.cleanOrderStatus(record.latestReturnStatusSubtype) : 'Unavailable'}`}
                      {record.statusSubtypeUpdated ? ` as of ${this.cleanISODate(record.statusSubtypeUpdated)}` : ''}
                      <br/>
                    </>
                  )
                }
                {!(this.context.featureAutoTracking) || (record.returnStatus === 'Resolved' || record.returnStatus === 'Rejected' || record.returnStatus === 'Received') 
                  ? null
                  : `Estimated Delivery: ${this.cleanISODate(record.estimatedDelivery)}`}
              </>
            } 
            placement={'topLeft'}
          >
            <Icon 
              type={this.getIconType(record)} 
              theme="twoTone"
              twoToneColor={this.getIconColor(record)}
              style={{
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'left',
              }}
            >
            </Icon>
          </Tooltip>
        ),
        onHeaderCell: () => {
          return {
            onClick: () => this.props.handleSorter('journey')
          };
        },
        className: 'filter',
      },
      {
        title: <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Label <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'errors',
        key: 'errors',
        render: (text, record) => (
          <React.Fragment>
            {record.labelStatus === 'ERROR' ? (
              <div className="u-width--100" style={{ textAlign: 'center' }}>
                <Tooltip
                  placement="top"
                  title={
                    record.labelErrorTransactionSource
                      ? record.labelErrorTransactionSource +
                        ': ' +
                        record.labelErrorTransactionText +
                        ' (label could not be created & sent to customer)'
                      : record.labelErrorTransactionText +
                        ' (label could not be created & sent to customer).'
                  }
                >
                  <Badge status="error" />
                </Tooltip>
              </div>
            ) : (
              <div className="u-width--100" style={{ textAlign: 'center' }}>
                <Tooltip
                  placement="top"
                  title={'Shipping label has been successfully created.'}
                >
                  <Badge status="success" />
                </Tooltip>
              </div>
            )}
          </React.Fragment>
        ),
        onHeaderCell: () => {
          return {
            onClick: () => this.props.handleSorter('labelStatus')
          };
        },
        className: 'filter',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text) => (
          <span>
            <Tag style={typeColor[text]} key={text}>{this.typeReturn[text]}</Tag>
          </span>
        ),
      },
      {
        title: <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Order <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'orderName',
        key: 'orderName',
        render: (text, record) => {
          if (record.orderName) {
            return (
              <React.Fragment>
                <span>{record.orderName}</span>
              </React.Fragment>
            );
          } else {
            return <span>{record.orderNumber}</span>;
          }
        },
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.props.handleSorter('orderName');
            },
          };
        },
        className: 'filter',
      },
      {
        title: <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Customer <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'customerFirstName',
        key: 'customerFirstName',
        render: (text, record) => (
          <span style={{ textTransform: 'capitalize' }}>
            {record.customerFirstName + ' ' + record.customerLastName}
          </span>
        ),
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.props.handleSorter('customerFirstName');
            },
          };
        },
        className: 'filter',
      },
      {
        title:  <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Resolution <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'resolution',
        key: 'resolution',
        render: (text, record) => {
          const isCustomResolution =
            record.resolution && record.resolution.includes('refundToCustom');
          if (isCustomResolution) {
            const customResolutionLabel = record.resolutionLabel;
            return (
              <Tooltip placement="top" title={customResolutionLabel}>
                <span>{this.resolutionOptions[record.resolution]}</span>
              </Tooltip>
            );
          } else {
            return record.resolution
              ? this.resolutionOptions[text]
              : 'Multiple';
          }
        },
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.props.handleSorter('resolution');
            },
          };
        },
        className: 'filter',
      },
      {
        title:  <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}> Request date <Icon type="shrink" rotate={135} /> </span>,
        dataIndex: 'requestDate',
        key: 'requestDate',
        render: (text, record) => (
          <span> {moment(record.requestDate).format('MMM DD, YYYY')} </span>
        ),
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.props.handleSorter('createdAt');
            },
          };
        },
        className: 'filter',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <Button
              type="default"
              size="small"
              onClick={() => {
                this.setState({ drawerVisible: true, returnId: record.id }),
                trackFSEvent('Click Manage Return Order', {
                  feature: `Return Setting`,
                });
                trackingVitally('rr_managereturnorder_clicked');
              }}
            >
              Manage
            </Button>
          );
        },
      },
    ];
  }


  getIconType = (record) => {
    const currentStep = this.getCurrentStep(record);
    switch (currentStep) {
    case -1:
      return 'info-circle';
    case 0:
      return 'credit-card';
    case 1:
      return 'mail';
    case 2:
      return 'car';
    case 3:
      return 'check-circle';
    case 4: 
      return 'close-square';
    default:
      return 'loading';
    }
  };

  getIconColor = (record) => {
    const currentStep = this.getCurrentStep(record);
    switch (currentStep) {
    case -1:
      return '#CCAC00';
    case 0:
      return '#eb2f96';
    case 1:
      return '#ff6600';
    case 2:
      return '#2db7f5';
    case 3:
      return '#52c41a';
    case 4:
      return 'red';
    default:
      return '#67C2C2';
    }
  }

  cleanOrderStatus = (orderStatus) => {
    if (!orderStatus) {
      return "Unavailable";
    }
    const words = orderStatus.split('_');
    const cleanedStatus = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return cleanedStatus.join(' ');
  }

  cleanISODate = (date) => {
    if (!date) {
      return "Unavailable";
    }
    return new Date(date)
      .toISOString()
      .replace('T', ' ')
      .replace(/\.\d{3}/, '')
      .replace('Z', ' (UTC)');
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.props.setSelectedRows(selectedRowKeys, selectedRows);
  };

  triggerPagination = (page) => {
    const paginationObject = {
      current: page,
      pageSize: 10,
    };
    this.props.paginatePages(paginationObject);
    this.setState({
      currentPage: paginationObject.current,
    });
  };

  hideDrawer = () => {
    this.setState({
      drawerVisible: false,
    });
    if (!this.props.searchValue) {
      this.props.resetSearchResults(this.state.currentPage);
    } else if (this.props.searchValue) {
      this.props.searchTerm(this.props.searchValue);
    }
  };

  getCurrentStep = (returnObject) => {
    const statusToStepMap = {
      "Pending": 0,
      "Approved": 1,
      "Shipped": 2,
      "In Transit": 2,
      "Received": 3,
      "Resolved": 3,
      "Rejected": 4,
    };
    const orderStatus = returnObject.returnStatus;
    if (orderStatus == null || orderStatus == '') {
      return -1;
    }
    return statusToStepMap[orderStatus];
  }

  getCarrierName(carrierCode) {
    if (!carrierCode) return 'could not be retrieved.';

    const carrierNameMappings = {
      'stamps_com': 'Stamps.com',
    };

    const normalizedCarrierCode = carrierCode.toLowerCase();
    if (carrierNameMappings.hasOwnProperty(normalizedCarrierCode)) {
      return carrierNameMappings[normalizedCarrierCode];
    }
  
    // List of common carrier acronyms
    const carriers = [
      'UPS', 'USPS', 'FEDEX', 'DHL', 'EMS', 'TNT', 'DPD', 'CANADA POST',
      'ROYAL MAIL', 'AUSPOST', 'PUROLATOR', 'GLS', 'HERMES', 'LASERSHIP',
      'ONTRAC', 'FASTWAY', 'SENDLE', 'ASENDIA', 'ARAMEX', 'YAMATO', 'SEUR',
      'CHRONOPOST', 'COLISSIMO', 'POSTNL', 'BPOST', 'CORREOS', 'LA POSTE',
      'AUSTRALIA POST'
    ];
  
    let carrierName = carrierCode.replace(/_/g, ' ');
  
    const carrierAcronym = carrierName.split(' ')[0].toUpperCase();
    if (carriers.includes(carrierAcronym)) {
      return carrierAcronym;
    }
  
    carrierName = carrierName
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  
    return carrierName;
  }

  render() {
    // if (isEmpty(this.props.returns)) {
    //   return <Table columns={this.emptyColumns} dataSource={this.data} pagination={false} />
    // }

    const rowSelection = {
      onChange: this.onSelectChange,
    };
    return (
      <React.Fragment>
        <div>
          <div style={{ overflowX: 'auto' }}>
            <Table
              rowSelection={rowSelection}
              columns={this.columns}
              rowKey="returnNumber"
              dataSource={this.props.returns}
              pagination={{
                total: this.props.returnsCount,
                current: this.props.currentPage,
                pageSize: 10,
                onChange: (page) => this.triggerPagination(page),
              }}
              style={{ minWidth: '768px' }}
              locale={{
                emptyText: (
                  <div className="flex-row flex-middle-xxs flex-center-xxs">
                    <div className="flex-col-xxs-12">
                      <IconTray size="md" />
                    </div>
                    <div>
                      <p className="TextBody TextBody--xxxs">No Data</p>
                    </div>
                  </div>
                ),
              }}
            />
            {this.state.drawerVisible ? (
              <ReturnManagement
                returnId={this.state.returnId}
                drawerVisible={this.state.drawerVisible}
                resolutionOptions={this.resolutionOptions}
                hideDrawer={this.hideDrawer.bind(this)}
                updateData={this.props.updateData.bind(this)}
                setVisibilityOfResetSearchButton={this.props.setVisibilityOfResetSearchButton.bind(
                  this
                )}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReturnsTable;
