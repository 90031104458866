/**
 * Component for our Analytics Graphs pages
 * including navigation and routing for different
 * graphs.
 *
 * @component
 */

import { Tabs } from 'antd';
import { Component, createRef } from 'react';

import moment from 'moment';
import Page from '../../../../components/Page';
import AdvancedExchange from './graphs/advancedExchangeVolume';
import ExchangeVolume from './graphs/ExchangeVolume';
import Overview from './graphs/Overview';
import RefundVolume from './graphs/RefundVolume';
import ReturnVolume from './graphs/ReturnVolume';
import StoreCreditVolume from './graphs/StoreCreditVolume';



const { TabPane } = Tabs;

class Trends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      defaultActiveKey: props.defaultActiveKey,
    };
    this.isMountedRef = createRef(true);
  }

  componentDidMount() {
    this.isMountedRef.current = true;
  }

  componentWillUnmount() {
    this.isMountedRef.current = false;
  }

  updateDate = (dateStrings) => {
    if (this.isMountedRef.current) {
      this.setState({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    }
  };

  render() {
    return (
      <>
        <div className="u-marginBottom--lg">
          <h1 className="TextHeading TextHeading--sm u-marginBottom--md">Trends</h1>

          <div className="ContentPanel  u-paddingTop--sm">
            <Tabs defaultActiveKey={this.props.defaultActiveKey || "1"} animated={false}>
              <TabPane tab="Overview" key="1">
                <Page
                  component={() => (
                    <Overview
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateDate={this.updateDate}
                    />
                  )}
                  title="Overview"
                />
              </TabPane>

              <TabPane tab="Returns" key="2">
                <Page
                  component={() => (
                    <ReturnVolume
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateDate={this.updateDate}
                    />
                  )}
                  title="Return Volume"
                />
              </TabPane>
              <TabPane tab="Refunds" key="3">
                <Page
                  component={() => (
                    <RefundVolume
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateDate={this.updateDate}
                    />
                  )}
                  title="Refund Volume"
                />
              </TabPane>
              <TabPane tab="Exchanges" key="4">
                <Page
                  component={() => (
                    <ExchangeVolume
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateDate={this.updateDate}
                    />
                  )}
                  title="Exchange Volume"
                />
              </TabPane>
              <TabPane tab="Collection exchanges" key="5">
                <Page
                  component={() => (
                    <AdvancedExchange
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateDate={this.updateDate}
                    />
                  )}
                  title="Collection Exchange Volume"
                />
              </TabPane>
              <TabPane tab="Store credits" key="6">
                <Page
                  component={() => (
                    <StoreCreditVolume
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      updateDate={this.updateDate}
                    />
                  )}
                  title="Store Credit Volume"
                />
              </TabPane>
            </Tabs>
          </div>
        </div>

        {/* <Row>
        <Col span={4}>
          <Menu
            mode="inline"
            onClick={this.handleClick}
            defaultSelectedKeys={['/dashboard/analytics/trends/return-volume']}
            selectedKeys={[location.pathname]}
          >
            <Menu.ItemGroup key="return" title="Return">
              <Menu.Item key="/dashboard/analytics/trends/return-volume">
                <Link to="/dashboard/analytics/trends/return-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="refund" title="Refund">
              <Menu.Item key="/dashboard/analytics/trends/refund-volume">
                <Link to="/dashboard/analytics/trends/refund-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="exchange" title="Exchange">
              <Menu.Item key="/dashboard/analytics/trends/exchange-volume">
                <Link to="/dashboard/analytics/trends/exchange-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="store-credit" title="Store Credit">
              <Menu.Item key="/dashboard/analytics/trends/store-credit-volume">
                <Link to="/dashboard/analytics/trends/store-credit-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="Collection-Exchange" title="Collection Exchange">
              <Menu.Item key="/dashboard/analytics/trends/advanced-exchange-volume">
                <Link to="/dashboard/analytics/trends/advanced-exchange-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="compare" title="Compare">
              <Menu.Item key="/dashboard/analytics/trends/overview">
                <Link to="/dashboard/analytics/trends/overview">
                  <span>Overview</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Col>
        <Col span={20}>
          <Content
            style={{
              paddingLeft: 24,
              background: '#fff',
              minHeight: 600,
            }}
          >
            <Switch>
              <Route
                exact
                path="/dashboard/analytics/trends/return-volume"
                render={() => (
                  <Page
                    component={() => (
                      <ReturnVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Return Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/refund-volume"
                render={() => (
                  <Page
                    component={() => (
                      <RefundVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Refund Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/exchange-volume"
                render={() => (
                  <Page
                    component={() => (
                      <ExchangeVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Exchange Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/store-credit-volume"
                render={() => (
                  <Page
                    component={() => (
                      <StoreCreditVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Store Credit Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/advanced-exchange-volume"
                render={() => (
                  <Page
                    component={() => (
                      <AdvancedExchange
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Collection Exchange Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/overview"
                render={() => (
                  <Page
                    component={() => (
                      <Overview
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Overview"
                  />
                )}
              />
              <Route path="*" component={ErrorRedirect} />
            </Switch>
          </Content>
        </Col>
      </Row> */}
      </>
    );
  }
}

export default Trends;
