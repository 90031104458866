/**
 * Component for Collection-Exchanges settings page
 * that let's a sort selected collections from Shopify.
 * 
 * @component
 */

import { Button, Table } from "antd";
import React from "react";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IconTray } from "../../../../../components/CustomReturnsIcons";

let draggingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > draggingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < draggingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    draggingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  // monitor is the item to be dragged
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

class DraggableCollectionList extends React.Component {
  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  columns = [
    {
      title: "Collection Title",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button type="ghost" onClick={() => this.removeCollection(record)}>Delete</Button>
        </span>
      ),
    },
  ];
  removeCollection = (record) => {
    this.props.removeCollection(record);
  };
  moveRow = (oldIndex, newIndex) => {
    const { collections } = this.props;
    const dragRow = collections[oldIndex];

    if (oldIndex === newIndex) return;

    let start, end;
    if (oldIndex > newIndex) {
      start = newIndex;
      end = oldIndex;
    } else {
      start = oldIndex;
      end = newIndex;
    }

    //determining starting position of newly arrange elements
    let startingPosition =
      start !== 0 ? collections[start - 1].position + 1 : 1;

    // Swapping the collection's position in the array to see the order visually
    collections.splice(oldIndex, 1);
    collections.splice(newIndex, 0, dragRow);

    // assign new position to collections
    for (let i = start; i <= end; i++) {
      collections[i].position = startingPosition;
      startingPosition++;
    }

    //replacing the collection with new positions
    this.props.updateCollections(collections);
  };

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <div style={{ overflowX: "auto" }}>
          <div style={{ minWidth: 768 }}>
            <Table
              style={{ paddingBottom: 5, paddingTop: 5 }}
              pagination={false}
              bordered={false}
              columns={this.columns}
              dataSource={this.props.collections}
              components={this.components}
              rowKey={(record) => record.collectionId}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              locale={{
                emptyText: (
                  <div className="flex-row flex-middle-xxs flex-center-xxs">
                    <div className="flex-col-xxs-12">
                      <IconTray size="md" />
                    </div>
                    <div>
                      <p className="TextBody TextBody--xxxs">No Data</p>
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </DndProvider>
    );
  }
}

export default DraggableCollectionList;
