/**
 * Component for Collection-Exchanges settings page
 * that let's a user add a new Exchange-option.
 *
 * @component
 */

import React from 'react';
import axios from 'axios';
import {
  Form,
  Input,
  Row,
  Button,
  Typography,
  Switch,
  Select,
  Col,
  Card,
  message,
} from 'antd';
import DraggableCollectionList from './DraggableCollectionList';
import { trackFSEvent } from '../../../../../helpers/fullstory';
import { trackingVitally } from '../../../../../helpers/vitally';

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;
class AddAdvancedExchange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shopifyCollections: [],
      selectedCollectionId: '',
      advancedExchangeId: this.props.advancedExchangeId
        ? this.props.advancedExchangeId
        : null,
      active: this.props.active ? true : false,
      productName: this.props.productName ? this.props.productName : '',
      showProductTitle: this.props.showProductTitle ? true : false,
      collections: this.props.collections ? this.props.collections : [],
      numberOfExchangeOptions: this.props.numberOfExchangeOptions || 0,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(
        '/api/advanced-exchanges/shopify/collections'
      );
      if (response.data == null) {
        return;
      }
      this.setState({
        shopifyCollections: response.data,
      });
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }

    if (this.props.setSaveHandler) {
      this.props.setSaveHandler(this.handleSubmit);
    }
  }

  handleActiveChange() {
    this.setState({
      active: !this.state.active,
    });
  }

  handleKeyChange = (e) => {
    const target = e.target.id;
    const value = e.target.value.trim();

    this.setState({
      [target]: value,
    });
  };

  handleShowProductTitleChange() {
    this.setState({
      showProductTitle: !this.state.showProductTitle,
    });
  }
  updateCollections(newCollections) {
    this.setState({
      collections: newCollections,
    });
  }
  onChange(value) {
    let collections = this.state.collections;
    let index = collections.findIndex(
      (collection) => collection.collectionId === value
    );

    if (index !== -1) {
      return message.error('Collection already exists.', 5);
    }
    let shopifyCollections = this.state.shopifyCollections;

    let position = 0;
    for (let i = 0; i < collections.length; i++) {
      let collection = collections[i];
      if (collection.position >= position) {
        position = collection.position;
      }
    }

    position++;
    index = shopifyCollections.findIndex(
      (collection) => collection.id === value
    );
    if (index !== -1) {
      let newCollections = [
        ...collections,
        {
          collectionId: shopifyCollections[index].id,
          title: shopifyCollections[index].title,
          position,
        },
      ];
      this.setState({
        collections: newCollections,
        selectedCollectionId: '',
      });
    }
  }

  removeCollection(collectionToBeRemoved) {
    let { collections } = this.state;
    let newCollections = collections.filter(
      (collection) =>
        collection.collectionId !== collectionToBeRemoved.collectionId
    );
    this.setState({
      collections: newCollections,
    });
  }

  handleSubmit = async () => {
    let {
      advancedExchangeId,
      active,
      collections,
      showProductTitle,
      productName,
    } = this.state;
    if (collections.length > 0 && productName) {
      try {
        let response = await axios.post('/api/advanced-exchanges', {
          advancedExchangeId,
          active,
          collections,
          showProductTitle,
          productName,
        });
        if (advancedExchangeId) {
          trackFSEvent('Edit Collection Exchange', {
            feature: 'Exchanges',
            numberOfExchangeOptions: this.state.numberOfExchangeOptions,
            exchangeOptionName: productName,
            exchangeCollectionsForMatchProducts: collections
              .map((i) => i.title)
              .join(','),
            enableExchangeOption: active,
          });
        } else {
          trackFSEvent('Add Collection Exchange', {
            feature: 'Exchanges',
            numberOfExchangeOptions: this.state.numberOfExchangeOptions + 1,
            exchangeOptionName: productName,
            exchangeCollectionsForMatchProducts: collections
              .map((i) => i.title)
              .join(','),
            enableExchangeOption: active,
          });
          trackingVitally('rr_collectionexchange_added');
        }
        if (response.status === 200) {
          this.props.resetEditArguments();
          return message.success(
            `Collection Exchanges Option ${
              advancedExchangeId ? 'updated' : 'created'
            } successfully.`,
            3
          );
        }
      } catch (err) {
        return message.error('Error while saving the exchange options.', 3);
      }
    } else {
      return message.error(
        'At least one exchange option and product name are required.',
        3
      );
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { collections } = this.state;
    return (
      <div>
        <div layout="vertical">
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card
                size="small"
                title="Active"
                extra={
                  <Switch
                    checked={this.state.active}
                    onChange={this.handleActiveChange.bind(this)}
                  />
                }
              >
                <Text type="primary" style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                  Enable this Exchange Option on the Returns-Portal
                </Text>
              </Card>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Matching term">
                <p>
                  Add either the exact Name of a Product you want us to match or
                  a word that matches multiple Product names. We'll search for
                  your term in the Product Name (not case-sensitive). Products
                  that match your term will then show an additional
                  exchange-option for the customer with products from your
                  collections below.
                </p>
                <FormItem>
                  {getFieldDecorator('productName', {
                    initialValue: this.state.productName,
                  })(
                    <Input
                      placeholder="Enter Product Name"
                      onChange={(e) => this.handleKeyChange(e)}
                    />
                  )}
                </FormItem>
              </Card>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Add a Collection">
                <p>
                  Adding a collection from your Store will expand the selection
                  of items that the customer can choose from. Maximum of 3
                  collections can be added.
                </p>
                <FormItem noStyle>
                  <Select
                    disabled={collections && collections.length > 2}
                    value={this.state.selectedCollectionId}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear={true}
                    onChange={this.onChange.bind(this)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.shopifyCollections.map((collection) => (
                      <Option key={collection.id} value={collection.id}>
                        {collection.title}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Card>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingBottom: 20 }}
          >
            <Col span={22}>
              <Card size="small" title="Selected Collections">
                <p>
                  Products from these Collections will be shown to the customer
                  as exchange options to their original product. If you select
                  more than one Collection we will display the products in the
                  order of the Collections selected here. Use Drag & Drop to
                  change positions.
                </p>
                <DraggableCollectionList
                  collections={collections}
                  updateCollections={this.updateCollections.bind(this)}
                  removeCollection={this.removeCollection.bind(this)}
                />
              </Card>
            </Col>
          </Row>

          {/*

              <Row
                type="flex"
                justify="space-between"
                align="middle"
                style={{ paddingBottom: 20 }}
              >
                <Text type="primary" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  Show Product Titles
                </Text>

                <Switch
                  checked={this.state.showProductTitle}
                  onChange={this.handleShowProductTitleChange.bind(this)}
                />
                <span>
                  Display the product title alongside the product image. Otherwise
                  we'll only show a gallery with the product image alone.
                </span>
              </Row>

            */}

          <Row
            type="flex"
            justify="space-around"
            align="top"
            className="u-marginTop--xlg"
          >
            <Col span={22}>
              <FormItem>
                <Row type="flex" justify="end" align="top" className="u-columnGap--md">
                  <Button onClick={this.props.toggleAddExchangeForm}>
                    Cancel
                  </Button>{' '}
                  <Button onClick={this.handleSubmit} type="primary">
                    Save changes
                  </Button>
                </Row>
              </FormItem>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="center"
            align="middle"
            className="dashboard-content"
          >
            <FormItem></FormItem>
          </Row>
        </div>
      </div>
    );
  }
}

const WrappedAddAdvancedExchange = Form.create()(AddAdvancedExchange);
export default WrappedAddAdvancedExchange;
