/**
 * Component for Warranty-Returns settings page in the Dashboard.
 *
 * @component
 */

import {
    Alert,
    Button,
    Col,
    Collapse,
    Divider,
    Row,
    Table,
    Tag,
    message
  } from 'antd';
  import axios from 'axios';
  import React from 'react';
  import AddWarranty from './components/AddWarranty';
  import ReturnCusomizeQuery from '../returns/components/ReturnCustomizeQuery';
  import ReturnNoteCusomize from '../returns/components/ReturnNoteCustomize';
  import { IconTray } from '../../../../components/CustomReturnsIcons';
  import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
  
  const { Panel } = Collapse;
  
  class WarrantyReturns extends React.Component {
    static contextType = SubscriptionContext;
  
    constructor(props) {
      super(props);
      this.state = {
        displayAddWarrantySection: false,
        loading: true,
        fieldIndex: undefined,
        warrantyFields: [],
        showModal: false,
        modalVisible: false,
        modalCustomize: false,
        modalCustomQuery: false,
        indexModalCustomQuery: null,
        warrantyAdditionalFields: [],
      };
    }
  
    async componentDidMount() {
      try {
        const response = await axios.get('/api/returnSettings');
        if (response.data == null) {
          return;
        }
        this.setState({
          ...response.data,
          warrantyFields: response.data.warrantyFields,
          loading: false
        });
      } catch (err) {
        this.setState({
          loading: false
        });
        message.error(`Error getting Return Settings from the server`);
      }
  
      if (this.props.setCancelHandler) {
        this.props.setCancelHandler(this.toggleAddExchangeForm);
      }
    }
  
    columns = [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'warrantyTitle',
      },
      {
        title: 'Time Limit',
        key: 'timeLimit',
        dataIndex: 'warrantyTimeLimit',
        render: (text) => {
          return <Tag color={'cyan'}>{text}</Tag>;
        },
      },
      {
        title: 'Product List',
        key: 'warrantyProducts',
        dataIndex: 'warrantyProducts',
        render: (products, record) => {
          return (
            <div
              style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
              {products.map((value) => {
                return (
                  <Tag color="gold" key={
                      value.variantTitle
                        ? `${value.title}(${value.variantTitle})`
                        : value.title
                    }
                    style={{ marginBottom: 3 }}
                  >
                    {value.variantTitle
                      ? `${value.title}(${value.variantTitle})`
                      : value.title}
                  </Tag>
                );
              })}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record, index) => (
          <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
            <Button type="ghost" size="small" onClick={() => this.editWarrantyField(index)}>Edit</Button>
            <Button type="ghost" size="small" onClick={() => this.handleDelete('warranty',record)}>Delete</Button>
          </div>
        ),
      },
    ];
  
    handleDelete = async (type,record) => {
      const payload = {};
      if (type === 'warranty') {
        const warrantyFields = this.state.warrantyFields?.filter(field => 
          !(field.warrantyTitle === record.warrantyTitle && field.warrantyTimeLimit === record.warrantyTimeLimit)
        );
        payload.warrantyFields = warrantyFields;
      }
      try {
        const response = await axios.post('/api/returnSettings/update', payload);
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your warranty settings. Please try again.',
            5
          );
        }
        this.setState({
          warrantyFields: response.data?.warrantyFields,
        });
        return message.success('Successfully deleted.', 5);
      } catch (err) {
        console.log("errerrerrerrerr",err)
        return message.error(
          `Error deleting the ${type === 'warranty' ? 'Warranty' : 'Field'
          }. Please try again.`,
          5
        );
      }
    };
  
    toggleAddExchangeForm = () => {
      this.setState({
        fieldIndex: undefined,
        displayAddWarrantySection: !this.state.displayAddWarrantySection,
      });
      this.props.setShowAdvancedExchangesButtons(!this.state.displayAddWarrantySection);
    };
  
    async editWarrantyField(index) {
      this.setState({
        fieldIndex: index + 1,
        displayAddWarrantySection: true,
      });
    }
  
    async resetEditArguments() {
      this.setState(
        {
          displayAddWarrantySection: false,
          fieldIndex: undefined,
        }
      );
      this.props.setShowAdvancedExchangesButtons(false);
    }

    //Handle open note customize
    showCustomizeNoteModal = () => {
      this.setState({
        modalCustomize: true,
      });
    };

    handleModalCustomize = () => {
      this.setState({
        modalCustomize: false,
      });
    };

    handleCancelModalCustomize = () => {
      this.setState({
        modalCustomize: false,
      });
    };
  
    //Handle open customize query
    showCustomizeQueryModal = (position) => {
      this.setState({
        modalCustomQuery: true,
        indexModalCustomQuery: position === null ? null : position,
      });
    };
  
    handleCustomizeQueryModal = () => {
      this.setState({
        modalCustomQuery: false,
      });
    };
  
    handleCancelCustomizeQueryModal = () => {
      this.setState({
        modalCustomQuery: false,
      });
    };
  
    render() {
      if (this.state.loading) {
        return (
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <div className="GlobalLoader GlobalLoader--md"></div>
          </div>
        );
      }

      const warrantyAdditionalFields = this.state.warrantyAdditionalFields || [];

      return (
        <React.Fragment>
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="top"
              className="u-marginBottom--md"
            >
              <Col span={22}>
                <p>
                  Offer different warranties to customers for returns.
                </p>
                <Alert
                  message="Important"
                  className="u-marginTop--md"
                  description={
                    <React.Fragment>
                      <span>
                        Create multiple warranties for a list of products for a specific time period.
                        Customize notes and questions, which can also be configured for warranty-type returns.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" align="top">
              <Col span={22} style={{overflowX: 'auto'}}>
                <div className="flex-row flex-top-xxs flex-between-xxs u-rowGap--sm">
                  <div className="flex-col-md-3 flex-col-xxs-12">
                    <h4>Customize customer journey</h4>
                    <span>
                      Create custom queries to communicate with your customers for warranty type returns. 
                      You can make the customer notes section optional or required. You
                      can also add additional questions and create checkboxes or
                      radio-buttons to get the information you need from your
                      customers.
                    </span>
                  </div>
                  <div className="flex-col-md-8 flex-col-xxs-12">
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        <Col span={22}>
                          <h4>Customize notes</h4>
                          <Row
                            className="u-marginBottom--md u-rowGap--sm"
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <Col>
                              <p className="u-marginBottom--sm">If you would like to edit customer notes, click the Customize button.</p>
                            
                              {this.state.modalCustomize ? (
                                <ReturnNoteCusomize
                                  visible={this.state.modalCustomize}
                                  onOk={this.handleModalCustomize}
                                  onCancel={this.handleCancelModalCustomize}
                                  isWarrantySettings={true}
                                />
                              ) : null}
                            
                              <Button
                                type="custom-button"
                                onClick={this.showCustomizeNoteModal}
                              >
                                Customize
                              </Button>
                            </Col>
                          </Row>

                          {warrantyAdditionalFields && warrantyAdditionalFields.length > 0 && (
                            <React.Fragment>
                              <h4>Questions</h4>
                              <Row type="flex" className="u-width--100 u-marginBottom--md u-rowGap--sm">
                                {warrantyAdditionalFields.map((item, index) => {
                                  return (
                                    <Row
                                      key={index}
                                      type="flex"
                                      justify="space-between"
                                      align="middle"
                                      className="u-rowGap--sm u-width--100"
                                    >
                                      {this.state.modalCustomQuery &&
                                      this.state.indexModalCustomQuery === index ? (
                                          <ReturnCusomizeQuery
                                            position={index}
                                            visible={this.state.modalCustomQuery}
                                            onOk={this.handleCustomizeQueryModal}
                                            onCancel={
                                              this.handleCancelCustomizeQueryModal
                                            }
                                            additionalFields={warrantyAdditionalFields}
                                            isWarrantySettings={true}
                                          />
                                        ) : null}
                                      <Col style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        Q{index + 1}: Type: {item.type}. Title:{' '}
                                        {item.title}
                                      </Col>
                                      <Col
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'end',
                                        }}
                                      >
                                        <Button
                                          className="custom-button"
                                          onClick={() => {
                                            this.showCustomizeQueryModal(index);
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Row>
                            </React.Fragment>
                          )}
                          <h4>Add new questions</h4>
                          <Row
                            className="u-marginBottom--md u-rowGap--sm"
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <Col>
                              <p className="u-marginBottom--sm">
                              If you would like to include multiple questions in your customer's journey, click the Add button.
                              </p>
                            
                              {this.state.modalCustomQuery &&
                              (this.state.indexModalCustomQuery === null ||
                                this.state.indexModalCustomQuery === undefined) ? (
                                  <ReturnCusomizeQuery
                                    visible={this.state.modalCustomQuery}
                                    onOk={this.handleCustomizeQueryModal}
                                    onCancel={this.handleCancelCustomizeQueryModal}
                                    additionalFields={warrantyAdditionalFields}
                                    isWarrantySettings={true}
                                  />
                                ) : null}
                              <Row
                                style={{ display: 'flex', justifyContent: 'flex-start' }}
                              >
                                <Button
                                  type="ghost"
                                  onClick={() => {
                                    this.showCustomizeQueryModal(null);
                                  }}
                                  disabled={warrantyAdditionalFields.length >= 5}
                                >
                                  <span>Add</span>
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            span={22}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {warrantyAdditionalFields.length}/5 questions already added
                          </Row>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {this.state.displayAddWarrantySection ? (
              <AddWarranty
                warrantyFields = {this.state.warrantyFields}
                setSaveHandler={this.props.setSaveHandler}
                resetEditArguments={this.resetEditArguments.bind(this)}
                toggleAddExchangeForm={this.toggleAddExchangeForm.bind(this)}
                fieldIndex={this.state.fieldIndex}
              />
            ) : (
              <React.Fragment>
                <Row type="flex" justify="center" align="top">
                  <Col span={22} style={{overflowX: 'auto'}}>
                    <Table
                      bordered={false}
                      columns={this.columns}
                      dataSource={this.state.warrantyFields}
                      rowKey={(record, index) => index}
                      locale={{
                        emptyText: (
                          <div className="flex-row flex-middle-xxs flex-center-xxs">
                            <div className="flex-col-xxs-12">
                              <IconTray size="md" />
                            </div>
                            <div>
                              <p className="TextBody TextBody--xxxs">No Data</p>
                            </div>
                          </div>
                        ),
                      }}
                      style={{ minWidth: '768px' }}
                    />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-around"
                  align="top"
                  className="u-marginTop--sm u-marginBottom--md"
                >
                  <Col span={22}>
                    <Row type="flex" justify="end" align="top">
                      <Button
                        type="primary"
                        onClick={this.toggleAddExchangeForm}
                      >
                        Add Warranty Option
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        </React.Fragment>
      );
    }
  }
  
  export default WarrantyReturns;
  