/**
 * Component for Custom Translations settings page in the Dashboard.
 *
 * @component
 */


import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Icon,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  Tag,
  Popconfirm
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import '../../../../styles/App.css';
import { trackingVitally } from '../../../../helpers/vitally';

const { Option } = Select;

const existingLanguages = [
  { code: 'hr', name: 'Croatian' },
  { code: 'da', name: 'Danish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'hi', name: 'Hindi' },
  { code: 'it', name: 'Italian' },
  { code: 'no', name: 'Norwegian' },
  { code: 'pl', name: 'Polish' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'es', name: 'Spanish' },
  { code: 'sv', name: 'Swedish' }
]

class Translations extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: undefined,
      defaultLanguage: undefined,
      languageActive: false,
      languageObject: [],
      referenceObject: {},
      buttonDisabled: true,
      uniqueKey: undefined,
      languages: []
    };
  }
  
  componentDidMount = async () => {
    try {
      const response = await axios.get('/api/translations')
      this.setState({
        languages: response.data.translations
      })
    } catch (err) {
      console.log(err)
    }

  }


  loadTranslation = (selectedLanguage) => {
    this.setState(

      {
        selectedLanguage,
      },
      async () => {
        try {
          const response = await axios.post('/api/translations', {
            selectedLanguage,
          });

          if (response.data.status === 'error') {
            return message.error('Error loading translations. Try again.', 5);
          }
          const {
            languageActive,
            defaultLanguage,
            languageObject,
            referenceObject,
          } = response.data;
          return this.setState({
            languageActive,
            defaultLanguage,
            languageObject,
            referenceObject,
            buttonDisabled: false,
            uniqueKey: Date.now(),
          });
        } catch (err) {
          return message.error('Error loading translations. Try again.', 5);
        }
      }
    );
  };

  updateTranslation = (e) => {
    const translationId = e.target.id;
    const translation = e.target.value;

    this.setState((prevState) => ({
      referenceObject: {
        ...prevState.referenceObject,
        [translationId]: translation,
      },
    }));
  };

  replaceLanguages = (
    {
      selectedLanguage,
      updatedTranslations,
      defaultLanguage,
      languageActive
    }
  ) => {
    this.setState(prevState => {
      const languageExists = prevState.languages.some(language => language.languageCode === selectedLanguage);

      
      if (languageExists) {
        return {
          languages: prevState.languages.map(language => {
            if(language.languageCode === selectedLanguage) {
              return { ...language, ...updatedTranslations, defaultLanguage, languageActive }
            }
            return language
          })
        }
      }

      return {
        languages: [...prevState.languages, {
          languageCode: selectedLanguage,
          ...updatedTranslations,
          defaultLanguage,
          languageActive
        }]
      }
    })

  }

  saveTranslation = async ({skipSaveMessage}) => {
    try {
      const updatedTranslations = this.state.referenceObject;
      const { languageActive, defaultLanguage, selectedLanguage } = this.state;
      const response = await axios.post('/api/translations/update', {
        updatedTranslations,
        languageActive,
        defaultLanguage,
        selectedLanguage,
      });

      this.replaceLanguages({selectedLanguage, updatedTranslations, defaultLanguage, languageActive});

      if (response.data.status === 'error') {
        return message.error('Error saving translations. Try again.', 5);
      }
      trackFSEvent('Select language ', {
        feature: 'Translation',
        selectedLanguage,
      });
      if (languageActive) {
        trackFSEvent('Activate language on the returns portal', {
          feature: 'Translation',
          selectedLanguage,
        });
      }
      if (defaultLanguage) {
        trackFSEvent('Set default language', {
          feature: 'Translation',
          selectedLanguage,
        });
      }
      if (!skipSaveMessage) {
        message.success('Language settings saved.', 5);
      }

    } catch (err) {
      return message.error('Error saving translations. Try again.', 5);
    }
  };
  trackEvents = (values) => {};
  toggleLanguage = (e) => {
    this.setState({
      languageActive: e.target.checked,
    });
  };

  toggleDefaultLanguage = async (e) => {
    try {
      await this.saveTranslation({skipSaveMessage: true});
      const response = await axios.post('/api/translations/defaultLanguage', {
        selectedLanguage: this.state.selectedLanguage
      });

      this.setState({
        languages: response.data.translations,
        defaultLanguage: true
      })

      message.success('Language settings saved.', 5);

    } catch (err) {
      console.log(err)
    }

  };

  toggleActiveLanguage = async (e) => {
    try {
      const nextLanguageActive = !this.state.languageActive;
      const response = await axios.post('/api/translations/languageActive', {
        selectedLanguage: this.state.selectedLanguage,
        languageActive: nextLanguageActive
      });
      if (nextLanguageActive) {
        trackingVitally('rr_languagecustomization_enable');
      }
      else {
        trackingVitally('rr_languagecustomization_disable');
      }
      this.setState({
        languages: response.data.translations,
        languageActive: nextLanguageActive,
        defaultLanguage: nextLanguageActive ? this.state.defaultLanguage : null 
      })


      message.success('Language settings saved.', 5);
    } catch (err) {
      console.log(err)
    }

  }

  TranslationList = ({ translations }) => {
    function capitalizeFirstWord(description) {
      return description.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }

    const groupedTranslations = translations.reduce((translationType, item) => {
      const { description } = item || {};
      const index = description ? capitalizeFirstWord(description) : 'Other';
      if (!translationType[index]) {
        translationType[index] = [];
      }
      translationType[index].push(item);
      return translationType;
    }, {});

    return (
      <div >
        {Object.entries(groupedTranslations).map(
          ([description, items], index) => (
            <div key={index}>
              <h3>{description}</h3>
              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}
              >
                <div style={{width:'100%'}}>
                  {items.map((item) => (
                    <div
                      key={item.id}
                      className="flex-row flex-top-xxs flex-between-xxs"
                    >
                      <div className="flex-col-md-3 flex-col-xxs-12">
                        <h4>Original text</h4>
                        <span class="TextBody TextBody--xxxs u-marginBottom--md">
                          <span style={{fontSize:'20px', lineHeight:'14px'}}>“</span>
                          {item.referenceTranslation}
                          <span style={{fontSize:'20px', lineHeight:'14px'}}>”</span>
                        </span>
                      </div>
                      <div className="flex-col-md-9 flex-col-xxs-12">
                        <div className="ContentPanel">
                          <h4>Translated text</h4>
                          <Input
                            placeholder="Enter your translation here"
                            defaultValue={item.translation}
                            id={item.key}
                            key={this.state.uniqueKey}
                            onChange={this.updateTranslation.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="flex-col-xxs-12">
                        <Divider></Divider>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          className='u-marginBottom--lg'
        > 
          <Button
            type="primary"
            onClick={this.saveTranslation.bind(this)}
            disabled={this.state.buttonDisabled}
          >
            Save changes
          </Button>
        </div>
      </div>
    );
  };

  resetTranslation = async () => {
    try { 
      const response = await axios.post('/api/translations/reset', {
        selectedLanguage: this.state.selectedLanguage,
      });
      const {
        languageObject,
        referenceObject,
      } = response.data;
      this.setState({
        languageObject,
        referenceObject,
        buttonDisabled: false,
        uniqueKey: Date.now(),
        languages: this.state.languages.filter(language => language.languageCode !== this.state.selectedLanguage),
      });

      await this.saveTranslation({skipSaveMessage: true});
    } catch (err) {
      console.log(err);
    }
  }

  getTags = (languageCode) => {
    const language = this.state.languages.find(l => l.languageCode === languageCode);

    const element = []

    if(language?.languageActive) {
      element.push(
      <Tooltip title="This language is active on your returns portal but not by default. The portal will only display in this language when we recognize this language for your customers.">    
      <Tag color="green" style={{marginLeft: 6}}>Active</Tag>
      </Tooltip>
      )
    }

    if(language?.defaultLanguage) {
      element.push(
        <Tooltip title="This is your default language for the returns portal and will be displayed if a different active language is not recognized.">
        <Tag color="gray" style={{marginLeft: 6}}>Default</Tag>
        </Tooltip>
      )
    }


    return element
  };

  sortLanguages = (languages) => {
    return languages.sort((a, b) => {
      const languageDataA = this.state.languages.find(l => l.languageCode === a.code)
      const languageDataB = this.state.languages.find(l => l.languageCode === b.code)

      if (languageDataA?.defaultLanguage && !languageDataB?.defaultLanguage) return -1;
      if (!languageDataA?.defaultLanguage && languageDataB?.defaultLanguage) return 1;

      if (languageDataA?.languageActive && !languageDataB?.languageActive) return -1;
      if (!languageDataA?.languageActive && languageDataB?.languageActive) return 1;

      return 0;
    });
  }

  filterActiveOrDefaultLanguage = (languages) => {
    return languages.filter(language => {
      const languageData = this.state.languages.find(l => l.languageCode === language.code)
      return languageData?.languageActive || languageData?.defaultLanguage
    })
  }


  filterNotActiveOrDefaultLanguage = (languages) => {
    return languages.filter(language => {
      const languageData = this.state.languages.find(l => l.languageCode === language.code)
      return !languageData?.languageActive && !languageData?.defaultLanguage
    })
  }

  languageOption = (language) => {
    return (
      <Option value={language.code} key={language.code}>
        {language.name} {this.getTags(language.code)}
      </Option>
    )
  }



  render() {
    const activeOrDefaultLanguages = this.sortLanguages(this.filterActiveOrDefaultLanguage([...existingLanguages, ...languageCodes])).map(this.languageOption);
    const remainingExistingLanguages = this.sortLanguages(this.filterNotActiveOrDefaultLanguage(existingLanguages)).map(this.languageOption);
    const remainingNotExistingLanguages = this.sortLanguages(this.filterNotActiveOrDefaultLanguage(languageCodes)).map(this.languageOption);



    return (
      <React.Fragment>
        <PageButtonHeader
          headingTitle={'Translations'}
          headingRightContent={
            <Button
              type="primary"
              onClick={this.saveTranslation.bind(this)}
              disabled={this.state.buttonDisabled}
            >
              Save changes
            </Button>
          }
        ></PageButtonHeader>
        <Row type="flex" justify="space-around" align="top">
          <Col>
            <Alert
              message="How do Translations work?"
              description={
                <span>
                  You can find a detailed explanation of our Translations{' '}
                  <a
                    href="https://support.richreturns.io/en/articles/9272514-translations-default-language"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here in our Docs.
                  </a>{' '}
                  By default we have translations for the following languages
                  already built in: Croatian, Danish, Dutch, English, Finnish,
                  French (beta), German, Hindi (beta), Italian, Norwegian,
                  Polish, Slovenian, Spanish (beta), Swedish (beta)
                  <br />
                  <br />
                  <strong>
                    Looking to customize a Translation or only enable certain
                    Languages?
                  </strong>{' '}
                  <br /> See further down below on this page how our
                  Language-Customization Feature works.
                </span>
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        {this.context.featureLanguageCustomization ? (
          <React.Fragment>
            <div>
              <div className="ContentPanel">
                <Row style={{ marginBottom: 10 }}>
                  <h3>Select translation language</h3>
                </Row>
                <Row type="flex">
                  <Col style={{ width: '100%' }}>
                    <Select
                      style={{ width: '100%',maxWidth:'480px' }}
                      placeholder="Select language"
                      onChange={this.loadTranslation.bind(this)}
                    >
                      {activeOrDefaultLanguages}
                      <Option value="disabled" key="disabled" disabled={true}>
                        --- Languages with existing translations
                      </Option>
                      {remainingExistingLanguages}
                      <Option value="disabled2" key="disabled2" disabled={true}>
                        --- Languages without existing translations
                      </Option>
                      {remainingNotExistingLanguages}
                    </Select>
                  </Col>
                </Row>

                {this.state.selectedLanguage && 
                <Row type="flex" style={{gap: "10px"}}>
                <Row type="flex" align="middle" style={{ paddingTop: 20 }}>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                     <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
                      <Button
                        onClick={this.toggleActiveLanguage.bind(this)}
                        style={{
                          color: this.state.languageActive ?  '#da121d' : '#2b7e01',
                          borderColor: this.state.languageActive ?  '#f0827c' : '#5ccb07' ,
                          backgroundColor: this.state.languageActive ?  '#fff0f0' : '#f4feec'
                        }}
                      >
                          {this.state.languageActive ? 'Deactivate this language on returns portal' : 'Activate this language on the returns portal'}
                      </Button>

                      <Tooltip
                        placement="bottom"
                        title="This language is active on your returns portal but not by default. The portal will only display in this language when we recognize this language for your customers."
                        >
                        <Icon type="info-circle"></Icon>
                      </Tooltip>
                    </div>
                  </Row>
                </Row>

                <Row type="flex" align="middle" style={{ paddingTop: 20 }}>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
                      <Button
                        onClick={this.toggleDefaultLanguage.bind(this)}
                        disabled={this.state.defaultLanguage}
                        style={{
                          backgroundColor: this.state.defaultLanguage ? undefined : 'gray',
                          color: this.state.defaultLanguage ? undefined : 'white'
                        }}
                      >
                          {this.state.defaultLanguage ? 'This is your default language' : 'Make this the default language'}

                      </Button>


                      <Tooltip
                        placement="bottom"
                        title="This is your default language for the returns portal and will be displayed if a different active language is not recognized."
                      >
                        <Icon type="info-circle"></Icon>
                      </Tooltip>
                    </div>
                  </Row>
                </Row>
                <Row type="flex" align="middle" style={{ paddingTop: 20 }}>
                <Popconfirm
                  title="Are you sure you want to reset this language?"
                  onConfirm={this.resetTranslation.bind(this)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
                    <Button>Reset to original</Button>
                    <Tooltip
                      placement="bottom"
                      title="This will reset all of your existing translations to the original ones."
                    >
                      <Icon type="info-circle"></Icon>
                    </Tooltip>
                  </div>
                  </Popconfirm>
                </Row>
                </Row>

              }
              </div>
              <Row type="flex" style={{ marginTop: 30, justifyContent:'flex-end' }}>
                <this.TranslationList
                  translations={this.state.languageObject}
                />
              </Row>
            </div>
          </React.Fragment>
        ) : (
          <Upgrade
            videoId="KFgs3uP6vrQ"
            title="Put your brand's voice first when it comes to customer satisfaction."
            description="Upgrade to the Pro Plan to customize text on your returns portal and enable or disable languages as you like."
          />
        )}
      </React.Fragment>
    );
  }
}

const languageCodes = [
  { code: 'ab', name: 'Abkhaz', nativeName: 'аҧсуа' },
  { code: 'aa', name: 'Afar', nativeName: 'Afaraf' },
  { code: 'af', name: 'Afrikaans', nativeName: 'Afrikaans' },
  { code: 'ak', name: 'Akan', nativeName: 'Akan' },
  { code: 'sq', name: 'Albanian', nativeName: 'Shqip' },
  { code: 'am', name: 'Amharic', nativeName: 'አማርኛ' },
  { code: 'ar', name: 'Arabic', nativeName: 'العربية' },
  { code: 'an', name: 'Aragonese', nativeName: 'Aragonés' },
  { code: 'hy', name: 'Armenian', nativeName: 'Հայերեն' },
  { code: 'as', name: 'Assamese', nativeName: 'অসমীয়া' },
  { code: 'av', name: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { code: 'ae', name: 'Avestan', nativeName: 'avesta' },
  { code: 'ay', name: 'Aymara', nativeName: 'aymar aru' },
  { code: 'az', name: 'Azerbaijani', nativeName: 'azərbaycan dili' },
  { code: 'bm', name: 'Bambara', nativeName: 'bamanankan' },
  { code: 'ba', name: 'Bashkir', nativeName: 'башҡорт теле' },
  { code: 'eu', name: 'Basque', nativeName: 'euskara, euskera' },
  { code: 'be', name: 'Belarusian', nativeName: 'Беларуская' },
  { code: 'bn', name: 'Bengali', nativeName: 'বাংলা' },
  { code: 'bh', name: 'Bihari', nativeName: 'भोजपुरी' },
  { code: 'bi', name: 'Bislama', nativeName: 'Bislama' },
  { code: 'bs', name: 'Bosnian', nativeName: 'bosanski jezik' },
  { code: 'br', name: 'Breton', nativeName: 'brezhoneg' },
  { code: 'bg', name: 'Bulgarian', nativeName: 'български език' },
  { code: 'my', name: 'Burmese', nativeName: 'ဗမာစာ' },
  { code: 'ca', name: 'Catalan; Valencian', nativeName: 'Català' },
  { code: 'ch', name: 'Chamorro', nativeName: 'Chamoru' },
  { code: 'ce', name: 'Chechen', nativeName: 'нохчийн мотт' },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { code: 'zh', name: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { code: 'cv', name: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
  { code: 'kw', name: 'Cornish', nativeName: 'Kernewek' },
  { code: 'co', name: 'Corsican', nativeName: 'corsu, lingua corsa' },
  { code: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { code: 'cs', name: 'Czech', nativeName: 'česky, čeština' },
  { code: 'dv', name: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
  { code: 'eo', name: 'Esperanto', nativeName: 'Esperanto' },
  { code: 'et', name: 'Estonian', nativeName: 'eesti, eesti keel' },
  { code: 'ee', name: 'Ewe', nativeName: 'Eʋegbe' },
  { code: 'fo', name: 'Faroese', nativeName: 'føroyskt' },
  { code: 'fj', name: 'Fijian', nativeName: 'vosa Vakaviti' },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { code: 'gl', name: 'Galician', nativeName: 'Galego' },
  { code: 'ka', name: 'Georgian', nativeName: 'ქართული' },
  { code: 'el', name: 'Greek, Modern', nativeName: 'Ελληνικά' },
  { code: 'gn', name: 'Guaraní', nativeName: 'Avañeẽ' },
  { code: 'gu', name: 'Gujarati', nativeName: 'ગુજરાતી' },
  { code: 'ht', name: 'Haitian; Haitian Creole', nativeName: 'Kreyòl ayisyen' },
  { code: 'ha', name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { code: 'he', name: 'Hebrew (modern)', nativeName: 'עברית' },
  { code: 'hz', name: 'Herero', nativeName: 'Otjiherero' },
  { code: 'ho', name: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { code: 'hu', name: 'Hungarian', nativeName: 'Magyar' },
  { code: 'ia', name: 'Interlingua', nativeName: 'Interlingua' },
  { code: 'id', name: 'Indonesian', nativeName: 'Bahasa Indonesia' },
  {
    code: 'ie',
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  { code: 'ga', name: 'Irish', nativeName: 'Gaeilge' },
  { code: 'ig', name: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { code: 'ik', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { code: 'io', name: 'Ido', nativeName: 'Ido' },
  { code: 'is', name: 'Icelandic', nativeName: 'Íslenska' },
  { code: 'iu', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { code: 'ja', name: 'Japanese', nativeName: '日本語 (にほんご／にっぽんご)' },
  { code: 'jv', name: 'Javanese', nativeName: 'basa Jawa' },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { code: 'kn', name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { code: 'kr', name: 'Kanuri', nativeName: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { code: 'kk', name: 'Kazakh', nativeName: 'Қазақ тілі' },
  { code: 'km', name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { code: 'ki', name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
  { code: 'rw', name: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { code: 'ky', name: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
  { code: 'kv', name: 'Komi', nativeName: 'коми кыв' },
  { code: 'kg', name: 'Kongo', nativeName: 'KiKongo' },
  {
    code: 'ko',
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { code: 'ku', name: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
  { code: 'kj', name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { code: 'la', name: 'Latin', nativeName: 'latine, lingua latina' },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  { code: 'lg', name: 'Luganda', nativeName: 'Luganda' },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  { code: 'ln', name: 'Lingala', nativeName: 'Lingála' },
  { code: 'lo', name: 'Lao', nativeName: 'ພາສາລາວ' },
  { code: 'lt', name: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { code: 'lu', name: 'Luba-Katanga', nativeName: '' },
  { code: 'lv', name: 'Latvian', nativeName: 'latviešu valoda' },
  { code: 'gv', name: 'Manx', nativeName: 'Gaelg, Gailck' },
  { code: 'mk', name: 'Macedonian', nativeName: 'македонски јазик' },
  { code: 'mg', name: 'Malagasy', nativeName: 'Malagasy fiteny' },
  { code: 'ms', name: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { code: 'ml', name: 'Malayalam', nativeName: 'മലയാളം' },
  { code: 'mt', name: 'Maltese', nativeName: 'Malti' },
  { code: 'mi', name: 'Māori', nativeName: 'te reo Māori' },
  { code: 'mr', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { code: 'mh', name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { code: 'mn', name: 'Mongolian', nativeName: 'монгол' },
  { code: 'na', name: 'Nauru', nativeName: 'Ekakairũ Naoero' },
  { code: 'nv', name: 'Navajo, Navaho', nativeName: 'Diné bizaad, Dinékʼehǰí' },
  { code: 'nb', name: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
  { code: 'nd', name: 'North Ndebele', nativeName: 'isiNdebele' },
  { code: 'ne', name: 'Nepali', nativeName: 'नेपाली' },
  { code: 'ng', name: 'Ndonga', nativeName: 'Owambo' },
  { code: 'nn', name: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
  { code: 'ii', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { code: 'nr', name: 'South Ndebele', nativeName: 'isiNdebele' },
  { code: 'oc', name: 'Occitan', nativeName: 'Occitan' },
  { code: 'oj', name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    code: 'cu',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { code: 'om', name: 'Oromo', nativeName: 'Afaan Oromoo' },
  { code: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { code: 'os', name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { code: 'pa', name: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { code: 'pi', name: 'Pāli', nativeName: 'पाऴि' },
  { code: 'fa', name: 'Persian', nativeName: 'فارسی' },
  { code: 'ps', name: 'Pashto, Pushto', nativeName: 'پښتو' },
  { code: 'pt', name: 'Portuguese', nativeName: 'Português' },
  { code: 'qu', name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { code: 'rm', name: 'Romansh', nativeName: 'rumantsch grischun' },
  { code: 'rn', name: 'Kirundi', nativeName: 'kiRundi' },
  { code: 'ro', name: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
  { code: 'ru', name: 'Russian', nativeName: 'русский язык' },
  { code: 'sa', name: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
  { code: 'sc', name: 'Sardinian', nativeName: 'sardu' },
  { code: 'sd', name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { code: 'se', name: 'Northern Sami', nativeName: 'Davvisámegiella' },
  { code: 'sm', name: 'Samoan', nativeName: 'gagana faa Samoa' },
  { code: 'sg', name: 'Sango', nativeName: 'yângâ tî sängö' },
  { code: 'sr', name: 'Serbian', nativeName: 'српски језик' },
  { code: 'gd', name: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
  { code: 'sn', name: 'Shona', nativeName: 'chiShona' },
  { code: 'si', name: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
  { code: 'sk', name: 'Slovak', nativeName: 'slovenčina' },
  { code: 'so', name: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
  { code: 'st', name: 'Southern Sotho', nativeName: 'Sesotho' },
  { code: 'su', name: 'Sundanese', nativeName: 'Basa Sunda' },
  { code: 'sw', name: 'Swahili', nativeName: 'Kiswahili' },
  { code: 'ss', name: 'Swati', nativeName: 'SiSwati' },
  { code: 'ta', name: 'Tamil', nativeName: 'தமிழ்' },
  { code: 'te', name: 'Telugu', nativeName: 'తెలుగు' },
  { code: 'tg', name: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { code: 'th', name: 'Thai', nativeName: 'ไทย' },
  { code: 'ti', name: 'Tigrinya', nativeName: 'ትግርኛ' },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  { code: 'tk', name: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
  { code: 'tl', name: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { code: 'tn', name: 'Tswana', nativeName: 'Setswana' },
  { code: 'to', name: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
  { code: 'tr', name: 'Turkish', nativeName: 'Türkçe' },
  { code: 'ts', name: 'Tsonga', nativeName: 'Xitsonga' },
  { code: 'tt', name: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { code: 'tw', name: 'Twi', nativeName: 'Twi' },
  { code: 'ty', name: 'Tahitian', nativeName: 'Reo Tahiti' },
  { code: 'ug', name: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { code: 'uk', name: 'Ukrainian', nativeName: 'українська' },
  { code: 'ur', name: 'Urdu', nativeName: 'اردو' },
  { code: 'uz', name: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { code: 've', name: 'Venda', nativeName: 'Tshivenḓa' },
  { code: 'vi', name: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { code: 'vo', name: 'Volapük', nativeName: 'Volapük' },
  { code: 'wa', name: 'Walloon', nativeName: 'Walon' },
  { code: 'cy', name: 'Welsh', nativeName: 'Cymraeg' },
  { code: 'wo', name: 'Wolof', nativeName: 'Wollof' },
  { code: 'fy', name: 'Western Frisian', nativeName: 'Frysk' },
  { code: 'xh', name: 'Xhosa', nativeName: 'isiXhosa' },
  { code: 'yi', name: 'Yiddish', nativeName: 'ייִדיש' },
  { code: 'yo', name: 'Yoruba', nativeName: 'Yorùbá' },
  { code: 'za', name: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];

export default Translations;
