/**
 * Component for our Analytics CSV Export.
 *
 * @component
 */

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Icon,
  Menu,
  message,
  Row,
  Spin,
} from 'antd';
import axios from 'axios';
import { Parser } from 'json2csv';
import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';

import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { trackingVitally } from '../../../../helpers/vitally';

const { RangePicker } = DatePicker;

class AnalyticsExport extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disabledDateRange: undefined,
      date: undefined,
      startDate: undefined,
      endDate: undefined,
      visibleReturnStatus: false,
      allowedStatus: {
        Pending: 'Pending',
        Approved: 'Approved',
        Shipped: 'Shipped',
        InTransit: 'In Transit',
        Received: 'Received',
        Resolved: 'Resolved',
        Rejected: 'Rejected',
      },
      filters: {
        status: {
          Pending: true,
          Approved: true,
          Shipped: true,
          InTransit: true,
          Received: true,
          Resolved: true,
          Rejected: true,
        },
      },
      lineItemLevel: [],
      lineItemLevelUniqueRMA: [],
      returnLevel: [],
      returnExports: [
        {
          returnLevel: '',
          lineItemLevel: '',
          lineItemLevelUniqueRMA: '',
        },
      ],
      emailExport: false,
    };
  }

  async handleSubmit() {
    this.setState({
      loading: true,
    });

    const startDate = this.state.startDate;
    const endDate = this.state.endDate;

    if (startDate == null || endDate == null) {
      this.setState({
        loading: false,
      });
      return message.error('Select a start and end date first.', 4);
    }

    try {
      const response = await axios.post('/api/analytics/export', {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        status: {
          ...this.state.filters.status,
        },
      });

      if (response.data.error) {
        this.setState({
          loading: false,
        });
        return message.error('Error fetching analytics data from server.', 4);
      }

      if (response.data.emailExport === true) {
        this.setState({
          loading: false,
        });
        return message.success(
          'The amount of data is too large, we will email you a report within a few minutes',
          5
        );
      }

      let headersReturnsArray = [
        { fields: headersReturns },
      ];

      let headersLineItemsArray = [
        { fields: headersLineItems },
      ];

      let returnExports = [...this.state.returnExports];
      for (let i = 0; i < returnExports.length; i++) {
        const returnsOnlyHeaders = headersReturnsArray[i];
        const returnsAndLineItemsHeaders = headersLineItemsArray[i];
        let returnsOnlyParser = new Parser(returnsOnlyHeaders);
        let returnsAndLineItemsParser = new Parser(returnsAndLineItemsHeaders);
        returnExports[i].returnLevel = returnsOnlyParser.parse(
          response.data.returnLevel
        );
        returnExports[i].lineItemLevel = returnsAndLineItemsParser.parse(
          response.data.lineItemLevel
        );
        returnExports[i].lineItemLevelUniqueRMA =
          returnsAndLineItemsParser.parse(response.data.lineItemLevelUniqueRMA);
      }

      return this.setState({
        ...response.data,
        loading: false,
        returnExports,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error('Error fetching analytics data.', 4);
    }
  }

  trackEvents = (type) => {
    trackFSEvent('Export Returns', {
      feature: 'Analytics',
      exportType: type,
    });
    trackingVitally('rr_returns_exported');
  };

  onChange(dates, dateStrings) {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    });
  }

  handleVisibleChange = (target, flag) => {
    this.setState({ [target]: flag });
  };

  checkIfAllStatusUnchecked = (e) => {
    return !Object.keys(this.state.filters.status).some((key) =>
      key === e.target.name ? e.target.checked : this.state.filters.status[key]
    );
  };

  handleMenuClick = (e, target) => {
    if (this.checkIfAllStatusUnchecked(e)) {
      return;
    }

    this.setState({
      filters: {
        ...this.state.filters,
        [target]: {
          ...this.state.filters[target],
          [e.target.name]: e.target.checked,
        },
      },
    });
  };

  resetFilters = (target) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [target]: {},
        status: {
          Pending: true,
          Approved: true,
          Shipped: true,
          InTransit: true,
          Received: true,
          Resolved: true,
          Rejected: true,
        },
      },
    });
  };

  render() {
    const timestamp = Date.now();


    const menuStatus = (
      <Menu>
        {Object.entries(this.state.allowedStatus)
          // eslint-disable-next-line no-unused-vars
          ?.filter(([key, _]) => key !== 'All')
          ?.map(([key, value]) => {
            return (
              <Menu.Item key={key}>
                <Checkbox
                  style={{ width: '100%' }}
                  name={key}
                  checked={
                    this.state?.filters?.status &&
                    this.state?.filters?.status[key]
                  }
                  onChange={(e) => this.handleMenuClick(e, 'status')}
                >
                  {value}
                </Checkbox>
              </Menu.Item>
            );
          })}
        <Menu.Item key="buttons" className="test">
          <Row type="flex" justify="center">
            <Button type="link" onClick={() => this.resetFilters('status')}>
              Reset
            </Button>
          </Row>
        </Menu.Item>
      </Menu>
    );

    if (this.context.featureExportData === undefined) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return this.context.featureExportData ? (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="TextHeading TextHeading--sm u-marginBottom--md">
              Export data
            </h1>
          </Col>
        </Row>

        <div className="ContentPanel">
          <Divider style={{ marginTop: 10, marginBottom: 20 }}>
            Select a data range for export
          </Divider>

          <Row
            type="flex"
            justify="center"
            gutter={12}
            style={{ rowGap: '10px', marginTop: '10px' }}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Spin spinning={this.state.loading}>
                <RangePicker
                  style={{ width: '100%' }}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month'),
                    ],
                    'Year to Date': [moment().startOf('year'), moment()],
                    'Last Year': [
                      moment().subtract(1, 'year').startOf('year'),
                      moment().subtract(1, 'year').endOf('year'),
                    ],
                  }}
                  onChange={this.onChange.bind(this)}
                  // disabledDate={disabledDate}
                  onCalendarChange={(dateArray) => {
                    const endDateSelected = dateArray[1] != null;
                    if (endDateSelected) {
                      return this.setState({
                        date: undefined,
                      });
                    }

                    const startDateSelected = dateArray[0];
                    if (startDateSelected) {
                      this.setState({
                        date: dateArray[0],
                      });
                    }
                  }}
                  format="YYYY-MM-DD"
                  showTime={{ format: 'HH:mm' }}
                />
              </Spin>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
            >
              <Dropdown
                style={{ width: '100%' }}
                overlay={menuStatus}
                onVisibleChange={(flag) =>
                  this.handleVisibleChange('visibleReturnStatus', flag)
                }
                visible={this.state.visibleReturnStatus}
              >
                <Button style={{ width: '100%' }}>
                  Status <Icon type="down" />
                </Button>
              </Dropdown>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 3 }}
            >
              <Button
                style={{ width: '100%' }}
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.state.startDate || !this.state.endDate}
              >
                Search
                {/* <Icon type="search" /> */}
              </Button>
            </Col>
          </Row>

          <Divider style={{ marginTop: 30, marginBottom: 30 }}>
            Select status
          </Divider>

          <Row
            type="flex"
            justify="center"
            align="top"
            style={{ paddingBottom: 15, rowGap: '15px' }}
            gutter={16}
          >
            <Col lg={8} md={24} sm={24} xs={24}>
              <Button
                loading={this.state.loading}
                disabled={this.state.returnLevel.length > 0 ? false : true}
                block
                onClick={() => {
                  this.trackEvents('Export returns');
                }}
              >
                {this.state.returnLevel.length > 0 ? (
                  <CSVLink
                    data={this.state.returnExports[0].returnLevel}
                    filename={`RichReturns-export-returns-${timestamp}.csv`}
                  >
                    Export returns
                  </CSVLink>
                ) : (
                  <span>Export returns</span>
                )}
              </Button>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Button
                loading={this.state.loading}
                disabled={this.state.lineItemLevel.length > 0 ? false : true}
                block
                onClick={() => {
                  this.trackEvents('Export returns (incl. line-items)');
                }}
              >
                {this.state.lineItemLevel.length > 0 ? (
                  <CSVLink
                    data={this.state.returnExports[0].lineItemLevel}
                    filename={`RichReturns-export-returns-lineItems-${timestamp}.csv`}
                  >
                    Export returns (incl. line-items)
                  </CSVLink>
                ) : (
                  <span>Export returns (incl. line-items)</span>
                )}
              </Button>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Button
                loading={this.state.loading}
                disabled={
                  this.state.lineItemLevelUniqueRMA.length > 0 ? false : true
                }
                block
                onClick={() => {
                  this.trackEvents(
                    'Export returns (incl. line-items / RMA once)'
                  );
                }}
              >
                {this.state.lineItemLevelUniqueRMA.length > 0 ? (
                  <CSVLink
                    data={this.state.returnExports[0].lineItemLevelUniqueRMA}
                    filename={`RichReturns-export-returns-lineItems-RMA-${timestamp}.csv`}
                  >
                    Export returns (incl. line-items / RMA once)
                  </CSVLink>
                ) : (
                  <span>Export returns (incl. line-items / RMA once)</span>
                )}
              </Button>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="center"
            align="top"
            style={{ paddingTop: 35, paddingBottom: 35 }}
          >
            <Col>
              <p>
                Export up to 1000 results as CSV in your dashboard. If there are
                more than a 1000 results, your report will be sent to your email
                address. You can also use our API to export more than 1000
                results (or export multiple date ranges)..
              </p>
            </Col>
          </Row>
        </div>

        <Row type="flex" justify="center" align="top">
          <Col>
            <p>
              <Icon type="exclamation-circle" /> If you encounter issues on this
              Analytics-Page make sure to disable any ad-blockers in your
              browser.
            </p>
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      <Upgrade
        videoId="It4YfYu96pk"
        title="Export your full returns data and gain insights into your business."
        description="Upgrade to the Pro Plan to export data on your returns as CSV files and crunch the numbers to your liking."
      />
    );
  }
}

export default AnalyticsExport;

const headersV1returns = [
  { label: 'returnNumber', value: 'returnNumber' },
  { label: 'platform', value: 'platform' },
  { label: 'vendor', value: 'vendor' },
  { label: 'returnStatus', value: 'returnStatus' },
  { label: 'orderId', value: 'orderId' },
  { label: 'orderNumber', value: 'orderNumber' },
  { label: 'orderName', value: 'orderName' },
  { label: 'orderDate', value: 'orderDate' },
  { label: 'dateFulfilled', value: 'dateFulfilled' },
  { label: 'dateDelivered', value: 'dateDelivered' },
  { label: 'requestDate', value: 'requestDate' },
  { label: 'dateReceived', value: 'dateReceived' },
  { label: 'customDeliveryDate', value: 'customDeliveryDate' },
  { label: 'financialStatus', value: 'financialStatus' },
  { label: 'resolution', value: 'resolution' },
  { label: 'resolutionLabel', value: 'resolutionLabel' },
  { label: 'currency', value: 'currency' },
  { label: 'orderTotal', value: 'orderTotal' },
  { label: 'refundTotal', value: 'refundTotal' },
  { label: 'discount', value: 'discount' },
  { label: 'discountCodes', value: 'discountCodes' },
  { label: 'tax', value: 'tax' },
  { label: 'taxRate', value: 'taxRate' },
  { label: 'labelFeeValue', value: 'labelFeeValue' },
  { label: 'restockingFeeValue', value: 'restockingFeeValue' },
  { label: 'restockingFeeType', value: 'restockingFeeType' },
  { label: 'restockingFeeIncludeTax', value: 'restockingFeeIncludeTax' },
  { label: 'shippingMethod', value: 'shippingMethod' },
  { label: 'customerPhotoUrl1', value: 'customerPhotoUrl1' },
  { label: 'customerPhotoUrl2', value: 'customerPhotoUrl2' },
  { label: 'customerPhotoUrl3', value: 'customerPhotoUrl3' },
  { label: 'customerId', value: 'customerId' },
  { label: 'customerFirstName', value: 'customerFirstName' },
  { label: 'customerLastName', value: 'customerLastName' },
  { label: 'customerEmail', value: 'customerEmail' },
  { label: 'mobilePhone', value: 'mobilePhone' },
  { label: 'labelObjectState', value: 'labelObjectState' },
  { label: 'labelStatus', value: 'labelStatus' },
  { label: 'labelObjectCreated', value: 'labelObjectCreated' },
  { label: 'labelObjectId', value: 'labelObjectId' },
  { label: 'labelTrackingNumber', value: 'labelTrackingNumber' },
  { label: 'labelTrackingUrlProvider', value: 'labelTrackingUrlProvider' },
  { label: 'labelLabelUrl', value: 'labelLabelUrl' },
  { label: 'labelRateAmount', value: 'labelRateAmount' },
  { label: 'labelRateCurrency', value: 'labelRateCurrency' },
  { label: 'labelRateProvider', value: 'labelRateProvider' },
  { label: 'labelRateServicelevelName', value: 'labelRateServicelevelName' },
  { label: 'labelErrorShipmentSource', value: 'labelErrorShipmentSource' },
  { label: 'labelErrorShipmentText', value: 'labelErrorShipmentText' },
  { label: 'labelErrorRateSource', value: 'labelErrorRateSource' },
  { label: 'labelErrorRateText', value: 'labelErrorRateText' },
  {
    label: 'labelErrorTransactionSource',
    value: 'labelErrorTransactionSource',
  },
  { label: 'labelErrorTransactionText', value: 'labelErrorTransactionText' },
  { label: 'shippingFirstName', value: 'shippingFirstName' },
  { label: 'shippingLastName', value: 'shippingLastName' },
  { label: 'shippingName', value: 'shippingName' },
  { label: 'shippingCompany', value: 'shippingCompany' },
  { label: 'shippingAddress1', value: 'shippingAddress1' },
  { label: 'shippingAddress2', value: 'shippingAddress2' },
  { label: 'shippingCity', value: 'shippingCity' },
  { label: 'shippingZip', value: 'shippingZip' },
  { label: 'shippingProvince', value: 'shippingProvince' },
  { label: 'shippingProvinceCode', value: 'shippingProvinceCode' },
  { label: 'shippingCountry', value: 'shippingCountry' },
  { label: 'shippingCountryCode', value: 'shippingCountryCode' },
  { label: 'shippingPhone', value: 'shippingPhone' },
  { label: 'customShippingLabelUrl', value: 'customShippingLabelUrl' },
  { label: 'exchangeOrderType', value: 'exchangeOrderType' },
  { label: 'exchangeOrderId', value: 'exchangeOrderId' },
  { label: 'exchangeOrderNumber', value: 'exchangeOrderNumber' },
  { label: 'exchangeOrderError', value: 'exchangeOrderError' },
  { label: 'ruleActionManualApproval', value: 'ruleActionManualApproval' },
  {
    label: 'ruleActionCustomReturnAddress',
    value: 'ruleActionCustomReturnAddress',
  },
  {
    label: 'ruleCustomReturnAddressName',
    value: 'ruleCustomReturnAddressName',
  },
  {
    label: 'ruleCustomReturnAddressCompany',
    value: 'ruleCustomReturnAddressCompany',
  },
  {
    label: 'ruleCustomReturnAddressStreet1',
    value: 'ruleCustomReturnAddressStreet1',
  },
  {
    label: 'ruleCustomReturnAddressStreet2',
    value: 'ruleCustomReturnAddressStreet2',
  },
  {
    label: 'ruleCustomReturnAddressCity',
    value: 'ruleCustomReturnAddressCity',
  },
  { label: 'ruleCustomReturnAddressZip', value: 'ruleCustomReturnAddressZip' },
  {
    label: 'ruleCustomReturnAddressProvince',
    value: 'ruleCustomReturnAddressProvince',
  },
  {
    label: 'ruleCustomReturnAddressProvinceCode',
    value: 'ruleCustomReturnAddressProvinceCode',
  },
  {
    label: 'ruleCustomReturnAddressCountry',
    value: 'ruleCustomReturnAddressCountry',
  },
  {
    label: 'ruleCustomReturnAddressCountryCode',
    value: 'ruleCustomReturnAddressCountryCode',
  },
  {
    label: 'ruleCustomReturnAddressPhone',
    value: 'ruleCustomReturnAddressPhone',
  },
];

const headersV1lineItems = [
  // Todo: decouple this from headersV1returns
  ...headersV1returns,
  { label: 'productReturnStatus', value: 'productReturnStatus' },
  { label: 'wantsToReturn', value: 'wantsToReturn' },
  { label: 'returnReason', value: 'returnReason' },
  { label: 'lineItemId', value: 'lineItemId' },
  { label: 'productId', value: 'productId' },
  { label: 'productName', value: 'productName' },
  { label: 'variantId', value: 'variantId' },
  { label: 'variantName', value: 'variantName' },
  { label: 'imageUrl', value: 'imageUrl' },
  { label: 'price', value: 'price' },
  { label: 'quantity', value: 'quantity' },
  { label: 'weightInGrams', value: 'weightInGrams' },
  { label: 'exchangeItemVariantId', value: 'exchangeItemVariantId' },
  { label: 'exchangeItemVariantName', value: 'exchangeItemVariantName' },
  { label: 'exchangeItemPrice', value: 'exchangeItemPrice' },
  { label: 'exchangeItemWeightInGrams', value: 'exchangeItemWeightInGrams' },
];

const headersReturns = [...headersV1returns,
  { label: 'fulfillmentLocationId', value: 'fulfillmentLocationId' },
  { label: 'staffNotes', value: 'staffNotes' },
  { label: 'paymentGateway', value: 'paymentGateway' },
  { label: 'updatedAt', value: 'updatedAt' },
];

const headersLineItems = [...headersV1lineItems,
  { label: 'notes', value: 'notes' },
  { label: 'fulfillmentLocationId', value: 'fulfillmentLocationId' },
  { label: 'stockLocationId', value: 'stockLocationId' },
  { label: 'stockLocationName', value: 'stockLocationName' },
  { label: 'sku', value: 'sku' },
  { label: 'staffNotes', value: 'staffNotes' },
  { label: 'productVendor', value: 'productVendor' },
  { label: 'updatedAt', value: 'updatedAt' },
];
