/**
 * Component for our Graph on the volume of Exchanges.
 * 
 * @component
 */

import React from 'react';
import VolumeGraph from '../VolumeGraph';

class AdvancedExchangeVolume extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return(
      <VolumeGraph
        url="/api/analytics/advanced-exchange-volume-by-date"
        title="Collection Exchange Volume"
        tooltip="Shows the number of Exchange items requested per day."
        config={{
          xField: "date",
          yField: "volume",
          xAxis: { tickCount: 5 },
          yAxis: { tickInterval: 1 },
          smooth: true,
        }}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    )
  }
}

export default AdvancedExchangeVolume;