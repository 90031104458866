/**
 * Main Component for our Analytics Reports that loads
 * all different Report types. Also includes a Feature-
 * Gate for this feature.
 *
 * @component
 */

import axios from 'axios';
import { Parser } from 'json2csv';
import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Icon,
  message,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import '../../../../styles/App.css';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { trackingVitally } from '../../../../helpers/vitally';
const { RangePicker } = DatePicker;
const { Option } = Select;

let timeout = undefined;

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exportLoading: false,
      data: [],
      dataToBeExported: [],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      date: undefined,
      searchValue: undefined,
      options: [],
    };
  }
  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }

  async handleExport() {
    this.setState({
      exportLoading: true,
    });

    if (this.props.startDate == null || this.props.endDate == null) {
      this.setState({
        exportLoading: false,
      });
      return message.error('Select a start and end date first.', 4);
    }

    try {
      this.trackEventExportReport();
      const response = await axios.post(this.props.url, {
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        isExport: true,
        searchTerm: this.state.searchValue,
      });

      if (response.data.error) {
        this.setState({
          exportLoading: false,
        });
        return message.error('Error creating export file of the report.', 4);
      }

      if (response.data.emailExport) {
        this.setState({
          exportLoading: false,
        });
        return message.success(
          'Report is being generated. You will receive an email shortly with the download link.',
          4
        );
      }

      let reportDataParser = new Parser({ fields: this.props.exportHeaders });
      response.data.data = response.data.data.map((product) => {
        if (Array.isArray(product.returnReasons)) {
          product.returnReasons = product.returnReasons.join(', ');
        }
        if (Array.isArray(product.notes)) {
          product.notes = product.notes.join(', ');
        }
        return product;
      });
      let dataToBeExported = reportDataParser.parse(response.data.data);

      return this.setState({
        exportLoading: false,
        dataToBeExported,
      });
    } catch (err) {
      this.setState({
        exportLoading: false,
      });
      return message.error('Error creating report csv.', 4);
    }
  }

  trackEventChangeDate(url, startDate, endDate) {
    try {
      if (typeof this.props.startDate === 'string') {
        const urlParts = url.split('/');
        trackFSEvent('Check Analytics Timeframe', {
          feature: `Analytics`,
          reportType: urlParts[urlParts.length - 2],
          subReportType: urlParts[urlParts.length - 1],
          startDate,
          endDate,
        });
        trackingVitally('rr_analyticstimeframe_adjusted');
      }
    } catch (err) {
      console.log('Error tracking event', err);
    }
  }

  trackEventExportReport() {
    try {
      const url = this.props.url;
      const urlParts = url.split('/');
      trackFSEvent('Export analytics report', {
        feature: `Analytics`,
        reportType: urlParts[urlParts.length - 2],
        subReportType: urlParts[urlParts.length - 1],
      });
      trackingVitally('rr_analytics report_exported');
    } catch (err) {
      console.log('Error tracking event', err);
    }
  };

  async handleSubmit() {
    if (this.context && this.context.featureAnalyticsAdvanced === false) {
      return;
    }

    this.setState({
      loading: true,
    });

    const { currentPage, pageSize } = this.state;

    if (!this.props.startDate || !this.props.endDate) {
      this.setState({
        loading: false,
      });
      return message.error('Select a start and end date.', 4);
    }
    try {
      this.trackEventChangeDate(this.props.url, this.props.startDate, this.props.endDate);
      const response = await axios.post(this.props.url, {
        currentPage,
        pageSize,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });

      if (response.data.error) {
        return message.error('Error fetching report data from server.', 4);
      }

      this.setState({
        data: response.data.data,
        totalRecords: response.data.totalRecords,
        loading: false,
      });

      return;
    } catch (err) {
      message.error('Error fetching report data.', 4);
    }
  }

  onChange(dates, updateData) {
    this.props.updateDate(updateData);
    this.setState({
      currentPage: 1, // to reset the active page
    });
  }

  triggerPagination = async (paginationObject) => {
    const currentPage = paginationObject.current;
    const { pageSize } = paginationObject;
    this.setState(
      {
        currentPage,
        pageSize,
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  handleResetDataToBeExported = () => {
    this.setState({
      dataToBeExported: [],
    });
  };
  getRanges = () => {
    let ranges = {
      Today: [moment(), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
    const allowedDomains = [
      'bellybandit.myshopify.com',
      'kate-quinn-organics.myshopify.com',
    ];
    if (allowedDomains.includes(this.context?.myshopify_domain)) {
      ranges = {
        ...ranges,
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year'),
        ],
      };
    }
    return ranges;
  };

  handleSearchChange = (value) => {
    this.setState({
      searchValue: value,
    });
    const data = this.state.options.filter((option) => {
      if (this.props.reportName === 'most-returned-products') {
        return option.productId === value;
      } else {
        return option.variantId === value;
      }
    });
    this.setState({
      data,
    });
  };

  searchReturns = async (value) => {
    try {
      this.setState({
        searchValue: value,
      });
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        const { currentPage, pageSize } = this.state;
        const response = await axios.post(this.props.url, {
          searchTerm: this.state.searchValue,
          currentPage,
          pageSize,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        });
        if (response.data.error) {
          return message.error('Error fetching report data from server.', 4);
        }
        this.setState({
          options: response.data.data,
          totalRecords: response.data.totalRecords,
          loading: false,
        });
      }, 300);
      return;
    } catch (err) {
      message.error('Error fetching report data.', 4);
    }
  };

  handleReset = () => {
    this.setState({
      searchValue: undefined,
      options: [],
    });
    this.handleSubmit();
  };

  onOk() {
    this.setState({
      loading: true,
    });
    this.handleSubmit();
  }

  render() {
    const { loading, currentPage, totalRecords, data, date } = this.state;
    const timestamp = Date.now();
    // eslint-disable-next-line no-unused-vars
    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      let tooLate = date && current.diff(date, 'days') > 45;
      let tooEarly = date && date.diff(current, 'days') > 45;
      const allowedDomains = [
        'bellybandit.myshopify.com',
        'kate-quinn-organics.myshopify.com',
      ];
      if (allowedDomains.includes(this.context?.myshopify_domain)) {
        tooLate = date && current.diff(date, 'days') > 365;
        tooEarly = date && date.diff(current, 'days') > 365;
      }
      return tooEarly || tooLate;
    };
    const optionId =
      this.props.reportName === 'most-returned-products'
        ? 'productId'
        : 'variantId';

    if (this.context.featureAnalyticsAdvanced === undefined) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="u-marginBottom--md">
          <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--xs flex-start-xxs">
            {/* <Tooltip title={this.props.tooltip}>
                <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--xs flex-start-xxs">
                  <div>
                    <Text style={{fontSize:14}}>
                      {this.props.title}
                    </Text>
                  </div>
                  <Icon
                    style={{ fontSize: "11px"}}
                    type="info-circle"
                  />
                </div>
            </Tooltip> */}

            <div className="TextBody TextBody--xxs u-marginBottom--none">
              {this.props.tooltip}
            </div>
          </div>
        </div>

        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <Row className="u-marginBottom--md">
              <Col>
                <Spin spinning={loading}>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="top"
                    style={{ rowGap: 10 }}
                    gutter={16}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                    >
                      <Row
                        type="flex"
                        justify="space-between"
                        align="top"
                        gutter={16}
                      >
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 18 }}
                          md={{ span: 18 }}
                          lg={{ span: 18 }}
                        >
                          <RangePicker
                            style={{ width: '100%' }}
                            ranges={{
                              Today: [moment(), moment()],
                              'This Month': [
                                moment().startOf('month'),
                                moment().endOf('month'),
                              ],
                              'Last Month': [
                                moment().subtract(1, 'month').startOf('month'),
                                moment().subtract(1, 'month').endOf('month'),
                              ],
                              'Year to Date': [
                                moment().startOf('year'),
                                moment(),
                              ],
                              'Last Year': [
                                moment().subtract(1, 'year').startOf('year'),
                                moment().subtract(1, 'year').endOf('year'),
                              ],
                            }}
                            defaultValue={[
                              moment(this.props.startDate),
                              moment(this.props.endDate),
                            ]}
                            // ranges={this.getRanges()}
                            onChange={this.onChange.bind(this)}
                            // disabledDate={disabledDate}
                            onCalendarChange={(dateArray) => {
                              const endDateSelected = dateArray[1] != null;
                              if (endDateSelected) {
                                return this.setState({
                                  date: undefined,
                                });
                              }

                              const startDateSelected = dateArray[0];
                              if (startDateSelected) {
                                this.setState({
                                  date: dateArray[0],
                                });
                              }
                            }}
                            format="YYYY-MM-DD"
                            showTime={{ format: 'HH:mm' }}
                            onOk={() => {
                              setTimeout(() => {
                                this.onOk();
                              }, 0);
                            }}
                          />
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 6 }}
                          md={{ span: 6 }}
                          lg={{ span: 6 }}
                        >
                          {this.state.dataToBeExported.length > 0 ? (
                            <Button
                              type="primary"
                              block={true}
                              onClick={this.handleResetDataToBeExported.bind(
                                this
                              )}
                            >
                              <CSVLink
                                data={this.state.dataToBeExported}
                                filename={`RichReturns-report-${this.props.reportName}-${timestamp}.csv`}
                              >
                                {/* <Icon type="download" />  */}
                                CSV
                              </CSVLink>
                            </Button>
                          ) : (
                            <Button
                              block={true}
                              loading={this.state.exportLoading}
                              onClick={this.handleExport.bind(this)}
                            >
                              <span>
                                {/* <Icon type="export" />  */}
                                Export
                              </span>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {(this.props.reportName === 'most-returned-products' ||
                      this.props.reportName === 'most-returned-variants') && (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8, offset: 4 }}
                      >
                        <Select
                          showSearch
                          showArrow={false}
                          filterOption={false}
                          notFoundContent={null}
                          style={{ width: '100%' }}
                          placeholder={
                            this.props.reportName === 'most-returned-products'
                              ? 'Search for a Product'
                              : 'Search for a Variant'
                          }
                          value={this.state.searchValue}
                          onChange={this.handleSearchChange}
                          onSearch={this.searchReturns}
                        >
                          {this.state.options?.map((option) => {
                            return (
                              <Option
                                key={option[optionId]}
                                value={option[optionId]}
                              >
                                {this.props.reportName ===
                                'most-returned-products'
                                  ? option.productName
                                  : option.variantName}
                              </Option>
                            );
                          })}
                        </Select>

                        {this.state.searchValue && (
                          <Icon
                            className="cross-icon-reports"
                            style={{
                              color: '#000000',
                              position: 'absolute',
                              right: '15px',
                              top: '14px',
                            }}
                            type="close"
                            onClick={this.handleReset}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </Spin>
              </Col>
            </Row>
            <div style={{ overflowX: 'auto' }}>
              <div style={{ minWidth: 768 }}>
                <Table
                  columns={this.props.columns}
                  dataSource={data}
                  rowKey={(_, index) => index}
                  bordered={false}
                  pagination={{
                    simple: true,
                    defaultCurrent: currentPage,
                    total: parseInt(totalRecords),
                  }}
                  onChange={(e) => this.triggerPagination(e)}
                  locale={{
                    emptyText: (
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">No Data</p>
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Row type="flex" justify="space-around" align="top">
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <Upgrade
                title="Business Intelligence at your fingertips."
                description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                videoId="VjbdXeknVMM"
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default Report;
