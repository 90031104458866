/**
 * Component to register Klaviyo public Apikey.
 *
 * @component
 */

import React from 'react'
import { Modal, Input, message } from 'antd';
import axios from 'axios'
import { trackFSEvent } from '../helpers/fullstory';
import { trackingVitally } from '../helpers/vitally';

class KlaviyoModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      confirmLoading: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  handleOk = async () => {
    if (!this.state.inputValue) {
      return message.error("please enter the Api key.", 5);
    }
    this.setState({
      confirmLoading: true,
    });
    try {
      let response = await axios.post("/api/integrations/klaviyo/key", {
        publicKey: this.state.inputValue,
      });
      if (response.data.error) {
        this.props.modalVisible(null);
        this.setState({
          confirmLoading: false,
          inputValue: "",
        });
        return message.error("something went wrong please try again.", 5);
      }
      this.props.modalVisible(null);
      this.setState({
        confirmLoading: false,
        inputValue: "",
      });
      trackFSEvent("Connect app", {
        feature: "Integrations",
        integratedApp: "klaviyo"
      });
      trackingVitally('rr_Klaviyo_integrated');
      return message.success(response.data.message, 5);
    } catch (err) {
      this.props.modalVisible(null);
      this.setState({
        confirmLoading: false,
        inputValue: "",
      });
      return message.error("something went wrong please try again", 5);
    }
  };

  render() {
    return (
      <Modal
        title="Set up your Klaviyo integration"
        visible={true}
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={()=>this.props.modalVisible(null)}
      >
        <span>
        You can find your Public API-Key for your Klaviyo account {" "}  
          <a
            href="https://www.klaviyo.com/account#api-keys-tab"
            target="_blank"
            rel="noopener noreferrer"
          >
           here.
          </a>
        </span>
        <br /><br />
        <span><strong>API Key</strong></span>
        <Input
          size="large"
          value={this.state.inputValue}
          placeholder="Enter your Klaviyo Public API-Key"
          onChange={(e) => this.handleInputChange(e)}
        />
      </Modal>
    );
  }
}

export default KlaviyoModal;
