/**
 * Status: BETA
 *
 * Component for Warranty settings page in the Dashboard
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  message,
  Row,
  Table,
  Modal,
  Input,
  Tag,
  Popconfirm,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { IconTray } from '../../../../components/CustomReturnsIcons';

const FormItem = Form.Item;

class CaseIssues extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      requireCaseIssues: false,
      caseIssueModal: false,
      caseIssue: undefined,
      warrantyCaseIssues: [],
      loading: true,
      isButtonLoading: false,
      caseIssuePosition: undefined,
      pageNumber: 1,
    };
  }

  handleRefundStatusType = (value) => {
    this.setState({
      refundStatus: value,
    });
  };

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        this.setState({ loading: false }); // Set loading to false if no data
        return;
      }

      this.setState({
        ...response.data,
        warrantyCaseIssues: response.data?.warrantyCaseIssues ? response.data.warrantyCaseIssues : [],
        loading: false, // Set loading to false after data is set
      });
    } catch (err) {
      this.setState({ loading: false }); // Set loading to false in case of error
      message.error('Error retrieving your warranties settings. Try again.', 5);
    }

    if (this.props.setSaveHandler) {
      this.props.setSaveHandler(this.handleOk);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      try {
        const response = await axios.post(
          '/api/returnSettings/update',
          values
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your warranties settings. Please try again.',
            5
          );
        }
        this.setState({
          ...response.data,
        });
        return message.success('Warranties settings updated successfully.', 5);
      } catch (err) {
        return message.error(
          'Error updating your warranties settings. Please try again.',
          5
        );
      }
    });
  };

  handleOk = async () => {
    try {
      this.setState({
        isButtonLoading: true,
      })
      const { caseIssue, warrantyCaseIssues = [], caseIssuePosition } = this.state;
      if (!caseIssue) {
        this.setState({
          isButtonLoading: false,
        })
        return message.error(
          'Case Issue cannot be empty.',
          5
        );
      }
      const payload = {
        caseIssue,
      }
      if (caseIssuePosition) {
        warrantyCaseIssues[caseIssuePosition - 1] = payload;
      } else {
        warrantyCaseIssues.push(payload);
      }
      const response = await axios.post('/api/returnSettings/update',
        {warrantyCaseIssues},
      );
      if (response.data.status === 'error') {
        this.setState({
          isButtonLoading: false,
          caseIssuePosition: undefined,
          caseIssue: undefined,
        })
        return message.error(
          'Error updating your warranties settings. Please try again.',
          5
        );
      }
      const pageNumber = Math.ceil(response.data?.warrantyCaseIssues.length/10 || 0);
      this.setState({
        isButtonLoading: false,
        caseIssuePosition: undefined,
        caseIssue: undefined,
        caseIssueModal: false,
        warrantyCaseIssues: response.data?.warrantyCaseIssues || [],
        pageNumber,
      })
      return message.success('Warranty case issues updated successfully.', 5);
    } catch (err) {
      this.setState({
        isButtonLoading: false,
        caseIssuePosition: undefined,
        caseIssue: undefined,
      })
      return message.error(
        'Error updating your warranties settings. Please try again.',
        5
      );
    }
  }

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  handleCaseIssue = (value) => {
    this.setState({
      caseIssue: value,
    })
  }

  caseIssueModalOpen = () => {
    this.setState({
      caseIssueModal: true,
    });
  };

  handleCaseIssueEdit = (index) => {
    const caseIssues = this.state.warrantyCaseIssues;
    const pageNumber = this.state.pageNumber;
    const position = ((pageNumber - 1) * 10) + index - 1;
    const caseIssuePosition = pageNumber ? position : index - 1;
    const caseIssue = caseIssues[caseIssuePosition]?.caseIssue || '';
    this.setState({
      caseIssue,
      caseIssuePosition: caseIssuePosition + 1,
      caseIssueModal: true,
    });
  }

  caseIssueModalClose = () => {
    this.setState({
      caseIssueModal: false,
      caseIssuePosition: undefined,
      caseIssue: undefined,
    });
  };

  handleCaseIssueDelete = async (index) => {
    try {
      const page = this.state.pageNumber;
      const position = ((page - 1) * 10) + index;
      const caseIssuePosition = page ? position : index;
      const warrantyCaseIssues = this.state.warrantyCaseIssues?.filter((value, issueIndex) => issueIndex !== caseIssuePosition);
      const response = await axios.post('/api/returnSettings/update', {warrantyCaseIssues});
      if (response.data.status === 'error') {
        return message.error(
          'Error deleting the case issue. Please try again.',
          5
        );
      }
      const pageNumber = Math.ceil(response.data?.warrantyCaseIssues.length/10 || 0);
      this.setState({
        warrantyCaseIssues: response.data?.warrantyCaseIssues ? response.data.warrantyCaseIssues : [],
        pageNumber
      });
      return message.success('Successfully deleted the case issue.', 5);
    } catch (err) {
      return message.error(
        'Error deleting the case issue. Please try again.',
        5
      );
    }
    };

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'No.',
        key: (text, record, index) => {
          return `no-${text}`;
        },
        render: (caseIssue, record, index) => {
          const position = (index + 1) + (10 * (this.state.pageNumber - 1));
          return <Tag
            color="gold"
            key={position}
            style={{ marginBottom: 3 }}
          >
            {position}
          </Tag>
        },
      },
      {
        title: 'Case Issue',
        dataIndex: 'caseIssue',
        key: (text, record, index) => {
          return `caseIssue-${text}`;
        },
        render: (caseIssue) => {
          return <Tag
            color="gold"
            key={caseIssue}
            style={{ marginBottom: 3 }}
          >
            {caseIssue}
          </Tag>
        },
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: (text, record, index) => {
          return `edit-${text}`;
        },
        render: (text, record, index) => {
          return (
            <Button size="small" onClick={(e) => this.handleCaseIssueEdit(index + 1)}>
              Edit
            </Button>
          );
        },
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record, index) => {
          return `delete-${text}`;
        },
        render: (text, record , index) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete.?"
              onConfirm={() => this.handleCaseIssueDelete(index)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          );
        },
      },
    ];

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="dashboard-content"
            >
              <Col span={22}>
                <Alert
                  className="u-marginBottom--lg"
                  message="Important: Warranties and Case-issue"
                  description="Here you can add the case issues for the warranty return method."
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Form onSubmit={this.handleSubmit}>
                  <PageButtonHeader
                    headingTitle={'Warranties'}
                    headingRightContent={
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ padding: 'var(--input-padding)' }}
                        >
                          Save changes
                        </Button>
                      </FormItem>
                    }
                  ></PageButtonHeader>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>Enforce Case Issues</h4>
                      <span>
                        Enable this option to make case issues mandatory for the warranty return method.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'requireCaseIssues',
                              {}
                            )(
                              <Checkbox
                                checked={this.state.requireCaseIssues}
                                onChange={(e) => this.handleChange(e)}
                              >
                                Enable
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {(
                    <div className="ContentPanel">
                      <div className="u-marginBottom--sm">
                        <h4>Case Issue</h4>
                        <span>
                          Enable merchants to categorize warranty-related returns by creating custom case issues.
                          This setting allows you to define specific issues such as 'Defective Product,' 
                          'Wrong Item Shipped,' or 'Customer Satisfaction Issue,' which can be selected 
                          when a return is classified as a warranty case.
                        </span>
                        <br/>
                      </div>
                      <div className="u-marginBottom--md">
                        <Button
                          type="ghost"
                          style={{ width: '75px' }}
                          onClick={this.caseIssueModalOpen}
                        >
                          Add
                        </Button>
                      </div>
                      <div style={{ maxWidth: "1050px" }}>
                        {this.state.reserveStockFields?.length > 0 && (
                          <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: 768 }}>
                              <Table
                                rowKey={(_, index) => index}
                                columns={columns}
                                dataSource={this.state.warrantyCaseIssues}
                                pagination={{
                                  current: this.state.pageNumber,
                                  onChange: (page) => this.setState({ pageNumber: page}),
                                }}
                                locale={{
                                  emptyText: (
                                    <div className="flex-row flex-middle-xxs flex-center-xxs">
                                      <div className="flex-col-xxs-12">
                                        <IconTray size="md" />
                                      </div>
                                      <div>
                                        <p className="TextBody TextBody--xxxs">
                                          No Data
                                        </p>
                                      </div>
                                    </div>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {this.state.caseIssueModal && (
                          <Modal
                            title="Add up your warranty case issues"
                            visible={true}
                            width="50%"
                            onOk={this.handleOk}
                            onCancel={this.caseIssueModalClose}
                            footer={null}
                            getContainer={false} //Fix error Blocked aria-hidden
                          >
                            <Row type="flex" justify="space-around" align="middle">
                              <Col span={10}>
                                <h4>Warranty Case Issue</h4>
                                <span>
                                  Enter case issue for warranty related returns.
                                </span>
                              </Col>
                              <Col span={10}>
                                <Input
                                  name="caseIssue"
                                  onChange={(e) => this.handleCaseIssue(e.target.value)}
                                  value={this.state.caseIssue}
                                  placeholder={'Enter case issue'}
                                />
                              </Col>
                            </Row>
                            <Row
                              type="flex"
                              justify="space-around"
                              align="middle"
                              className="dashboard-content"
                            >
                              <Col span={24}>
                                <Row type="flex" justify="end">
                                  <Button
                                    type="primary"
                                    onClick={this.handleOk}
                                    loading={this.state.isButtonLoading}
                                  >
                                    {this.state.caseIssuePosition ? 'Save changes' : 'Add'}
                                  </Button>
                                </Row>
                              </Col>
                            </Row>
                          </Modal>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const WrappedSettingsCaseIssues = Form.create()(CaseIssues);
export default WrappedSettingsCaseIssues;
