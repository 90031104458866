import { Icon, Layout, Select } from 'antd';
import axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import ExternalPortalLanguage from '../../components/ExternalPortalLanguage';
import { TranslateContext } from '../../contexts/TranslationContext';
import ReturnPortalRouter from '../../routes/ReturnPortal';
import { initFullStory, trackFSEvent } from '../../helpers/fullstory';
//styles
import '../../styles/Visitor.css';

// i18n
// import { FormattedMessage } from 'react-intl';
import { IconTray } from '../../components/CustomReturnsIcons';
import { getLocale } from '../../helpers/localePortal';
import { shopminisDeepLink } from '../../constants/constants';
import { FormattedMessage } from 'react-intl';

const { Header, Content } = Layout;
class ReturnPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: {},
      productData: {},
      returnSettings: {},
      brandName: undefined,
      storeUrl: undefined,
      storeShopifyUrl: undefined,
      faviconUrl: undefined,
      logoUrl: undefined,
      backgroundImageUrl: undefined,
      brandColor: undefined,
      brandColorButton: '#FFFFFF',
      brandColorButtonText: undefined,
      brandColorText: undefined,
      contactEmail: undefined,
      companyIdentifier: undefined,
      orderEmail: undefined,
      orderNumber: undefined,
      orderId: undefined,
      removeBackToStoreHeader: true,
      loadingSettings: true,
      brandFontStyle: undefined,
      brandFontSize: undefined,
      brandIncentivesColor: undefined,
      language: null,
      enableTranslation: false,
      enabledTranslations: [],
      fromShopminis: false,
      isInitFullStory: false,
    };
  }

  async componentDidMount() {
    try {
      const url = window.location.href;
      const urlObject = new URL(url);
      const companyIdentifier =
        urlObject.searchParams.get('identifier') ||
        urlObject.searchParams.get('c');
      const returnNumber = urlObject.searchParams.get('returnNumber');
      const fromShopminis = urlObject.searchParams.get('shopminis');
      const orderId = urlObject.searchParams.get('orderId');
      this.setState({
        fromShopminis: fromShopminis === 'true' ? true : false,
        orderId,
      });

      if (companyIdentifier) {
        await this.initFullStoryPortal(companyIdentifier);
        trackFSEvent('Access return portal by customers', {
          returnSource: fromShopminis ? 'Shop Mini' : 'Web Portal',
        });
        const response = await axios.post('/api/visitors/settings/brand', {
          companyIdentifier,
        });
        this.setState({
          ...response.data,
        });
        const responseWithResolutionOptions = await axios.post(
          '/api/visitors/return/resolutions',
          {
            companyIdentifier,
            returnNumber,
          }
        );
        this.setState({
          ...responseWithResolutionOptions.data,
        });

        const listEnabledTranslations = response.data?.translations || [];
        const listTranslationCode =
          listEnabledTranslations.map(
            (translation) => translation.languageCode
          ) || [];
        const locale = getLocale(
          listTranslationCode,
          listEnabledTranslations.find(
            (translation) => translation.defaultLanguage
          )?.languageCode
        );
        this.setState({
          language: locale,
          enabledTranslations: listEnabledTranslations,
        });
      }
    } catch (err) {
      console.log('Error while getting brand Settings', err);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isInitFullStory) {
      const url = window.location.href;
      const urlObject = new URL(url);
      const companyIdentifier =
        urlObject.searchParams.get('identifier') ||
        urlObject.searchParams.get('c');
      if (companyIdentifier) {
        this.initFullStoryPortal(companyIdentifier);
      }
    }
  }

  initFullStoryPortal = async (companyIdentifier) => {
    try {
      const merchantInfo = await axios.post(
        '/api/visitors/settings/getMerchantInfo',
        {
          companyIdentifier,
        }
      );
      if (merchantInfo) {
        const userData = merchantInfo.data?.userData;

        const userStoreInfo = merchantInfo.data;

        const email = userData.shopifyProfileEmail
          ? userData.shopifyProfileEmail
          : userData.customer_email;
        const user_id = userData.domain;

        const shopify_plan = userData.plan_name;
        const plan_name = userStoreInfo.subscription
          ? userStoreInfo.subscription.shopifyPlanName
          : '';
        const name = userData.name;
        initFullStory({
          domain: userData.domain,
          displayName: name,
          email,
          status_str: 'active', // 'active' or 'uninstalled'
          shopId_str: user_id, // unique identifier of shop (ie 'xxxxx' in xxxxx.myshopify.com or xxxxx.mybigcommerce.com, unique URL)
          platform_str: 'shopify', // platform name (ie 'shopify', 'bigcommerce')
          shopify_planName: shopify_plan, // platform plan name (ie 'shopify_plus')
          appHubId_str: 'rich-returns', // business unit name (ie 'conversionbear')
          appName_str: 'Rich Returns', // app name (ie 'checkoutbear')
          //new user property more properties
          plan_types: plan_name,
          country: userData,
          days_active: userStoreInfo.trackingInfo.daysActive,
          return_number: userStoreInfo.trackingInfo.totalReturns,
          trial_period: userStoreInfo.trackingInfo.trialPeriod,
          trial_days: userStoreInfo.trackingInfo.trialDays,
          add_ons: userStoreInfo?.trackingInfo?.addOns,
          number_of_rules: userStoreInfo?.trackingInfo?.totalRules,
          subscription_interval_time: userStoreInfo?.trackingInfo?.intervalTime,
        });
        this.setState({
          isInitFullStory: true,
        });
      }
    } catch (err) {
      console.log('Error while getting user data', err);
    }
  };

  updateBrandSettings(settingsObject) {
    this.setState({
      ...settingsObject,
    });
  }

  handleCloseLoader = () => {
    this.setState({
      loadingSettings: false,
    });
  };

  handleLanguageChange = (language) => {
    this.setState({
      language,
    });
  };

  setEnableTranslation = (enable) => {
    this.setState({
      enableTranslation: enable,
    });
  };

  getStoreUrl = () => {
    const url =
      this.state.fromShopminis && this.state.orderId
        ? shopminisDeepLink + this.state.orderId
        : this.state.storeUrl;
    return url;
  };

  render() {
    const children = [];
    const enableTranslation = this.state.enableTranslation;
    const listTranslationCode =
      this.state.enabledTranslations?.map(
        (translation) => translation.languageCode
      ) || [];
    const defaultLanguage = getLocale(listTranslationCode);
    for (let i = 0; i < this.state.enabledTranslations?.length; i++) {
      children.push(
        <Select.Option key={this.state.enabledTranslations[i].languageCode}>
          {this.state.enabledTranslations[i].languageCode.toUpperCase()}
        </Select.Option>
      );
    }

    const languageDropdown = (
      <Select
        placeholder="Language"
        style={{
          width: '100px',
          position: 'fixed',
          top: '10px',
          right: '10px',
          zIndex: 1000,
        }}
        onChange={this.handleLanguageChange}
        defaultValue={defaultLanguage}
        notFoundContent={
          <div className="flex-row flex-middle-xxs flex-center-xxs">
            <div className="flex-col-xxs-12">
              <IconTray size="md" />
            </div>
            <div>
              <p className="TextBody TextBody--xxxs">No Data</p>
            </div>
          </div>
        }
      >
        {children}
      </Select>
    );

    return (
      <TranslateContext.Provider
        value={{
          enableTranslation: this.state.enableTranslation,
          setEnableTranslation: this.setEnableTranslation,
        }}
      >
        <ExternalPortalLanguage
          brandName={this.state.brandName}
          handleCloseLoader={this.handleCloseLoader}
          language={this.state.language}
          transla
        >
          <Helmet>
            <link rel="icon" href={this.state.faviconUrl} />
          </Helmet>
          <Layout
            style={
              this.state.backgroundImageUrl
                ? {
                    backgroundImage: `url(${this.state.backgroundImageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }
                : { background: this.state.brandColor }
            }
            className="PageLayout PageLayout--portal flex-center-xxs portalPageAlignment"
          >
            {this.state.removeBackToStoreHeader ? (
              <Header
                style={{
                  justifyContent: 'flex-end',
                  background: 'unset',
                  boxShadow: 'unset',
                }}
              >
                {enableTranslation &&
                  Boolean(this.state.enabledTranslations.length > 0) && (
                    <span>{languageDropdown}</span>
                  )}
              </Header>
            ) : (
              <Header>
                <span>
                  {this.state.loadingSettings ? (
                    <div className="GlobalLoader GlobalLoader--xs"></div>
                  ) : this.state.brandName ? (
                    <a
                      className="blackLink"
                      href={this.getStoreUrl()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        type="caret-left"
                        className="u-marginRight--xs"
                      ></Icon>
                      <FormattedMessage
                        id="app.visitor.returnToStore"
                        description="Return to store"
                        defaultMessage={`Return to ${this.state.brandName}`}
                        values={{}}
                      />
                    </a>
                  ) : (
                    <a
                      className="blackLink"
                      href={this.getStoreUrl()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        type="caret-left"
                        className="u-marginRight--xs"
                      ></Icon>
                      <FormattedMessage
                        id="app.visitor.returnToStore"
                        description="Return to store"
                        defaultMessage="Return to store"
                        values={{}}
                      />
                    </a>
                  )}
                </span>
                {enableTranslation &&
                  Boolean(this.state.enabledTranslations.length > 0) && (
                    <span>{languageDropdown}</span>
                  )}
              </Header>
            )}
            <Content id="mainContentElement">
              <ReturnPortalRouter
                updateBrandSettings={this.updateBrandSettings.bind(this)}
                {...this.state}
              />
            </Content>
          </Layout>
        </ExternalPortalLanguage>
      </TranslateContext.Provider>
    );
  }
}

export default ReturnPortal;
