/**
 * Component for Advanced-Rules settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import {
  canadaStateName,
  frequentlyUsedCountries,
  nonFrequentlyUsedCountries,
  TYPE_OF_CUSTOMIZE_QUERY,
  USStateNames,
} from '../../../../constants/constants';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import RuleCondition from './RuleCondition';
import { trackingVitally } from '../../../../helpers/vitally';


const FormItem = Form.Item;

const { Option } = Select;
const { Text } = Typography;

class SettingsRules extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      showDialogForAddingNewRule: false,
      enableEditMode: false,
      selectedRule: {},
      disableProductCheck: false,
      disableProductRule: false,
      customAddressName: 'Custom',
      trackingChange: {
        countEnableRuleCondition: 0,
        conditionUpdated: false,
      },
      listCollections: [],
      listCarrier: [],
      listStoreAddresses: [],
    };
  }

  updateRules(rules) {
    this.setState({
      ...rules,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    // data check
    if (!this.checkRuleValues()) {
      return;
    }

    try {
      const response = await axios.post(
        '/api/returnSettings/rules/create',
        this.state.selectedRule
      );
      if (response.data.status === 'error') {
        return message.error('Error creating rule. Please try again.', 5);
      }
      await this.context.updateContext();
      this.toggleDialogForAddingNewRule();
      trackFSEvent('Add advanced rules', this.state.selectedRule);
      trackingVitally('rr_advancedrules_added');
      return message.success('Rule created successfully.', 5);
    } catch (err) {
      return message.error('Error creating rule. Please try again.', 5);
    }
  };

  checkRuleValues() {
    if (!this.state.selectedRule) {
      message.error('Please enter all data necessary to create a rule.', 5);
      return false;
    }

    if (
      this.state.selectedRule.value == null ||
      this.state.selectedRule.value === ''
    ) {
      message.error('The value field cannot be empty.', 5);
      return false;
    }

    if (
      this.state.selectedRule.enableMultipleRuleCondition &&
      (this.state.selectedRule.secondaryValue == null ||
        this.state.selectedRule.secondaryValue === '')
    ) {
      message.error('The secondary value field cannot be empty.', 5);
      return false;
    }
    if (
      this.state.selectedRule.name == null ||
      this.state.selectedRule.name === ''
    ) {
      message.error('The name field cannot be empty.', 5);
      return false;
    }
    if (
      (this.state.selectedRule.customRestockingFeeType === 'percentage' ||
        this.state.selectedRule.customRestockingFeeType === 'percentageTax') &&
      Number(this.state.selectedRule.customRestockingFeeAmount) > 100
    ) {
      message.error("Percentage amount can't be greater then 100", 5);
      return false;
    }
    if (
      this.state.selectedRule.enableMultipleRuleCondition &&
      this.state.selectedRule.secondaryConditionOperator === 'AND' &&
      this.state.selectedRule.secondaryTrigger ===
        this.state.selectedRule.trigger
    ) {
      message.error(
        'Unable to apply AND condition on same triggers, Please check',
        5
      );
      return false;
    }
    if (
      this.state.selectedRule.enableExtraNote &&
      this.state.selectedRule.configExtraNote &&
      ['checkbox', 'radio-button'].includes(
        this.state.selectedRule.configExtraNote.type
      ) &&
      this.state.selectedRule.configExtraNote.options.filter(
        (i) => i.trim().length > 0
      ).length < 1
    ) {
      message.error('Please add at least 1 options for customer note', 5);
      return false;
    }

    return true;
  }

  handleUpdate = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    // data check
    if (!this.checkRuleValues()) {
      return;
    }

    try {
      const response = await axios.post(
        '/api/returnSettings/rules/update',
        this.state.selectedRule
      );
      if (response.data.status === 'error') {
        return message.error('Error updating rule. Please try again.', 5);
      }
      trackFSEvent('Change rules setting', this.state.selectedRule);
      trackingVitally('rr_rulessetting_changed');
      if (e && e.preventDefault) {
        this.toggleDialogForAddingNewRule();
      }
      return message.success('Rule updated successfully.', 5);
    } catch (err) {
      return message.error('Error updating rule. Please try again.', 5);
    }
  };

  deleteRule = async (rule) => {
    try {
      trackFSEvent('Delete rule condition', rule);
      trackingVitally('rr_rulecondition_deleted');
      const response = await axios.post(
        '/api/returnSettings/rules/delete',
        rule
      );
      if (response.data.status === 'error') {
        return message.error('Error deleting rule. Please try again.', 5);
      }

      await this.context.updateContext();

      // reload component
      this.toggleDialogForAddingNewRule();
      this.toggleDialogForAddingNewRule();

      return message.success('Rule deleted successfully.', 5);
    } catch (err) {
      return message.error('Error deleting rule. Please try again.', 5);
    }
  };

  toggleDialogForAddingNewRule = () => {
    this.setState((prevState) => ({
      showDialogForAddingNewRule: !prevState.showDialogForAddingNewRule,
      enableEditMode: false,
      selectedRule: {
        name: '',
        priority: undefined,
        trigger: 'orderCountry',
        operator: 'is',
        value: '',
        secondaryTrigger: 'originalOrderValue',
        secondaryOperator: 'is',
        secondaryValue: '',
        enableMultipleRuleCondition: false,
        secondaryConditionOperator: 'AND',
        enableCategoryGeneral: true,
        enableCategoryReturnOptions: false,
        enableCategoryShippingMethods: false,
        enableCategoryActions: false,
        changeCarrier: false,
        customCarrier: null,
        generalAllowReturn: true,
        optionRefund: true,
        optionCredit: false,
        optionExchange: false,
        optionAdvancedExchange: false,
        optionStoreWideExchange: false,
        optionCustom1: false,
        optionCustom2: false,
        optionCustom3: false,
        methodCustomerChoice: true,
        methodInStore: false,
        methodLabelCreation: false,
        methodLabelManual: false,
        methodCustomerPaidLabel: false,
        actionManualApproval: false,
        actionAutoApproval: false,
        actionCustomReturnAddress: false,
        customReturnAddressName: undefined,
        customReturnAddressCompany: undefined,
        customReturnAddressStreet1: undefined,
        customReturnAddressStreet2: undefined,
        customReturnAddressCity: undefined,
        customReturnAddressZip: undefined,
        customReturnAddressProvince: undefined,
        customReturnAddressProvinceCode: undefined,
        customReturnAddressCountry: undefined,
        customReturnAddressCountryCode: undefined,
        customReturnAddressPhone: undefined,
        actionCustomLabelFee: false,
        customLabelFeeType: 'fixed',
        customLabelFeeAmount: undefined,
        customLabelFeeCurrency: undefined,
        actionCustomRestockingFee: false,
        customRestockingFeeType: 'fixed',
        customRestockingFeeAmount: undefined,
        customRestockingFeeCurrency: undefined,
        actionCustomerPhotoUpload: false,
        stripePaymentsRule: false,
        disableProductRule: false,
        enableCustomTimeFrame: false,
        customReturnTimeFrame: 30, //days
        isActive: true,
        exchangeCollections: [],
      },
      // categories
      showCategoryGeneral: true,
      showCategoryReturnOptions: true,
      showCategoryShippingMethods: true,
      showCategoryActions: true,
      trackingChange: {
        countEnableRuleCondition: 0,
        conditionUpdated: false,
      },
    }));
  };

  toggleEditMode = (e, rule) => {
    this.setState(
      (prevState) => ({
        enableEditMode: !prevState.enableEditMode,
        showDialogForAddingNewRule: !prevState.showDialogForAddingNewRule,
        selectedRule: {
          ...rule,
          trigger: rule.RuleConditions[0].trigger,
          operator: rule.RuleConditions[0].operator,
          value: rule.RuleConditions[0].value,
          secondaryTrigger: rule.RuleConditions[0].secondaryTrigger,
          secondaryOperator: rule.RuleConditions[0].secondaryOperator,
          secondaryValue: rule.RuleConditions[0].secondaryValue,
          enableMultipleRuleCondition:
            rule.RuleConditions[0].enableMultipleRuleCondition,
          secondaryConditionOperator:
            rule.RuleConditions[0].secondaryConditionOperator,
        },
      }),
      () => {
        const disableProductRule = this.hanldeDisableProdcut();
        this.setState({
          disableProductCheck: rule.disableProductRule
            ? rule.disableProductRule
            : disableProductRule,
        });
        return this.checkCategoriesToDisplay(this.state.selectedRule.trigger);
      }
    );
  };

  updateInput = async (e, fieldId, dateString) => {
    let fieldValue;

    // store user input
    if (e?.target) {
      if (e.target.value) {
        fieldValue = e.target.value;
      } else if (e.target.value === '') {
        fieldValue = e.target.value;
      } else {
        fieldValue = e.target.checked;
      }
    } else if (e === true || e === false) {
      fieldValue = e;
    } else {
      // display relevant options for rule
      this.checkCategoriesToDisplay(e);

      fieldValue = e;
    }
    if (!e && dateString) {
      fieldValue = dateString;
    }

    this.setState(
      (prevState) => ({
        selectedRule: {
          ...prevState.selectedRule,
          [fieldId]: fieldValue,
        },
      }),
      () => {
        const disableProductCheck = this.hanldeDisableProdcut();
        this.setState({
          disableProductCheck: disableProductCheck,
        });
      }
    );

    let countEnableRuleCondition =
      this.state.trackingChange.countEnableRuleCondition;
    if (fieldId === 'enableMultipleRuleCondition') {
      countEnableRuleCondition += 1;
    }
    if (
      [
        'enableMultipleRuleCondition',
        'secondaryConditionOperator',
        'secondaryTrigger',
        'secondaryOperator',
        'secondaryValue',
      ].includes(fieldId)
    ) {
      this.setState({
        trackingChange: {
          ...this.state.trackingChange,
          countEnableRuleCondition,
          conditionUpdated: true,
        },
      });
    }
  };

  handleStripeUpdate = (e) => {
    this.updateInput(e, 'stripePaymentsRule');
    this.setState((prevState) => ({
      selectedRule: {
        ...prevState.selectedRule,
        stripePaymentsRule: e?.target?.checked,
      },
    }));
  };

  hanldeDisableProdcut = () => {
    const productLevelTriggers = [
      'productSKU',
      'productTag',
      'productDiscount',
    ];
    if (
      productLevelTriggers.includes(this.state.selectedRule.trigger) ||
      (this.state.selectedRule.enableMultipleRuleCondition &&
        productLevelTriggers.includes(this.state.selectedRule.secondaryTrigger))
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleSwitchChange = (record, checked) => {
    this.setState(
      {
        selectedRule: {
          ...record,
          isActive: checked,
          trigger: record.RuleConditions[0].trigger,
          operator: record.RuleConditions[0].operator,
          value: record.RuleConditions[0].value,
          secondaryTrigger: record.RuleConditions[0].secondaryTrigger,
          secondaryOperator: record.RuleConditions[0].secondaryOperator,
          secondaryValue: record.RuleConditions[0].secondaryValue,
          enableMultipleRuleCondition:
            record.RuleConditions[0].enableMultipleRuleCondition,
          secondaryConditionOperator:
            record.RuleConditions[0].secondaryConditionOperator,
        },
      },
      this.handleUpdate
    );
  };

  handleConfirm = (record, callback) => {
    try {
      // Ensure that enabling a rule does not allow user to exceed the maximum rule amount
      if (this.context.featureRulesQuota > 0 || record.isActive) {
        this.handleSwitchChange(record, !record.isActive);

        const updatedRules = [...this.state.rules];
        const ruleIndex = updatedRules.findIndex((r) => r.id === record.id);
        updatedRules[ruleIndex].isActive = !record.isActive;

        this.setState({ rules: updatedRules }, () => {
          if (callback) {
            callback();
          }
        });
      } else {
        return message.warning(
          'Active rule limit reached. Disable another rule to make space.',
          5
        );
      }
    } catch (err) {
      return message.error('Error toggling rule. Please try again.', 5);
    }
  };

  checkCategoriesToDisplay(selectedTrigger) {
    const availableTriggers = [
      'orderCountry',
      'orderTag',
      'orderDays',
      'discountCode',
      'originalOrderValue',
      'paymentGateway',
      'selectedAction',
      'selectedReason',
      'productTag',
      'productSKU',
      'productCollection',
      'orderDateRange',
    ];

    if (!availableTriggers.includes(selectedTrigger)) {
      return;
    }

    let showCategoryGeneral = false;
    let showCategoryReturnOptions = false;
    let showCategoryShippingMethods = false;
    let showCategoryActions = false;

    const categoryGeneralTriggers = [
      'orderCountry',
      'orderTag',
      'orderDays',
      'discountCode',
      'originalOrderValue',
      'paymentGateway',
      'productTag',
      'productSKU',
      'productCollection',
      'orderDateRange',
    ];

    const categoryReturnOptionsTriggers = [
      'orderCountry',
      'orderTag',
      'orderDays',
      'discountCode',
      'originalOrderValue',
      'paymentGateway',
      'productTag',
      'productSKU',
      'productCollection',
      'orderDateRange',
    ];

    const categoryShippingMethodsTriggers = [
      'orderCountry',
      'orderTag',
      'orderDays',
      'discountCode',
      'originalOrderValue',
      'paymentGateway',
      'selectedAction',
      'selectedReason',
      'productTag',
      'productSKU',
      'productCollection',
      'orderDateRange',
    ];

    const categoryActions = [
      'orderCountry',
      'orderTag',
      'orderDays',
      'discountCode',
      'originalOrderValue',
      'paymentGateway',
      'selectedAction',
      'selectedReason',
      'productTag',
      'productSKU',
      'productCollection',
      'orderDateRange',
    ];

    if (categoryGeneralTriggers.includes(selectedTrigger)) {
      showCategoryGeneral = true;
    }

    if (categoryReturnOptionsTriggers.includes(selectedTrigger)) {
      showCategoryReturnOptions = true;
    }

    if (categoryShippingMethodsTriggers.includes(selectedTrigger)) {
      showCategoryShippingMethods = true;
    }

    if (categoryActions.includes(selectedTrigger)) {
      showCategoryActions = true;
    }

    this.setState({
      showCategoryGeneral,
      showCategoryReturnOptions,
      showCategoryShippingMethods,
      showCategoryActions,
    });
  }

  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(
        <Option
          key={`frequentlyUsedCountries${country.code}`}
          value={country.code}
        >
          {country.name}
        </Option>
      );
    });

    const disabledoption = (
      <Option key="disabled" value="disabled" disabled={true}>
        ---------
      </Option>
    );
    countriesList.push(disabledoption);
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(
        <Option
          key={`nonFrequentlyUsedCountries${country.code}`}
          value={country.code}
        >
          {country.name}
        </Option>
      );
    });

    return countriesList;
  };

  getlistCarrier = () => {
    return this.state.listCarrier.map((carrier) => {
      return (
        <Option key={carrier.labelService} value={carrier.labelService}>
          {carrier.name}
        </Option>
      );
    });
  };

  getUSStateList = () => {
    const USStateList = USStateNames.map((state) => {
      return (
        <Option key={state.code} value={state.code}>
          {state.name}
        </Option>
      );
    });

    return USStateList;
  };

  getCanadaStateList = () => {
    const canadaStateList = canadaStateName.map((state) => {
      return <Option key={state.code} value={state.code}>{state.name}</Option>;
    });
    return canadaStateList;
  };
  handleChangeCollection = (value) => {
    this.setState((prevState) => ({
      selectedRule: {
        ...prevState.selectedRule,
        exchangeCollections: value,
      },
    }));
  };
  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings/rules/listData');
      this.setState({
        listCollections: response.data.listCollections,
        listCarrier: response.data.carriers,
        listStoreAddresses: response.data.listStoreAddresses,
      });
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  }

  onUpdateType = (type) => {
    const configExtraNote = {
      ...this.state.selectedRule.configExtraNote,
      type: type,
      options: this.state.selectedRule.configExtraNote?.options || [''],
    };
    this.setState((prevState) => ({
      selectedRule: {
        ...prevState.selectedRule,
        configExtraNote: configExtraNote,
      },
    }));
  };

  handleAddOption = () => {
    const configExtraNote = this.state.selectedRule.configExtraNote;
    if (!configExtraNote.options) {
      configExtraNote.options = [];
    }
    if (configExtraNote.options.length < 3) {
      configExtraNote.options.push('');
      this.setState({
        selectedRule: {
          ...this.state.selectedRule,
          configExtraNote: configExtraNote,
        },
      });
    }
  };
  handleMinusOption = () => {
    const configExtraNote = this.state.selectedRule.configExtraNote;
    if (!configExtraNote.options) {
      configExtraNote.options = [];
    }
    if (configExtraNote.options.length > 1) {
      configExtraNote.options.pop();
      this.setState({
        selectedRule: {
          ...this.state.selectedRule,
          configExtraNote: configExtraNote,
        },
      });
    }
  };

  handleChangeOptions = (e) => {
    const configExtraNote = this.state.selectedRule.configExtraNote;
    configExtraNote.options[Number(e.target.id)] = e.target.value;
    this.setState({
      selectedRule: {
        ...this.state.selectedRule,
        configExtraNote: configExtraNote,
      },
    });
  };

  onChangeRequired = (e) => {
    const configExtraNote = {
      ...this.state.selectedRule.configExtraNote,
      isRequired: e.target.checked,
    };
    this.setState({
      selectedRule: {
        ...this.state.selectedRule,
        configExtraNote: configExtraNote,
      },
    });
  };

  loadCustomReturnAddress = (addressId) => {
    const storeAddress = this.state.listStoreAddresses.find(
      (address) => address.id === addressId
    );
    if (storeAddress) {
      this.setState((prevState) => ({
        customAddressName: storeAddress.locationName,
        selectedRule: {
          ...prevState.selectedRule,
          customReturnAddressName: storeAddress.locationName,
          customReturnAddressCompany: storeAddress.company,
          customReturnAddressStreet1: storeAddress.streetAddress,
          customReturnAddressStreet2: storeAddress.optional,
          customReturnAddressCity: storeAddress.city,
          customReturnAddressZip: storeAddress.zipCode,
          customReturnAddressProvince: storeAddress.state,
          customReturnAddressProvinceCode: storeAddress.state,
          customReturnAddressCountry: storeAddress.country,
          customReturnAddressCountryCode: storeAddress.country,
          customReturnAddressPhone: storeAddress.phone,
        },
      }));
    }
  };

  resetCustomReturnAddress = (resetAll) => {
    if (resetAll) {
      this.setState((prevState) => ({
        customAddressName: 'Custom',
        selectedRule: {
          ...prevState.selectedRule,
          customReturnAddressName: undefined,
          customReturnAddressCompany: undefined,
          customReturnAddressStreet1: undefined,
          customReturnAddressStreet2: undefined,
          customReturnAddressCity: undefined,
          customReturnAddressZip: undefined,
          customReturnAddressProvince: undefined,
          customReturnAddressProvinceCode: undefined,
          customReturnAddressCountry: undefined,
          customReturnAddressCountryCode: undefined,
          customReturnAddressPhone: undefined,
        },
      }));
    } else {
      this.setState({
        customAddressName: 'Custom',
      });
    }
  };

  render() {
    if (this.context.featureRules === undefined) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    const listCollections = this.state.listCollections.map((collection) => {
      return (
        <Option value={collection.id} label={collection.title}>
          {collection.title}
        </Option>
      );
    });
    return (
      <React.Fragment>
        <PageButtonHeader
          headingTitle={'Advanced Rules'}
          headingRightContent={
            <div className="flex-row flex-row--noMargin u-columnGap--sm">
            <Tooltip
              placement="bottom"
              title={
                'Your default Return Strategy is defined under the Configuration/Returns tab. Only add a rule in cases where you want to deviate from this default strategy.'
              }
            >
              <Button type="ghost" style={{ padding: 'var(--input-padding)' }}>
                Helpful tips
              </Button>
            </Tooltip>
            {this.state.showDialogForAddingNewRule && (
                <>
                  {this.state.enableEditMode ? (
                    <>
                      <Button
                        onClick={this.handleUpdate.bind(this)}
                        type="primary"
                      >
                        Update
                      </Button>
                      <Button
                        onClick={this.toggleDialogForAddingNewRule.bind(this)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={this.toggleDialogForAddingNewRule.bind(this)}
                        style={{ padding: 'var(--input-padding)' }}
                        type="ghost"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit.bind(this)}
                        type="primary"
                        style={{ padding: 'var(--input-padding)' }}
                      >
                        Save changes
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          }
        ></PageButtonHeader>
        {this.context.featureRules ? (
          <React.Fragment>
            <Row type="flex" align="top" style={{ marginBottom: 20 }}>
              <Col>
                {this.context.featureRulesQuota > 0 ? (
                  <Text>
                    You have {this.context.featureRulesQuota} rules left on your
                    current plan.{' '}
                  </Text>
                ) : (
                  <React.Fragment>
                    <p>
                      <Text>
                        You have {this.context.featureRulesQuota} rules left on
                        your current plan.
                      </Text>
                    </p>
                    {this.context.directBilling ? (
                      <p>
                        <Text strong>
                          Upgrade your plan to access additional rules.
                        </Text>{' '}
                        <Link to="/dashboard/account/plan">
                          <Button size="small" type="danger">
                            See plans
                          </Button>
                        </Link>
                      </p>
                    ) : (
                      <p>
                        <Text strong>
                          Upgrade your plan to access additional rules.
                        </Text>{' '}
                        <Link to="/dashboard/account">
                          <Button size="small" type="danger">
                            See plans
                          </Button>
                        </Link>
                      </p>
                    )}
                  </React.Fragment>
                )}
              </Col>
            </Row>

            <Row type="flex" align="top">
              <Col span={24}>
                <Alert
                  message="Looking for tutorials on Advanced Rules?"
                  description={
                    <React.Fragment>
                      <p className="u-marginBottom--none">
                        If you are looking for common scenarios in how to use
                        advanced rules, please refer to help in our{' '}
                        <a
                          href="https://support.richreturns.io/en/collections/9390042-advanced-rules"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          documentation
                        </a>
                        .
                      </p>
                    </React.Fragment>
                  }
                  className="u-marginBottom--lg"
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            {!this.state.showDialogForAddingNewRule ? (
              <RulesTable
                rules={this.state.rules}
                toggleEditMode={this.toggleEditMode.bind(this)}
                updateRules={this.updateRules.bind(this)}
                deleteRule={this.deleteRule.bind(this)}
                handleUpdate={this.handleUpdate.bind(this)}
                handleConfirm={this.handleConfirm.bind(this)}
              />
            ) : (
              <React.Fragment>
                <Row type="flex" align="top">
                  <Col span={24}>
                    <div className="ContentPanel">
                      <h3 className="u-marginBottom--md">Rule name</h3>
                      <div className="flex-row u-rowGap--sm u-columnGap--sm">
                        <div className="flex-col-xxs-12">
                          <h4>
                            Rule name <em>(give it something unique)</em>
                          </h4>
                          <Input
                            defaultValue={
                              this.state.selectedRule
                                ? this.state.selectedRule.name
                                : undefined
                            }
                            placeholder="Give the rule a name"
                            onChange={(e) => this.updateInput(e, 'name')}
                            style={{ width: '100%' }}
                          />
                        </div>
                        {this.state.enableEditMode ? (
                          <>
                            <div className="flex-col-xxs-12">
                              <Col span={24}>
                                <h4>Rule priority</h4>
                                <Select
                                  defaultValue={
                                    this.state.selectedRule
                                      ? this.state.selectedRule.priority
                                      : 1
                                  }
                                  style={{ width: '100%' }}
                                  placeholder="Select priority"
                                  onChange={(e) =>
                                    this.updateInput(e, 'priority')
                                  }
                                  suffixIcon={<span>Rule priority</span>}
                                >
                                  {this.state.rules &&
                                  this.state.rules.length > 0 ? (
                                    this.state.rules.map((rule) => {
                                      return (
                                        <Option
                                          key={rule.id}
                                          value={rule.priority}
                                        >
                                          {rule.priority}
                                        </Option>
                                      );
                                    })
                                  ) : (
                                    <Option key="loading" value="loading">
                                      Loading data...
                                    </Option>
                                  )}
                                </Select>
                              </Col>
                            </div>
                            <div className="flex-col-xxs-12 u-marginTop--sm">
                              <h4>Do you want to enable this rule?</h4>
                              <div className="flex-row flex-middle-xxs flex-row--noMargin u-columnGap--sm">
                                <Switch
                                  checked={this.state.selectedRule.isActive}
                                  onChange={() => {
                                    this.setState((prevState) => ({
                                      selectedRule: {
                                        ...prevState.selectedRule,
                                        isActive:
                                          !prevState.selectedRule.isActive,
                                      },
                                    }));
                                  }}
                                />
                                <span>Enable rule</span>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
                <RuleCondition
                  selectedRule={this.state.selectedRule}
                  updateInput={this.updateInput}
                />
                <Row type="flex" justify="space-around" align="top">
                  <Col span={24}>
                    <div className="ContentPanel">
                      <h3>Rule effect</h3>
                      <p className="u-marginBottom--md">
                        What should change when the rule triggers?
                      </p>
                      <Row type="flex" justify="center" align="middle">
                        <Col md={24} xs={24}>
                          {this.state.showCategoryGeneral ? (
                            <React.Fragment>
                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                              >
                                <Col md={24} xs={24}>
                                  <Divider>General options</Divider>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ marginBottom: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <span>Make changes to this section?</span>{' '}
                                  <Switch
                                    defaultChecked={
                                      this.state.selectedRule
                                        ? this.state.selectedRule
                                            .enableCategoryGeneral
                                        : true
                                    }
                                    onChange={(e) =>
                                      this.updateInput(
                                        e,
                                        'enableCategoryGeneral'
                                      )
                                    }
                                  />
                                </Col>
                              </Row>

                              {!this.state.disableProductCheck && (
                                <Row type="flex" justify="center" align="top">
                                  <Col md={24} xs={24} className="u-rowGap--sm">
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .generalAllowReturn
                                          : true
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'generalAllowReturn'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryGeneral
                                          : false
                                      }
                                    >
                                      Allow returns
                                    </Checkbox>
                                  </Col>
                                </Row>
                              )}
                              {this.state.disableProductCheck && (
                                <Row type="flex" justify="center" align="top">
                                  <Col md={24} xs={24} className="u-rowGap--sm">
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .disableProductRule
                                          : true
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'disableProductRule'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryGeneral
                                          : false
                                      }
                                    >
                                      Exclude this product from returns.
                                    </Checkbox>
                                  </Col>
                                </Row>
                              )}
                            </React.Fragment>
                          ) : null}

                          {this.state.showCategoryReturnOptions ? (
                            <React.Fragment>
                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                              >
                                <Col md={24} xs={24}>
                                  <Divider>Return options</Divider>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ marginBottom: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <span>Make changes to this section?</span>{' '}
                                  <Switch
                                    defaultChecked={
                                      this.state.selectedRule
                                        ? this.state.selectedRule
                                            .enableCategoryReturnOptions
                                        : true
                                    }
                                    onChange={(e) =>
                                      this.updateInput(
                                        e,
                                        'enableCategoryReturnOptions'
                                      )
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                style={{ flexDirection: 'column' }}
                                className="u-rowGap--sm"
                              >
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule.optionRefund
                                          : true
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'optionRefund')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryReturnOptions
                                          : true
                                      }
                                    >
                                      Refund
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule.optionCredit
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'optionCredit')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryReturnOptions
                                          : true
                                      }
                                    >
                                      Store Credit
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .optionExchange
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'optionExchange')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryReturnOptions
                                          : true
                                      }
                                    >
                                      Exchange
                                    </Checkbox>
                                  </Col>
                                </Row>

                                {this.context.featureAdvancedExchanges ? (
                                  <Row
                                    type="flex"
                                    justify="space-around"
                                    align="top"
                                  >
                                    <Col md={24} xs={24}>
                                      <Checkbox
                                        defaultChecked={
                                          this.state.selectedRule
                                            ? this.state.selectedRule
                                                .optionAdvancedExchange
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.updateInput(
                                            e,
                                            'optionAdvancedExchange'
                                          )
                                        }
                                        disabled={
                                          this.state.selectedRule
                                            ? !this.state.selectedRule
                                                .enableCategoryReturnOptions
                                            : true
                                        }
                                      >
                                        Collection Exchange
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                ) : null}
                                {this.context.featureStoreWideExchange ? (
                                  <Row
                                    type="flex"
                                    justify="space-around"
                                    align="top"
                                  >
                                    <Col md={24} xs={24}>
                                      <Checkbox
                                        defaultChecked={
                                          this.state.selectedRule
                                            ? this.state.selectedRule
                                                .optionStoreWideExchange
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.updateInput(
                                            e,
                                            'optionStoreWideExchange'
                                          )
                                        }
                                        disabled={
                                          this.state.selectedRule
                                            ? !this.state.selectedRule
                                                .enableCategoryReturnOptions
                                            : true
                                        }
                                      >
                                        Store-Wide Exchange
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                ) : null}

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .optionCustom1
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'optionCustom1')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryReturnOptions
                                          : true
                                      }
                                    >
                                      Custom Option 1
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .optionCustom2
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'optionCustom2')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryReturnOptions
                                          : true
                                      }
                                    >
                                      Custom Option 2
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .optionCustom3
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'optionCustom3')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryReturnOptions
                                          : true
                                      }
                                    >
                                      Custom Option 3
                                    </Checkbox>
                                  </Col>
                                </Row>
                              </Row>
                            </React.Fragment>
                          ) : null}

                          {this.state.showCategoryShippingMethods ? (
                            <Row>
                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                              >
                                <Col md={24} xs={24}>
                                  <Divider>Return methods</Divider>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ marginBottom: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <span>Make changes to this section?</span>{' '}
                                  <Switch
                                    defaultChecked={
                                      this.state.selectedRule
                                        ? this.state.selectedRule
                                            .enableCategoryShippingMethods
                                        : true
                                    }
                                    onChange={(e) =>
                                      this.updateInput(
                                        e,
                                        'enableCategoryShippingMethods'
                                      )
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                style={{ flexDirection: 'column' }}
                                className="u-rowGap--sm"
                              >
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .methodCustomerChoice
                                          : true
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'methodCustomerChoice'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryShippingMethods
                                          : true
                                      }
                                    >
                                      Customer is responsible for shipping
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .methodLabelCreation
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'methodLabelCreation'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryShippingMethods
                                          : true
                                      }
                                    >
                                      Pre-Paid Return Label (automatic)
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .methodLabelManual
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'methodLabelManual')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryShippingMethods
                                          : true
                                      }
                                    >
                                      Pre-Paid Return Label (manual)
                                    </Checkbox>
                                  </Col>
                                </Row>
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .methodCustomerPaidLabel
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'methodCustomerPaidLabel'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryShippingMethods
                                          : true
                                      }
                                    >
                                      Customer Paid Return Label
                                    </Checkbox>
                                  </Col>
                                </Row>

                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                >
                                  <Col md={24} xs={24}>
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .methodInStore
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'methodInStore')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryShippingMethods
                                          : true
                                      }
                                    >
                                      In-Store Return
                                    </Checkbox>
                                  </Col>
                                </Row>
                              </Row>
                            </Row>
                          ) : null}

                          {this.state.showCategoryActions ? (
                            <React.Fragment>
                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                              >
                                <Col md={24} xs={24}>
                                  <Divider>Other options</Divider>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ marginBottom: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <span>Make changes to this section?</span>{' '}
                                  <Switch
                                    defaultChecked={
                                      this.state.selectedRule
                                        ? this.state.selectedRule
                                            .enableCategoryActions
                                        : true
                                    }
                                    onChange={(e) =>
                                      this.updateInput(
                                        e,
                                        'enableCategoryActions'
                                      )
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Manual Approval"
                                    extra={
                                      <Tooltip title="Helpful when you auto-approve returns but want to manually approve selected returns, e.g. for faulty items or warranty related cases.">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .actionManualApproval
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'actionManualApproval'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule &&
                                        !this.state.selectedRule
                                          .actionAutoApproval
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Manually approve return ("Pending" status)
                                    </Checkbox>
                                  </Card>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Auto Approve"
                                    extra={
                                      <Tooltip title="You can enable Auto-Approval for all returns under the Configuration / Returns tab. Use this option here to auto-approve selected returns.">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .actionAutoApproval
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'actionAutoApproval'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule &&
                                        !this.state.selectedRule
                                          .actionManualApproval
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Auto approve return ("Approved" status)
                                    </Checkbox>
                                  </Card>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Custom Return Address"
                                    extra={
                                      <Tooltip title="Use a custom return address when the conditions match. This address is then available in the dynamic variable {RETURN_ADDRESS} for the email templates as well as for generating the shipping-label if you have prepaid-labels enabled.">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .actionCustomReturnAddress
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'actionCustomReturnAddress'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Use a custom return address
                                    </Checkbox>

                                    {this.state.selectedRule &&
                                    this.state.selectedRule
                                      .actionCustomReturnAddress ? (
                                      <React.Fragment>
                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>
                                              Load an address from your multiple
                                              addresses
                                            </h4>
                                            <Select
                                              value={
                                                this.state.customAddressName
                                              }
                                              style={{ width: '100%' }}
                                              onChange={(e) => {
                                                this.loadCustomReturnAddress(e);
                                              }}
                                            >
                                              {this.state.listStoreAddresses.map(
                                                (address) => {
                                                  return (
                                                    <Option
                                                      key={address.id}
                                                      value={address.id}
                                                    >
                                                      {address.locationName}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </Col>
                                        </Row>
                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>Country</h4>
                                            <Select
                                              placeholder="Select country"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressCountryCode'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressCountryCode
                                                  : undefined
                                              }
                                              showSearch
                                              optionFilterProp="children"
                                              filterOption={(input, option) =>
                                                option.props.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                              style={{ width: '100%' }}
                                              suffixIcon={<span>Country</span>}
                                            >
                                              {this.getCountriesList()}
                                            </Select>
                                          </Col>
                                        </Row>

                                        {this.state.selectedRule
                                          .customReturnAddressCountryCode ===
                                          'CA' ||
                                        this.state.selectedRule
                                          .customReturnAddressCountryCode ===
                                          'US' ? (
                                          <Row
                                            type="flex"
                                            justify="space-around"
                                            align="top"
                                            style={{ marginTop: 10 }}
                                          >
                                            <Col span={24}>
                                              <h4>State / Province / Region</h4>
                                              <Select
                                                placeholder="State / Province / Region"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                  option.props.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                      input.toLowerCase()
                                                    ) >= 0
                                                }
                                                defaultValue={
                                                  this.state.selectedRule
                                                    ? this.state.selectedRule
                                                        .customReturnAddressProvinceCode
                                                    : undefined
                                                }
                                                value={
                                                  this.state.selectedRule
                                                    ? this.state.selectedRule
                                                        .customReturnAddressProvinceCode
                                                    : undefined
                                                }
                                                onChange={(e) => {
                                                  this.updateInput(
                                                    e,
                                                    'customReturnAddressProvinceCode'
                                                  );
                                                  this.resetCustomReturnAddress();
                                                }}
                                                style={{ width: '100%' }}
                                                suffixIcon={
                                                  <span>
                                                    State / Province / Region
                                                  </span>
                                                }
                                              >
                                                {this.state.selectedRule
                                                  .customReturnAddressCountryCode ===
                                                'US'
                                                  ? this.getUSStateList()
                                                  : this.state.selectedRule
                                                      .customReturnAddressCountryCode ===
                                                    'CA'
                                                  ? this.getCanadaStateList()
                                                  : null}
                                              </Select>
                                            </Col>
                                          </Row>
                                        ) : null}

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>Name</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressName
                                                  : undefined
                                              }
                                              // addonBefore="Name"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressName'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>Company</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressCompany
                                                  : undefined
                                              }
                                              // addonBefore="Company"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressCompany'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>Street 1</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressStreet1
                                                  : undefined
                                              }
                                              // addonBefore="Street 1"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressStreet1'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>Street 2</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressStreet2
                                                  : undefined
                                              }
                                              // addonBefore="Street 2"
                                              placeholder="optional - not all carriers include this line on the label (if used for prepaid labels)"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressStreet2'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>City</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressCity
                                                  : undefined
                                              }
                                              // addonBefore="City"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressCity'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>ZIP code</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressZip
                                                  : undefined
                                              }
                                              // addonBefore="ZIP code"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressZip'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row
                                          type="flex"
                                          justify="space-around"
                                          align="top"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Col span={24}>
                                            <h4>Phone</h4>
                                            <Input
                                              value={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customReturnAddressPhone
                                                  : undefined
                                              }
                                              // addonBefore="Phone"
                                              placeholder="Required by some carriers"
                                              onChange={(e) => {
                                                this.updateInput(
                                                  e,
                                                  'customReturnAddressPhone'
                                                );
                                                this.resetCustomReturnAddress();
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    ) : null}
                                  </Card>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Custom Label Fee"
                                    extra={
                                      <Tooltip title="Set a label fee that applies only to returns matching this rule.">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .actionCustomLabelFee
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'actionCustomLabelFee'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Set a custom label fee
                                    </Checkbox>

                                    {!this.state.selectedRule
                                      .actionCustomLabelFee ? null : (
                                      <React.Fragment>
                                        <Row
                                          type="flex"
                                          justify="center"
                                          align="top"
                                          style={{ marginTop: 20 }}
                                        >
                                          <Col span={24}>
                                            <Select
                                              defaultValue={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customLabelFeeType
                                                  : 'fixed'
                                              }
                                              style={{ width: '100%' }}
                                              placeholder="Select Type"
                                              onChange={(e) =>
                                                this.updateInput(
                                                  e,
                                                  'customLabelFeeType'
                                                )
                                              }
                                              suffixIcon={<span>Type</span>}
                                            >
                                              <Option value="fixed" key="fixed">
                                                Fixed Fee in your Store's
                                                default currency
                                              </Option>
                                            </Select>
                                          </Col>
                                        </Row>
                                        <Row
                                          type="flex"
                                          justify="center"
                                          align="top"
                                          style={{ marginTop: 20 }}
                                        >
                                          <Col span={24}>
                                            <InputNumber
                                              min={0}
                                              step={0.1}
                                              precision={2}
                                              placeholder="Enter amount"
                                              style={{ width: '100%' }}
                                              defaultValue={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customLabelFeeAmount
                                                  : 5
                                              }
                                              onChange={(e) =>
                                                this.updateInput(
                                                  e,
                                                  'customLabelFeeAmount'
                                                )
                                              }
                                            />
                                          </Col>
                                        </Row>

                                        {/*
                                                      <Row type="flex" justify="center" align="top" style={{ marginTop: 20 }}>
                                                    
                                                        <Col span={24}>
                                                          <Select
                                                            defaultValue={this.state.selectedRule ? this.state.selectedRule.customLabelFeeCurrency : "USD"}
                                                            style={{ width: "100%" }}
                                                            placeholder="Select Currency"
                                                            onChange={ (e) => this.updateInput(e, "customLabelFeeCurrency")}
                                                            suffixIcon={<span>Currency</span>}
                                                          >
                                                            <Option value="usd">USD</Option>
                                                            <Option value="eur">EUR</Option>
                                                          </Select>
                                                        </Col>

                                                      </Row>
                                                    */}
                                      </React.Fragment>
                                    )}
                                  </Card>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Custom Restocking Fee"
                                    extra={
                                      <Tooltip title="Set a restocking fee that applies only to returns matching this rule.  Percentage amount can't be greater then 100">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .actionCustomRestockingFee
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'actionCustomRestockingFee'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Set a custom restocking fee
                                    </Checkbox>

                                    {!this.state.selectedRule
                                      .actionCustomRestockingFee ? null : (
                                      <React.Fragment>
                                        <Row
                                          type="flex"
                                          justify="center"
                                          align="top"
                                          style={{ marginTop: 20 }}
                                        >
                                          <Col span={24}>
                                            <Select
                                              defaultValue={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customRestockingFeeType
                                                  : 'fixed'
                                              }
                                              style={{ width: '100%' }}
                                              placeholder="Select Type"
                                              onChange={(e) =>
                                                this.updateInput(
                                                  e,
                                                  'customRestockingFeeType'
                                                )
                                              }
                                              suffixIcon={<span>Type</span>}
                                            >
                                              <Option key="fixed" value="fixed">
                                                Fixed Fee in your Store's
                                                default currency
                                              </Option>
                                              <Option
                                                key="perItem"
                                                value="perItem"
                                              >
                                                Per-item Fee in your Store's
                                                default currency
                                              </Option>
                                              <Option
                                                key="percentage"
                                                value="percentage"
                                              >
                                                Restocking Fee in Percentage
                                              </Option>
                                              <Option
                                                key="percentageTax"
                                                value="percentageTax"
                                              >
                                                Restocking Fee include tax in
                                                percentage
                                              </Option>
                                            </Select>
                                          </Col>
                                        </Row>
                                        <Row
                                          type="flex"
                                          justify="center"
                                          align="top"
                                          style={{ marginTop: 20 }}
                                        >
                                          <Col span={24}>
                                            <InputNumber
                                              min={0}
                                              step={0.1}
                                              precision={2}
                                              placeholder="Enter amount"
                                              style={{ width: '100%' }}
                                              defaultValue={
                                                this.state.selectedRule
                                                  ? this.state.selectedRule
                                                      .customRestockingFeeAmount
                                                  : 5
                                              }
                                              onChange={(e) =>
                                                this.updateInput(
                                                  e,
                                                  'customRestockingFeeAmount'
                                                )
                                              }
                                            />
                                          </Col>
                                        </Row>

                                        {/*

                                                      <Row type="flex" justify="center" align="top" style={{ marginTop: 20 }}>
                                                    
                                                        <Col span={24}>
                                                          <Select
                                                            defaultValue={this.state.selectedRule ? this.state.selectedRule.customRestockingFeeCurrency : "USD"}
                                                            style={{ width: "100%" }}
                                                            placeholder="Select Currency"
                                                            onChange={ (e) => this.updateInput(e, "customRestockingFeeCurrency")}
                                                            suffixIcon={<span>Currency</span>}
                                                          >
                                                            <Option value="usd">USD</Option>
                                                            <Option value="eur">EUR</Option>
                                                          </Select>
                                                        </Col>

                                                      </Row>

                                                    */}
                                      </React.Fragment>
                                    )}
                                  </Card>
                                </Col>
                              </Row>
                              {this.context.featureStripePayment && (
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                  style={{ marginTop: 20 }}
                                >
                                  <Col md={24} xs={24}>
                                    <Card
                                      title="Stripe Payments"
                                      extra={
                                        <Tooltip title="You can enable Stripe Payments for all returns under the Configuration / Returns tab. Use this option here to enable it for selected returns.">
                                          <Icon type="info-circle" />
                                        </Tooltip>
                                      }
                                    >
                                      <Checkbox
                                        defaultChecked={
                                          this.state.selectedRule
                                            ? this.state.selectedRule
                                                .stripePaymentsRule
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.handleStripeUpdate(e)
                                        }
                                        disabled={
                                          this.state.selectedRule &&
                                          !this.state.selectedRule
                                            .enableCategoryActions
                                        }
                                        checked={
                                          this.state.selectedRule
                                            .stripePaymentsRule
                                        }
                                      >
                                        Enable Stripe Payments
                                      </Checkbox>
                                      {this.state.selectedRule
                                        .stripePaymentsRule && (
                                        <Checkbox
                                          defaultChecked={
                                            this.state.selectedRule
                                              ? this.state.selectedRule
                                                  .stripeLabelCostRule
                                              : false
                                          }
                                          onChange={(e) =>
                                            this.updateInput(
                                              e,
                                              'stripeLabelCostRule'
                                            )
                                          }
                                          disabled={
                                            this.state.selectedRule &&
                                            !this.state.selectedRule
                                              .enableCategoryActions
                                          }
                                          style={{
                                            marginLeft: 0,
                                            marginTop: 10,
                                          }}
                                        >
                                          Enable Label Cost Payments through
                                          Stripe
                                        </Checkbox>
                                      )}
                                    </Card>
                                  </Col>
                                </Row>
                              )}
                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Return Timeframe"
                                    extra={
                                      <Tooltip title="Use this option to modify the return timeframe for certain orders">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .enableCustomTimeFrame
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(
                                          e,
                                          'enableCustomTimeFrame'
                                        )
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Enable Custom Return Timeframe
                                    </Checkbox>
                                    {this.state.selectedRule
                                      .enableCustomTimeFrame && (
                                      <Row
                                        type="flex"
                                        justify="center"
                                        align="top"
                                        style={{ marginTop: 20 }}
                                      >
                                        <Col span={24}>
                                          <InputNumber
                                            min={0}
                                            step={1}
                                            placeholder="Enter Days"
                                            style={{ width: '100%' }}
                                            defaultValue={
                                              this.state.selectedRule
                                                ? this.state.selectedRule
                                                    .customReturnTimeFrame
                                                : 30
                                            }
                                            onChange={(e) =>
                                              this.updateInput(
                                                e,
                                                'customReturnTimeFrame'
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                  </Card>
                                </Col>
                              </Row>

                              {this.context.featureCustomerPhotoUpload ? (
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                  style={{ marginTop: 20 }}
                                >
                                  <Col md={24} xs={24}>
                                    <Card
                                      title="Customer Photo Upload"
                                      extra={
                                        <Tooltip title="Helpful when customers claim a faulty item and your staff wants to check the photos before approving a return. You can customize the text accompanying the dialog under the Configuration / Returns tab, also make sure to check 'Require' there if you want to make this mandatory for the customer.">
                                          <Icon type="info-circle" />
                                        </Tooltip>
                                      }
                                    >
                                      <Checkbox
                                        defaultChecked={
                                          this.state.selectedRule
                                            ? this.state.selectedRule
                                                .actionCustomerPhotoUpload
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.updateInput(
                                            e,
                                            'actionCustomerPhotoUpload'
                                          )
                                        }
                                        disabled={
                                          this.state.selectedRule
                                            ? !this.state.selectedRule
                                                .enableCategoryActions
                                            : true
                                        }
                                      >
                                        Ask the customer to upload photos for
                                        the return
                                      </Checkbox>
                                    </Card>
                                  </Col>
                                </Row>
                              ) : (
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                  style={{ marginTop: 20 }}
                                >
                                  <Col md={24} xs={24}>
                                    <Card
                                      title="Customer Photo Upload"
                                      extra={
                                        <Tooltip title="Helpful when customers claim a faulty item and your staff wants to check the photos before approving a return. You can customize the text accompanying the dialog under the Configuration / Returns tab, also make sure to check 'Require' there if you want to make this mandatory for the customer.">
                                          <Icon type="info-circle" />
                                        </Tooltip>
                                      }
                                    >
                                      {this.context.directBilling ? (
                                        <p>
                                          Ask customers for photos with the
                                          Photo-Upload Add-On. See details{' '}
                                          <Link to="/dashboard/account/plan">
                                            subscription plans
                                          </Link>{' '}
                                          for details
                                        </p>
                                      ) : (
                                        <p>
                                          Ask customers for photos with the
                                          Photo-Upload Add-On. See{' '}
                                          <Link to="/dashboard/account/profile">
                                            subscription plans
                                          </Link>{' '}
                                          for details.
                                        </p>
                                      )}
                                    </Card>
                                  </Col>
                                </Row>
                              )}

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Add customer note"
                                    extra={
                                      <Tooltip title="Use this option to add an additional customer note for additional information about the return from the customer.">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule &&
                                        this.state.selectedRule.enableExtraNote
                                          ? this.state.selectedRule
                                              .enableExtraNote
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'enableExtraNote')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Add customer note
                                    </Checkbox>
                                    {this.state.selectedRule &&
                                      this.state.selectedRule.enableExtraNote &&
                                      this.state.selectedRule
                                        .enableCategoryActions && (
                                        <React.Fragment>
                                          <Row
                                            type="flex"
                                            align="top"
                                            style={{ marginTop: 10 }}
                                          >
                                            <Checkbox
                                              checked={
                                                this.state.selectedRule
                                                  .configExtraNote
                                                  ?.isRequired || false
                                              }
                                              onChange={this.onChangeRequired}
                                              defaultValue={false}
                                            >
                                              Required?
                                            </Checkbox>
                                          </Row>
                                          <Row
                                            type="flex"
                                            align="top"
                                            style={{ marginTop: 15 }}
                                          >
                                            Your query here
                                          </Row>
                                          <Row
                                            type="flex"
                                            justify="center"
                                            align="top"
                                            style={{ marginTop: 10 }}
                                          >
                                            <Col span={24}>
                                              <Input
                                                placeholder="Example: Please select all that apply down below in terms of your satisfaction"
                                                style={{ width: '100%' }}
                                                defaultValue={
                                                  this.state.selectedRule
                                                    ? this.state.selectedRule
                                                        .titleExtraNote
                                                    : undefined
                                                }
                                                onChange={(e) =>
                                                  this.updateInput(
                                                    e,
                                                    'titleExtraNote'
                                                  )
                                                }
                                                disabled={
                                                  this.state.selectedRule
                                                    ? !this.state.selectedRule
                                                        .enableCategoryActions
                                                    : true
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <Row
                                            type="flex"
                                            align="top"
                                            style={{ marginTop: 10 }}
                                          >
                                            Choose your type
                                          </Row>
                                          <Row
                                            type="flex"
                                            justify="center"
                                            align="top"
                                            style={{ marginTop: 10 }}
                                          >
                                            <Select
                                              placeholder="Choose your type"
                                              onChange={this.onUpdateType}
                                              style={{ width: '100%' }}
                                              defaultValue={
                                                this.state.selectedRule &&
                                                this.state.selectedRule
                                                  .configExtraNote
                                                  ? this.state.selectedRule
                                                      .configExtraNote.type
                                                  : 'note'
                                              }
                                            >
                                              {TYPE_OF_CUSTOMIZE_QUERY.map(
                                                (i, key) => (
                                                  <Option
                                                    key={key}
                                                    value={i.value}
                                                  >
                                                    {i.label}
                                                  </Option>
                                                )
                                              )}
                                            </Select>
                                          </Row>
                                          {this.state.selectedRule &&
                                            this.state.selectedRule
                                              .configExtraNote &&
                                            [
                                              'checkbox',
                                              'radio-button',
                                            ].includes(
                                              this.state.selectedRule
                                                .configExtraNote.type
                                            ) && (
                                              <React.Fragment>
                                                <Row
                                                  span={24}
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '20px',
                                                  }}
                                                >
                                                  {this.state.selectedRule
                                                    .configExtraNote.type ===
                                                  'checkbox'
                                                    ? 'Checkboxes (up to 3)'
                                                    : 'Radio buttions (up to 3)'}
                                                  :{' '}
                                                  <Icon
                                                    type="plus-circle"
                                                    style={{
                                                      fontSize: '20px',
                                                      padding: '2px',
                                                    }}
                                                    onClick={
                                                      this.handleAddOption
                                                    }
                                                  />
                                                  <Icon
                                                    type="minus-circle"
                                                    style={{
                                                      fontSize: '20px',
                                                      padding: '2px',
                                                    }}
                                                    onClick={
                                                      this.handleMinusOption
                                                    }
                                                  />
                                                </Row>
                                                <Row span={24}>
                                                  {this.state.selectedRule
                                                    .configExtraNote.options &&
                                                    this.state.selectedRule.configExtraNote.options.map(
                                                      (i, key) => (
                                                        <Col
                                                          span={8}
                                                          key={key}
                                                          style={{
                                                            padding: '3px',
                                                          }}
                                                        >
                                                          <Row>
                                                            Option {key + 1}{' '}
                                                          </Row>
                                                          <Row>
                                                            <Input
                                                              span={22}
                                                              id={key.toString()}
                                                              onChange={
                                                                this
                                                                  .handleChangeOptions
                                                              }
                                                              defaultValue={i}
                                                            />
                                                          </Row>
                                                        </Col>
                                                      )
                                                    )}
                                                </Row>
                                              </React.Fragment>
                                            )}
                                        </React.Fragment>
                                      )}
                                  </Card>
                                </Col>
                              </Row>

                              <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                                style={{ marginTop: 20 }}
                              >
                                <Col md={24} xs={24}>
                                  <Card
                                    title="Carrier Preference"
                                    extra={
                                      <Tooltip title="Use this option to set a specific carrier for returns matching this rule.">
                                        <Icon type="info-circle" />
                                      </Tooltip>
                                    }
                                  >
                                    <Checkbox
                                      defaultChecked={
                                        this.state.selectedRule
                                          ? this.state.selectedRule
                                              .changeCarrier
                                          : true
                                      }
                                      onChange={(e) =>
                                        this.updateInput(e, 'changeCarrier')
                                      }
                                      disabled={
                                        this.state.selectedRule
                                          ? !this.state.selectedRule
                                              .enableCategoryActions
                                          : true
                                      }
                                    >
                                      Enable this checkbox to choose a specific
                                      carrier for this rule.
                                    </Checkbox>
                                    {this.state.selectedRule?.changeCarrier && (
                                      <Select
                                        defaultValue={
                                          this.state.selectedRule
                                            ? this.state.selectedRule
                                                .customCarrier
                                            : undefined
                                        }
                                        placeholder="Select Carrier"
                                        onChange={(e) =>
                                          this.updateInput(e, 'customCarrier')
                                        }
                                        style={{
                                          width: '100%',
                                          paddingTop: '20px',
                                        }}
                                        disabled={
                                          this.state.selectedRule
                                            ? !this.state.selectedRule
                                                .enableCategoryActions
                                            : true
                                        }
                                        notFoundContent={
                                          <div className="flex-row flex-middle-xxs flex-center-xxs">
                                            <div className="flex-col-xxs-12">
                                              <IconTray size="md" />
                                            </div>
                                            <div>
                                              <p className="TextBody TextBody--xxxs">
                                                No Data
                                              </p>
                                            </div>
                                          </div>
                                        }
                                      >
                                        {this.getlistCarrier()}
                                      </Select>
                                    )}
                                  </Card>
                                </Col>
                              </Row>
                            </React.Fragment>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                {/* <Row type="flex" justify="space-around" align="top">
                  <Col>
                    {this.state.enableEditMode ? (
                      <Button
                        type="primary"
                        onClick={this.handleUpdate.bind(this)}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        onClick={this.handleSubmit.bind(this)}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row> */}
              </React.Fragment>
            )}
            <Row
              type="flex"
              justify="end"
              align="top"
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Col>
                {this.state.showDialogForAddingNewRule ? (
                  <React.Fragment>
                    {this.state.enableEditMode ? (
                      <div className="flex-row flex-row--noMargin u-columnGap--sm">
                        <Button
                          onClick={this.handleUpdate.bind(this)}
                          type="primary"
                        >
                          Update
                        </Button>{' '}
                        <Button
                          onClick={this.toggleDialogForAddingNewRule.bind(this)}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div className="flex-row flex-row--noMargin u-columnGap--sm">
                        <Button
                          onClick={this.toggleDialogForAddingNewRule.bind(this)}
                          style={{ padding: 'var(--input-padding)' }}
                          type="ghost"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.handleSubmit.bind(this)}
                          type="primary"
                          style={{ padding: 'var(--input-padding)' }}
                        >
                          Save changes
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <Button
                    type="primary"
                    onClick={this.toggleDialogForAddingNewRule.bind(this)}
                    disabled={
                      this.context.featureRulesQuota === 0 ? true : false
                    }
                  >
                    Add a new rule
                  </Button>
                )}
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Upgrade
            title="Build advanced rules & scenarios for your returns."
            description="Upgrade to a higher plan to tailor returns to your business processes and goals based on order data and customer actions."
            faqLink="https://support.richreturns.io/en/articles/9272498-overview-of-advanced-rules"
            videoId="DwAxUUS7A0o"
          />
        )}
      </React.Fragment>
    );
  }
}

class RulesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.columns = [
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: (text, record) => {
          return `priority-${record.id}`;
        },
      },
      {
        title: 'Rule Name',
        dataIndex: 'name',
        key: (text, record) => {
          return `name-${record.id}`;
        },
      },
      {
        title: 'Enable',
        dataIndex: 'enable',
        key: (text, record) => {
          return `enable-${record.id}`;
        },
        render: (text, record) => (
          <Popconfirm
            title="Are you sure? This will affect returns falling under this rule's criteria."
            onConfirm={() => this.props.handleConfirm(record, () => {})}
            okText="Yes"
            cancelText="No"
          >
            <Switch checked={record.isActive} />
          </Popconfirm>
        ),
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: (text, record) => {
          return `edit-${record.id}`;
        },
        render: (text, record) => {
          return (
            <Button
              type="ghost"
              onClick={(e) => this.props.toggleEditMode(e, record)}
              style={{ padding: 'var(--input-padding)' }}
            >
              Edit rule
            </Button>
          );
        },
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record) => {
          return `delete-${record.id}`;
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete this rule?"
              onConfirm={() => this.props.deleteRule(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="ghost" style={{ padding: 'var(--input-padding)' }}>
                Delete rule
              </Button>
            </Popconfirm>
          );
        },
      },
    ];
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings/rules');
      if (response.data == null) {
        this.setState({ loading: false });
        return;
      }
      this.props.updateRules(response.data);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  }
  render() {
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <Row type="flex">
        <Col span={24}>
          <div className="ContentPanel u-paddingTop--lg">
            <div style={{ overflowX: 'auto' }}>
              <div style={{ minWidth: 768 }}>
                <Table
                  columns={this.columns}
                  dataSource={this.props.rules}
                  locale={{
                    emptyText: (
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">No Data</p>
                        </div>
                      </div>
                    ),
                  }}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const WrappedSettingsRules = Form.create()(SettingsRules);
export default WrappedSettingsRules;
