/**
 * Component to register Stripe credentials.
 *
 * @component
 */

import React from 'react';
import { Modal, Input, Button, message, Icon, Tooltip } from 'antd';
import axios from 'axios';
import { trackFSEvent } from '../helpers/fullstory';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { trackingVitally } from '../helpers/vitally';

class StripeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripePublishableKey: '',
      stripeSecretKey: '',
      webhookSignature: '',
      stripeWebhookUrl: '',
      CompanyId: undefined,
      enableStripePayment: false,
      trackingChange: {
        insertPublishableKey: false,
        insertSecretKey: false,
        addStripeWebhookSignature: false,
      },
      loading: true,
    };
  }

  componentDidMount = async () => {
    try {
      await this.fetchReturnSettings();
      await this.generateWebhookUrl();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your Stripe settings. Please try again.', 5);
    }
  };

  fetchReturnSettings = async () => {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data === null) {
        return message.error('Error retrieving your Stripe credentials. Try again.', 5);
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error('Error retrieving your Stripe settings. Try again.', 5);
    }
  };

  generateWebhookUrl = async () => {
    try {
      const response = await axios.get('/api/integrations/appInfo');
      if (response.data == null) {
        return;
      }
      const env = response?.data?.env;
      const appUrl = response?.data?.appUrl;
      const CompanyId = this.state.CompanyId || response?.data?.companyId;
      let url = 'https://your-ngrok-url/api/stripe/webhook';
      if (env === 'production') {
        url = `${appUrl}/api/stripe/webhook`;
      }
      url = `${url}/${CompanyId}`;
      this.setState({
        stripeWebhookUrl: url,
      });
    } catch (err) {
      message.error('Error creating webhook URL. Try again.', 5);
    }
  };

  handleInputChange = (e) => {
    const insertPublishableKey = e.target.id === 'stripePublishableKey';
    const insertSecretKey = e.target.id === 'stripeSecretKey';
    const addStripeWebhookSignature = e.target.id === 'webhookSignature';
    this.setState({
      [e.target.id]: e.target.value,
      trackingChange: {
        ...this.state.trackingChange,
        insertPublishableKey: insertPublishableKey || this.state.trackingChange.insertPublishableKey,
        insertSecretKey: insertSecretKey || this.state.trackingChange.insertSecretKey,
        addStripeWebhookSignature:
          addStripeWebhookSignature || this.state.trackingChange.addStripeWebhookSignature,
      },
    });
  };

  handleOk = async () => {
    const { stripePublishableKey, stripeSecretKey } = this.state;
    if (!stripePublishableKey || !stripeSecretKey) {
      return message.error('Please fill in both your API keys.', 5);
    }
    this.setState({ confirmLoading: true });
    try {
      const response = await axios.post('/api/returnSettings/update', {
        stripePublishableKey: stripePublishableKey ? stripePublishableKey.trim() : null,
        stripeSecretKey: stripeSecretKey ? stripeSecretKey.trim() : null,
        webhookSignature: this.state.webhookSignature ? this.state.webhookSignature.trim() : null,
      });
      if (response.data.status === 'error') {
        message.error('Error updating your Stripe credentials. Please try again.', 5);
      } else {
        message.success('Stripe credentials successfully updated.', 5);
        if (this.props.refreshConnectionStatus) {
          await this.props.refreshConnectionStatus();
        }
        trackFSEvent("Connect app", {
          feature: "Integrations",
          integratedApp: "stripe"
        });
        trackingVitally('rr_Stripe_integrated');
      }
      this.props.modalVisible(null);
      this.setState({ confirmLoading: false });
    } catch (err) {
      message.error('Error updating your Stripe credentials. Please try again.', 5);
      this.props.modalVisible(null);
      this.setState({ confirmLoading: false });
    }
  };

  render() {
    const {
      stripePublishableKey,
      stripeSecretKey,
      webhookSignature,
      stripeWebhookUrl,
      confirmLoading,
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Modal
        title="Set up your Stripe integration"
        width="680px"
        visible={true}
        onOk={this.handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => this.props.modalVisible(null)}
      >
        <p>
          Follow our <a href="https://support.richreturns.io/en/articles/9272043-stripe-integration" target="_blank">guide</a> to set up your Stripe integration.
        </p>
        <br />
        <span><strong>Publishable Key</strong></span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            id="stripePublishableKey"
            size="large"
            value={stripePublishableKey}
            placeholder="Enter your Stripe Publishable Key"
            onChange={this.handleInputChange}
            style={{ flex: 1, marginRight: '10px' }}
          />
          <Tooltip title={<span>You can find your key <a href="https://dashboard.stripe.com/login?redirect=%2Ftest%2Fapikeys" target="_blank">here</a>.</span>}>
            <Icon type="info-circle" />
          </Tooltip>
        </div>
        <br />
        <span><strong>Secret Key</strong></span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            id="stripeSecretKey"
            size="large"
            value={stripeSecretKey}
            placeholder="Enter your Stripe Secret Key"
            onChange={this.handleInputChange}
            style={{ flex: 1 }}
          />
          <Tooltip title={<span>You can find your key <a href="https://dashboard.stripe.com/login?redirect=%2Ftest%2Fapikeys" target="_blank">here</a>.</span>}>
            <Icon type="info-circle" />
          </Tooltip>
        </div>
        <br />
        <span><strong>Stripe Webhook Signature</strong></span>
        <Input
          id="webhookSignature"
          size="large"
          value={webhookSignature}
          placeholder="Enter your Stripe Webhook Signature"
          onChange={this.handleInputChange}
        />
        <br /><br />
        <span><strong>Webhook URL</strong></span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            id="stripeWebhookUrl"
            size="large"
            value={stripeWebhookUrl}
            placeholder="Generate your Stripe Webhook URL"
            readOnly
            style={{ flex: 1, marginRight: '10px' }}
          />
          <Button type="primary" onClick={this.generateWebhookUrl}>
            Generate Webhook URL
          </Button>
        </div>
        
        {stripeWebhookUrl && (
          <CopyToClipboard text={stripeWebhookUrl}>
            <Button
              type="ghost"
              onClick={() => message.success('Webhook URL copied successfully', 3)}
              style={{ width: '100%' }}
            >
              <Icon type="copy" />
              Copy Webhook URL
            </Button>
          </CopyToClipboard>
        )}
      </Modal>
    );
  }
}

export default StripeModal;
