export const frequentlyUsedCountries = [
  { code: 'AT', name: 'Austria' },
  { code: 'AU', name: 'Australia' },
  { code: 'CA', name: 'Canada' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DE', name: 'Germany' },
  { code: 'IN', name: 'India' },
  { code: 'IT', name: 'Italy' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SE', name: 'Sweden' },
  { code: 'US', name: 'United States' },
  { code: 'GB', name: 'United Kingdom' },
];

export const nonFrequentlyUsedCountries = [
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BB', name: 'Barbados' },
  { code: 'WF', name: 'Wallis and Futuna' },
  { code: 'BL', name: 'Saint Barthelemy' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BN', name: 'Brunei' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BI', name: 'Burundi' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BW', name: 'Botswana' },
  { code: 'WS', name: 'Samoa' },
  { code: 'BQ', name: 'Bonaire, Saint Eustatius and Saba' },
  { code: 'BR', name: 'Brazil' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'JE', name: 'Jersey' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BZ', name: 'Belize' },
  { code: 'RU', name: 'Russia' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'RS', name: 'Serbia' },
  { code: 'TL', name: 'East Timor' },
  { code: 'RE', name: 'Reunion' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'RO', name: 'Romania' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { code: 'GR', name: 'Greece' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'JP', name: 'Japan' },
  { code: 'GY', name: 'Guyana' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'GE', name: 'Georgia' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GA', name: 'Gabon' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GH', name: 'Ghana' },
  { code: 'OM', name: 'Oman' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'JO', name: 'Jordan' },
  { code: 'HR', name: 'Croatia' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HU', name: 'Hungary' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HM', name: 'Heard Island and McDonald Islands' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'PS', name: 'Palestinian Territory' },
  { code: 'PW', name: 'Palau' },
  { code: 'PT', name: 'Portugal' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'PA', name: 'Panama' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PE', name: 'Peru' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PL', name: 'Poland' },
  { code: 'PM', name: 'Saint Pierre and Miquelon' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'EH', name: 'Western Sahara' },
  { code: 'EE', name: 'Estonia' },
  { code: 'EG', name: 'Egypt' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ZW', name: 'Zimbabwe' },
  { code: 'ES', name: 'Spain' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MD', name: 'Moldova' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MF', name: 'Saint Martin' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MC', name: 'Monaco' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'ML', name: 'Mali' },
  { code: 'MO', name: 'Macao' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MK', name: 'Macedonia' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'MT', name: 'Malta' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MV', name: 'Maldives' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'UG', name: 'Uganda' },
  { code: 'TZ', name: 'Tanzania' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MX', name: 'Mexico' },
  { code: 'IL', name: 'Israel' },
  { code: 'FR', name: 'France' },
  { code: 'IO', name: 'British Indian Ocean Territory' },
  { code: 'SH', name: 'Saint Helena' },
  { code: 'FI', name: 'Finland' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FK', name: 'Falkland Islands' },
  { code: 'FM', name: 'Micronesia' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NO', name: 'Norway' },
  { code: 'NA', name: 'Namibia' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NE', name: 'Niger' },
  { code: 'NF', name: 'Norfolk Island' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NU', name: 'Niue' },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'XK', name: 'Kosovo' },
  { code: 'CI', name: 'Ivory Coast' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'CO', name: 'Colombia' },
  { code: 'CN', name: 'China' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CL', name: 'Chile' },
  { code: 'CC', name: 'Cocos Islands' },
  { code: 'CG', name: 'Republic of the Congo' },
  { code: 'CF', name: 'Central African Republic' },
  { code: 'CD', name: 'Democratic Republic of the Congo' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CX', name: 'Christmas Island' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CW', name: 'Curacao' },
  { code: 'CV', name: 'Cape Verde' },
  { code: 'CU', name: 'Cuba' },
  { code: 'SZ', name: 'Swaziland' },
  { code: 'SY', name: 'Syria' },
  { code: 'SX', name: 'Sint Maarten' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'KN', name: 'Saint Kitts and Nevis' },
  { code: 'KM', name: 'Comoros' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'KR', name: 'South Korea' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'KP', name: 'North Korea' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'SN', name: 'Senegal' },
  { code: 'SM', name: 'San Marino' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KY', name: 'Cayman Islands' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SD', name: 'Sudan' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'VG', name: 'British Virgin Islands' },
  { code: 'YE', name: 'Yemen' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'UM', name: 'United States Minor Outlying Islands' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'LA', name: 'Laos' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TT', name: 'Trinidad and Tobago' },
  { code: 'TR', name: 'Turkey' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LV', name: 'Latvia' },
  { code: 'TO', name: 'Tonga' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TF', name: 'French Southern Territories' },
  { code: 'TG', name: 'Togo' },
  { code: 'TD', name: 'Chad' },
  { code: 'TC', name: 'Turks and Caicos Islands' },
  { code: 'LY', name: 'Libya' },
  { code: 'VA', name: 'Vatican' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AG', name: 'Antigua and Barbuda' },
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'VI', name: 'U.S. Virgin Islands' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IR', name: 'Iran' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AL', name: 'Albania' },
  { code: 'AO', name: 'Angola' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AX', name: 'Aland Islands' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'IE', name: 'Ireland' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'QA', name: 'Qatar' },
  { code: 'MZ', name: 'Mozambique' },
];

export const CountriesList = {
  ...frequentlyUsedCountries,
  ...nonFrequentlyUsedCountries,
};

export const USStateNames = [
  { code: 'AA', name: 'AA (Military)' },
  { code: 'AE', name: 'AE (Military)' },
  { code: 'AP', name: 'AP (Military)' },
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District Of Columbia' },
  { code: 'FM', name: 'Federated States Of Micronesia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'GU', name: 'Guam' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PW', name: 'Palau' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VI', name: 'Virgin Islands' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
];

export const canadaStateName = [
  { code: 'AB', name: 'Alberta' },
  { code: 'BC', name: 'British Columbia' },
  { code: 'MB', name: 'Manitoba' },
  { code: 'NB', name: 'New Brunswick' },
  { code: 'NF', name: 'Newfoundland' },
  { code: 'NT', name: 'Northwest Territories' },
  { code: 'NS', name: 'Nova Scotia' },
  { code: 'NU', name: 'Nunavut' },
  { code: 'ON', name: 'Ontario' },
  { code: 'PE', name: 'Prince Edward Island' },
  { code: 'PQ', name: 'Quebec' },
  { code: 'SK', name: 'Saskatchewan' },
  { code: 'YT', name: 'Yukon' },
];

export const frequentlyUsedLanguages = [
  { code: 'hr', name: 'Croatian' },
  { code: 'da', name: 'Danish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'hi', name: 'Hindi' },
  { code: 'it', name: 'Italian' },
  { code: 'no', name: 'Norwegian' },
  { code: 'pl', name: 'Polish' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'es', name: 'Spanish' },
  { code: 'sv', name: 'Swedish' },
];

export const nonFrequentlyUsedLanguages = [
  { code: 'ab', name: 'Abkhaz', nativeName: 'аҧсуа' },
  { code: 'aa', name: 'Afar', nativeName: 'Afaraf' },
  { code: 'af', name: 'Afrikaans', nativeName: 'Afrikaans' },
  { code: 'ak', name: 'Akan', nativeName: 'Akan' },
  { code: 'al', name: 'Albanian', nativeName: 'Shqip' },
  { code: 'am', name: 'Amharic', nativeName: 'አማርኛ' },
  { code: 'ar', name: 'Arabic', nativeName: 'العربية' },
  { code: 'an', name: 'Aragonese', nativeName: 'Aragonés' },
  { code: 'am', name: 'Armenian', nativeName: 'Հայերեն' },
  { code: 'as', name: 'Assamese', nativeName: 'অসমীয়া' },
  { code: 'av', name: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { code: 'ae', name: 'Avestan', nativeName: 'avesta' },
  { code: 'ay', name: 'Aymara', nativeName: 'aymar aru' },
  { code: 'az', name: 'Azerbaijani', nativeName: 'azərbaycan dili' },
  { code: 'bm', name: 'Bambara', nativeName: 'bamanankan' },
  { code: 'ba', name: 'Bashkir', nativeName: 'башҡорт теле' },
  { code: 'eu', name: 'Basque', nativeName: 'euskara, euskera' },
  { code: 'be', name: 'Belarusian', nativeName: 'Беларуская' },
  { code: 'bn', name: 'Bengali', nativeName: 'বাংলা' },
  { code: 'bh', name: 'Bihari', nativeName: 'भोजपुरी' },
  { code: 'bi', name: 'Bislama', nativeName: 'Bislama' },
  { code: 'bs', name: 'Bosnian', nativeName: 'bosanski jezik' },
  { code: 'br', name: 'Breton', nativeName: 'brezhoneg' },
  { code: 'bg', name: 'Bulgarian', nativeName: 'български език' },
  { code: 'my', name: 'Burmese', nativeName: 'ဗမာစာ' },
  { code: 'ca', name: 'Catalan; Valencian', nativeName: 'Català' },
  { code: 'ch', name: 'Chamorro', nativeName: 'Chamoru' },
  { code: 'ce', name: 'Chechen', nativeName: 'нохчийн мотт' },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { code: 'zh', name: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { code: 'cv', name: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
  { code: 'kw', name: 'Cornish', nativeName: 'Kernewek' },
  { code: 'co', name: 'Corsican', nativeName: 'corsu, lingua corsa' },
  { code: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { code: 'cs', name: 'Czech', nativeName: 'česky, čeština' },
  { code: 'dv', name: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
  { code: 'eo', name: 'Esperanto', nativeName: 'Esperanto' },
  { code: 'et', name: 'Estonian', nativeName: 'eesti, eesti keel' },
  { code: 'ee', name: 'Ewe', nativeName: 'Eʋegbe' },
  { code: 'fo', name: 'Faroese', nativeName: 'føroyskt' },
  { code: 'fj', name: 'Fijian', nativeName: 'vosa Vakaviti' },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { code: 'gl', name: 'Galician', nativeName: 'Galego' },
  { code: 'ka', name: 'Georgian', nativeName: 'ქართული' },
  { code: 'el', name: 'Greek, Modern', nativeName: 'Ελληνικά' },
  { code: 'gn', name: 'Guaraní', nativeName: 'Avañeẽ' },
  { code: 'gu', name: 'Gujarati', nativeName: 'ગુજરાતી' },
  { code: 'ht', name: 'Haitian; Haitian Creole', nativeName: 'Kreyòl ayisyen' },
  { code: 'ha', name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { code: 'he', name: 'Hebrew (modern)', nativeName: 'עברית' },
  { code: 'hz', name: 'Herero', nativeName: 'Otjiherero' },
  { code: 'ho', name: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { code: 'hu', name: 'Hungarian', nativeName: 'Magyar' },
  { code: 'ia', name: 'Interlingua', nativeName: 'Interlingua' },
  { code: 'id', name: 'Indonesian', nativeName: 'Bahasa Indonesia' },
  {
    code: 'ie',
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  { code: 'ga', name: 'Irish', nativeName: 'Gaeilge' },
  { code: 'ig', name: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { code: 'ik', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { code: 'io', name: 'Ido', nativeName: 'Ido' },
  { code: 'is', name: 'Icelandic', nativeName: 'Íslenska' },
  { code: 'iu', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { code: 'ja', name: 'Japanese', nativeName: '日本語 (にほんご／にっぽんご)' },
  { code: 'jv', name: 'Javanese', nativeName: 'basa Jawa' },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { code: 'kn', name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { code: 'kr', name: 'Kanuri', nativeName: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { code: 'kk', name: 'Kazakh', nativeName: 'Қазақ тілі' },
  { code: 'km', name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { code: 'ki', name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
  { code: 'rw', name: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { code: 'ky', name: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
  { code: 'kv', name: 'Komi', nativeName: 'коми кыв' },
  { code: 'kg', name: 'Kongo', nativeName: 'KiKongo' },
  {
    code: 'ko',
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { code: 'ku', name: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
  { code: 'kj', name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { code: 'la', name: 'Latin', nativeName: 'latine, lingua latina' },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  { code: 'lg', name: 'Luganda', nativeName: 'Luganda' },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  { code: 'ln', name: 'Lingala', nativeName: 'Lingála' },
  { code: 'lo', name: 'Lao', nativeName: 'ພາສາລາວ' },
  { code: 'lt', name: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { code: 'lu', name: 'Luba-Katanga', nativeName: '' },
  { code: 'lv', name: 'Latvian', nativeName: 'latviešu valoda' },
  { code: 'gv', name: 'Manx', nativeName: 'Gaelg, Gailck' },
  { code: 'mk', name: 'Macedonian', nativeName: 'македонски јазик' },
  { code: 'mg', name: 'Malagasy', nativeName: 'Malagasy fiteny' },
  { code: 'ms', name: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { code: 'ml', name: 'Malayalam', nativeName: 'മലയാളം' },
  { code: 'mt', name: 'Maltese', nativeName: 'Malti' },
  { code: 'mi', name: 'Māori', nativeName: 'te reo Māori' },
  { code: 'mr', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { code: 'mh', name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { code: 'mn', name: 'Mongolian', nativeName: 'монгол' },
  { code: 'na', name: 'Nauru', nativeName: 'Ekakairũ Naoero' },
  { code: 'nv', name: 'Navajo, Navaho', nativeName: 'Diné bizaad, Dinékʼehǰí' },
  { code: 'nb', name: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
  { code: 'nd', name: 'North Ndebele', nativeName: 'isiNdebele' },
  { code: 'ne', name: 'Nepali', nativeName: 'नेपाली' },
  { code: 'ng', name: 'Ndonga', nativeName: 'Owambo' },
  { code: 'nn', name: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
  { code: 'ii', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { code: 'nr', name: 'South Ndebele', nativeName: 'isiNdebele' },
  { code: 'oc', name: 'Occitan', nativeName: 'Occitan' },
  { code: 'oj', name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    code: 'cu',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { code: 'om', name: 'Oromo', nativeName: 'Afaan Oromoo' },
  { code: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { code: 'os', name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { code: 'pa', name: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { code: 'pi', name: 'Pāli', nativeName: 'पाऴि' },
  { code: 'fa', name: 'Persian', nativeName: 'فارسی' },
  { code: 'ps', name: 'Pashto, Pushto', nativeName: 'پښتو' },
  { code: 'pt', name: 'Portuguese', nativeName: 'Português' },
  { code: 'qu', name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { code: 'rm', name: 'Romansh', nativeName: 'rumantsch grischun' },
  { code: 'rn', name: 'Kirundi', nativeName: 'kiRundi' },
  { code: 'ro', name: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
  { code: 'ru', name: 'Russian', nativeName: 'русский язык' },
  { code: 'sa', name: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
  { code: 'sc', name: 'Sardinian', nativeName: 'sardu' },
  { code: 'sd', name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { code: 'se', name: 'Northern Sami', nativeName: 'Davvisámegiella' },
  { code: 'sm', name: 'Samoan', nativeName: 'gagana faa Samoa' },
  { code: 'sg', name: 'Sango', nativeName: 'yângâ tî sängö' },
  { code: 'sr', name: 'Serbian', nativeName: 'српски језик' },
  { code: 'gd', name: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
  { code: 'sn', name: 'Shona', nativeName: 'chiShona' },
  { code: 'si', name: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
  { code: 'sk', name: 'Slovak', nativeName: 'slovenčina' },
  { code: 'so', name: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
  { code: 'st', name: 'Southern Sotho', nativeName: 'Sesotho' },
  { code: 'su', name: 'Sundanese', nativeName: 'Basa Sunda' },
  { code: 'sw', name: 'Swahili', nativeName: 'Kiswahili' },
  { code: 'ss', name: 'Swati', nativeName: 'SiSwati' },
  { code: 'ta', name: 'Tamil', nativeName: 'தமிழ்' },
  { code: 'te', name: 'Telugu', nativeName: 'తెలుగు' },
  { code: 'tg', name: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { code: 'th', name: 'Thai', nativeName: 'ไทย' },
  { code: 'ti', name: 'Tigrinya', nativeName: 'ትግርኛ' },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  { code: 'tk', name: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
  { code: 'tl', name: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { code: 'tn', name: 'Tswana', nativeName: 'Setswana' },
  { code: 'to', name: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
  { code: 'tr', name: 'Turkish', nativeName: 'Türkçe' },
  { code: 'ts', name: 'Tsonga', nativeName: 'Xitsonga' },
  { code: 'tt', name: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { code: 'tw', name: 'Twi', nativeName: 'Twi' },
  { code: 'ty', name: 'Tahitian', nativeName: 'Reo Tahiti' },
  { code: 'ug', name: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { code: 'uk', name: 'Ukrainian', nativeName: 'українська' },
  { code: 'ur', name: 'Urdu', nativeName: 'اردو' },
  { code: 'uz', name: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { code: 've', name: 'Venda', nativeName: 'Tshivenḓa' },
  { code: 'vi', name: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { code: 'vo', name: 'Volapük', nativeName: 'Volapük' },
  { code: 'wa', name: 'Walloon', nativeName: 'Walon' },
  { code: 'cy', name: 'Welsh', nativeName: 'Cymraeg' },
  { code: 'wo', name: 'Wolof', nativeName: 'Wollof' },
  { code: 'fy', name: 'Western Frisian', nativeName: 'Frysk' },
  { code: 'xh', name: 'Xhosa', nativeName: 'isiXhosa' },
  { code: 'yi', name: 'Yiddish', nativeName: 'ייִדיש' },
  { code: 'yo', name: 'Yoruba', nativeName: 'Yorùbá' },
  { code: 'za', name: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];

export const Currencies = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  AUD: 'A$',
  CAD: 'C$',
  CNY: '¥',
  INR: '₹',
  BRL: 'R$',
  ZAR: 'R',
  MXN: 'Mex$',
  NZD: 'NZ$',
  SGD: 'S$',
  HKD: 'HK$',
  SEK: 'kr',
  NOK: 'kr',
  DKK: 'kr',
  CHF: 'CHF',
  TRY: '₺',
  RUB: '₽',
  KRW: '₩',
  IDR: 'Rp',
  MYR: 'RM',
  PHP: '₱',
  THB: '฿',
  VND: '₫',
  ARS: '$',
  CLP: '$',
  COP: '$',
  PEN: 'S/',
  UYU: '$U',
  IQD: 'ع.د',
  IRR: '﷼',
  SAR: '﷼',
  AED: 'د.إ',
  QAR: '﷼',
  BHD: 'BD',
  OMR: '﷼',
  JOD: 'JD',
  LBP: 'ل.ل',
  EGP: 'ج.م',
  NGN: '₦',
  ZMW: 'ZK',
  KES: 'Ksh',
  TZS: 'TSh',
  UGX: 'USh',
  GHS: '₵',
  ZWL: 'Z$',
  BWP: 'P',
  MUR: '₨',
  MZN: 'MT',
  SZL: 'E',
  LSL: 'M',
  TRY_OLD: '₤',
  RON: 'lei',
  HUF: 'Ft',
  PLN: 'zł',
  CZK: 'Kč',
  SGD_OLD: 'S$',
  LKR: 'Rs',
  NPR: '₨',
  BTN: 'Nu.',
  MVR: 'Rf',
  CVE: '$',
  XAF: 'FCFA',
  XOF: 'CFA',
  XOF_OLD: 'F',
  XPF: '₣',
  BSD: 'B$',
  BBD: 'Bds$',
  BZD: 'BZ$',
  BMD: 'BD$',
  KYD: 'CI$',
  FJD: 'FJ$',
  GYD: 'GY$',
  JMD: 'J$',
  LRD: 'L$',
  SRD: 'SR$',
  TTD: 'TT$',
  XCD: 'EC$',
  TND: 'د.ت',
  MRO: 'UM',
  UAH: '₴',
  BYN: 'Br',
  AMD: '֏',
  GEL: '₾',
  MDL: 'lei',
  AZN: '₼',
  KGS: 'сом',
  TJS: 'ЅМ',
  TMT: 'T',
  UZS: 'so‘m',
  KZT: '₸',
  YER: '﷼',
  OMR_OLD: '﷼',
  PKR: '₨',
};

export const allLanguages = [
  ...frequentlyUsedLanguages,
  ...nonFrequentlyUsedLanguages,
];

export const placeholderImageUrl =
  'https://returns5491536.s3.amazonaws.com/emailImage/56/960a5ffd50e1176c693aa94feb284d33749cc209474f71c7c1a807bb826e2208da43d3e7910792fdf592547454c10e41';

export const availableTranslations = [
  'de',
  'da',
  'en',
  'es',
  'fr',
  'fi',
  'hi',
  'hr',
  'it',
  'nl',
  'no',
  'pl',
  'sl',
  'sv',
  'tr',
];

export const TYPE_OF_CUSTOMIZE_QUERY = [
  {
    label: 'Note',
    value: 'note',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Radio-button',
    value: 'radio-button',
  },
];

export const shopminisDeepLink = 'https://shop.app/u/orders/';

export const shopMinisEarlyAccessStores = [
  'my-celebrity-closet.myshopify.com',
  'vetchy.myshopify.com',
  'alievastore.myshopify.com',
  'skullshaver.myshopify.com',
  'montaneuk.myshopify.com',
  'chelsey-smith-cosmetics.myshopify.com',
  'combat-iron-apparel.myshopify.com',
  'smocked-auctions-development-site.myshopify.com',
  'pacsafe-ltd.myshopify.com',
  'aromatech-systems-canada.myshopify.com',
  'til-valhalla-project.myshopify.com',
  'bowmarnutrition.myshopify.com',
  'shakawear-com.myshopify.com',
  'cipo-and-baxx-t.myshopify.com',
  'skullshaveruk.myshopify.com',
  'chipre-basic-denim.myshopify.com',
  'miik.myshopify.com',
  'bellybandit.myshopify.com',
  'ganactive-com.myshopify.com',
  'acme-merino.myshopify.com',
  'mamma-mia-covers.myshopify.com',
  'theclothesrak.myshopify.com',
  'michael-aram.myshopify.com',
  'georgia-hardinge.myshopify.com',
  'mycollegeshirt.myshopify.com',
  'beyond-polish.myshopify.com',
  'amalli-talli.myshopify.com',
  'meindl-usa.myshopify.com',
  'ink-alloy-2.myshopify.com',
  'srchealth.myshopify.com',
  'oneill-au.myshopify.com',
  'thirtytwo-us.myshopify.com',
  'dolly-l-llc.myshopify.com',
  'famous-footwear-shop.myshopify.com',
  'tungsten-fashion-jewelry.myshopify.com',
  'the-perfect-arm.myshopify.com',
  'loveandfit.myshopify.com',
  'wearewhatwere.myshopify.com',
  'thehisplacestore.myshopify.com',
  'jackie-london.myshopify.com',
  'good-feet-new-orleans.myshopify.com',
  'les-coupons-de-saint-pierre.myshopify.com',
  'etnies-us.myshopify.com',
  'oak-pearl-clothingco.myshopify.com',
  'mcs-apparel-shop.myshopify.com',
  'flyingcolorsbaby.myshopify.com',
  'ftficonversion.myshopify.com',
  'muurswagg.myshopify.com',
  'hansen-gretel-prod.myshopify.com',
  'leona-test.myshopify.com',
  'shopatshowcase.myshopify.com',
  'rh-signature-co.myshopify.com',
  'weekendbee.myshopify.com',
  'shell-station.myshopify.com',
  'the-gold-gods.myshopify.com',
  'undgretel.myshopify.com',
  'echelon-store.myshopify.com',
  'uk-jewellery-king.myshopify.com',
  'scrtco.myshopify.com',
  'marco-calzature-milano.myshopify.com',
  'kamoskinz.myshopify.com',
  'simply-tall-inc.myshopify.com',
  'theshoppemiami.myshopify.com',
  'dermalogica-uk.myshopify.com',
  'raishma-co-uk.myshopify.com',
  'roosbeach.myshopify.com',
  'twotags-au.myshopify.com',
  'bevilles-jewellers.myshopify.com',
  'lower-shop.myshopify.com',
  'awake-mode.myshopify.com',
  'paris-texas-apparel-co-2.myshopify.com',
  'lug-nut-guys.myshopify.com',
  'esskateboarding-us.myshopify.com',
  'branded-uniform-solutions.myshopify.com',
  'avenuemontaigne.myshopify.com',
  'kiwi-diamond.myshopify.com',
  'thehavenco.myshopify.com',
  'cliquefitness.myshopify.com',
  'aret-basewear.myshopify.com',
  'lucieandleo.myshopify.com',
  'cabourn.myshopify.com',
  'grayling.myshopify.com',
  'vaistoa.myshopify.com',
  'blakshop.myshopify.com',
  'shop-carine.myshopify.com',
  'peau-de-loup.myshopify.com',
  'golden-breed.myshopify.com',
  'sportive-plus.myshopify.com',
  'dermalogica-ca.myshopify.com',
  'pompomme-shop.myshopify.com',
  'claddaghringstore.myshopify.com',
  'ownley-online.myshopify.com',
  'emerica-us.myshopify.com',
  'ooh-la-loft.myshopify.com',
  'shop-zaikamoya.myshopify.com',
  'isle-of-mine.myshopify.com',
  'tileboom.myshopify.com',
  'wolven-threads.myshopify.com',
  'ltz-belts.myshopify.com',
  'bluhq.myshopify.com',
  'webx-6efb.myshopify.com',
  'berry-jane-usa.myshopify.com',
  'cognative-mtb.myshopify.com',
  'ideaparktest.myshopify.com',
  'pacsafe-eu.myshopify.com',
  'bikinibible.myshopify.com',
  'romika-usa.myshopify.com',
  'shrimps-com.myshopify.com',
  'assefs.myshopify.com',
  'sigrcc.myshopify.com',
  'luxe-tones.myshopify.com',
  'emmydeveaux.myshopify.com',
  'shop-freya.myshopify.com',
  'premiata-us.myshopify.com',
  'sqlab.myshopify.com',
  'shopreyllen.myshopify.com',
  'jinenstore.myshopify.com',
  'cord-bands.myshopify.com',
  'raffi-online.myshopify.com',
  'gorillarobes-com.myshopify.com',
  'luna-fied.myshopify.com',
  'thealkemyst.myshopify.com',
  'levonesofacover.myshopify.com',
  'jalaclothing-com.myshopify.com',
  'shophonu.myshopify.com',
  'aromatech-canada.myshopify.com',
  'lionparts.myshopify.com',
  'the-whole-bride.myshopify.com',
  'blowhammer-brand.myshopify.com',
  'slyk-shades.myshopify.com',
  'viggotailoring.myshopify.com',
  'stilform.myshopify.com',
  'bonvagonstore.myshopify.com',
  'essentials-for-zula.myshopify.com',
  'vajacases.myshopify.com',
  'aidashoreditch.myshopify.com',
  'poshpuppy-boutique.myshopify.com',
  'malek-neman.myshopify.com',
  'believe-accessories-inc.myshopify.com',
  'shopluluandroo.myshopify.com',
  'frankie-collective.myshopify.com',
  'lowa-fi.myshopify.com',
  'alquema.myshopify.com',
  'microfiberwholesale.myshopify.com',
  'ford-millinery.myshopify.com',
  'the-laundry-room.myshopify.com',
  'one-affirmation.myshopify.com',
  'camillaandmarc-nz.myshopify.com',
  'bows-and-arrows-co-llc.myshopify.com',
  'peixoto-wear-shop.myshopify.com',
];
export const WEIGHT_UNIT = {
  'lb': 'Pound',
  'g': 'Gram',
};

export const LENGTH_UNIT = {
  'in': 'Inch',
  'cm': 'Centimeter',
};

export const EDITOR_TYPE = {
  WYSIWYG: 'wysiwyg',
  HTML_EDITOR: 'html_editor',
}