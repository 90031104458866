/**
 * Component for our Return details sidebar that
 * lets users view and edit the details of a Return.
 *
 * @component
 */

import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Drawer,
  Icon,
  message,
  Select,
  Tag,
  Tooltip,
  Input,
  Col,
  Row,
  Switch,
  Popconfirm
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import '../../../../styles/App.css';
import ReturnDetailsCustomer from './ReturnDetailsCustomer';
import ReturnDetailsExchange from './ReturnDetailsExchange';
import ReturnDetailsLabelAutomatic from './ReturnDetailsLabelAutomatic';
import ReturnDetailsLabelManual from './ReturnDetailsLabelManual';
import ReturnDetailsNoteCustomer from './ReturnDetailsNoteCustomer';
import ReturnDetailsNoteStaff from './ReturnDetailsNoteStaff';
import ReturnDetailsPhotoUpload from './ReturnDetailsPhotoUpload';
import ReturnDetailsProduct from './ReturnDetailsProduct';
import ReturnDetailsRefundButton from './ReturnDetailsRefundButton';
import ReturnDetailsTimeline from './ReturnDetailsTimeline';
import StripeWebhookDetails from './StripeWebhookDetails';
import UpdateResolution from './UpdateResolution';

const Option = Select.Option;
const { TextArea } = Input;

const pluralize = (word, count) => (count === 1 ? word : `${word}s`);

/**
 * Formats seconds into days and hours
 * @param {number | string} seconds
 * @returns {string} Formatted time in days and hours
 */
// eslint-disable-next-line no-unused-vars
const formatTime = (seconds) => {
  if (Number.isNaN(seconds)) {
    return seconds;
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);

  if (!days) {
    return `${hours} ${pluralize('hour', hours)}`;
  }

  if (!hours) {
    return `${days} ${pluralize('day', days)}`;
  }

  return `${days} ${pluralize('day', days)} + ${hours} ${pluralize(
    'hour',
    hours
  )}`;
};

class ReturnGiftDetails extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      //control loading state
      loading: true,
      buttonLoadingOrder: false,

      //control the return object
      orderNumber: null,
      // orderNumber: "1079",
      productsOfReturn: [],
      productData: {},
      productIds: {},

      //control return request
      returnRequest: {
        action: {},
      },
      selectedProduct: null,

      availableResolutionOptions: [
        'storeCredit',
        'exchangeItem',
        'storeWideExchange',
      ],
      returnMethod: null,
      currency: null,
      stateOfReturn: null,
      notesKey: undefined,
      isLoading: false,
      drawerVisible: false,
      returnMethod: undefined,
      returnObject: null,
      labelService: null,
      shipDate: null,
      labelStatus: null,
      storeAddress: [],
      selectedStoreAddress: null,
      useGiftApiForStoreCredit: undefined,
      useDiscountCodeForStoreCredit: undefined,
      useStoreCredit: undefined,
      width: '50%',
      discountCodeStatusTracking: true,
      rejectionObservation: null,
      showObservationField: false,
    };
  }

  onChange = (date, dateString) => {
    this.setState({
      shipDate: dateString,
    });
  };

  handleGetReturn = async () => {
    try {
      const response = await axios.post(`/api/returns/${this.props.returnId}`);
      if (response.data.labelService && response.data.labelService.length > 0) {
        this.setState({
          labelService: response.data.labelService[0].labelService,
          // fetch {useGiftApiForStoreCredit, useDiscountCodeForStoreCredit} from returnSettings
          useGiftApiForStoreCredit:
            response.data.labelService[0].useGiftApiForStoreCredit,
          useDiscountCodeForStoreCredit:
            response.data.labelService[0].useDiscountCodeForStoreCredit,
          useStoreCredit: response.data.labelService[0].useStoreCredit,
        });
      }
      if (response?.data?.returnObject?.returnStatus === 'Rejected') {
        this.setState({
          showObservationField: true,
          rejectionObservation: response.data.returnObject.rejectionObservation,
        });
      }
      this.setState({
        returnObject: response.data.returnObject,
        loading: false,
        labelStatus: response.data.returnObject.labelStatus,
      });
    } catch (err) {
      return message.error(
        'Error getting return details. Please try again.',
        5
      );
    }
  };

  handleGetReturnMethod = async () => {};

  componentDidMount = async () => {
    await this.handleGetReturn();
  };

  adjustDrawerWidth = () => {
    try {
      const innerWidthOfWindow = window.innerWidth;
      if (innerWidthOfWindow) {
        if (innerWidthOfWindow >= 992) {
          this.setState({
            width: '50%',
          });
        } else if (innerWidthOfWindow >= 768) {
          this.setState({
            width: '70%',
          });
        } else if (innerWidthOfWindow >= 576) {
          this.setState({
            width: '80%',
          });
        } else {
          this.setState({
            width: '100%',
          });
        }
      }
    } catch (err) {
      console.log('Error while adjustig drawer width: ', err);
    }
  };
  getStoreAddresses = async () => {
    try {
      const response = await axios.get('/api/returnSettings/multipleAddress');
      const storeAddress = response.data.getStoreAddress;
      const filterAddress = storeAddress.find((e) => {
        return e.defaultActive === true;
      });
      this.setState({
        storeAddress,
        selectedStoreAddress: filterAddress,
      });
    } catch (err) {
      console.log('Error, unable to get store address', err);
    }
  };

  handleOnChange = (id) => {
    const filterStoreAddress = this.state.storeAddress.find((e) => {
      return e.id === id;
    });

    this.setState({
      selectedStoreAddress: filterStoreAddress,
    });
  };

  handleLoadingState = (value) => {
    this.setState({
      isLoading: value,
    });
  };

  createReturn = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      const returnNumber = this.state.returnObject?.returnNumber;
      const orderNumber = this.state.orderNumber;
      // check required order number
      if (!orderNumber) {
        this.setState({
          isLoading: false,
        });
        return message.error('Please enter the order number.', 5);
      }
      // check required return request
      const returnRequestKeys = Object.keys(this.state.returnRequest).filter(
        (key) =>
          key.startsWith('returnItemChecked') && this.state.returnRequest[key]
      );
      const lineItemsIds = returnRequestKeys.map((key) => key.split('_')[1]);
      if (!returnRequestKeys || returnRequestKeys.length === 0) {
        this.setState({
          isLoading: false,
        });
        return message.error(
          'Please select at least one product to return.',
          5
        );
      }
      // check required resolution for each product
      const listResolution = lineItemsIds
        .map((lineItemId) => {
          return this.state.returnRequest.action[lineItemId]?.values
            ?.updatedResolution;
        })
        .filter((resolution) => resolution);
      if (listResolution.length !== lineItemsIds.length) {
        this.setState({
          isLoading: false,
        });
        return message.error('Please select resolution for each product.', 5);
      }
      // check required return method
      if (!this.state.returnMethod) {
        this.setState({
          isLoading: false,
        });
        return message.error('Please select return method.', 5);
      };

      const data = await axios.post('/api/returns/updateGiftReturn', {
        orderNumber,
        returnNumber,
        returnRequest: this.state.returnRequest,
        shippingMethod: this.state.returnMethod,
      });

      this.setState({
        isLoading: false,
      });
      message.success('Status updated successfully.', 4);
      return this.props.handleGetReturn();
    } catch (err) {
      this.setState({
        isLoading: false,
      });

      return message.error(
        'Error updating status return. Please try again.',
        5
      );
    }
  };

  loadOrderData = async () => {
    try {
      this.setState({
        buttonLoadingOrder: true,
      });
      const response = await axios.post('/api/returns/loadOrderData', {
        orderNumber: this.state.orderNumber,
        returnNumber: this.state.returnObject?.returnNumber,
      });
      if (response.data?.orderData) {
        this.setState({
          productsOfReturn: response.data.orderData.line_items,
          productData: response.data.productData,
          productIds: response.data.productIds,
          buttonLoadingOrder: false,
          currency: response.data.orderData.currency,
        });
      }
    } catch (err) {
      this.setState({
        buttonLoadingOrder: false,
      });
      return message.error('Error loading order data. Please try again.', 5);
    }
  };

  handleChangeRequest = (field, value) => {
    const returnRequest = this.state.returnRequest;
    returnRequest[field] = value;
    if (field.startsWith('returnItemChecked') && !value) {
      const lineItem = field.split('_')[1];
      returnRequest.action[lineItem] = null;
      returnRequest[`resolution_${lineItem}`] = null;
    }
    this.setState({
      returnRequest,
    });
  };

  handleRejectionObservation = (e) => {
    this.setState({
      rejectionObservation: e.target.value,
    });
  };

  handleInputChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  resolutionOptionsDetail = {
    refundToCredit: {
      identifier: 'refundToCredit',
      title: 'Store Credit',
    },
    refundToExchange: {
      identifier: 'refundToExchange',
      title: 'Exchange Item',
    },
    storeWideExchange: {
      identifier: 'storeWideExchange',
      title: 'Store-Wide Exchange',
    },
    originalPaymentMethod: {
      identifier: 'originalPaymentMethod',
      title: 'Original Payment Method',
    },
    advancedExchange: {
      identifier: 'advancedExchange',
      title: 'Collection Exchange',
    },
  };

  closeResolutionOptions = () => {
    this.setState({
      showResolutionOptions: false,
    });
  };

  formatProductItem = (lineItem) => {
    return {
      id: this.state.productIds[lineItem.id],
      title: lineItem.title,
      lineItemId: lineItem.id,
      variant_Id: lineItem.variant_id,
      variant_title: lineItem.variant_title,
      price: lineItem.price,
      quantity: lineItem.quantity, //TODO: updated this to state
      productName: lineItem.title,
    };
  };

  updateProductRequest = (result) => {
    const productId = result.values.productId;
    const productLineItem = Object.keys(this.state.productIds).find(
      (key) => this.state.productIds[key] === productId
    );
    const returnRequest = {
      ...this.state.returnRequest,
    };
    returnRequest[`resolution_${productLineItem}`] =
      result?.values?.updatedResolution; // This will be used to display the resolution in the frontend
    returnRequest.action[productLineItem] = result; //This result will be use to update product in backend
    this.setState({
      returnRequest,
    });
  };

  handleSoftDeleteReturn = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      const response = await axios.delete(`/api/returns/${this.props.returnId}`);
      if (
        response.data.err &&
        response.data.err.error
      ) {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.err.error);
      }
      if (response.data.status === 'error') {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.error, 4);
      }

      this.setState({
        isLoading: false,
      });

      this.props.hideDrawer();
      return message.success('Return deleted successfully.', 4);
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      return message.error('Error deleting return. Please try again.', 5);
    }
  }

  render() {
    const giftReturnInfo = this.state.returnObject?.giftReturnInfo;
    let productList = !this.state.productsOfReturn
      ? 'Loading'
      : this.state.productsOfReturn.map((product, index) => {
          const insertDivider =
            index !== this.state.productsOfReturn.length - 1 ? (
              <Row>
                <Col>
                  <Divider />
                </Col>
              </Row>
            ) : (
              ''
            );
          let quantitySelectionArray = [];
          for (let i = 1; i <= product.quantity; i++) {
            quantitySelectionArray.push(i);
          }
          let quantitySelectionElements = quantitySelectionArray.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          });

          const productElement = (
            <React.Fragment key={product.id}>
              <Row
                type="flex"
                justify="center"
                align="middle"
                className={
                  this.state.showIncentives
                    ? 'returnProducts u-rowGap--md'
                    : 'u-rowGap--md'
                }
              >
                <Col md={4} xs={24}>
                  <Tooltip
                    title={
                      product.isExcludedFromReturns
                        ? 'Not eligible for return'
                        : ''
                    }
                    placement={'topLeft'}
                  >
                    <Switch
                      checked={
                        this.state.returnRequest[
                          `returnItemChecked_${product.id}`
                        ]
                      }
                      onChange={(value) => {
                        this.handleChangeRequest(
                          `returnItemChecked_${product.id}`,
                          value
                        );
                        // init quantity to 1 if not set
                        if (
                          !this.state.returnRequest[`quantity_${product.id}`]
                        ) {
                          this.handleChangeRequest(`quantity_${product.id}`, 1);
                        }
                      }}
                      disabled={product.isExcludedFromReturns}
                      style={{ marginBottom: 5 }}
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                    />
                  </Tooltip>
                </Col>
                <Col md={8} xs={24}>
                  <img
                    src={
                      this.state.productData[
                        `${product.product_id}-${product.variant_id}`
                      ]
                    }
                    style={{ maxHeight: 100, width: '100%', objectFit: 'contain', paddingRight: '5px' }}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <h2>{product.name}</h2>
                  <p>Order number: {this.props.orderNumber}</p>
                  <p>Quantity: {product.quantity}</p>
                  {product.deletedFromStore ? (
                    <Tooltip
                      placement="bottom"
                      title="This product cannot be returned as it has been removed from the store."
                    >
                      <span>This product cannot be returned</span>{' '}
                      <Icon type="info-circle" />
                    </Tooltip>
                  ) : null}
                </Col>
              </Row>

              <Row>
                {this.state.returnRequest[
                  `returnItemChecked_${product.id}`
                ] && (
                  <React.Fragment>
                    <Row>
                      {product.quantity > 1 ? (
                        <React.Fragment>
                          <Row type="flex">Quantity</Row>
                          <Row type="flex">
                            <Select
                              onChange={(value) => {
                                this.handleChangeRequest(
                                  `quantity_${product.id}`,
                                  value,
                                  product.id,
                                  product.variant_id
                                );
                              }}
                              defaultValue={1}
                              style={{ width: '100%' }}
                            >
                              {quantitySelectionElements}
                            </Select>
                          </Row>
                        </React.Fragment>
                      ) : null}
                    </Row>
                    <Row type="flex" style={{ paddingTop: '15px' }}>
                      Resolution:{' '}
                      {this.state.returnRequest[`resolution_${product.id}`] &&
                        this.resolutionOptionsDetail[
                          this.state.returnRequest[`resolution_${product.id}`]
                        ]?.title}
                    </Row>
                    <Row type="flex">
                      <Button
                        onClick={() =>
                          this.setState({
                            showResolutionOptions: true,
                            selectedProduct: product,
                          })
                        }
                        type="primary"
                        size="default"
                        style={{ width: '100%' }}
                      >
                        {this.state.returnRequest[`resolution_${product.id}`]
                          ? 'Update Resolution'
                          : 'Add Resolution'}
                      </Button>
                    </Row>
                    {this.state.showResolutionOptions && (
                      <UpdateResolution
                        {...this.props}
                        returnObject={{
                          ...this.state.returnObject,
                          currency: this.state.currency
                        }}
                        item={this.formatProductItem(
                          this.state.selectedProduct
                        )}
                        visible={this.state.showResolutionOptions}
                        handleClose={this.closeResolutionOptions}
                        resolutionGiftReturn={true}
                        updateProductRequest={this.updateProductRequest}
                      />
                    )}
                  </React.Fragment>
                )}
              </Row>

              <Col
                md={24}
                xs={24}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px',
                }}
              ></Col>

              <React.Fragment>{insertDivider}</React.Fragment>
            </React.Fragment>
          );
          return productElement;
        });

    return (
      <React.Fragment>
        {this.state.loading ? null : (
          <Drawer
            title={
              <div
                className="flex-row flex-between-xxs flex-middle-xxs flex-row--noMargin"
                style={{ gap: 10 }}
              >
                <div className="flex-row flex-middle-xxs flex-row--noMargin">
                  <div className="TextHeading TextHeading--xxs u-marginBottom--none">
                    {this.state.returnObject.returnNumber}
                  </div>
                </div>
                <div className="flex-row flex-row--noMargin">
                  <Button
                    onClick={this.createReturn.bind(this)}
                    loading={this.state.isLoading}
                    type="primary"
                    style={{ marginRight: 10 }}
                  >
                    Create Return
                  </Button>
                  <Button onClick={() => this.props.hideDrawer()}>
                    Cancel
                  </Button>
                </div>
              </div>
            }
            placement="right"
            visible={this.props.drawerVisible}
            onClose={() => this.props.hideDrawer()}
            closable={false}
          >
            <div
              className="flex-row flex-row--noMargin"
              style={{ flexDirection: 'column', gap: 20 }}
            >
              <div className="flex-row">
                {/* Load info return */}
                <div className="flex-col-xxs-12">
                  <h2 className="TextHeading TextHeading--xxxs u-marginBottom--sm">
                    Return Information
                  </h2>
                </div>
                <div className="flex-col-xxs-12">
                  <p>
                    <strong>Buyer's name:</strong> {giftReturnInfo.buyerName}
                  </p>
                  <p>
                    <strong>
                      The product that the customer wants to return:{' '}
                    </strong>
                    {giftReturnInfo.productReturn}
                  </p>
                  <p>
                    <strong>Resolution:</strong>{' '}
                    {giftReturnInfo.giftReturnResolution}
                  </p>
                  <p>
                    <strong>Note:</strong> {giftReturnInfo.giftReturnNote}
                  </p>
                </div>

                <div className="flex-col-xxs-12">
                  <strong className="TextHeading TextHeading--xxxs u-marginBottom--sm">
                    Update the return
                  </strong>
                </div>
                <div className="flex-col-xxs-12" style={{ display: 'flex' }}>
                  <Input
                    size="default"
                    style={{ margin: 0, marginRight: 10 }}
                    placeholder="Enter the order number that matches with the return"
                    onChange={(e) => this.handleInputChange('orderNumber', e)}
                  />
                  <Button
                    type="primary"
                    size="small"
                    onClick={this.loadOrderData}
                    loading={this.state.buttonLoadingOrder}
                  >
                    Load
                  </Button>
                </div>

                <div
                  className="flex-col-xxs-12"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '30px',
                  }}
                >
                  {productList}
                </div>

                {this.state.productsOfReturn.length > 0 && (
                  <div
                    className="flex-col-xxs-12"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '30px',
                      paddingBottom: '20px',
                    }}
                  >
                    <div>
                      <h2 className="TextHeading TextHeading--xxxs u-marginBottom--sm">
                        Return Method
                      </h2>
                    </div>
                    <Select
                      style={{ width: '100%', minWidth: '213px' }}
                      defaultValue={this.state.returnMethod}
                      onChange={(e) => this.setState({ returnMethod: e })}
                      placeholder="Select Return Method"
                    >
                      <Option value="methodLabelCreation">
                        Pre-Paid Return Label (automatic)
                      </Option>
                      <Option value="methodLabelManual">
                        Pre-Paid Return Label (manual)
                      </Option>
                      <Option value="methodCustomerPaidLabel">
                        Customer is responsible for label
                      </Option>
                      <Option value="methodInStore">In-Store Return</Option>
                      <Option value="methodCustomerChoice">
                        Customer is responsible for shipping
                      </Option>
                    </Select>
                  </div>
                )}

                <div className="flex-col-xxs-12">
                  <Popconfirm
                    title={
                      <React.Fragment>
                        <p className="TextBody TextBody--xxs">
                        Are you sure? By deleting this return, it will no longer be accessible and all actions already taken will not be undone. 
                        Once deleted, this return will not appear in reports, metrics, or analytics.
                        </p>
                      </React.Fragment>
                    }
                    onConfirm={() =>
                      this.handleSoftDeleteReturn()
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    okButtonProps={{ 
                      danger: true,
                      style: { backgroundColor: '#c54f4d', color: '#FFF', border: 'none', }
                    }}
                  >
        
                    <Button
                      loading={this.state.isLoading}
                      size="small"
                      className="delete-return-btn"
                      style={{
                        backgroundColor:'#c54f4d',
                        color: '#FFF',
                        border: 'none',
                        padding: 'var(--input-padding)',
                        width: '100%',
                        hover: {
                          background: '#c0392b',
                        }
                      }}
                    >
                      Delete Return Request
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

export default ReturnGiftDetails;
