/**
 * Component to load and distribute user and subscription
 * related information.
 * 
 * @component
 */

import React, { createContext, Component } from 'react';
import axios from "axios";

export const SubscriptionContext = createContext();

class SubscriptionContextProvider extends Component {
    state = {
      inTrial: undefined,
      directBilling: undefined,
      shopifyCustomApp: undefined,
      planTitle: undefined,
      planName: undefined,
      addOnCustomerPhotoUpload: undefined,
      monthlyQuota: undefined,
      usageChargeCount: undefined,
      balanceRemaining: undefined,
      balanceUsed: undefined,
      amountPerMonth: undefined,
      amountPerUsage: undefined,
      featureCustomerPhotoUpload: undefined,
      featureMultichannel: undefined,
      featureExportData: undefined,
      featureLanguageCustomization: undefined,
      featureExchangeSelection: undefined,
      featureStripePayment: undefined,
      featureStoreWideExchange: undefined,
      featureExchangeOrders: undefined,
      featureRules: undefined,
      featureRulesQuota: undefined,
      featureApi: undefined,
      featureRefunds: undefined,
      featureStoreCredit: undefined,
      featureCustomsForms: undefined,
      featureAutoTracking: undefined,
      featureLabelQRcode: undefined,
      featureAuditLog: undefined,
      companyId: null,
      first_use: false,
      storeShopifyUrl: undefined,
      identifier: undefined,
      featureAdvancedExchanges: false,
      myshopify_domain: "",
      featureCustomDomain: false,
      updateContext: this.updateContext.bind(this),
      featureAnalyticsAdvanced: undefined,
      featureMultipleAddress: undefined,
      featureMultipleAddressQuota: undefined,
      billingStartDate: undefined,
      featureAutoRefund: undefined,
      featureGiftApiForStoreCredit: undefined,
      featureDiscountCodeForStoreCredit: undefined,
      featureLabelReturnCurrency: undefined,
      featureUpdateResoultion: undefined,
      intervalTime: 'EVERY_30_DAYS',
      planStatus: undefined,
      featureInstantExchange: undefined,
    };

    async componentDidMount() {
      await this.updateContext();
    }

    async updateContext() {
      const response = await axios.get("/api/contexts");

      return this.setState({
        ...response.data,
        intervalTime: response.data.intervalTime ?? 'EVERY_30_DAYS',
      });
    }

    render() {
      return(
        <SubscriptionContext.Provider value={{...this.state}}>
          {this.props.children}
        </SubscriptionContext.Provider>
      );
    }
}

export default SubscriptionContextProvider;
