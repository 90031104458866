/**
 * Component to manage our  Return Reasons Languages
 *
 * @component
 */

import { Button, message, Modal, Row, Col, Alert } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { trackFSEvent } from '../../../../../helpers/fullstory';
import WrappedReturnReasonForm from './ReturnReasonForm';
import ReturnReasonTable from './ReturnReasonTable';
import { trackingVitally } from '../../../../../helpers/vitally';

class ReturnReasonDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      language: '',
      translation: '',
      submitType: '',
      filteredLanguages: [],
      returnReasons: [],
      returnReasonId: null,
      translationIndexNumber: null,
    };
  }

  getReturnReasons = async () => {
    try {
      const response = await axios.get('/api/returnSettings/returnReasons');
      if (response?.data) {
        this.setState({
          returnReasons: response.data,
        });
      }
    } catch (err) {
      return message.error('Something went wrong please try again', 5);
    }
  };

  componentDidMount = async () => {
    this.getReturnReasons();
  };

  toggleReturnReasonInputModal = async (type, data) => {
    if (data) {
      const filterLanguages = this.state.returnReasons.find(
        (retrunReason) => retrunReason.id === data.id
      );
      let languages = filterLanguages.translations.map(
        (language) => language.language
      );
      languages.push(filterLanguages.defaultLanguage);
      if (type === 'editTranslation') {
        this.setState({
          language: data.language,
          translation: data.translation,
          filteredLanguages: languages,
          returnReasonId: data.id,
          translationIndexNumber: data.key,
          submitType: 'editTranslation',
        });
      }
      if (type === 'editReturnReason') {
        this.setState({
          language: data.defaultLanguage,
          translation: data.reason,
          filteredLanguages: languages,
          returnReasonId: data.id,
          submitType: 'editReturnReason',
        });
      }
      if (type === 'addTranslation') {
        this.setState({
          language: '',
          translation: '',
          filteredLanguages: languages,
          returnReasonId: data.id,
          submitType: 'addTranslation',
        });
      }
    }

    if (type === 'addReturnReason') {
      this.setState({
        language: 'en',
        translation: '',
        submitType: 'addReturnReason',
      });
    }

    this.setState({
      visible: true,
    });
  };

  handleOk = async (data) => {
    if (!data) return;

    try {
      let payload = {
        defaultLanguage: data.language || 'en',
        reason: data.translation,
        translations: data.translations || [],
      };

      if (this.state.submitType === 'addReturnReason') {
        try {
          const response = await axios.post(
            `/api/returnSettings/returnReasons/create`,
            {
              payload,
            }
          );
          if (response.data.status === 400) {
            return message.error('Return reason already exist.');
          }
          message.success('Added successfully', 5);
          trackFSEvent('Add a Return Reason', {
            feature: 'Return Settings',
            selectedLanguage: data.language || 'en',
            returnReason: data.translation,
          });
          trackingVitally('rr_returnreason_added');
        } catch (error) {
          console.log(error, 'Error Occured while adding return reason');
        }
      }

      if (
        this.state.submitType === 'editReturnReason' ||
        this.state.submitType === 'addTranslation' ||
        this.state.submitType === 'editTranslation'
      ) {
        const returnReason = this.state.returnReasons.find(
          (returnReason) => returnReason.id === this.state.returnReasonId
        );

        if (this.state.submitType === 'editReturnReason') {
          payload = {
            defaultLanguage: data.language,
            reason: data.translation,
            translations: returnReason.translations,
          };
          try {
            const response = await axios.post(
              `/api/returnSettings/returnReasons/${this.state.returnReasonId}`,
              {
                payload,
              }
            );
            if (response.data.status === 400) {
              return message.error('Return reason already exist.');
            }
            message.success('Edit a Return Reason', 5);
            trackFSEvent('Add a Return Reason', {
              feature: 'Return Settings',
              selectedLanguage: data.language || 'en',
              returnReason: data.translation,
            });
          } catch (error) {
            console.log(error, 'Error Occured while updating return reason');
          }
        }

        if (this.state.submitType === 'addTranslation') {
          returnReason.translations.push(data);
          payload = {
            defaultLanguage: returnReason.language,
            reason: returnReason.translation,
            translations: returnReason.translations,
          };
          await axios.post(
            `/api/returnSettings/returnReasons/${this.state.returnReasonId}`,
            {
              payload,
            }
          );
          message.success('Added successfully', 5);
        }

        if (this.state.submitType === 'editTranslation') {
          const newTranslations = returnReason.translations.map(
            (translation, index) => {
              if (index === this.state.translationIndexNumber) {
                translation = data;
              }
              return translation;
            }
          );
          payload = {
            defaultLanguage: returnReason.language,
            reason: returnReason.translation,
            translations: newTranslations,
          };
          await axios.post(
            `/api/returnSettings/returnReasons/${this.state.returnReasonId}`,
            {
              payload,
            }
          );
          message.success('updated successfully', 5);
        }
      }

      this.setState({
        visible: false,
        filteredLanguages: [],
      });

      this.getReturnReasons();
    } catch (err) {
      return message.error('Something went wrong please try again', 5);
    }
  };

  handleDelete = async (type, data) => {
    const id = data.id;
    try {
      //Tracking event
      let deletedReturnReason = this.state.returnReasons.find(
        (returnReason) => returnReason.id === id
      );
      trackFSEvent('Delete a Return Reason', {
        feature: 'Return Settings',
        selectedLanguage: deletedReturnReason?.language || 'en',
        returnReason: deletedReturnReason?.reason,
      });
      trackingVitally('rr_returnreason_deleted');
      if (type === 'mainTableRowDeletion') {
        await axios.delete(`/api/returnSettings/returnReasons/${id}`);
      }

      if (type === 'nestedTableRowDeletion') {
        let deltedReturnReason = this.state.returnReasons.find(
          (returnReason) => returnReason.id === id
        );

        const deleteNestedRow = deltedReturnReason.translations.filter(
          (translation) => translation.language !== data.language
        );

        deltedReturnReason.translations = deleteNestedRow;

        const payload = {
          defaultLanguage: deltedReturnReason.language,
          reason: deltedReturnReason.translation,
          translations: deltedReturnReason.translations,
        };

        await axios.post(`/api/returnSettings/returnReasons/${id}`, {
          payload,
        });
      }
      this.getReturnReasons();
      return message.success('Return reason deleted successfully', 5);
    } catch (err) {
      return message.error('Something went wrong please try again', 5);
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleChange = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  handleDefaultReasons = async () => {
    try {
      const response = await axios.get('/api/returnSettings/returnReasons/defaultReasons');
      if (response?.data) {
        this.setState({
          returnReasons: response.data,
        });
      }
    } catch (err) {
        message.error('Something went wrong please try again', 5);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          width={720}
          title={
            this.props.featureMultilangReturnReasons
              ? 'Manage Return Reasons Language'
              : 'Manage Return Reasons'
          }
          visible={this.props.visible}
          onOk={this.props.onOk}
          onCancel={this.props.onCancel}
          closable={false}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col span={20}>
              <Alert
                message="Important"
                description={
                  <span>
                    The default return reasons are mapped to Shopify’s return reasons. 
                    This means that whenever a customer starts a return with one of these return reasons, 
                    the same return reason will also be displayed on the original order in Shopify.<br/>
                    Modifying these return reasons or adding new return reasons (and deleting these) may result in the loss of this functionality.
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          
          <ReturnReasonTable
            toggleReturnReasonInputModal={this.toggleReturnReasonInputModal}
            returnReason={this.state.returnReasons}
            handleDelete={this.handleDelete}
            featureMultilangReturnReasons={
              this.props.featureMultilangReturnReasons
            }
          />
          <Row type="flex" justify="end" style={{columnGap: '10px'}}>
            <Button
              type="primary"
              onClick={() =>
                this.toggleReturnReasonInputModal('addReturnReason')
              }
              className="u-marginTop--md"
            >
              Add Return-Reason
            </Button>
            {this.state.returnReasons?.length === 0 &&
              <Button
                type="secondary"
                onClick={() =>
                  this.handleDefaultReasons()
                }
                className="u-marginTop--md"
              >
                Populate Default-Reasons
              </Button>
            }
          </Row>
        </Modal>
        {this.state.visible ? (
          <WrappedReturnReasonForm
            visible={this.state.visible}
            onOk={this.handleOk}
            language={this.state.language}
            translation={this.state.translation}
            onCancel={this.handleCancel}
            filteredLanguages={this.state.filteredLanguages}
            featureMultilangReturnReasons={
              this.props.featureMultilangReturnReasons
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReturnReasonDialog;
