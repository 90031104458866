import { getAppInfo } from "./appInfo";
import axios from "axios";
import { message } from 'antd';

export const createShopifyUrl = async (redirectUrl, access_scopes = []) => {
  try {
    const {clientId, isProductionApp, env, appUrl} = await getAppInfo();
    const response = await axios.get("/api/connections");
    const nonceToken = "123456";
    const context = await axios.get("/api/contexts");
    const {shopifyCustomApp} = context.data;
    let  shopIdentifier = response.data.brandSettings.storeShopifyUrl;
    if (!shopIdentifier) {
      throw new Error("Please set your Shopify Store URL first before connecting. You'll find it under >>> Brand Settings");
    }
    if (shopIdentifier) {
      shopIdentifier = shopIdentifier.includes("https://") ? shopIdentifier : `https://${shopIdentifier}`;
    }
    const url = window.location.href;
    const urlObject = new URL(url);
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;
    const oAuthRedirectUrlShopify = `${urlProtocol}//${urlHostname}${redirectUrl}`;
    let scopes = ["read_orders", "read_all_orders", "read_products", "read_customers", "read_draft_orders", "write_orders", "write_draft_orders", "write_inventory", "write_price_rules", "read_price_rules", "write_discounts", "read_discounts", "read_gift_cards", "write_gift_cards", "read_returns", "write_returns"];
    let scopesForShopify = [...access_scopes, ...scopes]
    scopesForShopify = [...new Set(scopesForShopify)]
    let storeCreditScopes = [
      "write_store_credit_account_transactions", 
      "read_store_credit_account_transactions", 
      "read_store_credit_accounts",
      ...access_scopes,
    ];
    if(context && shopifyCustomApp){
      scopes = ["read_orders", "read_all_orders", "read_products", "read_customers", "read_draft_orders", "write_orders", "write_draft_orders", "write_inventory", "read_gift_cards", "write_gift_cards", "write_price_rules", "read_price_rules", "write_discounts", "read_discounts", "read_gift_cards", "write_gift_cards", "read_returns", "write_returns"];
      scopesForShopify = [...access_scopes, ...scopes]
      scopesForShopify = [...new Set(scopesForShopify)]
    }
    // adjust access scopes for local development
    // Shopify does not allow access to scopes 'read_all_orders' on sandbox apps
    if (env === 'development' || !isProductionApp || appUrl.includes('staging')) {
      scopes = ["read_orders", "read_products", "read_customers", "read_draft_orders", "write_orders", "write_draft_orders", "write_inventory", "write_price_rules", "read_price_rules", "write_discounts", "read_discounts", "read_gift_cards", "write_gift_cards", "read_returns", "write_returns"];
      scopesForShopify = [...access_scopes, ...scopes]
      scopesForShopify = [...new Set(scopesForShopify)]
    }
    scopesForShopify = scopesForShopify.join(",");
    storeCreditScopes = storeCreditScopes.join(",");
    const shopifyUrl = shopIdentifier
      + `/admin/oauth/authorize?client_id=${clientId}&scope=${scopesForShopify}&redirect_uri=`
      + oAuthRedirectUrlShopify
      + "&state="
      + nonceToken;
    const storeCreditShopifyUrl = shopIdentifier
      + `/admin/oauth/authorize?client_id=${clientId}&scope=${storeCreditScopes}&redirect_uri=`
      + oAuthRedirectUrlShopify
      + "&state="
      + nonceToken;

    if (redirectUrl === '/dashboard/connect/shopify') {
      return shopifyUrl;
    }

    return {
      shopifyUrl,
      storeCreditShopifyUrl
    };
  } catch (err) {
    console.log("Error loading your connection settings. Try again.", err)
    return message.error("Error while generating shopify URL. Please try again", 5);
  }
}