/**
 * Component for customer E-Mail settings page in the Dashboard.
 *
 * @component
 */

import {
  Row,
  Col,
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Tag,
  Tooltip,
  Upload,
  Radio,
} from 'antd';
import axios from 'axios';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { allLanguages } from '../../../../../src/constants/constants';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import Editor from './Editor';
import { EDITOR_TYPE } from '../../../../constants/constants';

import { trackingVitally } from '../../../../helpers/vitally';

function revertFromUrlSafe(urlSafeString) {
  return urlSafeString.replace(/%[0-9A-F]{2}/g, (code) =>
    String.fromCharCode(parseInt(code.slice(1), 16)),
  );
}


const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;
const { Panel } = Collapse;
const EditorOptions = [
  { label: 'WYSIWYG', value: EDITOR_TYPE.WYSIWYG },
  { label: 'HTML', value: EDITOR_TYPE.HTML_EDITOR },
];

const initialState = {
  emailPendingActive: true,
  emailPendingTitle: '',
  emailPendingContent: '',
  emailPendingAttachmentUrl: '',
  emailApprovedActive: true,
  emailApprovedTitle: '',
  emailApprovedContent: '',
  emailApprovedAttachmentUrl: '',
  emailApprovedLabelActive: false,
  emailApprovedLabelTitle: '',
  emailApprovedLabelContent: '',
  emailApprovedLabelAttachmentUrl: '',
  emailApprovedLabelErrorActive: false,
  emailApprovedLabelErrorTitle: '',
  emailApprovedLabelErrorContent: '',
  emailApprovedLabelErrorAttachmentUrl: '',
  emailApprovedLabelManualActive: false,
  emailApprovedLabelManualTitle: '',
  emailApprovedLabelManualContent: '',
  emailApprovedLabelManualAttachmentUrl: '',
  emailShippedActive: true,
  emailShippedTitle: '',
  emailShippedContent: '',
  emailShippedAttachmentUrl: '',
  emailReceivedActive: true,
  emailReceivedTitle: '',
  emailReceivedContent: '',
  emailReceivedAttachmentUrl: '',
  emailResolvedActive: true,
  emailResolvedTitle: '',
  emailResolvedContent: '',
  emailResolvedAttachmentUrl: '',
  emailRejectedActive: true,
  emailRejectedTitle: '',
  emailRejectedContent: '',
  emailRejectedAttachmentUrl: '',
  emailStoreCreditActive: true,
  emailStoreCreditTitle: '',
  emailStoreCreditContent: '',
  emailStoreCreditAttachmentUrl: '',
  defaultLocale: false,
  emailPendingContentHtml: '',
  emailApprovedContentHtml: '',
  emailShippedContentHtml: '',
  emailReceivedContentHtml: '',
  emailResolvedContentHtml: '',
  emailRejectedContentHtml: '',
  emailApprovedLabelContentHtml: '',
  emailApprovedLabelErrorContentHtml: '',
  emailApprovedLabelManualContentHtml: '',
  emailStoreCreditContentHtml: '',
  emailPendingEditor: EDITOR_TYPE.WYSIWYG,
  emailApprovedEditor: EDITOR_TYPE.WYSIWYG,
  emailApprovedLabelEditor: EDITOR_TYPE.WYSIWYG,
  emailApprovedLabelErrorEditor: EDITOR_TYPE.WYSIWYG,
  emailApprovedLabelManualEditor: EDITOR_TYPE.WYSIWYG,
  emailShippedEditor: EDITOR_TYPE.WYSIWYG,
  emailReceivedEditor: EDITOR_TYPE.WYSIWYG,
  emailResolvedEditor: EDITOR_TYPE.WYSIWYG,
  emailRejectedEditor: EDITOR_TYPE.WYSIWYG,
  emailStoreCreditEditor: EDITOR_TYPE.WYSIWYG,
  emailInTransitEditor: EDITOR_TYPE.WYSIWYG,
  emailReceivedGiftExchangeEditor: EDITOR_TYPE.WYSIWYG,
  emailInTransitActive: true,
  emailInTransitTitle: '',
  emailInTransitContent: '',
  emailInTransitAttachmentUrl: '',
  emailInTransitContentHtml: '',
};

class Email extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      locale: 'en',
      emailFromAddress: '',
      availableLanguages: [],
      selectedTemplate: 'Pending',
      imageUrl: '',
      visible: false,
      receivingEmail: '',
      enableHtmlEmailTemplates: false,
      enableHtmlEditor: false,
      emailLinkToButtonConversion: false,
      emailDefaultContent: {},
      originEditor: EDITOR_TYPE.WYSIWYG,
      isDecodeOrignal: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    this.initializeComponent();
  };

  initializeComponent = async () => {
    try {
      await this.fetchEmailSettings();
      this.setState({ 
        loading: false,
        originEditor: this.state[`email${this.state.selectedTemplate}Editor`],
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error loading email settings. Please try again.', 4);
    }
  };

  fetchEmailSettings = async () => {
    try {
      const response = await axios.get('/api/emailSettings');
      if (response.data.status === 'error') {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 4);
      }
      this.setState({
        enableHtmlEmailTemplates: response.data.enableHtmlEmailTemplates,
        enableHtmlEditor: response.data.enableHtmlEditor,
      });
      let emailData = response.data.emailSettings;
      let availableLanguages = [];
      emailData.map((email) => {
        availableLanguages.push(email.locale);
        if (email.defaultLocale === true) {
          emailData = email;
        }
      });
      const emailDefaultTemplate = response.data.emailDefault.reduce(
        (data, i) => {
          data[i.locale] = i;
          return data;
        },
        {}
      );
      return this.setState({
        ...emailData,
        availableLanguages: availableLanguages,
        emailDefaultContent: emailDefaultTemplate,
      });
    } catch (err) {
      return message.error(
        'There was an error loading your email templates. Try again.',
        4
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'Error saving your email templates. Try again.',
          4
        );
      }

      if (this.state.enableHtmlEmailTemplates) {
        let emailContent =
          this.state[`email${this.state.selectedTemplate}Editor`] &&
          this.state[`email${this.state.selectedTemplate}Editor`] === EDITOR_TYPE.HTML_EDITOR ?
            this.encodeHTML(
              this.state[`email${this.state.selectedTemplate}ContentHtml`]
            )
            : this.state[`email${this.state.selectedTemplate}ContentHtml`] || ''
        if (emailContent === '<p><br></p>') {
          return message.error("Email content can't be empty. Try again.", 4);
        }
        values = {
          ...values,
          [`email${this.state.selectedTemplate}ContentHtml`]: emailContent,
          [`email${this.state.selectedTemplate}Editor`]: this.state[`email${this.state.selectedTemplate}Editor`] || EDITOR_TYPE.WYSIWYG,
        };
      }
      try {
        const response = await axios.post('/api/emailSettings/update', values);
        if (response.data.status === 'error') {
          const errorMessage = response.data.error;
          return message.error(errorMessage, 4);
        }
        trackFSEvent('Edit Customer Notification', {
          feature: 'Email settings',
        });
        trackingVitally('rr_customeremail_edited');
        message.success('E-Mail settings updated successfully.', 4);
        let availableLanguages = this.state.availableLanguages;
        if (!this.state.availableLanguages.includes(response.data.locale)) {
          availableLanguages.push(response.data.locale);
        }
        this.props.form.resetFields();
        return this.setState({
          ...response.data,
          originEditor: this.state[`email${this.state.selectedTemplate}Editor`],
          isDecodeOrignal: false,
        });
      } catch (err) {
        return message.error(
          'There was an error saving your email templates. Try again.',
          4
        );
      }
    });
  };

  handleChange = async (value) => {
    await this.fetchEmailSettings();
    this.setState({
      selectedTemplate: value,
      originEditor: this.state[`email${value}Editor`],
      isDecodeOrignal: false,
    });
  };

  handleLanguageChange = async (value) => {
    this.setState({
      locale: value,
    });
    try {
      const response = await axios.get('/api/emailSettings');
      if (response.data.status === 'error') {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 4);
      }

      let emailData = response.data.emailSettings.filter((email) => {
        if (email.locale === value) {
          return email;
        }
      });
      if (emailData.length > 0) {
        this.setState({
          ...emailData[0],
          isDecodeOrignal: false,
        });
      } else {
        this.setState({
          ...initialState,
          isDecodeOrignal: false,
        });
      }
      this.props.form.resetFields();
    } catch (err) {
      return message.error(
        'There was an error changing your email templates. Try again.',
        4
      );
    }
  };
  onChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };
  onChangeAttachment = (e) => {
    this.setState({
      emailLinkToButtonConversion: e.target.checked,
    });
  };
  onChangeInput = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.value,
    });
  };

  onChangeText = (target, text) => {
    if (this.state[target] !== text) {
      this.setState({
        [target]: text,
        isDecodeOrignal: true,
      });
    }
  };

  async checkEmailImage(file) {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
    if (!allowedFileTypes.includes(file.type)) {
      message.error(
        'Only .jpg, .jpeg, .png and .svg files smaller than 1 MB allowed.',
        5
      );
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      message.error('Only files smaller than 2 MB allowed.', 5);
      return false;
    }
    return true;
  }

  async handleImageUpload(options) {
    options.onProgress({ percent: 20 });
    let signedUrl = '';
    let bucketUrl = '';
    try {
      let response = await axios.post('/api/emailSettings/image/upload', {
        fileType: options.file.type,
        fileSize: options.file.size / 1024 / 1024,
      });
      if (response.data.error === 'wrong-size') {
        return message.error('Only files smaller than 2 MB allowed.', 5);
      }
      if (response.data.error === 'wrong-format') {
        return message.error(
          'Only .jpg, .jpeg, .png and .svg files smaller than 1 MB allowed.',
          5
        );
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      options.onProgress({ percent: 50 });
    } catch (err) {
      console.log('err', err);
      return message.error('Error preparing upload. Try again.', 4);
    }

    try {
      // eslint-disable-next-line no-unused-vars
      let fileUpload = await axios.put(signedUrl, options.file, {
        headers: { 'Content-Type': options.file.type },
      });
      options.onProgress({ percent: 75 });
      let saveUrl = await axios.post('/api/emailSettings/image/update', {
        imageUrl: bucketUrl,
      });
      if (saveUrl.data.error) {
        return message.error('Error saving image. Try again.', 4);
      }
      message.success('Image uploaded successfully.', 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        imageUrl: bucketUrl,
        ...saveUrl.data,
      });
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error('Error saving inage. Try again.', 4);
    }
  }

  async handleDeleteImage() {
    try {
      const response = await axios.post('/api/emailSettings/image/delete', {
        locale: this.state.locale,
      });
      if (response.data.error) {
        return message.error('Error deleting current logo. Try again.', 4);
      }
      message.success('Current image deleted successfully', 4);
      const stateData = response.data;
      this.setState({
        ...stateData,
      });
    } catch (err) {
      message.error('Error deleting current image. Try again.', 4);
    }
  }

  async handleDeleteAttachment() {
    try {
      const response = await axios.post('/api/emailSettings/attachment/delete', {
        locale: this.state.locale,
        selectedTemplate: this.state.selectedTemplate,
      });
      if (response.data.error) {
        return message.error('Error deleting current attachment. Try again.', 4);
      }
      message.success('Current attachment deleted successfully', 4);
      const stateData = response.data;
      this.setState({
        ...stateData,
      });
    } catch (err) {
      message.error('Error deleting current attachment. Try again.', 4);
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  encodeHTML = (s) => {
    return Buffer.from(s).toString("base64");
  }

  sendEmail = async () => {
    try {
      const email = this.state.receivingEmail;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const test = emailRegex.test(email);
      if (!test) {
        return message.error('Please enter a valid email and try again.', 4);
      }
      const content = {
        emailTitle: this.state[`email${this.state.selectedTemplate}Title`],
        emailContent:
          this.state[`email${this.state.selectedTemplate}Editor`] &&
          this.state[`email${this.state.selectedTemplate}Editor`] === EDITOR_TYPE.HTML_EDITOR
            ? this.encodeHTML(
                this.state[`email${this.state.selectedTemplate}ContentHtml`]
              )
            : this.state[`email${this.state.selectedTemplate}ContentHtml`] || '',
      };
      const response = await axios.post('/api/emailSettings/sendTestEmail', {
        email,
        locale: this.state.locale,
        content,
        imageUrl: this.state.imageUrl,
        emailLinkToButtonConversion: this.state.emailLinkToButtonConversion,
        isEncode: this.state[`email${this.state.selectedTemplate}Editor`] === EDITOR_TYPE.HTML_EDITOR,
      });
      if (response.status != 200) {
        return message.error(
          'Something went wrong while sending email, Please try again later.',
          5
        );
      }
      this.setState({
        visible: false,
      });
      return message.success('Email sent successfully!', 5);
    } catch (err) {
      console.log('err: ', err); //TODO: remove this line
      message.error('Error sending email. Try again.', 4);
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeEmail = (e) => {
    this.setState({
      receivingEmail: e.target.value,
    });
  };

  checkAttachment = (file) => {
    // allow images, pdfs, docx, doc, txt, csv, xlsx, xls, pptx, ppt
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/svg+xml', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint'];
    if (!allowedFileTypes.includes(file.type)) {
      message.error('Only .png, .jpg, .jpeg, .pdf, .doc, .docx, .txt, .csv, .xlsx, .xls, .pptx, .ppt files allowed.', 5);
      return false;
    }
    // check file size
    if (file.size / 1024 / 1024 > 20) {
      message.error('Only files smaller than 20 MB allowed.', 5);
      return false;
    }

    return true;
  }

  handleAttachmentUpload = async (options) => {
    options.onProgress({ percent: 20 });
    let signedUrl = '';
    let bucketUrl = '';
    try {
      let response = await axios.post(
        '/api/emailSettings/attachment/upload',
        {
          fileType: options.file.type,
          fileSize: options.file.size / 1024 / 1024,
          fileName: options.file.name,
        }
      );
      if (response.data.error === 'wrong-size') {
        return message.error('Only files smaller than 20 MB allowed.', 5);
      }
      if (response.data.error === 'wrong-format') {
        return message.error(
          'Only .pdf, .doc, .docx, .txt, .csv, .xlsx, .xls, .pptx, .ppt files smaller than 20 MB allowed.',
          5
        );
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      options.onProgress({ percent: 50 });
    } catch (err) {
      console.log('err', err);
      return message.error('Error preparing upload. Try again.', 4);
    }

    try {
      await axios.put(signedUrl, options.file, {
        headers: { 'Content-Type': options.file.type },
      });
      options.onProgress({ percent: 75 });
      let saveUrl = await axios.post('/api/emailSettings/attachment/update', {
        attachmentUrl: bucketUrl,
        selectedTemplate: this.state.selectedTemplate,
      });
      if (saveUrl.data.error) {
        return message.error('Error saving file. Try again.', 4);
      }
      message.success('File uploaded successfully.', 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        ...saveUrl.data,
      });
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error('Error saving file. Try again.', 4);
    }
  }

  render() {
    const existingLanguages = allLanguages.map((language) => {
      if (this.state.availableLanguages.includes(language.code))
        return (
          <Option value={language.code} key={language.code}>
            {language.name}
          </Option>
        );
    });
    const remainingLanguages = allLanguages.map((language) => {
      if (!this.state.availableLanguages.includes(language.code))
        return (
          <Option value={language.code} key={language.code}>
            {language.name}
          </Option>
        );
    });
    const defaultTitle = this.state.emailDefaultContent[this.state.locale]
      ? this.state.emailDefaultContent[this.state.locale][
          `email${this.state.selectedTemplate}Title`
      ]
      : '';
    const defaultContent = this.state.emailDefaultContent[this.state.locale]
      ? this.state.emailDefaultContent[this.state.locale][
          `email${this.state.selectedTemplate}Content`
      ]
      : '';
    const defaultContentHtml = this.state.emailDefaultContent[this.state.locale]
      ? this.state.emailDefaultContent[this.state.locale][
          `email${this.state.selectedTemplate}ContentHtml`
      ]
      : '';
    const valueContentHtml =
      this.state[`email${this.state.selectedTemplate}ContentHtml`] &&
      this.state[`email${this.state.selectedTemplate}ContentHtml`].length > 0
        ? this.state[`email${this.state.selectedTemplate}ContentHtml`]
        : defaultContentHtml;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    const statusDescription = {
      Pending: (
        <React.Fragment>
          An email for a <strong>Pending Return</strong> is sent out to
          customers when they initially submit a return through the
          return-portal and wait for your approval.
        </React.Fragment>
      ),
      Approved: (
        <React.Fragment>
          An email for an <strong>Approved Return</strong> is sent out to
          customers once you approve the return in the dashboard (or
          auto-approved) and no prepaid label is enabled for this return. Make
          sure to <strong>include a return address</strong> (with country if you
          have international customers) and precise instructions for your
          customers.
        </React.Fragment>
      ),
      ApprovedLabel: (
        <React.Fragment>
          Email for an <strong>Approved Return</strong> that is sent out to
          customers instead of the regular Approved-Email once you approve the
          return in the dashboard (or auto-approved). This email will trigger if
          an automatic return label is present.
        </React.Fragment>
      ),
      ApprovedLabelError: (
        <React.Fragment>
          Email that is sent out when there is an issue creating the automatic
          label (e.g. address errors, etc). You want to tell the customer that
          you'll get back to them with a label asap to avoid additional support
          inquiries.
        </React.Fragment>
      ),
      ApprovedLabelManual: (
        <React.Fragment>
          Email for an <strong>Approved Return</strong> that is sent out to the
          customer instead of the regular Approved-Email once you approve the
          return in the dashboard. This email will trigger if a manually
          uploaded return label is present.
        </React.Fragment>
      ),
      Shipped: (
        <React.Fragment>
          An email for a <strong>Shipped Return</strong> is sent out to
          customers when the status of the return is set to shipped by either
          you or the customer.
        </React.Fragment>
      ),
      InTransit: (
        <React.Fragment>
          An email for an <strong>In Transit Return</strong> is sent out to
          customers when the status of the return is set to in transit by either
          you or the customer.
        </React.Fragment>
      ),
      Received: (
        <React.Fragment>
          An email for a <strong>Received Return</strong> is sent out to
          customers once you received the package or product.
        </React.Fragment>
      ),
      Resolved: (
        <React.Fragment>
          An email for a <strong>Resolved Return</strong> is sent out to
          customers once you issued a refund or exchange.
        </React.Fragment>
      ),
      Rejected: (
        <React.Fragment>
          An email for a <strong>Rejected Return</strong> is sent out to
          customers when you reject their return inquiry. You can include
          default reasons for a rejection in this email (like hygiene, out of
          refund period, final sale, etc). Reach out to the customer directly if
          you want to explain something you can't in this template.
        </React.Fragment>
      ),
      StoreCredit: (
        <React.Fragment>
          An email when <strong>Store Credit</strong> is issued during the
          refund process. This email contains the code of the gift-card or
          discount-code for the customer to redeem their store-credit.
        </React.Fragment>
      ),
    };
    // const enableLoadEmailTemplate = !!this.state.emailDefaultContent[this.state.locale];

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <React.Fragment>
          <Form onSubmit={this.handleSubmit} hideRequiredMark={true}>
            <PageButtonHeader
              headingTitle={'Customer emails'}
              headingRightContent={
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ padding: 'var(--input-padding)' }}
                  >
                    Save changes
                  </Button>
                </FormItem>
              }
            ></PageButtonHeader>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-xxs-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div className="flex-row u-rowGap--sm">
                    <div className="flex-col-xxs-12 flex-col-sm-6">
                      <h4>Email type</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem {...formItemLayout}>
                          {getFieldDecorator(`${this.state.selectedTemplate}`, {
                            initialValue: this.state.selectedTemplate,
                            setFieldsValue: this.state.selectedTemplate,
                          })(
                            <Select
                              style={{ width: '100%' }}
                              onChange={this.handleChange.bind(this)}
                              placeholder="Select template"
                            >
                              <Option value="Pending">Pending Return</Option>
                              <Option value="Approved">Approved Return</Option>
                              <Option value="ApprovedLabel">
                            Approved Return (Automatic Prepaid Label)
                              </Option>
                              <Option value="ApprovedLabelError">
                            Approved Return (Automatic Prepaid Label - Error)
                              </Option>
                              <Option value="ApprovedLabelManual">
                            Approved Return (Manually Uploaded Prepaid Label)
                              </Option>
                              <Option value="Shipped">Shipped Return</Option>
                              <Option value="InTransit">In Transit Return</Option>
                              <Option value="Received">Received Return</Option>
                              <Option value="Resolved">Resolved Return</Option>
                              <Option value="Rejected">Rejected Return</Option>
                              <Option value="ReceivedGiftExchange">Received Gift Exchange</Option>
                              {this.context.featureStoreCredit ? (
                                <Option value="StoreCredit">Store-Credit</Option>
                              ) : null}
                            </Select>
                          )}
                        </FormItem>
                        <div>{statusDescription[this.state.selectedTemplate]}</div>
                      </div>
                    </div>
                    <div className="flex-col-xxs-12 flex-col-sm-6">
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%' }}
                      >
                        <h4>Language</h4>
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem {...formItemLayout}>
                            {getFieldDecorator(`locale`, {
                              initialValue: this.state.locale,
                              setFieldsValue: this.state.locale,
                            })(
                              <Select
                                style={{ width: '100%' }}
                                onChange={this.handleLanguageChange.bind(this)}
                                placeholder="Select language"
                              >
                                <Option key="disabledExisting" disabled={true}>
                              --- Existing Languages
                                </Option>
                                {existingLanguages}
                                <Option key="disabledRemaining" disabled={true}>
                              --- Available Languages
                                </Option>
                                {remainingLanguages}
                              </Select>
                            )}
                          </FormItem>
                          <FormItem {...formItemLayout}>
                            {getFieldDecorator(
                          `defaultLocale`,
                          {}
                            )(
                              <Checkbox
                                checked={this.state.defaultLocale}
                                onChange={this.onChange.bind(this)}
                              >
                            Default language
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-xxs-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div className="u-marginBottom--md">
                    <h4>Email subject</h4>
                    <p>
                      Specify the subject line for your selected email template.
                    </p>
                    <div
                      className="flex-row flex-row--noMargin"
                      style={{ width: '100%' }}
                    >
                      <FormItem {...formItemLayout}>
                        {getFieldDecorator(
                          `email${this.state.selectedTemplate}Title`,
                          {
                            initialValue:
                              this.state[
                                `email${this.state.selectedTemplate}Title`
                              ] || defaultTitle,
                            rules: [
                              {
                                required: true,
                                message: 'Please enter a subject for this email.',
                              },
                            ],
                          }
                        )(
                          <Input
                            onChange={this.onChangeInput.bind(this)}
                            placeholder="Subject line (title) of your email."
                          />
                        )}
                      </FormItem>
                    </div>
                  </div>

                  <Row>
                    <Col span={18}>
                      <h4 style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        Email content
                        <Tooltip
                          placement="bottom"
                          title={
                            this.state.enableHtmlEmailTemplates
                              ? 'Great news! Our support now extends to text, Emojis, URLs, and HTML elements. Feel free to utilize all of these in your content!'
                              : 'We currently support text, Emojis and URLs. Do not add HTML elements.'
                          }
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </h4>
                      <p>Enter the content for your email that will be sent to your
                      customers.</p>
                    </Col>
                    {this.state.enableHtmlEmailTemplates && (
                      <Col span={6} style={{ textAlign: 'right', padding: 0 }}>
                        <Radio.Group 
                          defaultValue={this.state[`email${this.state.selectedTemplate}Editor`] || EDITOR_TYPE.WYSIWYG}
                          value={this.state[`email${this.state.selectedTemplate}Editor`] || EDITOR_TYPE.WYSIWYG}
                          size="small"
                          onChange={(e) => {
                            this.onChangeText(
                              `email${this.state.selectedTemplate}Editor`,
                              e.target.value
                            );
                          }}
                          style={{ padding: 0 }}
                        >
                          <Radio.Button value={EDITOR_TYPE.WYSIWYG} style={{ height: 'auto', borderRadius: 0 }}>Text Editor</Radio.Button>
                          <Radio.Button value={EDITOR_TYPE.HTML_EDITOR} style={{ height: 'auto', borderRadius: 0 }}>HTML</Radio.Button>
                        </Radio.Group>
                      </Col>
                    )}
                  </Row>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%', gap: 20 }}
                  >
                    <FormItem {...formItemLayout}>
                      {this.state.enableHtmlEmailTemplates 
                        && (!this.state[`email${this.state.selectedTemplate}Editor`] || this.state[`email${this.state.selectedTemplate}Editor`] === EDITOR_TYPE.WYSIWYG)
                        && (
                        <ReactQuill
                          theme="snow"
                          value={valueContentHtml}
                          onChange={(value) => {
                            this.onChangeText(
                              `email${this.state.selectedTemplate}ContentHtml`,
                              value
                            );
                          }}
                        />
                      )}
                      {this.state.enableHtmlEmailTemplates 
                        && (this.state[`email${this.state.selectedTemplate}Editor`] && this.state[`email${this.state.selectedTemplate}Editor`] === EDITOR_TYPE.HTML_EDITOR)
                        && (
                        <Editor
                          content={valueContentHtml}
                          onChange={(value) => {
                            this.onChangeText(
                              `email${this.state.selectedTemplate}ContentHtml`,
                              value
                            );
                          }}
                        />
                      )}
                      {!this.state.enableHtmlEmailTemplates && (
                        getFieldDecorator(
                          `email${this.state.selectedTemplate}Content`,
                          {
                            initialValue:
                              this.state[
                                `email${this.state.selectedTemplate}Content`
                              ] || defaultContent,
                            rules: [
                              {
                                required: true,
                                message: 'Please enter content for this email.',
                              },
                            ],
                          }
                        )(
                          <TextArea
                            onChange={this.onChangeInput.bind(this)}
                            placeholder="Content of your email."
                            autoSize={{ minRows: 4 }}
                          />
                        )
                      )}
                    </FormItem>
                    {this.state.enableHtmlEmailTemplates && (
                      <div
                        className="flex-row flex-row--noMargin flex-end-xxs"
                        style={{ width: '100%' }}
                      >
                        <React.Fragment>
                          <Button
                            type="primary"
                            onClick={this.showModal}
                            style={{ float: 'right' }}
                          >
                            Send test email
                          </Button>
                          <Modal
                            title="Send Test Email"
                            visible={this.state.visible}
                            onOk={this.sendEmail}
                            onCancel={this.handleCancel}
                          >
                            <h3>Please enter receiving email</h3>
                            <Input
                              type="email"
                              placeholder="Please enter email"
                              onChange={(value) => this.onChangeEmail(value)}
                            />
                          </Modal>
                        </React.Fragment>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Email personalization</h4>
                <span>
                  Use variables to effortlessly customize your emails.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem {...formItemLayout}>
                      <div>
                        <Collapse>
                          <Panel
                            header="Want to personalize your emails with variables?"
                            key="1"
                          >
                            <p>
                              You can use variables to dynamically personalize
                              your emails.
                            </p>
                            <p>
                              <strong>
                                IMPORTANT: copy the variables you want to use
                                including the curly-braces to your email. They
                                need to match exactly.
                              </strong>
                            </p>
                            <p>
                              <p>The following variables are available:</p>
                              <p>
                                <strong>Order related</strong>
                              </p>
                              <p>
                                <Tag>{'{RMA_NUMBER}'}</Tag> the number of the
                                return (also called RMA number)
                              </p>
                              <p>
                                <Tag>{'{ORDER_NAME}'}</Tag> the
                                order-name/number on Shopify, e.g. #1234
                              </p>
                              <p>
                                <Tag>{'{ORDER_ID}'}</Tag> the orderId on
                                Shopify, e.g. 123456
                              </p>
                              <p>
                                <Tag>{'{ITEM_DETAILS}'}</Tag> list of items with
                                reason for return
                              </p>
                              <p>
                                <Tag>{'{REJECTION_OBSERVATION}'}</Tag> Observation of 
                                rejected return
                              </p>
                              <p>
                                <strong>Business related</strong>
                              </p>
                              <p>
                                <Tag>{'{RETURN_ADDRESS}'}</Tag> the general
                                return address you have set in the
                                Return-Settings (without the phone number) or a
                                custom address when using our rules-feature
                              </p>
                              <p>
                                <strong>Customer related</strong>
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_EMAIL}'}</Tag> the email
                                address the customer used for the order
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_FIRST_NAME}'}</Tag> the first
                                name of the customer
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_LAST_NAME}'}</Tag> the last
                                name of the customer
                              </p>
                              <p>
                                <strong>
                                  Customer shipping address related
                                </strong>
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_ADDRESS}'}</Tag> the address of
                                the customer
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_APARTMENT_SUIT}'}</Tag> the
                                apartment, suit, etc of the customer
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_CITY}'}</Tag> the city of the
                                customer
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_STATE}'}</Tag> the state of the
                                customer
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_ZIP_CODE}'}</Tag> the ZIP code
                                of the customer
                              </p>
                              <p>
                                <Tag>{'{CUSTOMER_COUNTRY}'}</Tag> the country of
                                the customer
                              </p>
                              <p>
                                <strong>Shipping-label related</strong>
                              </p>
                              <p>
                                <Tag>{'{LABEL_DOWNLOAD_LINK}'}</Tag> the
                                download link to the PDF prepaid label, only
                                available if you have automatic prepaid labels
                                enabled
                              </p>
                              <p>
                                <Tag>{'{LABEL_QR_CODE_DOWNLOAD_LINK}'}</Tag> the
                                download link to the QR Code label. Only
                                available for USPS on EasyPost and if you have
                                this feature available on your plan
                              </p>
                              <p>
                                <Tag>{'{LABEL_TRACKING_LINK}'}</Tag> the
                                tracking link for the prepaid label, only
                                available if you have automatic prepaid labels
                                enabled
                              </p>
                              <p>
                                <Tag>{'{COMMERCIAL_INVOICE}'}</Tag> the download
                                link to the commercial invoice for international
                                shipments (if available)
                              </p>
                              <p>
                                <Tag>{'{MANUAL_LABEL_DOWNLOAD_LINK}'}</Tag> the
                                download link to the label, only available if
                                you uploaded the prepaid-label for the return
                              </p>
                              <p>
                                <Tag>
                                  {'{MANUAL_LABEL_SECOND_DOWNLOAD_LINK}'}
                                </Tag>{' '}
                                the download link to the second document/label,
                                only available if you uploaded the file in the
                                dashboard
                              </p>
                              {this.context.featureStoreCredit ? (
                                <React.Fragment>
                                  <p>
                                    <strong>Refund related</strong>
                                  </p>
                                  <p>
                                    <Tag>{'{STORE_CREDIT_CODE}'}</Tag> The code
                                    of the gift-card (only Shopify Plus) or
                                    discount-code for the customer to redeem
                                    their store-credit.
                                  </p>
                                  <p>
                                    <Tag>{'{STORE_CREDIT_AMOUNT}'}</Tag> The
                                    amount of store-credit in the form of
                                    gift-card (only Shopify Plus) or
                                    discount-code.
                                  </p>
                                  <p>
                                    <Tag>{'{STORE_CREDIT_CURRENCY}'}</Tag> The
                                    currency of store-credit including in the
                                    form of gift-card (only Shopify Plus) or
                                    discount-code.
                                  </p>
                                </React.Fragment>
                              ) : null}
                            </p>
                          </Panel>
                        </Collapse>
                      </div>
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            {this.state.enableHtmlEmailTemplates && (
              <React.Fragment>
                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Upload email image</h4>
                    <span>
                      Upload an image to display in your email notifications.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <div className="flex-row flex-row--noMargin">
                        {this.state.imageUrl === '' ||
                        this.state.imageUrl === null ? (
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                              style={{ width: '100%', gap: 20 }}
                            >
                              <img
                                src={
                                  'https://assets.reviews.io/img/all-global-assets/icons/icon-blank-photos--md.svg'
                                }
                                alt="Product Image"
                                style={{ width: 60 }}
                              />
                              <Upload
                                beforeUpload={(file) => {
                                  return this.checkEmailImage(file);
                                }}
                                customRequest={(options) => {
                                  this.handleImageUpload(options);
                                }}
                              >
                                <Button
                                  type="ghost"
                                  style={{ padding: 'var(--input-padding)' }}
                                >
                                Upload image
                                </Button>
                              </Upload>
                            </div>
                          ) : (
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                              style={{ width: '100%', gap: 20 }}
                            >
                              <img
                                src={this.state.imageUrl}
                                style={{ maxHeight: 100, maxWidth: 200 }}
                              />

                              <Popconfirm
                                title="Do you really want to delete the current email image? You can upload a new one later."
                                onConfirm={() => {
                                  this.handleDeleteImage();
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  type="ghost"
                                  style={{
                                    padding: 'var(--input-padding)',
                                  }}
                                  size="small"
                                >
                                Delete?
                                </Button>
                              </Popconfirm>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider></Divider>
              </React.Fragment>
            )}
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Set default email address</h4>
                <span>
                  This is typically your customer support email and will be used
                  for all outgoing communications.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <h4>Email address</h4>
                    <FormItem {...formItemLayout}>
                      {getFieldDecorator('emailFromAddress', {
                        initialValue: this.state.emailFromAddress,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a valid email-address.',
                          },
                        ],
                      })(
                        <>
                          <Input
                            style={{ width: '100%' }}
                            placeholder="Email your customers can reply to"
                            type="email"
                          />
                        </>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            {this.state.selectedTemplate === 'InTransit' ? (
              <>
                <Alert
                  className="u-marginBottom--sm"
                  message="Important"
                  description={
                    <span>
                      To ensure that your customers receive tracking
                      information, please remember to include{' '}
                      <strong>&#123;LABEL_TRACKING_LINK&#125;</strong> in your
                      email templates if you are using prepaid labels.
                    </span>
                  }
                  type="info"
                  showIcon
                />
                <br></br>
                <Divider></Divider>
              </>
            ) : null}
            {this.state.enableHtmlEmailTemplates && (
              <div
                className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                style={{ gap: 20, width: '100%' }}
              >
                <div
                  className="flex-col-md-3 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <h4>Convert links to buttons</h4>
                  <span>
                    Enable this option to transform links in your email template
                    into buttons.
                  </span>
                </div>
                <div
                  className="flex-col-md-8 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <div className="ContentPanel">
                    <div
                      className="flex-row flex-row--noMargin"
                      style={{ width: '100%', gap: 20 }}
                    >
                      <FormItem {...formItemLayout}>
                        {getFieldDecorator(
                          'emailLinkToButtonConversion',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.emailLinkToButtonConversion}
                            onChange={(e) => this.onChangeAttachment(e)}
                          >
                            Convert links into buttons
                          </Checkbox>
                        )}
                      </FormItem>
                      <Collapse>
                        <Panel
                          header="By checking the box, the following variables are going to be displayed as buttons (if applicable):"
                          key="1"
                        >
                          <p>
                            <p>
                              <strong>Shipping-label related</strong>
                            </p>
                            <p>
                              <Tag>{'{LABEL_DOWNLOAD_LINK}'}</Tag> the download
                              link to the PDF prepaid label, only available if
                              you have automatic prepaid labels enabled
                            </p>
                            <p>
                              <Tag>{'{LABEL_QR_CODE_DOWNLOAD_LINK}'}</Tag> the
                              download link to the QR Code label. Only available
                              for USPS on EasyPost and if you have this feature
                              available on your plan
                            </p>
                            <p>
                              <Tag>{'{LABEL_TRACKING_LINK}'}</Tag> the tracking
                              link for the prepaid label, only available if you
                              have automatic prepaid labels enabled
                            </p>
                            <p>
                              <Tag>{'{COMMERCIAL_INVOICE}'}</Tag> the download
                              link to the commercial invoice for international
                              shipments (if available)
                            </p>
                            <p>
                              <Tag>{'{MANUAL_LABEL_DOWNLOAD_LINK}'}</Tag> the
                              download link to the label, only available if you
                              uploaded the prepaid-label for the return
                            </p>
                            <p>
                              <Tag>{'{MANUAL_LABEL_SECOND_DOWNLOAD_LINK}'}</Tag>{' '}
                              the download link to the second document/label,
                              only available if you uploaded the file in the
                              dashboard
                            </p>
                          </p>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Upload attachment file</h4>
                <span>
                  Upload an attachment file to be sent to your customers in the email. (.png, .jpg, .jpeg, .pdf, .doc, .docx, .txt, .csv, .xlsx, .xls, .pptx, .ppt)
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    {this.state[`email${this.state.selectedTemplate}AttachmentUrl`] === '' ||
                        this.state[`email${this.state.selectedTemplate}AttachmentUrl`] === null ?
                    <Upload
                        beforeUpload={(file) => {
                          return this.checkAttachment(file);
                        }}
                        customRequest={(options) => {
                          this.handleAttachmentUpload(options);
                        }}
                      >
                      <Button>Upload file</Button>
                    </Upload>
                    : (
                      <div
                        className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                        style={{ width: '100%', gap: 20 }}
                      >
                        <p>Attachment file:{' '} 
                          <a 
                            href={this.state[`email${this.state.selectedTemplate}AttachmentUrl`]} 
                            target="_blank" rel="noopener noreferrer">
                              {this.state[`email${this.state.selectedTemplate}AttachmentUrl`].split('/').pop().split('--')[0]}
                          </a>
                        </p>
                        <Popconfirm
                          title="Do you really want to delete the current email attachment? You can upload a new one later."
                          onConfirm={() => {
                            this.handleDeleteAttachment();
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="ghost"
                            style={{
                              padding: 'var(--input-padding)',
                            }}
                            size="small"
                          >
                          Delete?
                          </Button>
                        </Popconfirm>
                      </div>
                    )
                  }
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            {this.state.selectedTemplate === 'InTransit' ? (
              <>
                <Alert
                  className="u-marginBottom--sm"
                  message="Important"
                  description={
                    <span>
                      To ensure that your customers receive tracking
                      information, please remember to include{' '}
                      <strong>&#123;LABEL_TRACKING_LINK&#125;</strong> in your
                      email templates if you are using prepaid labels.
                    </span>
                  }
                  type="info"
                  showIcon
                />
                <br></br>
                <Divider></Divider>
              </>
            ) : null}
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Send to customer?</h4>
                <span>
                  Enable this option to send the email template to your
                  customers.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem {...formItemLayout}>
                      {getFieldDecorator(
                        `email${this.state.selectedTemplate}Active`,
                        {}
                      )(
                        <Checkbox
                          checked={
                            this.state[
                              `email${this.state.selectedTemplate}Active`
                            ]
                          }
                          onChange={this.onChange.bind(this)}
                        >
                          Send email to customer
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-row flex-row--noMargin flex-end-xxs u-marginBottom--lg"
              style={{ width: '100%', marginTop: 30 }}
            >
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ padding: 'var(--input-padding)' }}
                >
                  Save changes
                </Button>
              </FormItem>
            </div>
          </Form>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const WrappedEmail = Form.create()(Email);
export default WrappedEmail;
