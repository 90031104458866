/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our Shippo, ShipStation and EasyPost
 * integrations for the user.
 *
 * @component
 */

import React from 'react';
import axios from 'axios';
import {
  Button,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  Alert,
  message,
  Checkbox,
  Tooltip,
} from 'antd';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import EasyPost from './ReturnLabelEasypost';
import Shippo from './ReturnLabelShippo';
import ShipStation from './ReturnLabelShipstation';
import ReturnLabelDimensions from './ReturnLabelDimensions';
import ReturnLabelWeight from './ReturnLabelWeight';
import ReturnLabelCustoms from './ReturnLabelCustoms';
import { trackFSEvent } from "../../../../helpers/fullstory";
import UPS from './ReturnLabelUPS';
import AusPost from './ReturnLabelAusPost';
import CanadaPost from './ReturnLabelCanadaPost';
import { trackingVitally } from '../../../../helpers/vitally';

const { Option } = Select;

class ReturnLabel extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      //control the modal
      loading: false,
      errorMessage: null,

      // common settings
      id: this.props.carrier.id || null,
      isDefault: this.props.carrier?.isDefault || false,
      labelService: this.props.carrier?.labelService || undefined,
      labelCarrier: this.props.carrier?.labelCarrier || undefined,
      labelCarrierName: this.props.carrier?.labelCarrierName || undefined,
      labelServicelevel: this.props.carrier?.labelServicelevel || undefined,
      labelServicelevelName:
        this.props.carrier?.labelServicelevelName || undefined,
      labelApiKey: this.props.carrier?.labelApiKey || undefined,
      labelApiSecret: this.props.carrier?.labelApiSecret || undefined,
      labelSelectCheapestRate:
        this.props.carrier?.labelSelectCheapestRate || false,
      carrierListShipStation:
        this.props.carrier?.carrierListShipStation || undefined,
      ratesListShipStation:
        this.props.carrier?.ratesListShipStation || undefined,
      carrierListEasyPost: this.props.carrier?.carrierListEasyPost || undefined,
      customsNumberOrExemption:
        this.props.carrier?.customsNumberOrExemption || undefined,
      shippoScanBasedLabels: this.props.carrier?.shippoScanBasedLabels || false,
      autoTracking: this.props.carrier?.autoTracking || false,
      easypostEnableReturnLabels:
        this.props.carrier?.easypostEnableReturnLabels || false,
      labelEnableQRcode: this.props.carrier?.labelEnableQRcode || false,
      enableSameLabelCurrency:
        this.props.carrier?.enableSameLabelCurrency || false,
      buyerAddressAdded: this.props.carrier?.buyerAddressAdded || false,
      domesticService: this.props.carrier?.domesticService || null,
      internationalService: this.props.carrier?.internationalService || null,
      labelAccountNumber: this.props.carrier?.labelAccountNumber,
      labelPassword: this.props.carrier?.labelPassword,
      labelDeliveryService: this.props.carrier?.labelDeliveryService,
      canadapostCustomerNumber: this.props.carrier?.canadapostCustomerNumber,
      canadapostOption: this.props.carrier?.canadapostOption,
      canadapostCurrency: this.props.carrier?.canadapostCurrency,
      canadapostConversionCAD: this.props.carrier?.canadapostConversionCAD,
      autoTrackingWebhookSecret:
        this.props.carrier?.autoTrackingWebhookSecret || undefined,

      // dimensions
      labelParcelDimensionsUnit:
        this.props.carrier?.labelParcelDimensionsUnit || undefined,
      labelParcelHeight: this.props.carrier?.labelParcelHeight || undefined,
      labelParcelWidth: this.props.carrier?.labelParcelWidth || undefined,
      labelParcelLength: this.props.carrier?.labelParcelLength || undefined,
      // weight
      labelParcelDefaultWeigth:
        this.props.carrier?.labelParcelDefaultWeigth || undefined,
      labelParcelWeightUnit:
        this.props.carrier?.labelParcelWeightUnit || undefined,
      upsEnabled: this.props.upsEnabled,
    };
  }

  async componentDidMount() {
    this.setState({
      ...this.props.state,
    });
    if (this.props.state.labelApiKey) {
      this.inputFieldValidation(this.props.state.labelApiKey);
    }
    try {
      const response = await axios.get('/api/connections');
      const couriers = ['ups'];
      response.data.connectionSettings.forEach((connection) => {
        if (
          couriers?.includes(connection.identifier) &&
          connection.status === 'connected'
        ) {
          this.setState({
            [connection.identifier]: 'connected',
          });
        }
      });
    } catch (err) {
      message.error('Error retrieving connections. Try again.', 5);
    }
  }

  handleCancel() {
    this.props.showConfigureModal(false);
  }

  handlePayload = () => {
    let labelkey;
    if (this.state.labelApiKey) {
      labelkey = this.state.labelApiKey.trim();
    } else {
      labelkey = this.state.labelApiKey;
    }
    let payload = {
      id: this.state.id,
      active: true,
      isDefault: this.state.isDefault,
      labelService: this.state.labelService,
      labelCarrier: this.state.labelCarrier,
      labelCarrierName: this.state.labelCarrierName,
      labelServicelevel: this.state.labelServicelevel ?? null,
      labelServicelevelName: this.state.labelServicelevelName ?? null,
      labelApiKey: labelkey,
      labelParcelHeight: this.state.labelParcelHeight,
      labelParcelWidth: this.state.labelParcelWidth,
      labelParcelLength: this.state.labelParcelLength,
      labelParcelWeightUnit: this.state.labelParcelWeightUnit,
      labelParcelDimensionsUnit: this.state.labelParcelDimensionsUnit,
      customsNumberOrExemption: this.state.customsNumberOrExemption,
      labelParcelDefaultWeigth: this.state.labelParcelDefaultWeigth,
      domesticService: this.state.domesticService,
      internationalService: this.state.internationalService,
    };
    if (this.state.labelService === 'easypost') {
      return {
        ...payload,
        carrierListEasyPost: this.state.carrierListEasyPost,
        autoTracking: this.state.autoTracking,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
        enableBuyerAddress: this.state.enableBuyerAddress,
        easypostEnableReturnLabels: this.state.easypostEnableReturnLabels,
        labelEnableQRcode: this.state.labelEnableQRcode,
        enableSameLabelCurrency: this.state.enableSameLabelCurrency,
      };
    }
    if (this.state.labelService === 'shippo') {
      return {
        ...payload,
        shippoScanBasedLabels: this.state.shippoScanBasedLabels,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
        autoTracking: this.state.autoTracking,
      };
    }
    if (this.state.labelService === 'shipstation') {
      const secretkey = this.state.labelApiSecret?.trim();
      return {
        ...payload,
        labelApiSecret: secretkey,
        ratesListShipStation: this.state.ratesListShipStation,
        autoTracking: this.state.autoTracking,
      };
    }
    if (this.state.labelService === 'ups') {
      return {
        ...payload,
        labelCarrierName: null,
        labelServicelevel: null,
      };
    }
    if (this.state.labelService === 'auspost') {
      return {
        ...payload,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
        labelAccountNumber: this.state.labelAccountNumber,
        labelPassword: this.state.labelPassword,
        labelDeliveryService: this.state.labelDeliveryService,
      };
    }
    if (this.state.labelService === 'canadapost') {
      return {
        ...payload,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
        canadapostCustomerNumber: this.state.canadapostCustomerNumber,
        canadapostOption: this.state.canadapostOption,
        canadapostCurrency: this.state.canadapostCurrency,
        canadapostConversionCAD: this.state.canadapostConversionCAD,
      };
    }

    //TODO: temporary payload
    return payload;
  };

  inputFieldValidation = (value) => {
    const regEx = /API|:|;|test|sandbox|api/gi;
    const result = value.match(regEx);
    if (result === null) {
      this.setState({
        errorMessage: true,
      });
    } else {
      this.setState({
        errorMessage: false,
      });
    }
  };

  handleSubmit = async () => {
    const values = this.handlePayload();
    if (!this.state.labelService) {
      return message.error('Shipping Platform is missing.', 5);
    }

    if (!this.state.labelService === 'ups' && !this.state.labelApiKey) {
      return message.error('API key is missing.', 5);
    } else if (this.state.labelService === 'ups' && !this.state.labelApiKey) {
      return message.error('Billing Number is missing.', 5);
    }

    if (this.state.labelService === 'shippo') {
      if (!this.state.labelApiKey) {
        return message.error('API key is missing.', 5);
      }
      if (!this.state.labelSelectCheapestRate && !this.state.labelCarrier) {
        return message.error('Carrier is missing.', 5);
      }
    }
    if (this.state.labelService === 'shipstation') {
      if (!this.state.labelApiSecret) {
        return message.error('API secret is missing.', 5);
      }
      if (!this.state.labelCarrier) {
        return message.error('Carrier is missing.', 5);
      }
    }

    if (this.state.labelService === 'auspost') {
      if (!this.state.labelAccountNumber) {
        return message.error('Account number is missing.', 5);
      }
      if (!this.state.labelPassword) {
        return message.error('Password is missing.', 5);
      }
      if (
        !this.state.labelDeliveryService &&
        !this.state.labelSelectCheapestRate
      ) {
        return message.error('Delivery service is missing.', 5);
      }
    } else if (this.state.errorMessage === false) {
      return message.error('Invalid Key.', 5);
    }

    if (
      this.state.enableBuyerAddress &&
      !this.state.buyerAddressAdded &&
      !this.props.state.buyerEmail
    ) {
      return message.error('Please add buyer address and try again.', 5);
    }

    if (this.state.labelService === 'canadapost') {
      if (!this.state.labelApiKey) {
        return message.error('API key is missing.', 5);
      }
      if (!this.state.canadapostCustomerNumber) {
        return message.error('Customer Number is missing.', 5);
      }
      if (!this.state.canadapostOption) {
        return message.error('Option is missing.', 5);
      }
    }

    if (this.state.labelService === 'easypost') {
      if (!this.state.labelApiKey) {
        return message.error('API key is missing.', 5);
      }
      if (!this.state.labelSelectCheapestRate && !this.state.labelCarrier) {
        return message.error('Carrier is missing.', 5);
      }
    }

    try {
      const response = await axios.post('/api/carrierSettings/update', values);
      if (response.data.status === 'error') {
        return message.error(
          'Error updating your return settings. Please try again.',
          5
        );
      }

      trackFSEvent('Configure prepaid return-labels', {
        feature: 'Return Settings',
        shippingPlatform: this.state.labelService,
        enableAutoTracking: this.state.autoTracking,
        enableScanBasedLabels: this.state.shippoScanBasedLabels || false,
        enableAutomaticChooseTheCheapestRate:
          this.state.labelSelectCheapestRate || false,
        carrierType: this.state.labelCarrier,
        serviceType: this.state.labelServicelevel,
      });
      trackingVitally('rr_prepaid return-labels_configued');
      message.success('Return settings successfully updated.', 5);
      return this.props.showConfigureModal();
    } catch (err) {
      if (err.response.status === 400 && err.response.data?.message) {
        return message.error(
          `${err.response.data?.message}. Please try again.`,
          5
        );
      }
      return message.error(
        'Error updating your return settings. Please try again.',
        5
      );
    }
  };

  saveSettings = async () => {
    const values = this.handlePayload();
    try {
      const response = await axios.post('/api/carrierSettings/update', values);
      if (response?.data?.carrierId) {
        this.setState({
          id: response?.data?.carrierId,
        });
      }
    } catch (err) {
      message.error(
        'Error updating your return settings. Please try again.',
        5
      );
    }
  };

  handleServiceChange = async (value) => {
    this.setState({
      labelService: value,
      labelSelectCheapestRate: false,
      labelCarrier: undefined,
      labelCarrierName: undefined,
      labelServicelevel: undefined,
      labelServicelevelName: undefined,
      labelApiKey: undefined,
      domesticService: null,
      internationalService: null,
    });
  };

  onChangeAttachment = (e) => {
    this.setState({
      enableSameLabelCurrency: e.target.checked,
    });
  };

  buyerAddressStatus = async (value) => {
    this.setState({
      buyerAddressAdded: value,
    });
  };

  render() {
    const handleChange = async (state, value) => {
      if (state === 'labelApiKey') {
        const trimValue = value.trim();
        if (!value) {
          this.setState({
            domesticService: null,
            internationalService: null,
          });
        }
        this.inputFieldValidation(trimValue);
        this.setState({
          [state]: value,
        });
      } else {
        this.setState({
          [state]: value,
        });
      }
    };

    return (
      <React.Fragment>
        <Modal
          width="50%"
          title="Set up your prepaid return-labels"
          visible={true}
          onOk={this.handleSubmit.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          okText="Save"
          cancelText="Close"
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this)}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSubmit.bind(this)}
            >
              Save
            </Button>,
          ]}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col span={16}>
              <Alert
                message="How to set up automated return labels?"
                description={
                  <span>
                    We cover the setup of automated return labels{' '}
                    <a
                      href="https://support.apphub.com/en/articles/6338243-how-to-set-up-automated-return-labels"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here in our Help Docs
                    </a>
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle">
            <Col span={10}>
              <h4>Shipping Platform</h4>
              <p>
                Click on the link at the top to see an overview of supported
                Shipping Platforms and Carriers.
              </p>
            </Col>
            <Col span={10}>
              <Select
                style={{ width: '100%' }}
                defaultValue={this.state.labelService}
                onSelect={this.handleServiceChange.bind(this)}
                disabled={true}
              >
                <Option value="shippers" disabled={true}>
                  -- Shippers --
                </Option>
                <Option value="shippo">Shippo</Option>
                <Option value="shipstation">ShipStation</Option>
                <Option value="easypost">EasyPost</Option>
                <Option value="carriers" disabled={true}>
                  -- Carriers --
                </Option>
                {this.state.upsEnabled ? (
                  <Option value="ups">UPS</Option>
                ) : (
                  <Option
                    value="ups"
                    disabled={true}
                    style={{ padding: 'unset' }}
                  >
                    <Tooltip
                      placement="bottom"
                      title={
                        <div>
                          Integrate UPS from your account to use this service.{' '}
                          <a href={`/dashboard/connect/courier`}>click here</a>
                        </div>
                      }
                    >
                      <div style={{ width: '100%', padding: '5px 12px' }}>
                        UPS
                      </div>
                    </Tooltip>
                  </Option>
                )}
                <Option value="auspost">Australia Post</Option>
                <Option value="canadapost">Canada Post</Option>
              </Select>
              {!this.state.labelApiKey &&
                this.state.labelService === 'easypost' && (
                  <React.Fragment>
                    <br />
                    <br />
                    <span>
                      If you don't have an EasyPost account click{' '}
                      <a
                        href="http://www.easypost.com/signup?utm_source=Rich-Returns"
                        target="_blank"
                      >
                        here
                      </a>
                    </span>
                  </React.Fragment>
                )}
            </Col>
          </Row>
          {/* Config service */}
          {this.state.labelService === 'easypost' ? (
            <EasyPost
              state={this.state}
              labelServicelevel={this.state.labelServicelevel}
              labelService={this.state.labelService}
              autoTracking={this.state.autoTracking}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelApiKey={this.state.labelApiKey}
              autoTrackingWebhookSecret={this.state.autoTrackingWebhookSecret}
              saveSettings={this.saveSettings}
              labelEnableQRcode={this.state.labelEnableQRcode}
              carrierListEasyPost={this.state.carrierListEasyPost}
              easypostEnableReturnLabels={this.state.easypostEnableReturnLabels}
              buyerAddressStatus={this.buyerAddressStatus}
              enableBuyerAddress={this.state.enableBuyerAddress}
              editOrUpdate={
                this.state.enableBuyerAddress &&
                !this.state.buyerAddressAdded &&
                !this.state.buyerEmail
                  ? true
                  : false
              }
            />
          ) : null}
          {this.state.labelService === 'easypost' &&
            this.context.featureLabelReturnCurrency && ( //TODO: Move to easypost component
              <React.Fragment>
                <Row type="flex" justify="space-around" align="middle">
                  <Col span={10}>
                    <h4>Same currency as return</h4>
                    <p>
                      If you want to use same currency in label as in return
                      please enable the checkbox
                    </p>
                  </Col>
                  <Col span={10}>
                    <Checkbox
                      checked={this.state.enableSameLabelCurrency}
                      onChange={(e) => this.onChangeAttachment(e)}
                    >
                      Enable
                    </Checkbox>
                  </Col>
                </Row>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
              </React.Fragment>
            )}

          {this.state.labelService === 'shippo' ? (
            <Shippo
              labelServicelevel={this.state.labelServicelevel}
              labelServicelevelName={this.state.labelServicelevelName}
              labelService={this.state.labelService}
              autoTracking={this.state.autoTracking}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelCarrierName={this.state.labelCarrierName}
              labelApiKey={this.state.labelApiKey}
              autoTrackingWebhookSecret={this.state.autoTrackingWebhookSecret}
              shippoScanBasedLabels={this.state.shippoScanBasedLabels}
              saveSettings={this.saveSettings}
            />
          ) : null}

          {this.state.labelService === 'shipstation' ? (
            <ShipStation
              labelServicelevel={this.state.labelServicelevel}
              labelService={this.state.labelService}
              labelApiSecret={this.state.labelApiSecret}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelApiKey={this.state.labelApiKey}
              ratesListShipStation={this.state.ratesListShipStation}
              carrierListShipStation={this.state.carrierListShipStation}
              autoTrackingWebhookSecret={this.state.autoTrackingWebhookSecret}
              autoTracking={this.state.autoTracking}
              saveSettings={this.saveSettings}
            />
          ) : null}
          {this.state.labelService === 'ups' ? (
            <UPS
              domesticService={this.state.domesticService}
              internationalService={this.state.internationalService}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelApiKey={this.state.labelApiKey}
            />
          ) : null}

          {this.state.labelService === 'auspost' ? (
            <AusPost
              labelAccountNumber={this.state.labelAccountNumber}
              labelApiKey={this.state.labelApiKey}
              labelPassword={this.state.labelPassword}
              labelDeliveryService={this.state.labelDeliveryService}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              saveSettings={this.saveSettings}
            />
          ) : null}

          {this.state.labelService === 'canadapost' ? (
            <CanadaPost
              state={this.state}
              labelServicelevel={this.state.labelServicelevel}
              labelServicelevelName={this.state.labelServicelevelName}
              labelService={this.state.labelService}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelApiKey={this.state.labelApiKey}
              saveSettings={this.saveSettings}
              canadapostCustomerNumber={this.state.canadapostCustomerNumber}
              canadapostOption={this.state.canadapostOption}
              canadapostCurrency={this.state.canadapostCurrency}
              canadapostConversionCAD={this.state.canadapostConversionCAD}
            />
          ) : null}

          {/* Custom demensions and weight */}
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <ReturnLabelDimensions
            labelParcelDimensionsUnit={this.state.labelParcelDimensionsUnit}
            handleChange={handleChange}
            labelParcelLength={this.state.labelParcelLength}
            labelParcelWidth={this.state.labelParcelWidth}
            labelParcelHeight={this.state.labelParcelHeight}
          />
          <ReturnLabelWeight
            handleChange={handleChange}
            labelParcelWeightUnit={this.state.labelParcelWeightUnit}
            labelParcelDefaultWeigth={this.state.labelParcelDefaultWeigth}
          />
          {!this.context.featureCustomsForms ? null : (
            <ReturnLabelCustoms
              handleChange={handleChange}
              customsNumberOrExemption={this.state.customsNumberOrExemption}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ReturnLabel;
