/**
 * Component to register enable ShopMinis integration.
 *
 * @component
 */

import React from 'react';
import { Modal, Input, message } from 'antd';
import axios from 'axios';
import { trackFSEvent } from '../helpers/fullstory';
import { Checkbox } from '../../node_modules/antd/lib/index';
import { trackingVitally } from '../helpers/vitally';

class ShopMinisModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandSettings: {
        isShopminisExtensionEnabled: false,
      },
      confirmLoading: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    try {
      await this.fetchBrandSettings();
      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      message.error('Error loading brand settings. Please try again.', 4);
    }
  };

  fetchBrandSettings = async () => {
    try {
      const response = await axios.get('/api/brandSettings');
      if (response.data.status === 'error') {
        return message.error(
          'Error retrieving your brand settings. Try again.',
          5
        );
      }
      if (response.data == null) {
        return;
      }

      this.setState({
        brandSettings: {
          ...response.data,
        },
      });
    } catch (err) {
      console.log(err);
      message.error('Error retrieving your brand settings. Try again.', 5);
    }
  };

  handleChange = (e) => {
    this.setState({
      brandSettings: {
        ...this.state.brandSettings,
        isShopminisExtensionEnabled: e.target.checked,
      },
    });
  };

  handleOk = async () => {
    this.setState({
      confirmLoading: true,
    });
    trackFSEvent('Edit Brand Settings', {
      feature: 'Brand Settings',
      ...this.state.brandSettings,
    });
    trackingVitally('rr_shopmini_integrated');
    try {
      const response = await axios.post(
        '/api/brandSettings/update',
        this.state.brandSettings
      );
      if (response.data.status === 'error') {
        return message.error('Error saving the data. Please check again.', 5);
      }
      message.success('Settings saved successfully.', 4);
      this.setState({
        ...response.data,
      });
    } catch (err) {
      return message.error('Error saving the data. Please try again.', 5);
    } finally {
      this.setState({
        confirmLoading: false,
      });
      this.props.modalVisible(null);
    }
  };

  render() {
    return (
      <Modal
        title="Enable Shop minis extension"
        visible={true}
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={() => this.props.modalVisible(null)}
      >
        <div
          className="flex-row flex-row--noMargin"
          style={{
            width: '100%',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <Checkbox
            disabled={this.state.loading || this.state.confirmLoading}
            checked={this.state.brandSettings.isShopminisExtensionEnabled}
            onChange={(e) => this.handleChange(e)}
          >
            Enable Shop Minis extension
          </Checkbox>
          {/* Commented because Shopify team requested us to hardcode the button label for now */}
          {/* <FormItem>
            {getFieldDecorator('shopminisExtensionButtonLabel', {
              initialValue: this.state.shopminisExtensionButtonLabel,
            })(
              <Input placeholder="Start a return" />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('shopminisReturnInProgressLabel', {
              initialValue: this.state.shopminisReturnInProgressLabel,
            })(
              <Input placeholder="Return Requested" />
            )}
          </FormItem> */}
        </div>
      </Modal>
    );
  }
}

export default ShopMinisModal;
