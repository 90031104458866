/**
 * Component for Store-Wide-Exchanges settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  message,
  Row,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { trackingVitally } from '../../../../helpers/vitally';
const FormItem = Form.Item;
const { Panel } = Collapse;

class StoreWideExchanges extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      exchangesEnableHighPriced: false,
      storeWideOverselling: false,
      storeWideBalance: false,
      storeWideDiscount: false,
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      const data = response.data;
      if (!data) {
        this.setState({ loading: false });
        return;
      }
      this.setState({
        ...data,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your return settings. Try again.', 5);
    }

    if (this.props.setSaveHandler) {
      this.props.setSaveHandler(this.handleSubmit);
    }
  }

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      if (this.state.storeWideDiscount && values.storeWideBalance) {
        values = {
          ...values,
          storeWideDiscount: false,
        };
      }
      trackFSEvent('Change Store-Wide Exchanges', {
        feature: 'Exchanges',
        ...values,
      });
      trackingVitally('rr_storewideexchanges_changed');
      try {
        const response = await axios.post('/api/returnSettings/update', values);
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your exchange settings. Please try again.',
            5
          );
        }
        this.setState({
          ...response.data,
        });
        return message.success('Return settings successfully updated.', 5);
      } catch (err) {
        console.log('Error updating your exchange settings', err);
        return message.error(
          'Error updating your exchange settings. Please try again.',
          5
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    if (this.context.featureStoreWideExchange === undefined) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {!this.context.featureStoreWideExchange ? (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Alert
                  message="Already on our Plus Plan?"
                  description={
                    <React.Fragment>
                      <span>
                        Please reach out to us directly in order to have this
                        feature enabled and set up for your account.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="top"
              className="u-marginBottom--lg u-marginTop--md"
            >
              <Col span={22}>
                <Alert
                  message="Important"
                  description={
                    <React.Fragment>
                      <span>
                        Make sure to enable the 'Store-Wide Exchange' option in
                        the general{' '}
                        <Link to="/dashboard/settings">
                          Configuration-Returns tab
                        </Link>{' '}
                        or in the Advanced Rules.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-around"
              align="top"
              className="u-marginBottom--md"
            >
              <Col span={22}>
                <p>
                  Let customers exchange for any product from all over the
                  store.
                </p>
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmit} className="removeFormMargin">
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={10}>
                  <h4>Enable Higher Price Products</h4>
                  <span>
                    Please check this box to enable customers to exchange items
                    for higher-value products.
                  </span>
                </Col>

                <Col xs={22} sm={22} md={22} lg={10}>
                  <FormItem>
                    {getFieldDecorator('exchangesEnableHighPriced')(
                      <Checkbox
                        checked={this.state.exchangesEnableHighPriced}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={10}>
                  <h4>Force overselling</h4>
                  <span>
                    By default we look at each product variant and check if the
                    option "Continue selling when out of stock" is enabled in
                    your Shopify store. Only then is a variant shown as being
                    available when there is no inventory. Enabling 'Force
                    overselling' will disregard this setting in Shopify and
                    always show variants as available for exchange.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={10}>
                  <FormItem>
                    {getFieldDecorator(
                      'storeWideOverselling',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.storeWideOverselling}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={10}>
                  <h4>Hide Balance message</h4>
                  <span>
                    Check this box if you want to hide the store-wide exchange
                    balance message.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={10}>
                  <FormItem>
                    {getFieldDecorator(
                      'storeWideBalance',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.storeWideBalance}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              {!this.state.storeWideBalance && (
                <React.Fragment>
                  <Row
                    type="flex"
                    justify="space-around"
                    align="middle"
                    className="u-rowGap--sm"
                  >
                    <Col xs={22} sm={22} md={22} lg={10}>
                      <h4>Store-Wide discount calculation</h4>
                      <span>
                        Check this box if you want to apply discount calculation
                        for the store-wide exchange balance message.
                      </span>
                    </Col>
                    <Col xs={22} sm={22} md={22} lg={10}>
                      <FormItem>
                        {getFieldDecorator(
                          'storeWideDiscount',
                          {}
                        )(
                          <Checkbox
                            checked={this.state.storeWideDiscount}
                            onChange={(e) => this.handleChange(e)}
                          >
                            Enable
                          </Checkbox>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </React.Fragment>
              )}

              <Row
                type="flex"
                justify="space-around"
                align="top"
                className="u-marginTop--lg"
              >
                <Col span={22}>
                  <FormItem>
                    <Row type="flex" justify="end" align="top">
                      <Button type="primary" htmlType="submit">
                        Save changes
                      </Button>
                    </Row>
                  </FormItem>
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-marginTop--lg"
              >
                <Col span={22}>
                  <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--sm u-marginBottom--md">
                    <div className="element-icon-component element-icon-component--transparent element-icon-component--sm">
                      <svg
                        width="53"
                        height="53"
                        viewBox="0 0 53 53"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M33.1186 24.7453C31.0993 21.8639 31.9005 20.1441 32.0948 18.2219C32.7352 11.9034 36.0168 11.1897 37.6139 12.5094C38.0308 12.8539 36.7182 14.7319 36.8666 16.374C37.1466 19.4604 40.3813 22.7746 37.9433 24.104C35.7112 25.3213 33.7484 25.6437 33.1186 24.7453Z"
                          fill="#5CD3CB"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M46.5224 42.687C43.421 43.2656 35.5894 42.9228 32.0287 42.2444C29.8751 41.8337 28.5536 38.3719 30.1542 38.5618C32.9632 38.8948 44.1701 37.1282 44.0879 34.8059C43.9943 32.1488 47.6354 32.0587 47.6486 37.5566C47.6513 38.8816 48.9259 42.2391 46.5224 42.687Z"
                          fill="#FEEF7C"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.8759 12.6335C20.7707 9.80684 14.189 9.35546 12.0575 9.19381C4.11284 8.59403 5.62069 13.046 6.39802 14.8498C7.07642 16.4265 8.81306 14.6272 10.1195 14.1449C11.759 13.5407 13.5848 13.8852 14.3304 13.9258C16.1403 14.0239 21.0101 16.2331 20.8759 12.6335Z"
                          fill="#5CD3CB"
                        ></path>
                        <path
                          d="M9.38441 11.4486C9.00723 11.4018 8.62828 11.5184 8.34385 11.7702C8.05941 12.0219 7.896 12.3832 7.896 12.763C7.896 17.7645 7.896 33.8067 7.896 38.2843C7.896 38.953 8.3942 39.5165 9.05758 39.5996C13.166 40.111 26.7684 41.8035 26.7684 41.8035C26.7684 41.8035 40.3709 40.111 44.4792 39.5996C45.1426 39.5165 45.6408 38.953 45.6408 38.2843C45.6408 33.8067 45.6408 17.7645 45.6408 12.763C45.6408 12.3832 45.4774 12.0219 45.193 11.7702C44.9085 11.5184 44.5296 11.4018 44.1524 11.4486C39.696 12.0025 26.7684 13.6119 26.7684 13.6119C26.7684 13.6119 13.8408 12.0025 9.38441 11.4486Z"
                          stroke="black"
                          strokeWidth="1.325"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M34.3659 13.0386L34.3633 23.8073L36.9567 22.7287L39.6633 23.8523L39.6624 12.5898"
                          stroke="black"
                          strokeWidth="1.325"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M27.7539 13.9131V40.4131"
                          stroke="black"
                          strokeWidth="1.325"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>

                    <div className="TextHeading TextHeading--xxs">
                    Helpful guides
                    </div>
                  </div>
                </Col>
                <Col span={22}>
                  <Collapse>
                    <Panel header={<strong>Top tips</strong>} key="1">
                      <p>
                        <strong>Good to know:</strong>
                      </p>
                      <p>
                        Store-Wide Exchanges are always assumed as even
                        exchanges which means that the customer is not
                        automatically charged/refunded for a price difference. 
                        If you want to charge customers for the price difference,
                        check out our{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://support.richreturns.io/en/articles/9272043-stripe-integration"
                        >
                          Stripe Integration.
                        </a>
                      </p>
                      <p>
                        While you can offer exchanges for cheaper/more expensive items, 
                        you need to manually charge or refund the price difference in 
                        this case unless you have your Stripe Integration set up.
                      </p>
                      <p>
                        We are checking the live inventory levels for the
                        selected product on your Shopify-store while the
                        customer chooses an exchange
                      </p>
                      <p>
                        Read{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://support.richreturns.io/en/articles/9272489-how-is-inventory-handled-variant-exchanges-one-click-exchange-orders"
                        >
                          here
                        </a>{' '}
                        in our documentation when and how inventory is handled.
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const WrappedStoreWideExchange = Form.create()(StoreWideExchanges);
export default WrappedStoreWideExchange;